import React from "react";
// import PropTypes from "prop-types";
import "./YoutubeEmbed.css";

const YoutubeEmbed = ({ source }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={source}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

// YoutubeEmbed.propTypes = {
//   embedId: PropTypes.string.isRequired,
// };

export default YoutubeEmbed;
