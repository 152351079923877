import React, {useEffect, useState} from "react";
import {IconList} from "../../constants/IconList";
import GlobalModal from "../Common/Modal/GlobalModal";
import {connect} from "react-redux";
import {updateStyle} from "../../actions/formBuilderAction";
import {FilledInput, FormControl, InputAdornment, NativeSelect} from "@material-ui/core";
import If from 'if-else-react';
import Utils from "../../helpers/Utils";
import CoreConstants from "../../constants/CoreConstants";
import CustomSwitch from "../Common/Switch";
import {styleFormSubmit} from "../../api/formBuilderApi";
import { confirmAlert } from "react-confirm-alert";

const FormStyle = props => {
    const [formStyle, setFormStyle] = useState(props.formStyle);

    useEffect(() => {
        let styles = {...props.formStyle}
        if(styles.form_page_box_shadow === undefined) {
            styles.form_page_box_shadow = 1;
        }
        setFormStyle(styles);
    },[props.formStyle]);

    const formStyleSubmit = () => {
        let formData = new FormData();

        if (formStyle.landing_page_bg_type == CoreConstants.LANDING_PAGE_BG_IMAGE) {
            let file_data = document.getElementById('landing-page-bg-file').files;
            if (file_data[0]) {
                formData.append("landing-page-bg-file", file_data[0]);
            }
        }

        formData.append("form_id", props.formDetails.id);
        formData.append("form-width", formStyle.form_width);
        formData.append("font-size", formStyle.font_size);
        formData.append("form-bg", formStyle.form_bg);
        formData.append("form-text-color", formStyle.form_text_color);
        formData.append("submit-button-text", formStyle.submit_button_text);
        formData.append("submit-button-color", formStyle.submit_button_color);
        formData.append("submit-button-bg", formStyle.submit_button_bg);
        formData.append("submit-button-width", formStyle.submit_button_width);
        formData.append("landing-page-top-logo", formStyle.landing_page_top_logo);
        formData.append("landing-page-bg-type", formStyle.landing_page_bg_type);
        formData.append("landing-page-bg-color", formStyle.landing_page_bg);
        formData.append("landing-page-bg-color", formStyle.landing_page_bg);
        formData.append("form-page-box-shadow", formStyle.form_page_box_shadow);

        styleFormSubmit(formData)
            .then(response => {
                if (response.data.status === 'success') {
                    Utils.showNotification(response.data.html, 'success');
                    props.updateStyle({formStyle: formStyle});
                    props.setOpenStyleModal(false);
                    confirmAlert({
                        title: 'To activate the effect, you must click the "Save Form" button.',
                        buttons: [
                            {
                                label: 'Ok',
                                onClick: () => {}
                            }
                        ]
                    });
                } else {
                    Utils.showNotification(response.data.html, 'error');
                }
            })
            .catch(error => {
                Utils.handleException(error);
            })
    }

    const formStyleSubmitCallback = response => {
        if (response.status === 'success') {
            Utils.showNotification(response.html, 'success');
            props.updateStyle({formStyle: formStyle});
            props.setOpenStyleModal(false);
        } else {
            Utils.showNotification(response.html, 'error');
        }
    }

    const handleLandingPageTopLogo = value => {
        setFormStyle({...formStyle, landing_page_top_logo: value});
    }

    const handleLandingPageBoxShaodow = value => {
        setFormStyle({...formStyle, form_page_box_shadow: value});
    }


    return (
        <React.Fragment>
            <GlobalModal open={props.openStyleModal} onClose={() => props.setOpenStyleModal(false)} title={"Form Style"} buttonText={"Save Style"} buttonIcon={IconList.copyIcon} buttonClick={formStyleSubmit}>
                <form action="/">
                    <h5 className={"mt-0 mb-4"}>Form Style</h5>
                    <div className="row mb-0">
                        <div className="input-field my-0 col s6 pl-0 pp_form_style">
                            <FormControl variant="filled" style={{minWidth: '100%'}}>
                                <label htmlFor="campaign-link">Width</label>
                                <FilledInput
                                    id="filled-adornment-weight"
                                    value={formStyle.form_width}
                                    onChange={(e) => setFormStyle({...formStyle, form_width: e.target.value})}
                                    endAdornment={<InputAdornment position="end">PX</InputAdornment>}
                                    aria-describedby="filled-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className="input-field my-0 col s6 pl-0 pp_form_style">
                            <FormControl variant="filled" style={{minWidth: '100%'}}>
                                <label htmlFor="campaign-link">Font Size</label>
                                <FilledInput
                                    id="filled-adornment-weight"
                                    value={formStyle.font_size}
                                    onChange={(e) => setFormStyle({...formStyle, font_size: e.target.value})}
                                    endAdornment={<InputAdornment position="end">PX</InputAdornment>}
                                    aria-describedby="filled-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field my-0 col s6 pl-0">
                            <label htmlFor="campaign-link">Form Background </label>
                            <div className="input-color-picker">
                                <label for="formBg">
                                  <input id="formBg" type="color" value={formStyle.form_bg} onChange={(e) => setFormStyle({...formStyle, form_bg: e.target.value})}/>
                                   <span>{formStyle.form_bg}</span>
                                </label>
                            </div>
                        </div>
                        <div className="input-field my-0 col s6 pl-0">
                            <label htmlFor="campaign-link">Text Color</label>
                            <div className="input-color-picker">
                                <label htmlFor="formTextColor">
                                    <input id="formTextColor" type="color" value={formStyle.form_text_color}
                                           onChange={(e) => setFormStyle({
                                               ...formStyle,
                                               form_text_color: e.target.value
                                           })}/>
                                    <span>{formStyle.form_text_color}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5 mt-4">
                        <div className="input-field my-0 col s12 pl-0">
                            <div className="pp_switcher_field">

                                <span className="pr-2">Box Shadow</span>
                                <CustomSwitch 
                                    status={formStyle.form_page_box_shadow} 
                                    brandColor={"#f94f72"} 
                                    onChange={handleLandingPageBoxShaodow}
                                />
                            </div>
                        </div>
                    </div>

                    <h5>Submit Button </h5>
                    <div className="row mb-0 mt-4">
                        <div className="input-field my-0 col s6 pl-0">
                            <label htmlFor="campaign-link">Text</label>
                            <input type="text" value={formStyle.submit_button_text} onChange={(e) => setFormStyle({...formStyle, submit_button_text: e.target.value})}/>
                        </div>
                        <div className="input-field my-0 col s6 pl-0">
                            <label htmlFor="campaign-link">Text Color</label>
                            <div className="input-color-picker">
                                <label htmlFor="textColor">
                                    <input id="textColor" type="color" value={formStyle.submit_button_color}
                                           onChange={(e) => setFormStyle({
                                               ...formStyle,
                                               submit_button_color: e.target.value
                                           })}/>
                                    <span>{formStyle.submit_button_color}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="input-field my-0 col s6 pl-0">
                            <label htmlFor="campaign-link">Background</label>
                              <div className="input-color-picker">
                               <label for="clBg">
                                <input id="clBg" type="color" value={formStyle.submit_button_bg} onChange={(e) => setFormStyle({...formStyle, submit_button_bg: e.target.value})}/>
                                <span>{formStyle.submit_button_bg}</span>
                                </label>

                              </div>
                        </div>
                        <div className="input-field my-0 col s6 pl-0">
                            <label htmlFor="campaign-link">Width</label>
                            <input type="number" value={formStyle.submit_button_width} onChange={(e) => setFormStyle({...formStyle, submit_button_width: e.target.value})}/>
                        </div>
                    </div>

                    <h5>Landing Page</h5>
                    <div className="row mb-4 mt-4">
                        <div className="input-field my-0 col s12 pl-0">
                            <div className="pp_switcher_field">

                                <span className="pr-2">Top Agency Logo</span>
                                <CustomSwitch 
                                    status={formStyle.landing_page_top_logo} 
                                    brandColor={"#f94f72"} 
                                    onChange={handleLandingPageTopLogo}
                                />
                                {/* <Switch onChange={handleLandingPageTopLogo} checked={formStyle.landing_page_top_logo}/> */}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-0">
                        <div className="input-field my-0 col s6 pl-0">
                            <NativeSelect
                            className="pp_select_field"
                                value={formStyle.landing_page_bg_type}
                                name="selectName"
                                onChange={(e) => setFormStyle({...formStyle, landing_page_bg_type: e.target.value})}
                                inputProps={{ 'aria-label': 'selectName' }}
                            >
                                <option selected={formStyle.landing_page_bg_type == CoreConstants.LANDING_PAGE_BG_COLOR} value={CoreConstants.LANDING_PAGE_BG_COLOR}>Background Color</option>
                                <option selected={formStyle.landing_page_bg_type == CoreConstants.LANDING_PAGE_BG_IMAGE} value={CoreConstants.LANDING_PAGE_BG_IMAGE}>Background Image</option>
                            </NativeSelect>
                        </div>
                        <div className="input-field my-0 col s6 pl-0">
                            <If condition={formStyle.landing_page_bg_type == CoreConstants.LANDING_PAGE_BG_COLOR}>
                            <div className="input-color-picker">
                                <label for="landingPageBg">
                                   <input id="landingPageBg" type="color" value={formStyle.landing_page_bg} onChange={(e) => setFormStyle({...formStyle, landing_page_bg: e.target.value})} />
                                    <span>{formStyle.landing_page_bg}</span>
                                </label>
                            </div>
                            </If>
                            <If condition={formStyle.landing_page_bg_type == CoreConstants.LANDING_PAGE_BG_IMAGE}>

                                <input type="file" id="landing-page-bg-file" onChange={(e) => {document.getElementById('landing_page_bg_preview').src = window.URL.createObjectURL(e.target.files[0])}} />
                            </If>
                        </div>
                    </div>
                    <If condition={formStyle.landing_page_bg_type == CoreConstants.LANDING_PAGE_BG_IMAGE}>
                        <div className="row mb-0">
                            <div className="input-field my-0 col s12 pl-0">
                                <img id="landing_page_bg_preview" className="img-fluid pt-3" alt="Background" width="400" src={`${Utils.getAccountData('baseUrl')}/upload/formBuilder/bg/${Utils.getAccountData('userId')}/${formStyle.landing_page_bg}`} />
                            </div>
                        </div>
                    </If>
                </form>
            </GlobalModal>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        formStyle: state.formBuilderReducer.formStyle,
        formDetails: state.formBuilderReducer.formDetails,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // styleFormSubmit: (params, callback) => dispatch(styleFormSubmit(params, callback)),
        updateStyle: (params) => dispatch(updateStyle(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormStyle);