import React, {useEffect, useState} from 'react';
import { IconList } from '../../constants/IconList';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import FormBuilder from "./FormBuilder";
import SectionTop from "./SectionTop";
import { fetchFormDetails } from '../../actions/formBuilderAction'
import {connect} from "react-redux";
import Empty from "../Common/Empty";
import If, {Else} from "if-else-react";
import PreviewAndState from "./PreviewAndState";
import Utils from "../../helpers/Utils";
import ListSkeleton from "../Common/Skeletons/ListSkeleton";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    }
}));

const EditFormBuilder = props => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panel2');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        //update page title
        document.title = `Form Setting | ${Utils.getAccountData('AuthUserAgencyName')}`;

        props.fetchFormDetails({uniqueCode: props.match.params.uniqueCode}, fetchFormDetailsResponse);
    }, []);

    const fetchFormDetailsResponse = (response) => {
        if (response.status === 'success') {
            setLoading(false);
        } else {
            setError(response.html);
        }
    }

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <React.Fragment>
            <If condition={error !== ''}>
                <Empty title={error}/>
            <Else />
                {/*<If condition={loading}>*/}
                {/*    <Loading size={50}/>*/}
                {/*<Else />*/}
                    <div className="systemform__sections">
                        {loading ?
                            <div className="section__top">
                                <div className="section__top__left">
                                    <ListSkeleton width={200}/>
                                </div>
                                <div className="section__top__right d-flex justify-content-end">
                                    <ListSkeleton width={200}/>
                                </div>
                            </div>
                            :
                            <SectionTop />
                        }

                        <div className="accroding__wr">
                            <div className={classes.root}>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={expanded === 'panel1' ? IconList.minusIcon : IconList.plusIcon}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className={classes.heading}>Preview and Stats</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {!loading &&
                                            <PreviewAndState/>
                                        }
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={expanded === 'panel2' ? IconList.minusIcon : IconList.plusIcon}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography>
                                            Form Builder <small className="ml-3">(Click or drag any field to add it to your form. ★ Fields are default system supported fields, should use single time in one form)</small>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {!loading &&
                                            <FormBuilder/>
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>

                    </div>
                {/*</If>*/}
            </If>
        </React.Fragment>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        fetchFormDetails: (params, callback) => dispatch(fetchFormDetails(params, callback))
    }
}

export default connect(null, mapDispatchToProps)(EditFormBuilder);