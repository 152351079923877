import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import Tippy from "@tippyjs/react";
import "tippy.js/animations/shift-toward.css";
import "tippy.js/dist/tippy.css"; // optional
import "tippy.js/themes/light.css";
import "./EditPageSubmitButton.css";
import useDelayCallback from "../../../../hooks/useDelayCallback";
import { useIsMounted } from "../../../../hooks/IsMounted";
import { FontSelectOptions } from "../../../Common/FontSelectOptions";

const ButtonSettingContent = (props) => {

  const isMounted = useIsMounted();
  const [backgroundColor, setBackgroundColor] = useState(props.btnInfo.buttonBackground ? props.btnInfo.buttonBackground : "#000");
  const [textColor, setTextColor] = useState(props.btnInfo.buttonTextColor ? props.btnInfo.buttonTextColor : "#fff");

  //Reducing rerenders on color changing
  useDelayCallback(() => {
    if(isMounted) {
      updateFields({buttonBackground : backgroundColor})
    }
  },[backgroundColor],500);

  //Reducing rerenders on color changing
  useDelayCallback(() => {
    if(isMounted) {
      updateFields({buttonTextColor : textColor})
    }
  },[textColor],500);

  const updateFields = (obj) => {
    const fields = [...props.fields];
    fields[props.btnInfo.index] = {...fields[props.btnInfo.index],...obj};
    props.updateFields(fields);
  }

  return <>
  <div className="button-input-field">
      <label>Button label</label>
      <input
        type="btnInfo"
        value={props.btnInfo.placeholder}
        onChange={e => updateFields({placeholder : e.target.value})}
      />
  </div>
  <div className="button-input-field mt-1">
      <label>Font style</label>
      <select className="d-block" 
        value={props.btnInfo.fontFamily ? props.btnInfo.fontFamily : "Arial, sans-serif"}
        onChange={e => updateFields({fontFamily : e.target.value})}>
         <FontSelectOptions/>
      </select>
  </div>
  <div className="button-input-field mt-1">
      <label>Button alignment</label>
      <select className="d-block" 
        value={props.btnInfo.alignment ? props.btnInfo.alignment : "left"}
        onChange={e => updateFields({alignment : e.target.value})}>
        <option value={"left"}>Left</option>
        <option value={"center"}>Center</option>
        <option value={"right"}>Right</option>
      </select>
  </div>
  <div className="button-input-field mt-1">
      <label>Button size</label>
      <select className="d-block" 
        value={props.btnInfo.buttonSize ? props.btnInfo.buttonSize : "small"}
        onChange={e => updateFields({buttonSize : e.target.value})}>
        <option value={"small"}>Small</option>
        <option value={"medium"}>Medium</option>
        <option value={"large"}>Large</option>
      </select>
  </div>
  <div className="button-input-field mt-1">
      <label>Button background color</label>
      <input type="color" value={backgroundColor} style={{backgroundColor: backgroundColor}} onChange={e => setBackgroundColor(e.target.value)}/>
  </div>
  <div className="button-input-field mt-1">
      <label>Button text color</label>
      <input type="color" value={textColor} style={{backgroundColor: textColor}} onChange={e => setTextColor(e.target.value)}/>
  </div>
  </>
}

const EditPageSubmitButton = (props) => {

  const content = <ButtonSettingContent {...props}/>
  const buttonStyle = {
    backgroundColor : props.btnInfo.buttonBackground ? props.btnInfo.buttonBackground : "#000",
    color : props.btnInfo.buttonTextColor ? props.btnInfo.buttonTextColor : "#fff",
    fontFamily : props.btnInfo.fontFamily ? props.btnInfo.fontFamily : "'Roboto', 'Helvetica', 'Arial', sans-serif"
  }
  
  return (
    <>
      <div className="action__button__submit ar__action_button_submit">
        <Tippy
          className="action__button__submit__setting ar__tippy"
          trigger={"click"}
          hideOnClick={true}
          animation={"shift-toward"}
          theme={"light"}
          placement={"right"}
          content={ content }
          interactive={true}
        >
          <Button variant="light">
            <FontAwesomeIcon icon={["fas", "cog"]} />
          </Button>
        </Tippy>
        <Tippy
          className="ar__tippy"
          trigger={"click"}
          hideOnClick={true}
          animation={"shift-toward"}
          theme={"light"}
          placement={"right"}
          content={ content }
          interactive={true}
        >
          <Button className="ar__send_button" variant="dark" style={buttonStyle}>
            <span className="mr-10">{props.btnInfo.placeholder}</span>
            <FontAwesomeIcon icon={["fas", "angle-right"]} />
          </Button>
        </Tippy>
      </div>
    </>
  );
}

export default EditPageSubmitButton;
