import React, {useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import If from "if-else-react";
import Button from "@material-ui/core/Button";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import {Slide} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
    },
}));

const PreviewForm = props => {
    const classes = useStyles();
    const [openPreviewModal, setOpenPreviewModal] = useState(false);

    return (
        <div className="form__top__left">
            <If condition={props.builderHtml}>
                <Button
                    className="off__icon"
                    onClick={() => setOpenPreviewModal(true)}
                    startIcon={<VisibilityOffIcon />}
                >
                    Preview
                </Button>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openPreviewModal}
                    onClose={setOpenPreviewModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 1000,
                    }}
                >
                    <Slide in={openPreviewModal} direction="left">
                        <div className="globalModalWrapper cardBordcastTab createNewForm" style={{minWidth: (props.width !== undefined ? props.width : 700)}}>
                            <div id="globalModal">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h1></h1>
                                    <div onClick={() => setOpenPreviewModal(false)} className="share_campaign_cross_button d-flex justify-content-center align-items-center modal-close">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M15.1252 17.0812L17.1357 15.0693L6.878 4.80485L4.86747 6.8167L15.1252 17.0812Z"
                                                fill="white"/>
                                            <path
                                                d="M6.878 17.0838L17.1357 6.81934L15.1252 4.80749L4.86747 15.072L6.878 17.0838Z"
                                                fill="white"/>
                                        </svg>
                                    </div>
                                </div>

                                {/* Modal Body */}

                                <div className="modalBody">
                                    <div dangerouslySetInnerHTML={{ __html: props.builderHtml }} />
                                </div>
                            </div>
                        </div>
                    </Slide>
                </Modal>
            </If>
        </div>
    );
}

export default PreviewForm;