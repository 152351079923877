import React, { useRef, useState } from "react";
import { RepositionView } from "./RepositionView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setCustomFormBuilderValue, setCustomFormSaveVisible } from "../../../../actions/customFormBuilderAction";
import GlobalDropdown, { GlobalDropDownButton, GlobalDropDownItem } from "../../../Common/Dropdown/GlobalDropdown";
import { confirmAlert } from "react-confirm-alert";
import { HeightChangeSettingView } from "./HeightChangeSettingView";

const DEFAULT_COVER_HEIGHT = 170;
const DEFAULT_COVER_POSITION = 50

export const CoverImagePreview = (props) => {

    const dispatch = useDispatch();
    const [isRepositionEnabled,setIsRepositionEnabled] = useState(false);
    const [isHeightSettingEnabled,setIsHeightSettingEnabled] = useState(false);
    const [isVisibleRepositionInfo,setVisibleRepositionInfo] = useState(false);
    const [positionPercent,setPositionPercent] = useState(props.builderJson?.coverPosition ? props.builderJson.coverPosition : DEFAULT_COVER_POSITION);
    const [coverHeight,setCoverHeight] = useState(props.builderJson?.coverHeight ? props.builderJson.coverHeight : DEFAULT_COVER_HEIGHT);
    const yPos = useRef(0);

    const handleDragStart = (e) => {
        setVisibleRepositionInfo(false);
        return false;
    }

    const handleDrag = (e) => {

        if(!isRepositionEnabled) {
            return "";
        }

        if(yPos.current !== 0 && e.screenY !== 0) {
            if(yPos.current > e.screenY && positionPercent < 100) {
                setPositionPercent(currentPositionPercent => currentPositionPercent + 1);
            } else if(yPos.current < e.screenY && positionPercent > 0) {
                setPositionPercent(currentPositionPercent => currentPositionPercent - 1);
            }
        }
        yPos.current = e.screenY;
    }

    const handleReposition = (status) => {
        setIsRepositionEnabled(status);
        setVisibleRepositionInfo(status);
    }

    const handleDelete = () => {
        confirmAlert({
            title: 'Confirm to delete the cover?',
            message: 'You will not able to undo it.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(setCustomFormBuilderValue({
                            key : "cover",
                            value : null
                        }));
                        dispatch(setCustomFormSaveVisible(true));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    }

    const handleSet = (key,value) => {
        dispatch(setCustomFormBuilderValue({
            key : key,
            value : value
        }));
        dispatch(setCustomFormSaveVisible(true));
        handleReposition(false);
        setIsHeightSettingEnabled(false);
    }

    const resetPosition = () => {
        setPositionPercent(props.builderJson?.coverPosition ? props.builderJson.coverPosition : DEFAULT_COVER_POSITION);
        handleReposition(false)
    }
    
    const renderRepositionButtons = () => {
        return (
            <>
            <Button
                variant="light"
                onClick={() => resetPosition()}
                className="mr-2"
                >
                <FontAwesomeIcon icon={["fas", "times"]} /> Cancel
            </Button>
            <Button
                variant="light"
                onClick={() => handleSet("coverPosition",positionPercent)}
            >
                <FontAwesomeIcon icon={["fas", "check"]} /> Set
            </Button>
            </>
        );
    }

    const resetHeight = () => {
        setCoverHeight(props.builderJson?.coverHeight ? props.builderJson.coverHeight : DEFAULT_COVER_HEIGHT);
        setIsHeightSettingEnabled(false)
    }

    const renderHeightChangeButtons = () => {
        return (
            <>
            <Button
                variant="light"
                onClick={() => resetHeight()}
                className="mr-2"
                >
                <FontAwesomeIcon icon={["fas", "times"]} /> Cancel
            </Button>
            <Button
                variant="light"
                onClick={() => handleSet("coverHeight",coverHeight)}
            >
                <FontAwesomeIcon icon={["fas", "check"]} /> Set
            </Button>
            </>
        );
    }

    const renderNormalButtons = () => {
        return (
            <GlobalDropdown>
                <GlobalDropDownButton>
                    <Button
                        variant="light"
                        className="mr-2">
                        <FontAwesomeIcon icon={["fas", "camera"]} /> Edit Cover
                    </Button>
                </GlobalDropDownButton>
                <GlobalDropDownItem onClick={() => props.handleUploadOpen("cover photo")} icon={<FontAwesomeIcon icon={["fas", "cloud-upload-alt"]} />} title="Upload Photo" onClickHide={true}/>
                <GlobalDropDownItem onClick={() => handleReposition(true)} icon={<FontAwesomeIcon icon={["fas", "arrows-alt"]} />} title="Reposition" onClickHide={true}/>
                <GlobalDropDownItem onClick={() => setIsHeightSettingEnabled(true)} icon={<FontAwesomeIcon icon={["fas", "arrows-alt-v"]} />} title="Change Height" onClickHide={true}/>
                <GlobalDropDownItem onClick={() => handleDelete()} icon={<FontAwesomeIcon icon={["fas", "trash-alt"]} />} title="Remove" onClickHide={true} className="gd__red-option" separate={true}/>
            </GlobalDropdown>
        );
    }

    return (
        <div className={`cover-area ${isRepositionEnabled ? "cursor-mouse-move" : ""}`} 
            onDrag={handleDrag}
            onDragStart={handleDragStart}
            style={{height : coverHeight}}>
            <RepositionView isRepositionEnabled={isVisibleRepositionInfo}/>
            <HeightChangeSettingView 
                isSettingEnabled={isHeightSettingEnabled}
                changeSetting={(value) => setCoverHeight(value)}
                defaultSetting={coverHeight}/>
            <img src={props.builderJson.cover} alt="cover" style={{objectPosition : `0 ${positionPercent}%`}}/>
            <div className="action-button">
            {props.prevModal ? (
                <Button onClick={() => props.setPreviewModal(false)}>
                <FontAwesomeIcon icon={["fas", "edit"]} /> Back to Edit
                </Button>
            ) : (
                isRepositionEnabled ? 
                renderRepositionButtons() : 
                isHeightSettingEnabled ? 
                renderHeightChangeButtons() :
                renderNormalButtons()
            )}
            </div>
        </div>
    );
};
