import React, {useEffect, useState} from 'react';
import { fetchCustomForms } from '../../../actions/customFormBuilderAction'
import {connect} from "react-redux";
import FormListHeader from "./FormListHeader";
import FormBottomButtons from "./FormBottomButtons";
import Utils from "../../../helpers/Utils";
import ListSkeleton from "../../Common/Skeletons/ListSkeleton";
import './formList.css';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'

const FormBuilderNew = props => {
    const isTempUser = Utils.getAccountData('isTemplateUser');
    const [loading, setLoading] = useState(props.loading);
    const [forms, setForms] = useState(props.forms);

    useEffect(() => {
        document.title = `Form Builder | ${Utils.getAccountData('AuthUserAgencyName')}`;
        window.setActiveSidebar('marketing');
    }, []);

    useEffect(() => {
        if (props.forms !== undefined) {
            setForms(props.forms);
        } else {
            props.fetchCustomForms();
        }
    }, [props.forms]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    const renderForms = () => {
        if (!loading) {
            if (typeof props.forms[0] === 'undefined') {
                return <div className="text-center w-100 mt-4"><h5>No Forms found in the system</h5></div>
            }
            return forms.map((form, i) => {
                return (
                    <div className="custom_form_list__wrapper">
                        <div className="form__top">
                            <div className="form__top__left">
                                <Link to={`/user/new/form-builder/${form.unique_id}`}>
                                    <h3>{form.title}</h3>
                                </Link>
                                {
                                    !isTempUser && (
                                        <Button>
                                            <Link to={`/c/form/${form.unique_id}`} target="_blank"><span>View Form</span></Link>
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                        <FormBottomButtons form={form}/>
                    </div>
                );
            })
        } else {
            let blocks = [];
            for (let i = 0; i < 6; i++) {
                blocks.push(
                    <div className="custom_form_list__wrapper">
                        <div className="form__top">
                            <div className="form__top__left">
                                <ListSkeleton width={160} />
                            </div>
                            <ListSkeleton width={120}/>
                        </div>

                        <ListSkeleton width={280} number={6}/>
                    </div>
                );
            }
            return blocks;
        }
    }

    return (
        <div>
            <FormListHeader />
            <div className="allform__sections">
                <div className="all__form__wr">
                    { renderForms() }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        forms: state.customFormBuilderReducer.forms,
        loading: state.customFormBuilderReducer.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCustomForms: () => dispatch(fetchCustomForms())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormBuilderNew);