import { all } from "redux-saga/effects";
import commonMiddleware from "./commonMiddleware";
import customFormBuilderMiddleware from "./customFormBuilderMiddleware";
import formBuilderMiddleware from "./formBuilderMiddleware";

export default function* rootMiddleware() {
    yield all(
        [
            formBuilderMiddleware(),
            customFormBuilderMiddleware(),
            commonMiddleware()
        ]
    )
}
