import React from 'react'
import BootstrapTooltip from '../../BootstrapTooltip';
import formIcons from '../AddModal/FormIcons';

const PreviewTextArea = (props) => {

    const labelColor = props.data.configuration.labelColor ? props.data.configuration.labelColor.value : "#333";
    const fontFamily = props.data.configuration.fontFamily ? props.data.configuration.fontFamily.value : "'Poppins', sans-serif";
    const fontSize = props.data.configuration.fontSize ? props.data.configuration.fontSize.value : 14;


    return (
        !props.data.configuration.hideBlock.isEnable && (
          <div className="mb-3 singleItem alt">
            <h5 style={{color : labelColor, fontFamily : fontFamily, fontSize : parseInt(fontSize)}}>{props.data.label}</h5>
            <div>
              { formIcons[props.data.type] }
              
              <textarea
                className='ml-1'
                Grids="3"
                cols="50"
                name={props.data.id}
                type={props.data.component}
                placeholder={props.data.placeholder}
                value={props.previewValue[props.data.id]}
                onChange={(e) => {
                  props.setPreviewValue({
                    ...props.previewValue,
                    [e.target.name]: e.target.value,
                  });
                }}
                id={props.data.id}
              />
              {props.data.configuration.required.isEnable && (
                <BootstrapTooltip
                  arrow
                  title="Required"
                  placement="top"
                >
                  <span className="ar__req">*</span>
                </BootstrapTooltip>
              )}
              { props.simpleValidator.addFormValidator( 
                props.data.component, 
                props.data.label === null ? "" : props.data.label, //Validator genrates error if value null 
                props.previewValue[props.data.id], 
                props.data.configuration ) }
            </div>
          </div>
        )
      );
}

export default PreviewTextArea;
