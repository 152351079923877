import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";

function EditCheckboxAction({ index, isButton, removeOption }) {
  return (
    <div className="col-lg-2 action-buttons">
      {!isButton && (
        <Button variant="light" onClick={() => removeOption(index)}>
          <FontAwesomeIcon icon={["far", "trash-alt"]} />
        </Button>
      )}
    </div>
  );
}

export default EditCheckboxAction;
