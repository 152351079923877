import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./LogicalDemoContent.css";
import { EmbedContent } from "./EmbedContent";
import { TextContent } from "./TextContent";
import { CheckboxContent } from "./CheckboxContent";
import { RadioContent } from "./RadioContent";
import { ChoiceContent } from "./ChoiceContent";
import { DropdownContent } from "./DropdownContent";

function LogicalDemoContent({ newFieldData, addInputField, addNewPage }) {

  const [isCreatedNewPage, setIsCreatedNewPage] = useState(false);
  const [selectedChoiceView,  setSelectedChoiceView] = useState(null);

  let { newFieldType } = newFieldData;
  let headline, detail, bottomDetail;

  if (newFieldType === "firstName") {
    headline = "First Name";
    detail =
      "Use this to collect first names as answers. If the full name name is John Due then enter John in this field.";
    bottomDetail = "John";
  } else if (newFieldType === "lastName") {
    headline = "Last Name";
    detail =
      "Use this to collect last names as answers. If the full name name is John Due then enter Due in this field.";
    bottomDetail = "Due";
  } else if (newFieldType === "email") {
    headline = "Email";
    detail =
      "Use this to collect correctly formatted email addresses as answers. If someone tries to add text in the wrong format, they will have to try again.";
    bottomDetail = "admin@example.com";
  } else if (newFieldType === "phoneNumber" || newFieldType === "phoneInput") {
    headline = "Phone Number";
    detail = "Use this to collect correctly formatted phone numbers.";
    bottomDetail = "1-123-1234567";
  } else if (newFieldType === "birthDate") {
    headline = "Birth Date";
    detail =
      "Use this to collect correctly formatted birth dates in the specific format (mm/dd/yyyy).";
    bottomDetail = "20/02/1996";
  } else if (newFieldType === "companyName") {
    headline = "Company Name";
    detail = "Use this to collect the company names.";
    bottomDetail = "Orangetoolz";
  } else if (newFieldType === "address") {
    headline = "Address";
    detail = "Use this to collect addresses.";
    bottomDetail = "3237 Hillside Drive";
  } else if (newFieldType === "city") {
    headline = "City";
    detail = "Use this to collect the names of the city.";
    bottomDetail = "NOVATO";
  } else if (newFieldType === "state") {
    headline = "State";
    detail = "Use this to collect the names of the states.";
    bottomDetail = "California";
  } else if (newFieldType === "zip") {
    headline = "Zip";
    detail = "Use this to collect the zip code of the address.";
    bottomDetail = "94998";
  } else if (newFieldType === "note") {
    headline = "Note";
    detail = "Use this to add a note.";
    bottomDetail = "This form is used for adding contacts";
  } else if (newFieldType === "ipAddress") {
    headline = "IP Address";
    detail = "Use this to collect all IP Addresses.";
    bottomDetail = "192.168.0.1";
  } else if (newFieldType === "shortAnswer") {
    headline = "Short Answer";
    detail =
      "Use this for short text answers. Add an answer label or placeholder text for guidance.";
    bottomDetail = "";
  } else if (newFieldType === "textarea") {
    headline = "Long Answer";
    detail = "Use this for long form answers without character limits.";
    bottomDetail = "Tell us bit more";
  } else if (newFieldType === "number") {
    headline = "Number";
    detail =
      "Use this is if you want a number as a response. Add a minimum and/or maximum number limit.";
    bottomDetail = "Number of Employees";
  } else if (newFieldType === "link") {
    headline = "Link";
    detail = "Use this to collect correctly formatted links or websites.";
    bottomDetail = "https://www.orangetoolz.com";
  } else if (newFieldType === "date") {
    headline = "Date";
    detail =
      "Use this to allow respondents to pick a date in this format: Month/Day/Year.";
    bottomDetail = "20/02/1996";
  } else if (newFieldType === "time") {
    headline = "Time";
    detail =
      "Use this to allow respondents to pick a time in this format: XX:XX XX";
    bottomDetail = "11:30 AM";
  } else if (newFieldType === "checkbox") {
    headline = "Checkbox";
    detail =
      "Use this to offer a range of answers to a question. Respondents can select multiple answers";
    bottomDetail = "Checkboxes";
  } else if (newFieldType === "radio") {
    headline = "Radio";
    detail =
      "Use this to offer a range of answers to a question. Respondents can select one answer";
    bottomDetail = "Radio Buttons";
  } else if (newFieldType === "choice") {
    headline = "Multiple Choice";
    detail =
      "Use this to offer a range of answers options. Respondents can only choose one answer.";
    bottomDetail = "Multiple Choices";
  } else if (newFieldType === "dropdown") {
    headline = "Dropdown";
    detail =
      "Use this for a long list of answer options like selecting your nationality.";
    bottomDetail = "Dropdown";
  } else if (newFieldType === "fileUpload") {
    headline = "File Upload";
    detail = "Use this to collect files, like a portfolio or resume.";
    bottomDetail = "File Uploads";
  }
  //   else if (newFieldType === "rating") {
  //     headline = "Rating";
  //     detail =
  //       "Use this for a simple and visual way of rating things. Get to know your customer's opinions about your customer's product on serve for example.";
  //     bottomDetail = "Rating";
  //   }
  else if (newFieldType === "hidden") {
    headline = "Hidden";
    detail =
      "Send custom data from your form link to your form submissions vai URL query string parameters.";
    bottomDetail = "Hidden Fields";
  } else if (newFieldType === "heading1") {
    headline = "Heading 1";
    detail = "Big heading suitable for a title of main sections in your from.";
    bottomDetail = "Heading 1";
  } else if (newFieldType === "heading2") {
    headline = "Heading 2";
    detail =
      "Medium heading suitable for a title of smaller sections in your form.";
    bottomDetail = "Heading 2";
  } else if (newFieldType === "heading3") {
    headline = "Heading 3";
    detail = "Small heading suitable for questions.";
    bottomDetail = "Heading 3";
  } else if (newFieldType === "label") {
    headline = "Label";
    detail =
      "Add a text label to an input field and explain what type of answer you expect.";
    bottomDetail = "Label";
  } else if (newFieldType === "texts") {
    headline = "Text";
    detail =
      "Use this to display any type of text. You can format it as you want and customize it to your needs.";
    bottomDetail = "Text";
  } else if (newFieldType === "newPage") {
    if (!isCreatedNewPage) {
      setIsCreatedNewPage(true);
    }
    headline = "New Page";
    detail =
      "Add a new page to your form. Use this to create multi-step forms, on to create a custom new page.";
    bottomDetail = "New Page";
  } else if (newFieldType === "thankYouPage") {
    headline = "Thank You Page";
    detail =
      "Add a 'Thank You' page to your form. This is the page your respondents will see after submitting the form.";
    bottomDetail = "Thank You Page";
  } else if (newFieldType === "embedImage") {
    headline = "Image";
    detail =
      "Embed image from an Image URL. Use Images to visualize products or to personalize your form with your visual style";
    bottomDetail = "Image";
  } else if (newFieldType === "embedVideo") {
    headline = "Video";
    detail =
      "Embed videos from Youtube, Vimeo, Loom, .MP4s and more. Insert this embed block and paste the video;s URL in embed link.";
    bottomDetail = "Video";
  } else if (newFieldType === "embedAudio") {
    headline = "Audio";
    detail =
      "Embed audio from Soundcloud, Spotify, .MP3s and more. Insert this ebed block and paste the audio URL on embed link.";
    bottomDetail = "Audio";
  } else if (newFieldType === "embedAnything") {
    headline = "Embed anything";
    detail =
      "Embed any online content: Websites, online PDFs, Google maps, Calendy, Github Gist, Tweets, Figma files, and more.";
    bottomDetail = "Embed anything";
  } else if(newFieldType === "progressBar") {
    headline = "Progress Bar";
    detail = "Add progress bar on top of the form. It will show the progress of filling up the form.It will count the total input field & calculate it by the completed input field. It will show in % value & after each input field it will increase the value.";
    bottomDetail = "Progress Bar";
  }
  
  const handleInsert = () => {
    console.log(newFieldData);
    if(newFieldType === "choice") {
      newFieldData.newConfiguration.selectedView.value = selectedChoiceView;
    }
    addInputField(newFieldData);
  }
  
  return (
    <div className="logical__demo__content__right__v2 ">
      <div className="logical__demo__content__right__top__v2">
        <div className="logical__demo__content__right__left">
          <h5>{headline}</h5>
          <p>{detail}</p>
        </div>
        <div className="logical__demo__content__right__right">
          {isCreatedNewPage ? (
            <Button
              variant="primary"
              onClick={addNewPage}
              // onClick={() => addInputField(addNewPage)}
            >
              Insert <FontAwesomeIcon icon={["fas", "arrow-right"]} />
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={handleInsert}
              disabled={newFieldType === "choice" && selectedChoiceView === null}
            >
              Insert <FontAwesomeIcon icon={["fas", "arrow-right"]} />
            </Button>
          )}
        </div>
      </div>

      <div className={`logical__demo__content__middle__v2 ${newFieldType === "choice" ? "multiple_choice_wrapper" : ""}`}>
        <h6 className="logical__demo__content__middle__heading__v2">Example</h6>

        {newFieldType === "firstName" || newFieldType === "lastName" || newFieldType === "email" ||
         newFieldType === "phoneNumber" || newFieldType === "phoneInput" || newFieldType === "birthDate" || 
         newFieldType === "companyName" || newFieldType === "city" || newFieldType === "state" || 
         newFieldType === "zip" || newFieldType === "ipAddress" ? (
          <FormGroup className="" controlId="exampleinputFirstName">
            <div className="logical__demo__content__middle__label alt v2">
              <label htmlFor="fname" className="input__label">
                { headline }
                <input
                  type="text"
                  placeholder={`Enter your ${headline.toLowerCase()}`}
                  disabled
                />
              </label>
            </div>
            <p className="text-muted">Sample Input: {bottomDetail}</p>
          </FormGroup>
        ) : newFieldType === "address" || newFieldType === "note" ? (
          <FormGroup className="mb-3" controlId="exampleinputAddress">
            <div className="logical__demo__content__middle__label alt ar__address">
              <label className="input__label">
                { headline }
                <textarea
                  rows="3"
                  cols="50"
                  placeholder={`Enter your ${headline.toLowerCase()}`}
                  disabled
                />
              </label>
            </div>
            <p className="text-muted"> Sample Input: {bottomDetail}</p>
          </FormGroup>
        ) : newFieldType === "textarea" ? (
          <FormGroup
            className="mb-3 mt-40px ar__customFormGroup"
            controlId="exampleinputTextarea1"
          >
            <input
              as="textarea"
              rows={3}
              disabled
              placeholder="Placeholder goes here"
            />
            <p className="text-muted"> Sample Input: {bottomDetail}</p>
          </FormGroup>
        ) : newFieldType === "checkbox" ? (
          <CheckboxContent/>
        ) : newFieldType === "radio" ? (
          <RadioContent/>
        ) : newFieldType === "choice" ? (
          <ChoiceContent 
            bottomDetail={bottomDetail}
            selectedView={selectedChoiceView}
            changeView={(value) => setSelectedChoiceView(value)}/>
        ) : newFieldType === "dropdown" ? (
          <DropdownContent/>
        ) : newFieldType === "fileUpload" ? (
          <div className="file-upload-wrapper mt-40px ar__file_uploads">
            <input
              type="file"
              id="input-file-now"
              class="file-upload"
              disabled
            />
          </div>
        ) : newFieldType === "hidden" ? (
          <div>
            <div className="hidden_fields mt-40px ar__customFormGroup">
              <input type="text" id="hidden" name="hidden" disabled />
            </div>
            <p className="text-muted"> {bottomDetail}</p>
          </div>
        ) : newFieldType === "heading1" || newFieldType === "heading2" || newFieldType === "heading3" || newFieldType === "texts" || newFieldType === "label" ? (
          <TextContent type={newFieldType}/>
        ) : newFieldType === "newPage" ? (
          <div className="d-flex flex-column mt-40px">
            <div className="newPageSample">
              <h4>Page 1</h4>
            </div>
            <div className="newPageSample">
              <h4>Page 2</h4>
            </div>
          </div>
        ) : newFieldType === "embedImage" || newFieldType === "embedVideo" || newFieldType === "embedAudio" || newFieldType === "embedAnything" ? (
          <EmbedContent type={newFieldType}/>
        ) : newFieldType === "shortAnswer" ? (
          <FormGroup
            className="mb-3 mt-40px ar__customFormGroup"
            controlId="formBasicEmail"
          >
            <input
              type={newFieldType}
              placeholder="Placeholder goes here"
              disabled
            />
            <p className="text-muted"> Sample Input: {bottomDetail}</p>
          </FormGroup>
        ): newFieldType === "progressBar" ? (
          <FormGroup
            className="mb-3 mt-40px ar__customFormGroup"
            controlId="formBasicEmail"
          >
            <img
              className="w-100" 
              src="https://d24gujj67p1uc1.cloudfront.net/asset/images/releases/1.0.0/form_demo_content_progressbar.png" 
              alt="progressbar"/>
            <p className="text-muted"> Sample: {bottomDetail}</p>
          </FormGroup>
        ) : (
          <FormGroup
            className="mb-3 mt-40px ar__customFormGroup"
            controlId="formBasicEmail"
          >
            <input
              type={newFieldType}
              placeholder="Placeholder goes here"
              disabled
            />
            <p className="text-muted"> Sample Input: {bottomDetail}</p>
          </FormGroup>
        )}
      </div>
    </div>
  );
}

export default LogicalDemoContent;