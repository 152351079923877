import React from "react";
import MultipleChoiceAction from "../MultipleChoice/MultipleChoiceAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import { Button } from "@material-ui/core";
import BootstrapTooltip from "../../BootstrapTooltip";

function Dropdown({
  allInfo,
  fields,
  updateFields,
  index,
  updateOptionPlaceholder,
  label,
  updateLabel,
}) {
  let type = "dropdown";

  //Add a Label
  const handleAddLabel = (id, i) => {
    let oldFields = [...fields];
    oldFields[i].dropdown.push({
      id: uuidv4(),
      dropdownValue: "",
      placeholder: "",
    });
    updateFields(oldFields);
  };

  //Remove a Label
  const handleRemoveLabel = (fieldIndex, index) => {
    let oldFields = [...fields];
    oldFields[fieldIndex].dropdown.splice(index, 1);
    updateFields(oldFields);
  };

  const renderlabels = () => {

    let labelColor = allInfo.configuration.labelColor ? allInfo.configuration.labelColor.value : "#333";
    let fontFamily = allInfo.configuration.fontFamily ? allInfo.configuration.fontFamily.value : "'Poppins', sans-serif";
    let fontSize = allInfo.configuration.fontSize ? allInfo.configuration.fontSize.value : 14;

    const setLabelColor = (element) => {
      if (element) {
        element.style.setProperty('color', labelColor, 'important');
        element.style.setProperty('font-family', fontFamily, 'important');
        element.style.setProperty('font-size', parseInt(fontSize)+"px", 'important');
      };
    }

    return (
      <div className="ar__specific_input">
        <input
          ref={setLabelColor}
          type="text"
          defaultValue={label}
          placeholder={"Add label here"}
          onChange={(e) => {
            updateLabel(e.target.value, index);
          }}
        />
        {allInfo.configuration.required.isEnable && (
          <BootstrapTooltip arrow title="Required" placement="top">
            <span className="ar__req">*</span>
          </BootstrapTooltip>
        )}
        {fields[index].dropdown.map((label, index) => {
          return (
            <div
              className="single-input-area my-2 inserted__fieldLevel"
              key={label.id}
            >
              <FontAwesomeIcon icon={["fas", "caret-down"]} />
              <input
                type="text"
                key={label.id}
                placeholder={"option"}
                aria-label="dropdown-label"
                aria-describedby="dropdown-label"
                defaultValue={label.placeholder}
                // required={allInfo.configuration.required.isEnable}
                onChange={(e) => {
                  // updateChoice(e.target.value, index);
                  updateOptionPlaceholder(
                    e.target.value,
                    allInfo.index,
                    index,
                    type
                  );
                }}
              />
              <div className="col-lg-2 action-buttons">
                <Button
                  variant="light"
                  onClick={() => handleRemoveLabel(allInfo.index, index)}
                >
                  <FontAwesomeIcon icon={["far", "trash-alt"]} />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {renderlabels()}
      <button
        className="ar__add_option"
        onClick={() => handleAddLabel(allInfo.id, allInfo.index)}
      >
        Add option
      </button>
    </div>
  );
}

export default Dropdown;
