import { getCheckboxDefaultOptions, getDropdownDefaultOptions, getMultipleChoiceDefaultOptions, getRadioDefaultOptions } from "./Type";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { CONTACT_FIELDS_MAP } from "../../constants/CoreConstants";


export const createFieldNewFieldObject = (fields,newFieldData) => {

    //Checking field already exists
    let allSelectedTypes = fields.reduce((prevField,currField) => (prevField.type ? prevField.type : prevField) + ',' + currField.type);
    if((typeof allSelectedTypes) === 'string') {
      if(newFieldData.newFieldType.match(/firstName|lastName|email|phoneNumber|birthDate|companyName|address|city|state|zip|note|ipAddress|progressBar/g) && allSelectedTypes.includes(newFieldData.newFieldType)) {
        window.showNotification('ERROR','This field is already added to the form');
        return false;
      }
    }

    //Checking custom field is already exists
    if(newFieldData.customFieldId) {
      if (fields.find(e => e.customFieldId === newFieldData.customFieldId)) {
        window.showNotification('ERROR','This custom field is already been used!');
        return false;
      }
    }


    const { page,type,index,newFieldType,newFieldName,newComponent,newLabel,newConfiguration } = newFieldData;
  
      let oldFields = [...fields];
  
      let newFieldObj = {
        id: uuidv4(),
        page: page,
        type: newFieldType,
        component: newComponent,
        label: newLabel,
        placeholder: "",
        name: newFieldName,
        configuration: newConfiguration
      };

      if(newFieldData['customFieldId']) {
        newFieldObj.customFieldId = newFieldData['customFieldId'];
      }

      switch(newFieldType) {
        case 'birthDate' :
          newFieldObj.configuration.specificDate.value = moment(new Date()).format('MM/DD/YYYY');
          newFieldObj.placeholder = 'MM/DD/YYYY';
          break;
        case 'date' :
          newFieldObj.configuration.specificDate.value = moment(new Date()).format('MM/DD/YYYY');
          newFieldObj.placeholder = 'MM/DD/YYYY';
          break; 
        case 'time' :
          newFieldObj.placeholder = 'HH:MM';
          newFieldObj.configuration.specificTime.value = '12:00 AM';
          break;        
        case 'checkbox' :
          if(Array.isArray(newFieldData['customFieldValues'])) {
            newFieldObj['checkbox'] = newFieldData['customFieldValues'].map(elem => ({ id: uuidv4(), optionValue : elem, placeholder: elem, isChecked: false }))
          } else {
            newFieldObj['checkbox'] = getCheckboxDefaultOptions();
          }
          break;
        case 'choice' :
          newFieldObj['choice'] = getMultipleChoiceDefaultOptions();
          break;
        case 'dropdown' :
          if(Array.isArray(newFieldData['customFieldValues'])) {
            newFieldObj['dropdown'] = newFieldData['customFieldValues'].map(elem => ({ id: uuidv4(), dropdownValue: elem, placeholder: elem }))
          } else {
            newFieldObj['dropdown'] = getDropdownDefaultOptions();
          }
          break;        
        case 'radio' :
          if(Array.isArray(newFieldData['customFieldValues'])) {
            newFieldObj['radio'] = newFieldData['customFieldValues'].map(elem => ({ id: uuidv4(), radioValue: elem, placeholder: elem }))
          } else {
            newFieldObj['radio'] = getRadioDefaultOptions();
          }
          break;        
        case 'embedImage' :
          newFieldObj['image'] = {};
          break;        
        case 'embedVideo' :
          newFieldObj['video'] = {};
          break;        
        case 'embedAudio' :
          newFieldObj['audio'] = {};
          break;        
        case 'embedAnything' :
          newFieldObj['anything'] = {};
          break;
        default :
          break;
      }
  
      if (type === "button") {
        oldFields.splice(index, 0, newFieldObj);
      } else {
        oldFields.splice(index + 1, 0, newFieldObj);
      }

      return oldFields;
}

export const getProgressbarPercentage = (step, fields) => {
  
  let totalFields = 0,totalPrevFields = 0;
  for(let i = 0;i<fields.length;i++) {
    let field = fields[i];
    if(field.type.match(/button|heading1|heading2|heading3|label|texts|newPage|embedImage|embedVideo|embedAudio|embedAnything/)) {
      continue;
    }

    if(fields[i].page <= step) {
      totalPrevFields++;
    }
    totalFields++;
  }

  return (totalPrevFields/totalFields)*100;
}

export const hasProgressbar = (progressbar) => {
  if(progressbar) {
    window.showNotification('ERROR','Progressbar already added');
    return true;
  }

  return false;
}
 
export const duplicateInputFieldNewForm = (id, index, type, fields) => {
  let copyField = [...fields];
  const separateValueForDuplicate = copyField.find(
    (item, index) => item.id === id
  );
  let duplicateField = { ...separateValueForDuplicate };
  let newId = uuidv4();
  duplicateField.id = newId;

  if (
    type === "radio" ||
    type === "checkbox" ||
    type === "choice" ||
    type === "dropdown"
  ) {
    duplicateField[type] = JSON.parse(
      JSON.stringify(duplicateField[type])
    )
  }

  if (type === "button") {
    copyField.splice(index, 0, duplicateField);
  } else {
    copyField.splice(index + 1, 0, duplicateField);
  }
  
  return copyField;
};

const getProcessFieldValue = (field,formFields) => {
  if (field) {
    if(field.type === "firstName" || field.type === "lastName" || field.type === "email" || field.type === "phoneNumber" || field.type === "birthDate" || field.type === "companyName" || field.type === "address" || field.type === "city" || field.type === "state" || field.type === "zip" || field.type === "note" || field.type === "ipAddress") {
      return {name : field.name, value : formFields[field.id]}
    } else if(field.type === "shortAnswer" || field.type === "textarea" || field.type === "phoneInput" || field.type === "number" ||  field.type === "link" || field.type === "date" || field.type === "time" || field.type === "radio") {
      return {name : field.label, value : formFields[field.id]}
    } else if(field.type === "checkbox") {
      let checkboxFields = [];
      formFields[field.id].forEach(el => {
        if(el.isChecked) {
          checkboxFields.push(el.placeholder);
        }
      })

      return {name : field.label, value : checkboxFields.toString()}

    } else if(field.type === "choice" || field.type === "dropdown") {
      return {name : field.label, value : formFields[field.id]}
    } else if(field.type === "fileUpload") {
      return {name : field.label ? field.label : field.type, value : formFields[field.id]}
    } else if(field.type === "hidden") {
      return {name : field.type, value : formFields[field.id]}
    }
  }

  return {};
}

export const getProcessFieldValueForPreview = (field,obj, fileCount = 0) => {
  if(field.type === "firstName" || field.type === "lastName" || field.type === "email" || field.type === "phoneNumber" || field.type === "birthDate" || field.type === "companyName" || field.type === "address" || field.type === "city" || field.type === "state" || field.type === "zip" || field.type === "note" || field.type === "ipAddress") {
    return { label : field.label, value : obj[field.name]}
  } else if(field.customFieldId) {
    let customField = obj['custom_fields'].find(objElem => objElem.id === field.customFieldId);
    return {label : field.label, value : customField?.value, isCustomField : true}
  } else if(field.type === "shortAnswer" || field.type === "textarea" || field.type === "phoneInput" || field.type === "number" ||  field.type === "link" || field.type === "date" || field.type === "time" || field.type === "radio" || field.type === "checkbox" || field.type === "choice" || field.type === "dropdown" || field.type === "checkbox") {
    return {label : field.label, value : obj[field.label]}
  } else if(field.type === 'fileUpload') {
    try {
      let label = field.label !== null ? field.label : 'fileUpload';
      let file = obj['uploaded_files'][label][fileCount];
      return {label : label, name : file.file_original_name, url : file.url, isFile : true}
    } catch(ex) {
      return null;
    }
  }

  return null;
}

export const processCustomFormSubmit = (fields,formFields) => {
  let processedFields = {};
  let customFields = [];

  //Processing except mapping fields
  for (const id in formFields) {
    let field = fields.find(el => el.id === id && !el.mapTo && !el.configuration.hideBlock.isEnable);
    let fieldValue = getProcessFieldValue(field,formFields);

    if(fieldValue.name) {
      //If the field is custom field
      if(field.customFieldId) {
        let customFieldIndex = customFields.findIndex(el => el.id === field.customFieldId);
        if(customFieldIndex === -1) {
          customFields.push({id : field.customFieldId, value : fieldValue.value });
        } else {
          customFields.splice(customFieldIndex, 1, {id : field.customFieldId, value : fieldValue.value });
        }
      } else {
        processedFields[fieldValue.name] = fieldValue.value;
      }
    }
  }

  //Processing only mapping fields
  for (const id in formFields) {
    let field = fields.find(el => el.id === id && el.mapTo && !el.configuration.hideBlock.isEnable);
    let fieldValue = getProcessFieldValue(field,formFields);
    
    if(fieldValue.name) {
      //If the field is custom field
      if(field.customFieldId) {
        let customFieldIndex = customFields.findIndex(el => el.id === field.customFieldId);
        if(customFieldIndex === -1) {
          customFields.push({id : field.customFieldId, value : fieldValue.value });
        } else {
          customFields.splice(customFieldIndex, 1, {id : field.customFieldId, value : fieldValue.value });
        }
      } else {
        processedFields[field.mapTo] = fieldValue.value;
      }
    }
  }

  //Adding custom fields to parameters
  if(customFields.length > 0) {
    processedFields.custom_fields = customFields;
  }

  //Separating files
  let files = {};
  for(let key in processedFields) {
    let field = processedFields[key];
    if(Array.isArray(field) && field.length > 0 && field[0].file_name) {
      files[key] = field;
      delete processedFields[key];
    }
  }
  processedFields.uploaded_files = files;
  //---------------------------------

  return processedFields;
}

export const validateFields = (fields) => {
  let res = {
    message : "You are good to go",
    status : true
  }

  let hasNumberOrMail = false;
  let hasEmptyLabel = false;
  let hasDuplicateLabels = false;
  let hasInvalidCharacters = false;
  let hasMismatchMappingType = false;
  let hasDuplicateMappingSet = false;
  let hasEmptyOption = false;
  let labelsMap = {}; //For checking duplicate label names.
  let mappingMap = {}; //For checking duplicate mapping set.
  for(let i = 0;i<fields.length;i++) {

    if(fields[i].type === "shortAnswer" || fields[i].type === "phoneInput" || fields[i].type === "textarea" || fields[i].type === "number" || fields[i].type === "link" ||  fields[i].type === "date" || fields[i].type === "time" || fields[i].type === "radio" || fields[i].type === "checkbox" || fields[i].type === "choice" || fields[i].type === "dropdown") {
      
      if(fields[i].label === "" || fields[i].label === null) {
        hasEmptyLabel = true;
      } else if(labelsMap[fields[i].label.toLowerCase()]) {
        hasDuplicateLabels = true;
      } else {
        if(fields[i].label.includes("_")) {
          hasInvalidCharacters = true;
        }
        labelsMap[fields[i].label.toLowerCase()] = true;
      }
    }

    if(fields[i].type === 'choice') {
      for(let j = 0; j<fields[i].choice.length; j++) {
        if(!fields[i].choice[j].placeholder) {
          hasEmptyOption = true;
          break;
        }
      }
    }

    if(fields[i].type === "email" || fields[i].type === "phoneNumber") {
      if(!fields[i].configuration.hideBlock.isEnable && fields[i].configuration.required.isEnable) {
        hasNumberOrMail = true;
      }
    } else if(fields[i].mapTo && CONTACT_FIELDS_MAP[fields[i].mapTo].type !== fields[i].component) {
      hasMismatchMappingType = true;
    }

    if(fields[i].mapTo) {
      if(mappingMap[fields[i].mapTo]) {
        hasDuplicateMappingSet = true;
      } else {
        mappingMap[fields[i].mapTo] = true;
      }
    }
  }

  if(!hasNumberOrMail) {
    res.message = "Email or phone field needs to be present and required";
    res.status = false;
  }

  if(hasEmptyLabel) {
    res.message = "All labels must be required";
    res.status = false;
  }

  if(hasInvalidCharacters) {
    res.message = "Special charcter '_' not allowed on labels";
    res.status = false;
  }

  if(hasDuplicateLabels) {
    res.message = "Labels must be unique";
    res.status = false;
  }

  if(hasMismatchMappingType) {
    res.message = "There are some mismatched mapping types. Please fix them from mapping setting.";
    res.status = false;
  }

  if(hasDuplicateMappingSet) {
    res.message = "There are some duplicate mapping set. Please fix them from mapping setting.";
    res.status = false;
  }

  if(hasEmptyOption) {
    res.message = "All choice options must be required.";
    res.status = false;
  }

  return res;
}

export const readyPreviewObject = (fields) => {
  let previewObject = {};

  fields.forEach(dt => {
    if(dt.type !== "button") {
      if (dt.type === "birthDate" || dt.type === "date") {
        previewObject[dt.id] =
          typeof dt.configuration.specificDate !== "undefined" &&
          dt.configuration.specificDate.isEnable === true
            ? moment(dt.configuration.specificDate.value).format('YYYY-MM-DD')
            : moment(new Date().toLocaleDateString()).format('YYYY-MM-DD');
      } else if (dt.type === "checkbox") {
        previewObject[dt.id] = JSON.parse(JSON.stringify(dt.checkbox))
      } else if (dt.type === "radio") {
        previewObject[dt.id] = (dt.configuration.defaultAnswer && dt.configuration.defaultAnswer.isEnable) ? dt.configuration.defaultAnswer.value : null;
      } else if (dt.type === "choice") {
        previewObject[dt.id] = (dt.configuration.defaultAnswer && dt.configuration.defaultAnswer.isEnable) ? dt.configuration.defaultAnswer.value : "";
      } else if (dt.type === "dropdown") {
        previewObject[dt.id] = (dt.configuration.defaultAnswer && dt.configuration.defaultAnswer.isEnable) ? dt.configuration.defaultAnswer.value : "";
        if(previewObject[dt.id] === "" && dt.dropdown.length > 0) {
          previewObject[dt.id] = dt.dropdown[0].placeholder;
        }
      } else if (dt.type === "time") {
        previewObject[dt.id] =
          typeof dt.configuration.specificTime !== "undefined" &&
          dt.configuration.specificTime.isEnable === true
            ? dt.configuration.specificTime.value
            : "12:00 PM";
      } else if(dt.type === "hidden") {
        previewObject[dt.id] = dt.placeholder;
      } else {
        previewObject[dt.id] =
          typeof dt.configuration.defaultAnswer !== "undefined" &&
          dt.configuration.defaultAnswer.isEnable === true
            ? dt.configuration.defaultAnswer.value
            : "";
      }
    }
  });

  return previewObject;
}

export const focusFirstField = (fields) => {
  for(let i = 0; i < fields.length; i++) {
    
    //Choice
    if(fields[i].type === 'choice' && fields[i].choice.length > 0) {
      let elem = document.getElementById(fields[i].choice[0]?.id+i);
      if(elem && elem.tagName.toLocaleLowerCase() === 'button') {
        elem?.focus();
        break;
      }
    }

    //Other input
    let elem = document.getElementById(fields[i].id);
    if(elem && (elem.tagName.toLocaleLowerCase() === 'input' || elem.tagName.toLocaleLowerCase() === 'textarea')) {
      elem?.focus();
      break;
    }
  }
}

export const focusNextField = (fields,index) => {
  for(let i = index+1;i<fields.length; i++) {

    //Choice
    if(fields[i].type === 'choice' && fields[i].choice.length > 0) {
      let elem = document.getElementById(fields[i].choice[0]?.id+i);
      if(elem && elem.tagName.toLocaleLowerCase() === 'button') {
        elem?.focus();
        return true;
      }
    }

    //Other input
    let elem = document.getElementById(fields[i].id);
    if(elem && (elem.tagName.toLocaleLowerCase() === 'input' || elem.tagName.toLocaleLowerCase() === 'textarea')) {
      elem?.focus();
      return true;
    }
  }
  return false;
}

export const getFormbackground = (builderJson) => {
  let backgroundObject = builderJson?.formBackground ? builderJson.formBackground : {};
  if(backgroundObject.image) {
    return { backgroundImage : `url(${backgroundObject.image})` }
  } else if(backgroundObject.color) {
    return { backgroundColor : backgroundObject.color }
  }

  return {}
}

export const testImageUrl = (url, timeoutT) => {
  return new Promise(function (resolve, reject) {
      var timeout = timeoutT || 5000;
      var timer, img = new Image();
      img.onerror = img.onabort = function () {
          clearTimeout(timer);
          reject("error");
      };
      img.onload = function () {
          clearTimeout(timer);
          resolve("success");
      };
      timer = setTimeout(function () {
          // reset .src to invalid URL so it stops previous
          // loading, but doesn't trigger new load
          img.src = "//!!!!/test.jpg";
          reject("timeout");
      }, timeout);
      img.src = url;
  });
}

const FormUtils = {
  readyPreviewObject,
  focusFirstField,
  focusNextField,
  getProgressbarPercentage
}

export default FormUtils;

