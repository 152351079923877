import React, { useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { IconList } from "../../../../../../../constants/IconList";
import GlobalSearchBox from "../../../../../../Common/GlobalSearchBox";
import { getPexelImages } from "../../../../../../../api/customFormBuilderApi";
import PexelImage from "./PexelImage";
import useDelayCallback from "../../../../../../../hooks/useDelayCallback";

const PexelImageList = (props) => {
  const [pexelImageLists, setPexelImageLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');

  useDelayCallback(() => {
    setLoading(true);
    getPexelImages({query:query})
      .then((response) => {
        setLoading(false);
        if (response.data.photos) {
          setPexelImageLists(response.data.photos);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error", error);
      });
  },[query],1000)

  const renderPexelImage = () => {
    return pexelImageLists.map((image,index) => (
      <PexelImage eachImage={image} key={image.id} setBackground={props.setBackground}/>
    ))
  };

  const loadingSkeleton = () => {
    let skeletons = [];
    for (let i = 0; i < 20; i++) {
        skeletons.push(
        <div
            key={i}
            className={`skeleton__each__image__section`}
        >
          <Skeleton variant="rect" className="skeleton__each__image"/>
        </div>
        );
    }
      return skeletons;
  };

  return (
    <>
      <div className="pexel__start__wr">
        <div className="pexel__start__top__section">
          <GlobalSearchBox
            wrapperClass="form_builder__pexels_search"
            placeholder="Search Pexel Image"
            onChange={(e) => setQuery(e.target.value)}
          />
          <div
            className="form_builder__pexels__back__button"
            onClick={props.backToMain}
          >
            {IconList.backArrowIcon}
            <span>Back</span>
          </div>
        </div>

        {loading && (
          <div className="pexel__image__skeleton__wr">
            {loadingSkeleton()}
          </div>
        )}

        {pexelImageLists.length <= 0 && !loading &&  (
          <div className="pexel__no__image__found__section">
            <span>No Image Found</span>
          </div>
        )}

        {pexelImageLists.length > 0 && !loading &&  (
          <div className="form__pexel__image__section__wr">
            {renderPexelImage()}
          </div>
        )}
      </div>
    </>
  );
};

export default PexelImageList;
