import React, {useState} from "react";
import {connect} from "react-redux";
import {MenuItem, Select, Radio, RadioGroup, FormControlLabel, FormControl} from "@material-ui/core";
import { deleteFormEntry, fetchSubmissionResult, moveContactToCampaign } from '../../api/formBuilderApi';
import Utils from "../../helpers/Utils";
import If from "if-else-react";
import {IconList} from "../../constants/IconList";
import GlobalModal from "../Common/Modal/GlobalModal";

const EntryAction = props => {
    const [openViewSubmission, setOpenViewSubmission] = useState(false);
    const [openMoveToCampaignModal, setOpenMoveToCampaign] = useState(false);
    const [entryDetails, setEntryDetails] = useState(null);
    const [selectedCampaign, setSelectedCampaign] = useState(65);
    const [campaignSearchKey, setCampaignSearchKey] = useState('');

    const callBack = e => {
        e.preventDefault();
        e.stopPropagation();

        switch (e.target.value) {
            case 'view_submission':
                viewSubmission();
                break;
            case 'add_to_campaign':
                addToCampaign();
                break;
            case 'delete':
                formEntryDelete();
                break;
            default:
                break;
        }
    }

    const viewSubmission = () => {
        fetchSubmissionResult({entryId: props.entry.id}).then(response => {
            if (response.data.status) {
                setOpenViewSubmission(true);
                setEntryDetails(response.data.entry);
            } else {
                Utils.showNotification(response.data.html, 'error');
            }
        })
    }

    const addToCampaign = () => {
        setOpenMoveToCampaign(true);
    }

    const formEntryDelete = () => {
        deleteFormEntry({entryId: props.entry.id}).then(response => {
            if (response.data.status === 'success') {
                Utils.showNotification(response.data.html, 'success');
                props.fetchFormEntries();
            } else {
                Utils.showNotification(response.data.html, 'error');
            }
        })
    }

    const renderFormEntries = () => {
        let entries = [];
        if (entryDetails != null) {
            JSON.parse(entryDetails.entries).map((entry, i) => {
                if (Object.values(entry)[0] != null) {
                    entries.push(
                        <div className="row">
                            <div className="input-field col s12">
                                <label htmlFor="from-title">{ Object.keys(entry) }</label>
                                <input readOnly type="text" value={ Object.values(entry)}/>
                            </div>
                        </div>
                    );
                }
            });
        }
        return entries;
    }

    const renderCampaigns = () => {
        return props.campaigns.map((campaign, i) => {
            if (campaignSearchKey) {
                if (campaign.title.toLowerCase().includes(campaignSearchKey.toLowerCase())) {
                    return <FormControlLabel value={campaign.id} control={<Radio />} label={campaign.title} checked={campaign.id == selectedCampaign} />
                }
            } else {
                return <FormControlLabel value={campaign.id} control={<Radio />} label={campaign.title} checked={campaign.id == selectedCampaign} />
            }

        });
    }

    const handleCampaignChange = (e) => {
        setSelectedCampaign(e.target.value);
    }

    const moveToCampaign = () => {
        moveContactToCampaign({contact_ids: [props.entry.contact_id], campaignId: selectedCampaign}).then(response => {
            if (response.data.status === 'success') {
                Utils.showNotification('Contact moved to selected campaign successfully', 'success');
                setOpenMoveToCampaign(false);
            } else {
                Utils.showNotification(response.data.html, 'error');
            }
        });
    }
    
    return (
        <div>
            <If condition={openViewSubmission}>
                <GlobalModal open={openViewSubmission} onClose={() => setOpenViewSubmission(false)} title={"Submission"}>
                    <form action="/">
                        { renderFormEntries() }
                    </form>
                </GlobalModal>
            </If>

            <If condition={openMoveToCampaignModal}>
                <GlobalModal open={openMoveToCampaignModal} onClose={() => setOpenMoveToCampaign(false)} title={"Add to Campaigns"} buttonText={"Assign Campaign"} buttonIcon={IconList.copyIcon} buttonClick={moveToCampaign}>
                    <div className="campaign_modal">
                        <p>You can add to the following campaigns:</p>
                        <div className="input_wrapper pp_add_to_campaigns d-flex mt-2">
                            <input type="text" placeholder="Type and Search" value={campaignSearchKey} onChange={e => setCampaignSearchKey(e.target.value)}/>
                            {IconList.search}
                        </div>
                        <div className="campaign_list">
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="campaign" name="campaign" value={selectedCampaign} onChange={handleCampaignChange}>
                                    { renderCampaigns() }
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                </GlobalModal>
            </If>

            <Select className="pp_form_entry_select" labelId="demo-simple-select-label" id="demo-simple-select" value={'select_action'} onChange={callBack}>
                <MenuItem disabled value={'select_action'}>Select Action</MenuItem>
                <MenuItem value="view_submission">View Submission</MenuItem>
                <MenuItem value="add_to_campaign">Add To Campaign</MenuItem>
                <MenuItem value="delete">Delete</MenuItem>
            </Select>
        </div>

    );
}

const mapStateToProps = state => {
    return {
        campaigns: state.formBuilderReducer.campaigns,
    }
}

export default connect(mapStateToProps, null)(EntryAction);