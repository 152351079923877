import React from "react";
import { Button, Modal } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CustomSwitch from "../Custom/CustomSwitch";
import "./FormStyleModal.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomFormBuilderValue,
  setCustomFormSaveVisible,
} from "../../../../actions/customFormBuilderAction";
import FormBackground from "./Background/FormBackground";
import FormAnimation from "./Animation/FormAnimation";

const FormStyleModal = (props) => {
  const dispatch = useDispatch();
  const { builderJson } = useSelector(
    (state) => state.customFormBuilderReducer
  );

  const handleToggleSwitch = (value) => {
    handleStoreToRedux("floatingCover", value ? 1 : 0);
  };

  const handleStoreToRedux = (key, value) => {
    dispatch(
      setCustomFormBuilderValue({
        key: key,
        value: value,
      })
    );
    dispatch(setCustomFormSaveVisible(true));
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.onClose(false)}
      className="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <div className="modal__preview__content__wr">
        <div className="modal__preview__content__heading__wr">
          <h2>Form Style</h2>
          <Button
            className="back__to__edit"
            onClick={() => props.onClose(false)}
          >
            <ExitToAppIcon />
            <span>Back to Edit</span>
          </Button>
        </div>

        <div className="form__style__body__wrapper">
          <div className="form__style__each__item">
            <div className="form__style__each__item__heading">
              <h1>Floating Cover</h1>
              <p>The cover will fix or hide in the top portion.</p>
            </div>
            <div className="form__style__each__item__custom__switch">
              <CustomSwitch
                status={builderJson?.floatingCover === 1}
                brandColor={"#f94f72"}
                onChange={handleToggleSwitch}
              />
            </div>
          </div>

          <div className="form__style__each__item">
            <FormBackground
              builderJson={builderJson}
              setBackground={(value) =>
                handleStoreToRedux("formBackground", value)
              }
            />
          </div>

          <div className="form__style__each__item">
            <FormAnimation 
              builderJson={builderJson}
              setAnimation={value => handleStoreToRedux("animation", value)} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FormStyleModal;
