import { sendRequest, sendRequestBackEnd } from './rootApi';
import Api from "../constants/Api";

export const createNewCustomForm = async (payload) => {
    return sendRequest("POST", Api.createCustomForm, payload);
}

export const fetchCustomFormList = async (payload) => {
    return sendRequest("POST", Api.customFormlist, payload);
}

export const deleteCustomForm = async (payload) => {
    return sendRequest("POST", Api.deleteCustomForm, payload);
}

export const cloneCustomForm = async (payload) => {
    return sendRequest("POST", Api.cloneCustomForm, payload);
}

export const getCustomFormEntries = async (payload) => {
    return sendRequest("POST", Api.getCustomFormEntries, payload);
}

export const fetchCustomFormDetail = async (payload) => {
    return sendRequest("POST", Api.customFormdetail, payload);
}

export const settingCustomFormSubmit = async (payload) => {
    return sendRequest("POST", Api.settingCustomFormSubmit, payload);
}

export const updateCustomForm = async (payload) => {
    return sendRequest("POST", Api.updateCustomForm, payload);
}

export const publicCustomFormDetails = async (payload) => {
    return sendRequest("POST", Api.publicCustomFormDetails, payload);
}

export const uploadNewFormBuilderImage = async (payload) => {
    return sendRequest("POST", Api.uploadNewFormBuilderImage, payload);
}

export const publicUploadFile = async (payload) => {
    return sendRequest("POST", Api.publicUploadFile, payload);
}

export const backendFormSubmit = async (payload, apiKey) => {
    return sendRequestBackEnd("POST", Api.backendFormSubmit, payload, apiKey);
}

export const fetchSubmissionResultCustomForm = async (payload) => {
    return sendRequest("POST", Api.fetchCustomFormSubmission, payload);
}

export const deleteCustomFormEntry = async (payload) => {
    return sendRequest("POST", Api.deleteCustomFormEntry, payload);
}

export const getPexelImages = async (payload) => {
    return sendRequest("POST", Api.getPexelImages, payload);
}

export const getIconFinderImages = async (payload) => {
    return sendRequest("POST", Api.getIconFinderImages, payload);
}