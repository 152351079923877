import React, { useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import FormBuilderModal from "../FormBuilderModal/FormBuilderModal";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { CircularProgress } from "@material-ui/core";
import CustomSwitch from "../Custom/CustomSwitch";
import { MenuItem, Select } from "@material-ui/core";
import { DROPDOWN_BOTTOM_SETUP } from "../../../../constants/CoreConstants";
import "./LogoUpload.css";
import { IconList } from "../../../../constants/IconList";
import {
  setCustomFormBuilderValue,
  setCustomFormSaveVisible,
} from "../../../../actions/customFormBuilderAction";
import { uploadNewFormBuilderImage } from "../../../../api/customFormBuilderApi";
import { BootstrapInput } from "../../BootstrapInput";

const logoSizes = ["Rectangular", "Circular", "Square"];
const MAX_FILE_SIZE = 10485760; //1024*1024*10 --- 10MB

const LogoUpload = (props) => {
  const [uploadStatus, setUploadStatus] = useState(false);

  const inputFile = useRef(null);
  const dispatch = useDispatch();

  const handleToggleSwitch = (value) => {
    handleChangeReduxValue("logoFillColor", value ? "#fff" : null);
  };

  const handleChangeReduxValue = (key,value) => {
    dispatch(
      setCustomFormBuilderValue({ key, value })
    );
    dispatch(setCustomFormSaveVisible(true));
  }

  const uploadImage = (e, type) => {
    if (
      e.target.files[0].type !== "image/jpeg" &&
      e.target.files[0].type !== "image/png" &&
      e.target.files[0].type !== "image/gif"
    ) {
      window.showNotification("ERROR", "Please select a valid image.");
      return;
    }

    if (e.target.files[0].size > MAX_FILE_SIZE) {
      window.showNotification("ERROR", "Maximum upload size : 10MB");
      return;
    }

    setUploadStatus(true);
    const dt = new FormData();
    dt.append("qqfile", e.target.files[0], e.target.files[0].name);
    uploadNewFormBuilderImage(dt).then((res) => {
      handleChangeReduxValue(type === "logo" ? "logo" : "cover",res.data.mediaUrl);
      setUploadStatus(false);
      props.closeModal();
    });
  };

  const handleDeleteLogo = (e) => {
    e.stopPropagation();
    confirmAlert({
      title: "Confirm to delete the logo?",
      message: "You will not able to undo it.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleChangeReduxValue("logo",null);
          },
        },
        {
          label: "No",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const renderUploading = () => {
    return (
      <div className="uploader__loader">
        <CircularProgress className="position-static mr-2" size={23} />
        <p>Uploading...</p>
      </div>
    );
  };

  const handleChangeLogoSize = (e) => {
    handleChangeReduxValue('logoSize',e.target.value);
  }

  const renderUploadOption = () => {
    return (
      <>
        <div className={"logo__upload__top__section " + (props.builderJson?.logo ? "logo__upload__justify__between" : "logo__upload__justify__center")}>
          <div className="logo__upload__top__left">
            <span>{props.builderJson?.logo ? "Change" : "Upload"} Logo</span>
          </div>
          {
            props.builderJson?.logo &&
            <div
              className="logo__upload__top__remove__section"
              onClick={handleDeleteLogo}
            >
             {IconList.deleteIcon}
              <span>Remove</span>
            </div>
          }
        </div>

        <div className="logo__upload__size__section">
          <span>Logo Size</span>
          <div className="logo__upload__size__dropdown">
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={props.builderJson?.logoSize !== undefined ? props.builderJson.logoSize : logoSizes[2]}
              MenuProps={DROPDOWN_BOTTOM_SETUP}
              input={<BootstrapInput />}
              onChange={handleChangeLogoSize}
            >
              {logoSizes.map((eachSize, index) => { 
                return (
                  <MenuItem
                    key={eachSize}
                    value={eachSize}
                    disableRipple
                  >
                    {eachSize}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </div>

        <div className="load__upload__fill__with__background__section">
          <span>Fill with white background</span>
          <div className="load__upload__fill__with__background__switch">
            <CustomSwitch
              status={props.builderJson?.logoFillColor === '#fff'}
              brandColor={"#f94f72"}
              onChange={handleToggleSwitch}
            />
          </div>
        </div>

        <div className="logo__upload__wrapper__v2__top">
          <span>{IconList.infoSvgIcon}</span>
          <p>Supports any .jpg, .png file up to 10 MB in size.</p>
        </div>

        <div
          className="logo__upload__button__section"
          onClick={(e) => inputFile.current.click()}
        >
          <input
            type="file"
            disabled={uploadStatus}
            accept="image/png, image/gif, image/jpeg"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={(e) => uploadImage(e, props.uploadType)}
          />
          <CloudUploadIcon />
          <span>{props.builderJson?.logo ? "Change" : "Upload"} Logo </span>
        </div>
      </>
    );
  };

  return (
    <div>
      <FormBuilderModal
        className="upload-modal ar__upload-modal"
        size="md"
        centered={false}
        open={props.uploadShow}
        onClose={() => props.closeModal()}
      >
        <div className="form__logo__upload__wr">
          {uploadStatus ? renderUploading() : renderUploadOption()}
        </div>
      </FormBuilderModal>
    </div>
  );
};

export default LogoUpload;
