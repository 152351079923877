import * as ACTION from "../constants/formBuilderActionTypes";

export const fetchForms = (payload, callback) => ({
    type: ACTION.FETCH_FORMS,
    payload: {payload, callback}
});

export const addForms = payload => ({
    type: ACTION.ADD_FORMS,
    payload: payload
});

export const fetchFormDetails = (payload, callback) => ({
    type: ACTION.FETCH_FORM_DETAIL,
    payload: {payload, callback}
});

export const settingFormSubmit = (payload, callback) => ({
    type: ACTION.SUBMIT_SETTING_FORM,
    payload: {payload, callback}
});

export const styleFormSubmit = (payload, callback) => ({
    type: ACTION.SUBMIT_STYLE_FORM,
    payload: {payload, callback}
});

export const updateStyle = (payload) => ({
    type: ACTION.UPDATE_STYLE_DETAILS,
    payload: payload
});

export const formSave = (payload, callback) => ({
    type: ACTION.UPDATE_FORM_SUBMIT,
    payload: {payload, callback}
});

export const fetchFormCategories = (payload, callback) => ({
    type: ACTION.FETCH_FORM_CATEGORIES,
    payload: {payload, callback}
});

export const getFormSettingPageData = payload => ({
    type: ACTION.FETCH_FORM_SETTING_PAGE_DATA,
    payload: payload
});