export const CONTACT_FIELDS_MAP = {
    first_name : { label : "First Name", type : "text"},
    last_name : { label : "Last Name", type : "text"},
    // number : { label : "Number", type : "phone"},
    // email : { label : "Email", type : "email"},
    country : { label : "Country", type : "text"},
    address : { label : "Address", type : "textarea"},
    state : { label : "State", type : "text"},
    city : { label : "City", type : "text"},
    zip : { label : "Zip", type : "text"},
    url : { label : "Url", type : "url"},
    deal_value : { label : "Deal Value", type : "number"},
    birth_date : { label : "Birth Date", type : "date"},
    anniversary_date : { label : "Anniversary Date", type : "date"},
    company_name : { label : "Company Name", type : "text"},

}

export const DROPDOWN_BOTTOM_SETUP = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center",
    },
    getContentAnchorEl: null,
}

export const MODAL_DEFAULT_OPTIONS = {
    currentPage: "",
    prevIndex: "",
    prevFieldType: "",
    newFieldType: "",
    newFieldName: "",
    newComponent: "",
    newConfiguration: ""
}

export const PROGRESSBAR_DEFAULT_OPTIONS = {
    backgroundColor : '#3C7EF3',
    color : '#FFFFFF'
}

const CoreConstants = {
    REDIRECT_AFTER_SUBMIT: 1,
    MESSAGE_AFTER_SUBMIT : 2,

    STATUS_ENABLE: 1,
    STATUS_DISABLE: 0,
    STATUS_INVISIBLE: 2,

    LANDING_PAGE_BG_COLOR: 1,
    LANDING_PAGE_BG_IMAGE: 2,

    VALUE_NO: 0,
    VALUE_YES: 1,

    FORM_ENTRY: {
        STATUS_CONTACT_INSERTED: 1,
        STATUS_DUPLICATE_CONTACT: 2,
        STATUS_ERROR: 3,

        CAMPAIGN_STATUS_SUCCESS: 1,
        CAMPAIGN_STATUS_ERROR: 0,

        STAGE_STATUS_SUCCESS: 1,
        STAGE_STATUS_ERROR: 0,
    }
}

export default CoreConstants;