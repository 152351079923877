import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCustomFormSaveVisible } from "../../../../actions/customFormBuilderAction";
import { useDelayMounted } from "../../../../hooks/useDelayMounted";
import { Editor } from '@tinymce/tinymce-react';


const FormNameEditor = ({ setFormName, data }) => {

  const dispatch = useDispatch();
  const isDelayMounted = useDelayMounted(1500);
  const { saveRequired } = useSelector(state => state.customFormBuilderReducer);

  const changeFormName = (formName) => {
    if(!saveRequired && isDelayMounted) {
      dispatch(setCustomFormSaveVisible(true));
    }
    setFormName(formName);
  }


  return (
    <div className="add_form_title_editor_wrapper">
      <Editor
        inline={true}
        value={data}
        onEditorChange={changeFormName}
        apiKey='bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr'
        init={{
          placeholder: "Enter a title",
          menubar: false,
          statusbar: false,
          toolbar: 'undo redo | formatselect | ' +
           'bold italic backcolor forecolor | alignleft aligncenter ' +
           'alignright alignjustify | ' +
           'removeformat',
          plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount'
           ],
           convert_urls: false,
          content_style: 'body { font-family:Helvetica,Arial,sans-serif;};',
          content_css: process.env.REACT_APP_BASE_URL + "css/formbuilderEditor.css?v=1.0",
          content_css_cors: true,
          forced_root_block : "h2"
        }}
       />
    </div>
  );
};

export default FormNameEditor;
