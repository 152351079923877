import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCustomFormBuilderValue, setCustomFormSaveVisible } from '../../../../actions/customFormBuilderAction'
import useDelayCallback from '../../../../hooks/useDelayCallback'
import { useDelayMounted } from '../../../../hooks/useDelayMounted'
import BootstrapTooltip from '../../BootstrapTooltip'
import FormBuilderModal from '../FormBuilderModal/FormBuilderModal'
import { PreviewProgressbar } from '../PreviewInputField/PreviewProgressbar'
import { FieldDeleteButton } from './FieldDeleteButton'

export const EditProgressbarField = (props) => {

    const dispatch = useDispatch();
    const isMounted = useDelayMounted(500);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [backgroundColor,setBackgroundColor] = useState('#3C7EF3');
    const [color,setColor] = useState('#FFFFFF');

    useEffect(() => {
        if(props.setting) {
            setBackgroundColor(props.setting.backgroundColor ? props.setting.backgroundColor : '#3C7EF3');
            setColor(props.setting.color ? props.setting.color : '#FFFFFF');
        }
    },[props.setting]);

    //To reduce re-render on color change.
    useDelayCallback(() => {
        if(isMounted) {
            let progressbarObj = {
                backgroundColor : backgroundColor,
                color : color
            };

            setReduxValue("progressbar",progressbarObj);
        }
    },[backgroundColor,color],100);

    const setReduxValue = (key,value) => {
        dispatch(setCustomFormSaveVisible(true));
        dispatch(setCustomFormBuilderValue({ key,value }));
    }

    const showFunctionalities = () => {
        return (
          <div>
            <h6>Options</h6>

            <div className="each_functionality">
                <h5>Color</h5>
                <input type="color" 
                      value={backgroundColor} 
                      style={{backgroundColor: backgroundColor}}
                      onChange={e => setBackgroundColor(e.target.value)}
                />
            </div>

            <div className="each_functionality">
                <h5>Font Color</h5>
                <input type="color" 
                      value={color} 
                      style={{backgroundColor: color}}
                      onChange={e => setColor(e.target.value)}
                />
            </div>
          </div>
        );
    };

    //Early return if there are no progressbar available
    if(!props.setting) {
        return "";
    }

    return (
        <div className='body__edit_progress_bar'>
            <div className='input__with__buttons'>
                <div className='specific__input__field__wr'>
                    <div className='all__action__buttons'>
                        <FormBuilderModal
                            open={isOpenPopup}
                            onClose={() => setIsOpenPopup(!isOpenPopup)}
                            className={"form__builder__modal ar__options_modal"}
                        >
                            {showFunctionalities()}
                        </FormBuilderModal>

                        <FieldDeleteButton removeFieldItem={() => setReduxValue("progressbar", null)}/>
                        <BootstrapTooltip arrow title="Options" placement="top">
                            <Button variant="light" onClick={() => setIsOpenPopup(!isOpenPopup)}>
                            <FontAwesomeIcon icon={["fas", "grip-vertical"]} />
                            </Button>
                        </BootstrapTooltip>
                    </div>

                    <div className='specific__input__field__wr__left'>
                        <PreviewProgressbar color={backgroundColor} fontColor={color}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
