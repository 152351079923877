import CoreConstants from "../../constants/CoreConstants";
import {connect} from "react-redux";

const EntryStatus = props => {

    const getEntryStatus = () => {
        if (props.entry.status === CoreConstants.FORM_ENTRY.STATUS_CONTACT_INSERTED) {
            return <p className="m-badge--success">Contact Added</p>
        } else if (props.entry.status === CoreConstants.FORM_ENTRY.STATUS_DUPLICATE_CONTACT){
            return <p className="m-badge--warning">Duplicate Added</p>
        } else{
            return <p className="m-badge--danger">Error</p>
        }
    }

    const getAddedStatus = () => {

        let campaignName = 'Campaign';
        let campaignText = '';
        let campaignBadge = '';
        let stageName = 'Stage';
        let pipelineName = 'Pipeline';
        let stageText = '';
        let pipelineText = '';
        let stageBadge = '';

        if (props.entry.campaign_status) {
           if (props.entry.campaign_id) {
                let campaign = props.campaigns.filter(campaign => campaign.id === props.entry.campaign_id);
                if (campaign.length) {
                    campaignName = campaign[0].title;
                } else {
                    campaignName = 'a deleted campaign';
                }
            }

            if (props.entry.campaign_status === CoreConstants.FORM_ENTRY.CAMPAIGN_STATUS_SUCCESS) {
                campaignText = 'Campaign - Added to ' + campaignName;
                campaignBadge = 'm-badge--success';
            } else if (props.entry.campaign_status === CoreConstants.FORM_ENTRY.CAMPAIGN_STATUS_ERROR) {
                campaignText = 'Error - Not added to ' + campaignName;
                campaignBadge = 'm-badge--danger';
            }

        }

       if (props.entry.stage_status) {

               if (props.entry.stage_id) {
                   stageName = props.entry.stage_title;
               }

               if (props.entry.stage_status === CoreConstants.FORM_ENTRY.STAGE_STATUS_SUCCESS) {
                   pipelineText = 'Pipeline - ' + pipelineName;
                   stageText = 'Stage - ' + stageName;
                   stageBadge = 'm-badge--success';
               } else if (props.entry.stage_status === CoreConstants.FORM_ENTRY.STAGE_STATUS_ERROR){
                   pipelineText = 'Pipeline - Not added to ' + pipelineName;
                   stageText = 'Error - Not added to ' + stageName;
                   stageBadge = 'm-badge--danger';
               }

           }

        if (campaignText !== '' && campaignBadge !== '' && stageText !== '' && stageBadge !== '') {
            return <div><p className={`m-badge m-badge--wide mr-1 ${campaignBadge}`}>{campaignText}</p><p className={`m-badge m-badge--wide mr-1 ${stageBadge}`}>{pipelineText}</p><p className={`m-badge m-badge--wide mr-1 ${stageBadge}`}>{stageText}</p></div>
        }else if (campaignText !== '' && campaignBadge !== '' && stageText === '') {
            return <p className={`m-badge m-badge--wide mr-1 ${campaignBadge}`}>{campaignText}</p>
        }else if (stageText !== '' && stageBadge !== '') {
            return <div><p className={`m-badge m-badge--wide mr-1 ${stageBadge}`}>{pipelineText}</p><p className={`m-badge m-badge--wide mr-1 ${stageBadge}`}>{stageText}</p></div>
        }else{
            return <></>
        }

    }

    return (
        <div>
            <p>{ getEntryStatus() }</p>
            <p>{ getAddedStatus() }</p>
        </div>

    );
}

const mapStateToProps = state => {
    return {
        campaigns: state.formBuilderReducer.campaigns,
        stages: state.formBuilderReducer.stages,
    }
}

export default connect(mapStateToProps, null)(EntryStatus);