const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/form-builder`
const contactUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const marketingUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/marketing`
const containerUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`

const backendUrl = `${process.env.REACT_APP_JAVAEND_URL}/${process.env.REACT_APP_API_VERSION}`


const Api = {
    list: `${rootUrl}/list`,
    create: `${rootUrl}/create`,
    delete: `${rootUrl}/delete`,
    clone: `${rootUrl}/clone`,
    detail: `${rootUrl}/detail`,
    getFormSettingPageData: `${rootUrl}/setting/data`,
    fetchStages: `${rootUrl}/setting/stages`,
    settingFormSubmit: `${rootUrl}/setting/submit`,
    styleFormSubmit: `${rootUrl}/style/submit`,
    getEditorData: `${rootUrl}/editor/data`,
    updateForm: `${rootUrl}/update`,
    fetchFormCategories: `${rootUrl}/templates`,
    cloneFormTemplate: `${rootUrl}/templates/clone`,
    getFormEntries: `${rootUrl}/entries`,
    deleteFormEntry: `${rootUrl}/entries/delete`,
    fetchSubmission: `${rootUrl}/entries/view`,
    moveToCampaign: `${marketingUrl}/contact/move/campaign`,

    fetchFormDetailsForPublicPage: `${rootUrl}/form/details`,
    formSubmit: `${rootUrl}/form/submit`,

    createCustomForm: `${rootUrl}/custom/create`,
    customFormlist: `${rootUrl}/custom/list`,
    deleteCustomForm: `${rootUrl}/custom/delete`,
    cloneCustomForm: `${rootUrl}/custom/clone`,
    getCustomFormEntries: `${rootUrl}/custom/entries`,
    customFormdetail: `${rootUrl}/custom/detail`,
    settingCustomFormSubmit: `${rootUrl}/custom/setting/submit`,
    updateCustomForm : `${rootUrl}/custom/update/form/submit`,
    publicCustomFormDetails : `${rootUrl}/custom/public/form/details`,
    fetchCustomFormSubmission: `${rootUrl}/custom/entries/view`,
    deleteCustomFormEntry: `${rootUrl}/custom/entries/delete`,

    uploadNewFormBuilderImage : `${contactUrl}/upload-email-file`,
    getCustomFields: `${contactUrl}/get-custom-fields`,
    
    backendFormSubmit : `${backendUrl}/form`,
    publicUploadFile : `${rootUrl}/custom/upload/file`,

    /* Background */
    getPexelImages: `${containerUrl}/pexels/get/images`,
    getIconFinderImages: `${containerUrl}/iconfinder/get/images`,

    
}

export default Api;