import "date-fns";
import React, { useState } from 'react'
import DateFnsUtils from "@date-io/date-fns";
import BootstrapTooltip from '../../BootstrapTooltip';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import moment from "moment";
import { parseISO } from 'date-fns';

const PreviewDate = (props) => {

    const labelColor = props.data.configuration.labelColor ? props.data.configuration.labelColor.value : "#333";
    const fontFamily = props.data.configuration.fontFamily ? props.data.configuration.fontFamily.value : "'Poppins', sans-serif";
    const fontSize = props.data.configuration.fontSize ? props.data.configuration.fontSize.value : 14;


    const [open, setOpen] = useState(false);

    const handleDateChange = (date) => {
        props.setPreviewValue({
          ...props.previewValue,
          [props.data.id]: moment(date).format('YYYY-MM-DD'),
        });
    };

    return (
        !props.data.configuration.hideBlock.isEnable && (
          <div className="mb-3 singleItem date-field">
            <div className="preview__date_field">
              <h5 style={{color : labelColor, fontFamily : fontFamily, fontSize : parseInt(fontSize)}}>{props.data.label}</h5>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  onClick={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  open={open}
                  inputProps={{readOnly : true}}
                  value={parseISO(props.previewValue[props.data.id])}
                  onChange={handleDateChange}
                  id={props.data.id}
                  margin="normal"
                  format="MM/dd/yyyy"
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>

              {props.data.configuration.required.isEnable && (
                <BootstrapTooltip
                  arrow
                  title="Required"
                  placement="top"
                >
                  <span className="ar__req">*</span>
                </BootstrapTooltip>
              )}
              {props.simpleValidator.addFormValidator(
                props.data.component,
                "date",
                props.previewValue[props.data.id],
                props.data.configuration
              )}
            </div>
          </div>
        )
      );
}

export default PreviewDate;
