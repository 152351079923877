import React from "react";
import "./LayoutBlock.css";
import { v4 as uuidv4 } from "uuid";

function LayoutBlock({
  field,
  updatePlaceholder,
  allInfo,
  placeholder,
  className,
  initialPlaceholder,
}) {

  const { index } = allInfo;
  
  const color = allInfo.configuration.labelColor ? allInfo.configuration.labelColor.value : "#333";
  let fontFamily = allInfo.configuration.fontFamily ? allInfo.configuration.fontFamily.value : "'Poppins', sans-serif";

  const setLabelColor = (element) => {
    if (element) {
      element.style.setProperty('color', color, 'important'); 
      element.style.setProperty('font-family', fontFamily, 'important');
    }
  }

  return (
    <input
      ref={setLabelColor}
      type="text"
      name={`layout_block_${field}_${uuidv4()}`}
      className={className}
      placeholder={initialPlaceholder}
      defaultValue={placeholder}
      onChange={(e) => updatePlaceholder(e.target.value, index)}
    />
  );
}

export default LayoutBlock;
