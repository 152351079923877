import { Button } from '@material-ui/core';
import React from 'react';
import BootstrapTooltip from '../../BootstrapTooltip';
import { FormControl, FormLabel } from '@material-ui/core';

const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

const PreviewChoiceList = (props) => {
    
    const labelColor = props.data.configuration.labelColor ? props.data.configuration.labelColor.value : "#333";
    const fontFamily = props.data.configuration.fontFamily ? props.data.configuration.fontFamily.value : "'Poppins', sans-serif";
    const fontSize = props.data.configuration.fontSize ? props.data.configuration.fontSize.value : 14;

    return (
        <FormControl className="ar__form_global_control ar__multiple">
        <FormLabel component="legend" style={{color : labelColor, fontFamily : fontFamily, fontSize : parseInt(fontSize)}}>
            {props.data.label}
            {props.data.configuration.required.isEnable && (
            <BootstrapTooltip
                arrow
                title="Required"
                placement="top"
            >
                <span className="ar__req">*</span>
            </BootstrapTooltip>
            )}
        </FormLabel>
        <div className="ar__flex_column">
            {props.data.choice.map((choice, choiceIndex) => {

                const updateChoice = () => {
                    let tempPreviewValue = { ...props.previewValue };
                    tempPreviewValue[props.data.id] = choice.placeholder;
                    props.setPreviewValue(tempPreviewValue);
                };

                const handlePressEnter = (e) => {
                    if(e.key === 'Enter') {
                        updateChoice();
                        props.onEnterPress(e)
                    }
                }

                return (
                    !props.data.configuration.hideBlock.isEnable && (
                    <Button
                        key={choice.id}
                        id={choice.id+props.index}
                        variant={
                        props.previewValue[props.data.id] === choice.placeholder
                            ? "contained"
                            : "outlined"
                        }
                        color="primary"
                        onClick={updateChoice}
                        onKeyDown={handlePressEnter}
                    >
                        { choice.icon ? 
                            <img className='choice-list-icon' src={choice.icon} alt="preview"/> : 
                            <span style={{ color: "red", fontWeight: 700 }}>
                                {alphabets[choiceIndex]}
                            </span>
                        }
                        {choice.placeholder}
                    </Button>
                    )
                );
            })}
        </div>
        {props.simpleValidator.addFormValidator(
            props.data.component,
            props.data.label === null ? "" : props.data.label, //Validator genrates error if label null,
            props.previewValue[props.data.id],
            props.data.configuration
        )}
        </FormControl>
      );
}

export default PreviewChoiceList;
