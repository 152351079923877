import React from 'react'

//Fields attributes are same as type
//field same as the source saved in JSON
const fields = {
    embedImage : {field : "image",title : "Embedded Image"},
    embedVideo : {field : "video",title : "Embedded Video"},
    embedAudio : {field : "audio",title : "Embedded Audio"},
    embedAnything : {field : "anything",title : "Embedded Anything"},
}

const PreviewEmbed = ({ data }) => {

    if(data.configuration.hideBlock.isEnable || !data[fields[data.type].field].source) {
      return "";
    }

    // Convert Youtube URL to embed ID
    const getEmbedId = (url) => {
      let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      let match = url.match(regExp);

      if (match && match[2].length === 11) {
          return match[2];
      } else {
          return false;
      }
    }

    const getSourceUrl = (url) => {
      let sourceUrl = url ? url : "";
      sourceUrl = url.replace('https://www.loom.com/share/', "https://www.loom.com/embed/");
      let embedId = getEmbedId(sourceUrl);
      return embedId ? `https://www.youtube.com/embed/${embedId}` : sourceUrl;
    }

    return (
        <div className="mb-3 singleItem">
          <div>
            <div className="video-responsive">
              <iframe
                width="853"
                height="480"
                src={getSourceUrl(data[fields[data.type].field].source)}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={fields[data.type].title}
              />
            </div>
          </div>
        </div>
    );
}

export default PreviewEmbed;
