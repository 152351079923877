import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core'
import React from 'react'

export const CheckboxContent = () => {
  return (
    <FormControl component="fieldset" className="mt-40px ">
        <FormLabel component="legend">Assign responsibility</FormLabel>
        <FormGroup>
            <FormControlLabel
            control={<Checkbox name="gilad" />}
            label="Gilad Gray"
            disabled
            />
            <FormControlLabel
            control={<Checkbox name="jason" />}
            label="Jason Killian"
            disabled
            checked
            />
            <FormControlLabel
            control={<Checkbox name="antoine" />}
            label="Antoine Llorca"
            disabled
            />
        </FormGroup>
    </FormControl>
  )
}
