import React from 'react'
import { Button } from '@material-ui/core';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";


export const PreviewButton = (props) => {

    const buttonStyle = {
      backgroundColor : props.data.buttonBackground ? props.data.buttonBackground : "#000",
      color : props.data.buttonTextColor ? props.data.buttonTextColor : "#fff",
      fontFamily : props.data.fontFamily ? props.data.fontFamily : "'Roboto', 'Helvetica', 'Arial', sans-serif"
    }

    return (
        <Button
          className={`new__form__builder__preview__submit__btn ${props.data.alignment}`}
          variant="dark"
          name={props.data.name}
          onClick={props.onClick}
          size={props.data.buttonSize ? props.data.buttonSize : 'medium'}
          style={buttonStyle}
        >
          {props.data.placeholder}
          <ArrowForwardIcon />
        </Button>
    );
}
