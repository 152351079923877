import React, { useState } from "react";
import { Button } from "@material-ui/core";
import FormBuilderModal from "../FormBuilderModal/FormBuilderModal";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { IconFinderOptions } from "./IconFinderOptions";
import { Search, Backup, Link } from "@material-ui/icons";
import "./MultipleChoices.css";
import BackgroundLink from "../FormStyle/Background/Link/BackgroundLink";
import { UploadImage } from "../FormStyle/Background/Upload/UploadImage";

const tabName = (index) => {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export const ChoiceIconPickModal = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [tabValue, setTabValue] = useState(0);

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	const handleImageChange = (imageObj) => {
		props.onImageChange(imageObj?.image ? imageObj.image : null);
	}

	

	return (
		<>
			<Button variant="contained" className="mx-1 cipm-button" size="small" onClick={() => setIsOpen(true)}>
				+ Add Icon
			</Button>
			<FormBuilderModal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				className="upload-modal ar__upload-modal choice-icon-picker-modal">
				<header>
					<h3>Add Icon</h3>
				</header>
				<div class="cipm-body-wrapper">
					<div>
						<Tabs value={tabValue} onChange={handleChangeTab} aria-label="simple tabs example">
							<Tab icon={<Search />} label="Search" {...tabName(0)} disableRipple />
							<Tab icon={<Backup />} label="Upload" {...tabName(1)} disableRipple />
							<Tab icon={<Link />} label="Link" {...tabName(2)} disableRipple />
						</Tabs>
					</div>
					<div className="cipm-tab-panel-wrapper">
						<TabPanel value={tabValue} index={0}>
							<IconFinderOptions onImageSelect={(image) => props.onImageChange(image)}/>
						</TabPanel>
						<TabPanel value={tabValue} index={1}>
							<div className="file__upload__wrapper__v2 choice__upload_wrapper">
								<UploadImage onImageUpload={(imageObj) => handleImageChange(imageObj)}/>
							</div>
						</TabPanel>
						<TabPanel value={tabValue} index={2}>
							<BackgroundLink onLinkSet={(imageObj) => handleImageChange(imageObj)}/>
						</TabPanel>
					</div>
				</div>
			</FormBuilderModal>
		</>
	);
};

export default ChoiceIconPickModal;

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box p={3}>
					<div>{children}</div>
				</Box>
			)}
		</div>
	);
}
