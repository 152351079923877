import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./EditActionButtons.css";
import ToggleButton from "../ToggleButton/ToggleButton";
import FormBuilderModal from "../FormBuilderModal/FormBuilderModal";
import BootstrapTooltip from "../../BootstrapTooltip";
import EditDateField from "./EditDateField";
import EditTimeField from "./EditTimeField";
import { FieldDeleteButton } from "./FieldDeleteButton";
import useDelayCallback from "../../../../hooks/useDelayCallback";
import { useIsMounted } from "../../../../hooks/IsMounted";
import { FontSelectOptions } from "../../../Common/FontSelectOptions";
import { FontSizeSelectOptions } from "../../../Common/FontSizeSelectOptions";
import { ChoiceViewSelectOptions } from "../../../Common/ChoiceViewSelectOptions";

const EditActionButtons = ({
  allInfo,
  handleModal,
  isButton,
  removeFieldItem,
  duplicateInputField,
  isOpen,
  togglePopup,
  fields,
  updateFields,
}) => {

  const isMounted = useIsMounted();
  const [color, setColor] = useState(allInfo.configuration.labelColor?.value);

  //Reducing the re-renders on color chaning.
  useDelayCallback(() => {
    if(isMounted) {
      handleSettingChange("labelColor",color);
    }
  },[color],100);



  const handleToggleChange = (item, isEnable, id, index, type) => {
    let newSelected = { ...allInfo.configuration[item] };
    let setIsEnable = isEnable === true ? false : true;

    newSelected.isEnable = setIsEnable;

    let newFields = fields;
    newFields[index] = {
      ...fields[index],
      configuration: { ...newFields[index].configuration, [item]: newSelected },
    };

    updateFields(newFields);
  };

  const handleOptionSetting = (item, type) => {
    if(type === "date") {
      return (
        <div className="ar__fullWidth_input">
          <EditDateField
            value={allInfo.configuration[item].value}
            onChange={(value) => handleSettingChange(item, value)}
          />
        </div>
      );
    } else if(type === "time") {
      return (
        <div className="ar__fullWidth_input">
        <EditTimeField
            value={allInfo.configuration[item].value}
            onChange={(value) => handleSettingChange(item, value)}
          />
        </div>
      );
    }

    return (
      <div className="ar__fullWidth_input">
        <input
          type={type}
          name="option-setting"
          defaultValue={allInfo.configuration[item].value}
          onChange={(e) => handleSettingChange(item, e.target.value)}
        />
      </div>
    );
  };

  const handleSettingChange = (item, value) => {
    let newFields = fields;
    let newSettingItem = {...newFields[allInfo.index].configuration[item]}
    newSettingItem.value = value;
    newFields[allInfo.index] = { ...fields[allInfo.index],
      configuration: { ...newFields[allInfo.index].configuration, [item] : newSettingItem},
    };
    updateFields(newFields);
  };

  const showFunctionalities = () => {
    const selectedKeys = Object.keys(allInfo.configuration);
    return (
      <div>
        <h6>Options</h6>

        {selectedKeys.map((item, index) => {
          return (
            <div className="each_functionality" key={index}>
              <h5>{allInfo.configuration[item].name}</h5>

              {
                item === "labelColor" ? 
                <input type="color" 
                  value={color} 
                  style={{backgroundColor: color}}
                  onChange={e => setColor(e.target.value)}
                /> : 
                item === "fontFamily" ? 
                <select className="d-block mt-2" value={allInfo.configuration[item].value} onChange={e => handleSettingChange(item,e.target.value)}>
                  <FontSelectOptions/>
                </select> :
                item === "fontSize" ? 
                <select className="d-block mt-2" value={allInfo.configuration[item].value} onChange={e => handleSettingChange(item,e.target.value)}>
                  <FontSizeSelectOptions/>
                </select> :
                item === "selectedView" ? 
                <select className="d-block mt-2" value={allInfo.configuration[item].value} onChange={e => handleSettingChange(item,e.target.value)}>
                  <ChoiceViewSelectOptions/>
                </select> :
                <ToggleButton
                  className="functionality_button"
                  selected={allInfo.configuration[item].isEnable}
                  toggleSelected={() =>
                    handleToggleChange(
                      item,
                      allInfo.configuration[item].isEnable,
                      allInfo.id,
                      allInfo.index,
                      allInfo.type
                    )
                  }
                />
              }
              
              

              {allInfo.configuration[item].showField &&
                allInfo.configuration[item].isEnable
                  ? handleOptionSetting(item, allInfo.configuration[item].type)
                  : ""}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="all__action__buttons">
      {!isButton && (
        <FieldDeleteButton removeFieldItem={() => removeFieldItem(allInfo.index)}/>        
      )}

      <FormBuilderModal
        open={isOpen}
        onClose={togglePopup}
        className={"form__builder__modal ar__options_modal"}
      >
        {showFunctionalities()}
      </FormBuilderModal>
      

      <BootstrapTooltip arrow title="Add" placement="top">
        <span className="edit_input__option-button" variant="light" onClick={() => handleModal(allInfo)}>
          <FontAwesomeIcon icon={["fas", "plus"]} />
        </span>
      </BootstrapTooltip>
      {!isButton &&
        allInfo.type !== "firstName" &&
        allInfo.type !== "lastName" &&
        allInfo.type !== "email" &&
        allInfo.type !== "phoneNumber" &&
        allInfo.type !== "birthDate" &&
        allInfo.type !== "companyName" &&
        allInfo.type !== "address" &&
        allInfo.type !== "city" &&
        allInfo.type !== "state" &&
        allInfo.type !== "zip" &&
        allInfo.type !== "note" &&
        allInfo.type !== "ipAddress" &&
        !allInfo.customFieldId && (
          
          <BootstrapTooltip arrow title="Duplicate" placement="top">
            <span className="edit_input__option-button"
              onClick={() =>
                duplicateInputField(allInfo.id, allInfo.index, allInfo.type)
              }
            >
              <FontAwesomeIcon icon={["fas", "clone"]} />
            </span>
          </BootstrapTooltip>
        )}
      <BootstrapTooltip arrow title="Options" placement="top">
        <span className="edit_input__option-button" onClick={() => togglePopup()}>
          <FontAwesomeIcon icon={["fas", "grip-vertical"]} />
        </span>
      </BootstrapTooltip>
    </div>
  );
};

export default EditActionButtons;
