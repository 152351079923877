import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { testImageUrl } from "../../../../NewFormUtils";

const BackgroundLink = (props) => {

  const [errorMessage, setErrorMessage] = useState("");
  const [verifying, setVerifying] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let value = e.target[0].value;
    if(value === "") {
      setErrorMessage("Please write the image url inside the input box.");
      return;
    }

    setVerifying(true);

    testImageUrl(value,5000).then((res) => {
      props.onLinkSet({
        image : value
      })
      setErrorMessage('');
      window.showNotification('SUCCESS','Successfully added');
    }).catch(error => {
      setErrorMessage('Please input a valid image URL. (Make sure you including \'https://\')');
    }).finally(res => {
      setVerifying(false);
    });

  }

  return (
    <div className="form__style__background__tabs__link">
      <form onSubmit={handleSubmit}>
        <input
          className="form__tabs__link__input"
          type="text"
          placeholder="Paste any image link from the web"
        />
        <p className="text-danger left-align">{ errorMessage }</p>
        <button className="form__tabs__link__button" type="submit">
          { verifying ? <><CircularProgress size={22} color="secondary" className="position-static mr-2"/> <p>Verifying</p></> : "Submit" }
        </button>
      </form>
    </div>
  );
};

export default BackgroundLink;
