import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover } from '@material-ui/core'
import React, { useState } from 'react'
import BootstrapTooltip from '../../BootstrapTooltip'

export const FieldDeleteButton = ({ removeFieldItem }) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
        <BootstrapTooltip arrow title="Delete" placement="top">
            <span className="edit_input__option-button" onClick={handleClick}>
                <FontAwesomeIcon icon={["far", "trash-alt"]} />
            </span>
        </BootstrapTooltip>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            >
            <Button aria-describedby={id} variant="contained" color="secondary" onClick={removeFieldItem}>
                Sure?
            </Button>
        </Popover>
        </>
    )
}
