import React from 'react'

const typeImages = {
    embedImage : 'form_demo_content_image.png',
    embedVideo : 'form_demo_content_embed_video.png',
    embedAudio : 'form_demo_content_embed_audio.png',
    embedAnything : 'form_demo_content_embed.png'
}

export const EmbedContent = (props) => {
  return (
    <div className="ar__embed_image mt-40px">
        <img
            className="embed__image"
            src={`https://d24gujj67p1uc1.cloudfront.net/asset/images/releases/1.0.0/${typeImages[props.type]}`}
            alt="demo content"
        />
    </div>
  )
}
