import React, { useEffect, useState } from "react";
import Thankyou from "../ThankYouPage/Thankyou";
import PreviewText from "./PreviewText";
import PreviewEmbed from "./PreviewEmbed";
import PreviewFileUpload from "./PreviewFileUpload";
import PreviewTextArea from "./PreviewTextArea";
import PreviewInput from "./PreviewInput";
import PreviewDate from "./PreviewDate";
import PreviewTime from './PreviewTime'
import PreviewCheckbox from "./PreviewCheckbox";
import { useSimpleFormValidator } from "./useSimpleFormValidator";
import PreviewDropdown from "./PreviewDropdown";
import PreviewRadio from "./PreviewRadio";
import PreviewDefaultField from "./PreviewDefaultField";
import PreviewHiddenField from "./PreviewHiddenField";
import { useSelector } from "react-redux";
import PreviewPhoneNumberInput from "./PreviewPhoneNumberInput";
import { PreviewButton } from "./PreviewButton";
import PreviewNumber from "./PreviewNumber";
import useStateCallback from "../../../../hooks/useStateCallback";
import FormUtils from "../../NewFormUtils";
import { PreviewProgressbar } from "./PreviewProgressbar";
import { AnimationHandler } from "./AnimationHandler";
import PreviewChoiceGrid from "./PreviewChoiceGrid";
import PreviewChoiceList from "./PreviewChoiceList";

export const PreviewPage = (props) => {

  const { formSetting } = useSelector(state => state.customFormBuilderReducer);

  const simpleValidator = useSimpleFormValidator();

  const [previewList, setPreviewList] = useStateCallback([]);
  const [previewValue, setPreviewValue] = useState({});
  const [percentage, setPercentage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const coverHeight = props.builderJson?.coverHeight ? props.builderJson.coverHeight : 170;
  const isFloatingEnabled = props.builderJson?.cover && props.builderJson?.floatingCover;

  useEffect(() => {
    loadData();
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = () => {
    let previewObject = FormUtils.readyPreviewObject(props.fields);
    setPreviewValue(previewObject);
    setPreviewList(
      props.fields.filter((dt) => dt.page === 1),
      state => FormUtils.focusFirstField(state)
    );
  }

  const handleNextPreviewSteps = (step) => {
    if (!simpleValidator.validate()) {
      return;
    }
    simpleValidator.hideMessages();
    let nextPage = props.fields.find((dt) => dt.page > step); //Next Page
    if (nextPage) {
      setPreviewList(
        props.fields.filter((dt) => dt.page === nextPage.page),
        () => setCurrentPage(currentPage + 1)
      );
    } else {
      setPreviewList("");
    }
    setPercentage(FormUtils.getProgressbarPercentage(step,props.fields));
  };

  const handleFocusNextField = (e,index) => {
    e.stopPropagation();
    if(!FormUtils.focusNextField(previewList,index)) {
      handleNextPreviewSteps(previewList[index].page);
    }
  }

  const handleFocusFirstField = () => {
    FormUtils.focusFirstField(previewList);
  }

  return (
    <>
      <div className={`new__form__builder__preview__body ${props.builderJson?.logo ? "logo__area" : ""} pb-5`} style={{ paddingTop: isFloatingEnabled ? coverHeight : 0}}>
        {
          props.builderJson.progressbar &&
          <PreviewProgressbar percentage={percentage} color={ props.builderJson.progressbar.backgroundColor } fontColor={ props.builderJson.progressbar.color }/>
        }
        <div className="new__form__builder__preview__body__container">
          { previewList &&
            <div
              className="new__form__builder__preview__title"
              dangerouslySetInnerHTML={{ __html: props.formName }}
            />
          }
          <AnimationHandler animation={props.builderJson.animation} page={currentPage} onAnimationChangeCallback={handleFocusFirstField}>
            <form onSubmit={e => e.preventDefault()}>
              {previewList ? (
                previewList.map((dt, index) => {
                  if (dt.type === "button") {
                    return <PreviewButton
                            key={dt.id}
                            data={dt}
                            onClick={() => handleNextPreviewSteps(dt.page)}
                          />
                  } else if (dt.type === "textarea" || dt.type === "address" || dt.type === "note") {
                    return <PreviewTextArea
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                          />
                  } else if (dt.type === "firstName" || dt.type === "lastName" || dt.type === "email" || dt.type === "companyName" || dt.type === "city" || dt.type === "state" || dt.type === "zip" || dt.type === "ipAddress" || dt.type === "link" || dt.type === "shortAnswer" || dt.type === "rating") {
                    return <PreviewInput
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                            onEnterPress={e => handleFocusNextField(e,index)}
                          />
                  } else if(dt.type === "number") {
                    return <PreviewNumber
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                            onEnterPress={e => handleFocusNextField(e,index)}
                          />
                  } else if (dt.type === "phoneNumber" || dt.type === "phoneInput") {
                    return <PreviewPhoneNumberInput
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                            onEnterPress={e => handleFocusNextField(e,index)}
                          />
                  } else if (dt.type === "date" || dt.type === "birthDate") {
                    return <PreviewDate
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                          />
                  } else if (dt.type === "time") {
                    return <PreviewTime
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                          />
                  } else if (dt.type === "fileUpload") {
                    return <PreviewFileUpload 
                            data={dt} 
                            key={dt.id}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                          />
                  } else if (dt.type === "checkbox") {
                    return <PreviewCheckbox
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                          />
                  } else if (dt.type === "choice") {
                    return dt.configuration.selectedView?.value === 'grid' ? 
                      <PreviewChoiceGrid
                            key={dt.id}
                            data={dt}
                            index={index}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                            onEnterPress={e => handleFocusNextField(e,index)}
                      /> : 
                      <PreviewChoiceList
                            key={dt.id}
                            data={dt}
                            index={index}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                            onEnterPress={e => handleFocusNextField(e,index)}
                      />
                  } else if (dt.type === "dropdown") {
                    return <PreviewDropdown
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                          />
                  } else if (dt.type === "radio") {
                    return <PreviewRadio
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                          />
                  } else if (dt.type === "hidden") {
                    return <PreviewHiddenField
                            key={dt.id}
                            data={dt}
                          />
                  } else if (dt.type === "heading1" || dt.type === "heading2" || dt.type === "heading3" || dt.type === "label" || dt.type === "texts") {
                    return <PreviewText 
                            data={dt} 
                            key={dt.id}
                          />;
                  } else if (dt.type === "embedImage" || dt.type === "embedVideo" || dt.type === "embedAudio" || dt.type === "embedAnything") {
                    return <PreviewEmbed 
                            data={dt} 
                            key={dt.id}
                          />;
                  } else if (dt.type === "thankYouPage") {
                    return !dt.configuration.hideBlock.isEnable && <Thankyou formSetting={formSetting} from="preview"/>;
                  } else {
                    return <PreviewDefaultField 
                            key={dt.id} 
                            data={dt}
                          />
                  }
                })
              ) : (
                <Thankyou formSetting={formSetting} from="preview"/>
              )}
            </form>
          </AnimationHandler>
        </div>
      </div>
    </>
  );
};
export default PreviewPage;
