import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {MenuItem, Select, Radio, RadioGroup, FormControlLabel, FormControl} from "@material-ui/core";
import { moveContactToCampaign } from '../../../api/formBuilderApi';
import Utils from "../../../helpers/Utils";
import {IconList} from "../../../constants/IconList";
import GlobalModal from "../../Common/Modal/GlobalModal";
import { deleteCustomFormEntry } from "../../../api/customFormBuilderApi";
import { confirmAlert } from "react-confirm-alert";
import CustomFields from './CustomFields';

const EntryAction = props => {

    const [openViewSubmission, setOpenViewSubmission] = useState(false);
    const [openMoveToCampaignModal, setOpenMoveToCampaign] = useState(false);
    
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [campaignSearchKey, setCampaignSearchKey] = useState('');

    const callBack = e => {
        e.preventDefault();
        e.stopPropagation();

        switch (e.target.value) {
            case 'view_submission':
                setOpenViewSubmission(true);
                break;
            case 'add_to_campaign':
                addToCampaign();
                break;
            case 'delete':
                formEntryDelete();
                break;
            default:
                break;
        }
    }



    const addToCampaign = () => {
        setOpenMoveToCampaign(true);
    }

    const formEntryDelete = () => {
        confirmAlert({
            title: 'Confirm to delete?',
            message: 'You will not able to undo it.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteCustomFormEntry({entryId: props.entry.id}).then(response => {
                            if (response.data.status === 'success') {
                                Utils.showNotification(response.data.html, 'success');
                                props.fetchFormEntries();
                            } else {
                                Utils.showNotification(response.data.html, 'error');
                            }
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    }

    const renderCampaigns = () => {
        return props.campaigns.map((campaign, i) => {
            if (campaignSearchKey) {
                if (campaign.title.toLowerCase().includes(campaignSearchKey.toLowerCase())) {
                    return <FormControlLabel value={campaign.id} control={<Radio />} label={campaign.title} checked={campaign.id == selectedCampaign} />
                }
            } else {
                return <FormControlLabel value={campaign.id} control={<Radio />} label={campaign.title} checked={campaign.id == selectedCampaign} />
            }

        });
    }

    const handleCampaignChange = (e) => {
        setSelectedCampaign(e.target.value);
    }

    const moveToCampaign = () => {
        if(!selectedCampaign) {
            window.showNotification('ERROR','Please select a campaign.');
            return;
        }
        moveContactToCampaign({contact_ids: [props.entry.contact_id], campaignId: selectedCampaign}).then(response => {
            if (response.data.status === 'success') {
                Utils.showNotification('Contact moved to selected campaign successfully', 'success');
                setOpenMoveToCampaign(false);
            } else {
                Utils.showNotification(response.data.html, 'error');
            }
        });
    }
    
    return (
        <div>

            {openViewSubmission && 
                <CustomFields
                    entry={props.entry}
                    openViewSubmission={openViewSubmission}
                    setOpenViewSubmission={setOpenViewSubmission}
                />
            }

            {openMoveToCampaignModal &&
                <GlobalModal open={openMoveToCampaignModal} onClose={() => setOpenMoveToCampaign(false)} title={"Add to Campaigns"} buttonText={"Assign Campaign"} buttonIcon={IconList.copyIcon} buttonClick={moveToCampaign}>
                    <div className="campaign_modal">
                        <p>You can add to the following campaigns:</p>
                        <div className="input_wrapper pp_add_to_campaigns d-flex mt-2">
                            <input type="text" placeholder="Type and Search" value={campaignSearchKey} onChange={e => setCampaignSearchKey(e.target.value)}/>
                            {IconList.search}
                        </div>
                        <div className="campaign_list">
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="campaign" name="campaign" value={selectedCampaign} onChange={handleCampaignChange}>
                                    { renderCampaigns() }
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                </GlobalModal>
            }

            <Select className="pp_form_entry_select" labelId="demo-simple-select-label" id="demo-simple-select" value={'select_action'} onChange={callBack}>
                <MenuItem className="dropdownhelper-menuitem-class" disabled value={'select_action'}>Select Action</MenuItem>
                <MenuItem className="dropdownhelper-menuitem-class" value="view_submission">View Submission</MenuItem>
                <MenuItem className="dropdownhelper-menuitem-class" value="add_to_campaign">Add To Campaign</MenuItem>
                <MenuItem className="dropdownhelper-menuitem-class" value="delete">Delete</MenuItem>
            </Select>
        </div>

    );
}

const mapStateToProps = state => {
    return {
        campaigns: state.formBuilderReducer.campaigns
    }
}

const mapDispatchToProps = dispatch => {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryAction);