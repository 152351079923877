import React, { useEffect, useState } from 'react';
import { useSimpleValidator } from '../../../../hooks/useSimpleValidator';
import FormBuilderModal from '../FormBuilderModal/FormBuilderModal';
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed';
import EditActionButtons from './EditActionButtons';

const embedDetails = {
    embedImage : {
        type : "image",
        title: "Embed Image",
        text : "Embed any kinds of Image",
        placeholder : "Paste the image link",
        icon : <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"/><circle cx="8.5" cy="8.5" r="1.5"/><path d="m21 15-5-5L5 21"/></svg>
    },
    embedVideo : {
        type : "video",
        title: "Embed Video",
        text : "Works with YouTube, Vimeo, Loom, MP4s and more",
        placeholder : "Paste the link",
        icon : <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="2" width="20" height="20" rx="2.18" ry="2.18"/><path d="M7 2v20M17 2v20M2 12h20M2 7h5M2 17h5m10 0h5M17 7h5"/></svg>
    },
    embedAudio : {
        type : "audio",
        title: "Embed Audio",
        text : "Works with SoundCloud, Spotify, MP3s and more",
        placeholder : "Paste the audio link",
        icon : <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M11 5 6 9H2v6h4l5 4V5zm8.07-.07a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"/></svg>
    },
    embedAnything : {
        type : "anything",
        title: "Embed Anything",
        text : "Works with links of Websites, PDFs, Calendly, Google Maps, Tweets, public Figma files and more",
        placeholder : "Paste the link",
        icon : <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"/><path d="m16.24 7.76-2.12 6.36-6.36 2.12 2.12-6.36 6.36-2.12z"/></svg>
    },
}

export const EditEmbedFields = (props) => {

    const embedObj = embedDetails[props.type];
    const [isOpen, setIsOpen] = useState(false);
    const [embedFileShow, setEmbedFileShow] = useState(false);
    const [links, setLinks] = useState("");
    const simpleValidator = useSimpleValidator({
        url : "Invalid URL. (Make sure you include 'https://')"
    });

    useEffect(() => {
        try {
            if(props.allInfo[embedObj.type].source) {
                setLinks(props.allInfo[embedObj.type].source);
                setEmbedFileShow(true);
            }
        } catch (e) { console.log(e) }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps


    // Convert Youtube URL to embed ID
    const getEmbedId = (url) => {
        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        let match = url.match(regExp);

        if (match && match[2].length === 11) {
            return match[2];
        } else {
            return false;
        }
    }

    const getSourceUrl = (url) => {
        let sourceUrl = url ? url : "";
        sourceUrl = url.replace('https://www.loom.com/share/', "https://www.loom.com/embed/");
        let embedId = getEmbedId(sourceUrl);
        return embedId ? `https://www.youtube.com/embed/${embedId}` : sourceUrl;
    }

    const addEmbeddedFile = (type) => {
        if(!simpleValidator.validate()){
            return "";
        }
        setEmbedFileShow(true);
        setIsOpen(false);
        props.handleAddEmbeddedFile(type,links)
    }

    const updateLink = (value) => {
        setLinks(value);
    };


    return (
        <div className={`specific__input__field__wr ${
            props.allInfo.configuration.hideBlock.isEnable
            ? "decrease__opacity"
            : ""
        }`}>
            <EditActionButtons
                allInfo={props.allInfo}
                handleModal={props.handleModal}
                addInputField={props.addInputField}
                removeFieldItem={props.removeFieldItem}
                duplicateInputField={props.duplicateInputField}
                isOpen={props.isOpen}
                togglePopup={props.togglePopup}
                fields={props.fields}
                updateFields={props.updateFields}
                setShow={props.setShow}
            />
            <div className="specific__input__field__wr__left ar__add_an">
                { embedFileShow && <YoutubeEmbed source={getSourceUrl(props.allInfo[embedObj.type].source)} /> } 
                <div className="specific__input__field__wr__left">
                    { embedObj.icon }
                    <input
                        type="button"
                        value={ embedFileShow ? `Change ${embedObj.title.toLowerCase()}` : embedObj.title }
                        onClick={() => setIsOpen(true)}
                    />
                    <FormBuilderModal
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        className={"form__builder__modal ar___embed_modal"}>
                        <b>Embed Link</b>
                        {simpleValidator.message("Embed link", links, "required|url")}
                        <input
                            value={links}
                            placeholder={ embedObj.placeholder }
                            onChange={(e) => updateLink(e.target.value)}
                        />

                        <button
                            className="ar__embed_submit"
                            onClick={() => addEmbeddedFile(embedObj.type)}
                        >
                            { embedObj.title }
                        </button>

                        <br />
                        <p>{ embedObj.text }</p>
                    </FormBuilderModal>
                </div>
            </div>
        </div>
    )
};
