import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

export function RouterPrompt(props) {
  const { when, onOK, onCancel } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return false;
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      await Promise.resolve(onOK());
    }

    history.block(() => {});
    history.push(currentPath);
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    await Promise.resolve(onCancel());
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <Dialog
        fullScreen={fullScreen}
        open={showPrompt}
        onClose={handleCancel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Sure to leave the page?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            There are unsaved changes. Are you sure want to leave this page ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOK} color="primary">
            Ok
          </Button>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  ) : null;
}