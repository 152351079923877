import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import "./ImagePreview.css";
import { useSelector } from "react-redux";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { CoverImagePreview } from "./CoverImagePreview";

const ImagePreview = ({ prevModal, handleUploadOpen, setPreviewModal}) => {

  const { builderJson } = useSelector(state => state.customFormBuilderReducer);
  
  return (
    <div className={prevModal && builderJson?.floatingCover ? "preview__top__fixed" : ""}>
      {
        prevModal && !builderJson?.cover &&
        <div className="modal__preview__content__heading__wr">
          <h2>Preview</h2>
          <Button
            className="back__to__edit"
            onClick={() => setPreviewModal(false)}
          >
            <ExitToAppIcon />
            <span>Back to Edit</span>
          </Button>
        </div>
      }

      {builderJson?.cover && 
        <CoverImagePreview 
          prevModal={prevModal} 
          handleUploadOpen={handleUploadOpen}
          setPreviewModal={setPreviewModal}
          builderJson={builderJson}/>}

      {builderJson?.logo && 
        <Container className={"logo-container"}>
          <div className="col-lg-8 mx-auto">
            <div
              className={`logo-area ${!builderJson?.cover ? "mt-3" : ""}`}
              onClick={() => handleUploadOpen && handleUploadOpen("logo")}
              title="change logo"
            >
              {builderJson?.logo && 
                <div 
                  className={`preview__image__ratio
                              ${!builderJson?.cover ? "mt-0" : ""}
                              ${builderJson?.logoFillColor ? "fill__with__color" : ""} 
                              ${builderJson?.logoSize !== undefined ? builderJson?.logoSize + "__logo" : ""}`}
                  style={ {backgroundImage: `url(${builderJson.logo})`} }
                />
              }
            </div>
          
          </div>
        </Container>
      }
    </div>
  );
}

export default ImagePreview;
