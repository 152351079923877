import React from 'react'
import BootstrapTooltip from '../../BootstrapTooltip';
import formIcons from '../AddModal/FormIcons';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import MaskedInput from 'react-text-mask';

const numberMask = createNumberMask({
  prefix: '',
  allowNegative : true
});

const PreviewNumber = (props) => {

    const labelColor = props.data.configuration.labelColor ? props.data.configuration.labelColor.value : "#333";
    const fontFamily = props.data.configuration.fontFamily ? props.data.configuration.fontFamily.value : "'Poppins', sans-serif";
    const fontSize = props.data.configuration.fontSize ? props.data.configuration.fontSize.value : 14;


    return (
        !props.data.configuration.hideBlock.isEnable && (
          <div className="mb-3 singleItem" key={props.data.id}>
            <h5 style={{color : labelColor, fontFamily : fontFamily, fontSize : parseInt(fontSize)}}>{props.data.label}</h5>
            <div>
              { formIcons[props.data.type] }
              <MaskedInput
                id={props.data.id}
                className='ml-1'
                mask={numberMask}
                name={props.data.id}
                placeholder={props.data.placeholder}
                value={props.previewValue[props.data.id]}
                onChange={(e) => {
                  let value = e.target.value.replace("_","");
                  value = value.replace(/,/g,"");
                  props.setPreviewValue({
                    ...props.previewValue,
                    [e.target.name]: value,
                  });
                }}
                onKeyDown={(e) => e.key === 'Enter' && props.onEnterPress(e)}
              />
              {props.data.configuration.required.isEnable && (
                <BootstrapTooltip
                  arrow
                  title="Required"
                  placement="top"
                >
                  <span className="ar__req">*</span>
                </BootstrapTooltip>
              )}
              {props.simpleValidator.addFormValidator(
                props.data.component,
                props.data.label === null ? "" : props.data.label, //Validator genrates error if value null
                props.previewValue[props.data.id],
                props.data.configuration
              )}
            </div>
          </div>
        )
      );
}

export default PreviewNumber;