export const IconList = {
    bluePlusIcon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10.0002 9.99967H3.3335H10.0002Z" fill="#3C7EF3"/> <path d="M10.0002 16.6663V9.99967M10.0002 9.99967V3.33301M10.0002 9.99967H16.6668M10.0002 9.99967H3.3335" stroke="#3C7EF3" stroke-width="4" stroke-linecap="round"/> </svg>,
    duplicateIcon: <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.333 6h-6C6.597 6 6 6.597 6 7.333v6c0 .737.597 1.334 1.333 1.334h6c.737 0 1.334-.597 1.334-1.334v-6c0-.736-.597-1.333-1.334-1.333z" stroke="#133159" stroke-opacity=".65" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/><path d="M3.333 10h-.666a1.333 1.333 0 0 1-1.333-1.333v-6a1.333 1.333 0 0 1 1.333-1.333h6A1.333 1.333 0 0 1 10 2.667v.667" stroke="#133159" stroke-opacity=".65" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/></svg> ,
    duplicateIconSize20: <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.667 7.5h-7.5c-.92 0-1.667.746-1.667 1.667v7.5c0 .92.746 1.666 1.667 1.666h7.5c.92 0 1.666-.746 1.666-1.666v-7.5c0-.92-.746-1.667-1.666-1.667z" stroke="#fff" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/><path d="M4.167 12.5h-.833a1.667 1.667 0 0 1-1.667-1.667v-7.5a1.667 1.667 0 0 1 1.667-1.666h7.5A1.667 1.667 0 0 1 12.5 3.333v.834" stroke="#fff" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/></svg>,
    pencilIcon : <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m9.086 2.914 4 4L4.4 15.602l-3.566.393a.75.75 0 0 1-.828-.828l.397-3.57 8.684-8.683zm6.475-.596L13.682.439a1.5 1.5 0 0 0-2.122 0L9.793 2.207l4 4 1.768-1.767a1.5 1.5 0 0 0 0-2.122z" fill="#133159" fill-opacity=".65"/></svg> ,
    trashIcon : <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m2 5 .93 8.828a.75.75 0 0 0 .745.672h8.65a.75.75 0 0 0 .746-.672L14 5H2zm7.75 6.796L8 10.046l-1.75 1.75L5.454 11l1.75-1.75-1.75-1.75.796-.796L8 8.454l1.75-1.75.796.796-1.75 1.75 1.75 1.75-.796.796zM14.625 1.5H1.375A.375.375 0 0 0 1 1.875v1.75c0 .207.168.375.375.375h13.25A.375.375 0 0 0 15 3.625v-1.75a.375.375 0 0 0-.375-.375z" fill="#FF264A"/></svg> ,
    redDeleteIcon: <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.7497 1.99983H8.99979V1.49984C8.99979 0.671425 8.32824 -0.00012207 7.49983 -0.00012207H5.49987C4.67145 -0.00012207 3.99991 0.671425 3.99991 1.49984V1.99983H1.24997C0.559643 1.99983 0 2.55947 0 3.2498V4.24978C0 4.52593 0.223839 4.74977 0.499988 4.74977H12.4997C12.7759 4.74977 12.9997 4.52593 12.9997 4.24978V3.2498C12.9997 2.55947 12.4401 1.99983 11.7497 1.99983ZM4.99988 1.49984C4.99988 1.22422 5.22425 0.999855 5.49987 0.999855H7.49983C7.77544 0.999855 7.99981 1.22422 7.99981 1.49984V1.99983H4.99988V1.49984Z" fill="#FF264A"/> <path d="M0.949209 5.75043C0.859992 5.75043 0.7889 5.82499 0.79315 5.91411L1.20564 14.5715C1.24376 15.3727 1.90187 16.0002 2.70373 16.0002H10.2967C11.0985 16.0002 11.7566 15.3727 11.7948 14.5715L12.2073 5.91411C12.2115 5.82499 12.1404 5.75043 12.0512 5.75043H0.949209ZM8.50016 7.0004C8.50016 6.72415 8.7239 6.50041 9.00015 6.50041C9.27639 6.50041 9.50014 6.72415 9.50014 7.0004V13.5002C9.50014 13.7765 9.27639 14.0002 9.00015 14.0002C8.7239 14.0002 8.50016 13.7765 8.50016 13.5002V7.0004ZM6.00022 7.0004C6.00022 6.72415 6.22396 6.50041 6.5002 6.50041C6.77645 6.50041 7.00019 6.72415 7.00019 7.0004V13.5002C7.00019 13.7765 6.77645 14.0002 6.5002 14.0002C6.22396 14.0002 6.00022 13.7765 6.00022 13.5002V7.0004ZM3.50027 7.0004C3.50027 6.72415 3.72402 6.50041 4.00026 6.50041C4.27651 6.50041 4.50025 6.72415 4.50025 7.0004V13.5002C4.50025 13.7765 4.27651 14.0002 4.00026 14.0002C3.72402 14.0002 3.50027 13.7765 3.50027 13.5002V7.0004Z" fill="#FF264A"/> </svg>,
    saveIcon: <svg width="18" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.38 4.678 13.79 1.8a1.677 1.677 0 0 0-.547-.407 1.532 1.532 0 0 0-.646-.142H3.375c-.597 0-1.169.263-1.591.732a2.648 2.648 0 0 0-.659 1.768v12.5c0 .663.237 1.299.659 1.768.422.469.994.732 1.591.732h11.25c.597 0 1.169-.263 1.591-.732a2.648 2.648 0 0 0 .659-1.768V6.004c0-.246-.043-.49-.128-.718a1.889 1.889 0 0 0-.366-.608zM4.22 4.375h6.187c.075 0 .146.033.2.091a.33.33 0 0 1 .082.221v1.875a.33.33 0 0 1-.083.221.268.268 0 0 1-.199.092H4.22a.268.268 0 0 1-.2-.092.33.33 0 0 1-.082-.22V4.686c0-.082.03-.162.083-.22a.268.268 0 0 1 .199-.092zM9.132 16.87a2.599 2.599 0 0 1-1.611-.463 3.051 3.051 0 0 1-1.094-1.395 3.444 3.444 0 0 1-.192-1.837 3.25 3.25 0 0 1 .776-1.636 2.734 2.734 0 0 1 1.473-.862 2.556 2.556 0 0 1 1.653.214c.519.255.957.678 1.255 1.214.299.537.444 1.16.417 1.79a3.298 3.298 0 0 1-.82 2.063c-.496.55-1.158.875-1.857.912z" fill="#fff"/><path d="M9 15.625c.932 0 1.688-.84 1.688-1.875 0-1.036-.756-1.875-1.688-1.875-.932 0-1.688.84-1.688 1.875 0 1.036.756 1.875 1.688 1.875z" fill="#fff"/></svg>,
    checkIcon: <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m13.333 25 10 8.333 13.334-16.666" stroke="#000" stroke-width="4"/></svg>,
    folderIcon: <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M48.438 14.844a5.469 5.469 0 0 0-5.47-5.469H21.496a2.333 2.333 0 0 1-1.3-.39L17.48 7.168a5.446 5.446 0 0 0-3.038-.919h-7.41a5.469 5.469 0 0 0-5.47 5.469v4.687a.781.781 0 0 0 .782.782h45.312a.781.781 0 0 0 .782-.782v-1.562zM1.562 38.28a5.469 5.469 0 0 0 5.47 5.469h35.937a5.469 5.469 0 0 0 5.468-5.469V21.094a.781.781 0 0 0-.78-.782H2.343a.781.781 0 0 0-.781.782V38.28z" fill="#000"/></svg>,
    closeIcon: <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M39.792 10.209c-8.125-8.125-21.459-8.125-29.584 0-8.124 8.125-8.124 21.458 0 29.583 8.126 8.125 21.25 8.125 29.376 0 8.124-8.125 8.333-21.459.208-29.584zM30.833 33.75 25 27.917l-5.833 5.833-2.917-2.916L22.084 25l-5.834-5.833 2.917-2.917L25 22.084l5.834-5.834 2.916 2.917L27.917 25l5.833 5.834-2.916 2.916z" fill="#000"/></svg>,
    shareIcon: <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M37.5 16.667a6.25 6.25 0 1 0 0-12.5 6.25 6.25 0 0 0 0 12.5zM12.5 31.25a6.25 6.25 0 1 0 0-12.5 6.25 6.25 0 0 0 0 12.5zM37.5 45.833a6.25 6.25 0 1 0 0-12.5 6.25 6.25 0 0 0 0 12.5zM17.896 28.146l14.229 8.291M32.104 13.563l-14.208 8.291" stroke="#000" stroke-width="3" stroke-linecap="round" strokeLinejoin="round"/></svg>,
    whiteFolderIcon: <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.375 5.938a2.188 2.188 0 0 0-2.188-2.188H8.598a.933.933 0 0 1-.52-.156l-1.086-.726A2.179 2.179 0 0 0 5.777 2.5H2.812A2.188 2.188 0 0 0 .626 4.688v1.875a.313.313 0 0 0 .313.312h18.125a.313.313 0 0 0 .312-.313v-.625zM.625 15.313A2.187 2.187 0 0 0 2.813 17.5h14.374a2.188 2.188 0 0 0 2.188-2.188V8.439a.313.313 0 0 0-.313-.313H.938a.313.313 0 0 0-.312.313v6.874z" fill="#fff"/></svg>,
    whiteCirclePlusIcon: <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="10" fill="#fff"/><path d="M13.846 10c0 .354-.03.641-.385.641h-2.82v2.82c0 .354-.287.385-.641.385-.355 0-.641-.031-.641-.385v-2.82h-2.82c-.354 0-.385-.287-.385-.641 0-.354.031-.641.385-.641h2.82v-2.82c0-.354.286-.385.641-.385.354 0 .641.03.641.385v2.82h2.82c.354 0 .385.287.385.641z" className="accent--fill--color"/></svg>,
    infoTooltipIcon: <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 8c0-3.313-2.688-6-6-6-3.313 0-6 2.688-6 6 0 3.313 2.688 6 6 6 3.313 0 6-2.688 6-6z" stroke="#133159" stroke-opacity=".35" stroke-miterlimit="10"/><path d="M7.82 5.189 8 8.999l.179-3.81a.18.18 0 0 0-.181-.188v0a.18.18 0 0 0-.178.188v0z" stroke="#133159" stroke-opacity=".35" stroke-linecap="round" strokeLinejoin="round"/><path d="M8 11.497a.625.625 0 1 1 0-1.25.625.625 0 0 1 0 1.25z" fill="#133159" fill-opacity=".35"/></svg>,
    useIcon: <svg width="10" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h10v15l-5-3.088L0 15V0z" fill="#133159"/></svg>,
    callIcon: <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.846 3.64a.442.442 0 0 0-.344.102.432.432 0 0 0-.15.324l-.004.296a.449.449 0 0 0 .385.451 3.609 3.609 0 0 1 2.05 1.024 3.608 3.608 0 0 1 1.024 2.05.451.451 0 0 0 .451.385l.297-.004a.439.439 0 0 0 .324-.15.433.433 0 0 0 .101-.344 4.811 4.811 0 0 0-1.37-2.765A4.811 4.811 0 0 0 8.847 3.64zm.007-2.365a.439.439 0 0 0-.334.108.43.43 0 0 0-.144.32l-.003.295a.449.449 0 0 0 .406.454 6.01 6.01 0 0 1 3.655 1.735 6.011 6.011 0 0 1 1.735 3.655.45.45 0 0 0 .454.406l.295-.003a.436.436 0 0 0 .409-.299.437.437 0 0 0 .019-.179A7.211 7.211 0 0 0 13.26 3.36a7.212 7.212 0 0 0-4.41-2.083h.002zM8.329 6.45l-.005.305a.451.451 0 0 0 .32.436 1.204 1.204 0 0 1 .785.785.456.456 0 0 0 .436.32l.305-.004a.438.438 0 0 0 .343-.173.434.434 0 0 0 .072-.378 2.41 2.41 0 0 0-1.707-1.707.444.444 0 0 0-.378.073.432.432 0 0 0-.172.343zM6.957 12.509a10.02 10.02 0 0 0 4.711 4.71l1.292-1.585a.618.618 0 0 1 .337-.21.635.635 0 0 1 .4.036l2.96 1.29a.647.647 0 0 1 .374.726l-.607 2.735a.618.618 0 0 1-.611.486C9.056 20.636 3.541 15.11 3.48 8.364a.623.623 0 0 1 .485-.612l2.734-.608a.648.648 0 0 1 .728.374l1.29 2.961a.635.635 0 0 1 .036.4.618.618 0 0 1-.21.337l-1.586 1.293z" fill="#fff"/></svg>,
    appointmentIcon: <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.735 9.141c.046 0 .092 0 .138.005V3.593H.341v9.6a1.38 1.38 0 0 0 1.379 1.38h7.65a4.475 4.475 0 0 1 4.366-5.431zM3.453 7.433H9.93a.292.292 0 0 1 0 .585H3.453a.292.292 0 0 1 0-.585zm3.877 3.3H3.453a.293.293 0 0 1 0-.585H7.33a.292.292 0 1 1 0 .585z" fill="#546376"/><path d="M13.734 9.73a3.885 3.885 0 1 0 0 7.77 3.885 3.885 0 0 0 0-7.77zm2.428 2.9-2.775 2.652a.29.29 0 0 1-.2.08.308.308 0 0 1-.209-.084l-1.575-1.58a.293.293 0 0 1 .413-.413l1.375 1.375 2.57-2.453a.292.292 0 0 1 .413.009.295.295 0 0 1-.012.413zM13.873 1.878A1.38 1.38 0 0 0 12.495.499h-1.839v.923a.292.292 0 1 1-.585 0V.5H4.14v.923a.292.292 0 1 1-.585 0V.5H1.716a1.38 1.38 0 0 0-1.38 1.379v1.136H13.87V1.878h.004z" fill="#546376"/></svg>,
    taskIcon: <svg width="13" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.498 2.014H8.293a.338.338 0 0 1-.317-.253A1.226 1.226 0 0 0 6.73.767a1.247 1.247 0 0 0-1.247 1.015.317.317 0 0 1-.317.232H4.003v2.114h5.495V2.014z" fill="#546376"/><path d="M12.879 16.261V4.151a.867.867 0 0 0-.93-.867H10.13v1.078c0 .17-.106.402-.275.402H3.622c-.17 0-.254-.233-.254-.402V3.284H1.55a.867.867 0 0 0-.93.867v12.11a.951.951 0 0 0 .93.973H11.95a.951.951 0 0 0 .93-.972zm-7.841-2.79-1.649 1.543a.296.296 0 0 1-.211.106.339.339 0 0 1-.233-.106l-.866-.887a.317.317 0 0 1 .02-.444c.124-.12.321-.12.445 0l.634.676 1.437-1.352a.317.317 0 0 1 .423.465zm0-3.381-1.649 1.543a.296.296 0 0 1-.211.106.339.339 0 0 1-.233-.106l-.866-.888a.317.317 0 0 1 .02-.444c.124-.12.321-.12.445 0l.634.677 1.437-1.353a.317.317 0 0 1 .423.465zm0-3.382L3.389 8.251a.296.296 0 0 1-.211.106.338.338 0 0 1-.233-.106l-.866-.887a.317.317 0 0 1 .02-.444c.124-.121.321-.121.445 0l.634.676 1.437-1.353a.317.317 0 0 1 .423.465zm6.15 7.99H6.327a.317.317 0 0 1 0-.635h4.86a.317.317 0 0 1 0 .634zm0-3.382H6.327a.317.317 0 0 1 0-.634h4.86a.317.317 0 0 1 0 .634zm0-3.382H6.327a.317.317 0 0 1 0-.634h4.86a.317.317 0 0 1 0 .634z" fill="#546376"/></svg>,
    deadLineIcon: <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.812.067A7.933 7.933 0 1 0 16.745 8 7.941 7.941 0 0 0 8.812.067z" fill="#546376"/><path d="M8.81 2.901a.567.567 0 0 0-.566.567v3.966H4.278a.567.567 0 1 0 0 1.133H8.81a.567.567 0 0 0 .566-.566V3.468a.567.567 0 0 0-.566-.567z" fill="#fff"/></svg>,
    blackEmailIcon: <svg width="17" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.76 9h-.03c-.94 0-1.887-.732-2.579-1.266-.112-.087-4.632-3.4-5.406-4.005V10.5a1.5 1.5 0 0 0 1.5 1.5h13a1.5 1.5 0 0 0 1.5-1.5V3.73c-.764.6-5.294 3.917-5.406 4.004C10.648 8.268 9.7 9 8.759 9zm6.485-9h-13a1.5 1.5 0 0 0-1.5 1.5v.594a.752.752 0 0 0 .288.59c.956.747 1.271 1.013 5.418 4.022.525.381 1.57 1.306 2.294 1.294.725.012 1.769-.912 2.294-1.294 4.147-3.01 4.462-3.272 5.419-4.022a.748.748 0 0 0 .287-.59V1.5a1.5 1.5 0 0 0-1.5-1.5z" fill="#546376"/></svg>,
    followupIcon: <svg width="21" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.66 17.485h7.658a6.394 6.394 0 0 0 5.025 2.439 6.41 6.41 0 0 0 6.402-6.403 6.41 6.41 0 0 0-6.402-6.402 6.397 6.397 0 0 0-5.14 2.59A7.02 7.02 0 0 0 .746 16.57c0 .504.409.914.914.914zm12.683-8.537a4.578 4.578 0 0 1 4.573 4.573 4.578 4.578 0 0 1-4.573 4.574A4.578 4.578 0 0 1 9.77 13.52a4.578 4.578 0 0 1 4.573-4.573zM7.759.076a3.83 3.83 0 0 0-3.826 3.827 3.83 3.83 0 0 0 3.826 3.826 3.83 3.83 0 0 0 3.826-3.826A3.83 3.83 0 0 0 7.76.076z" fill="#546376"/><path d="M12.511 14.436h.915v.915a.915.915 0 0 0 1.83 0v-.915h.914a.915.915 0 1 0 0-1.83h-.915v-.914a.915.915 0 0 0-1.83 0v.915h-.914a.915.915 0 0 0 0 1.829z" fill="#546376"/></svg>,
    otherIcon: <svg width="19" height="4" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="2.245" cy="2" r="2" fill="#546376"/><circle cx="9.245" cy="2" r="2" fill="#546376"/><circle cx="16.245" cy="2" r="2" fill="#546376"/></svg>,
    plusCircleIcon: <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="10" fill="#fff"/><path d="M13.846 10c0 .354-.031.64-.386.64h-2.82v2.82c0 .354-.286.386-.64.386-.355 0-.642-.032-.642-.386v-2.82h-2.82c-.353 0-.385-.286-.385-.64 0-.355.032-.642.386-.642h2.82v-2.82c0-.354.286-.385.64-.385.355 0 .641.031.641.386v2.82h2.82c.355 0 .386.286.386.64z" className="accent--fill--color"/></svg>,
    plusIcon26: <svg width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="10" fill="#fff"/><path d="M13.846 10c0 .354-.031.64-.386.64h-2.82v2.82c0 .354-.286.386-.64.386-.355 0-.642-.032-.642-.386v-2.82h-2.82c-.353 0-.385-.286-.385-.64 0-.355.032-.642.386-.642h2.82v-2.82c0-.354.286-.385.64-.385.355 0 .641.031.641.386v2.82h2.82c.355 0 .386.286.386.64z" className="accent--fill--color"/></svg>,
    rightArrowIcon : <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26 13C26 5.82063 20.1794 0 13 0C5.82063 0 0 5.82063 0 13C0 20.1794 5.82063 26 13 26C20.1794 26 26 20.1794 26 13ZM12.7094 18.71C12.6161 18.6175 12.542 18.5076 12.4913 18.3864C12.4405 18.2653 12.4141 18.1353 12.4136 18.004C12.4131 17.8726 12.4384 17.7425 12.4882 17.6209C12.538 17.4994 12.6112 17.3889 12.7038 17.2956L15.9738 14H7.625C7.35978 14 7.10543 13.8946 6.91789 13.7071C6.73036 13.5196 6.625 13.2652 6.625 13C6.625 12.7348 6.73036 12.4804 6.91789 12.2929C7.10543 12.1054 7.35978 12 7.625 12H15.9738L12.7038 8.70437C12.6113 8.61105 12.538 8.50043 12.4883 8.37881C12.4385 8.2572 12.4132 8.12698 12.4138 7.99558C12.4144 7.86419 12.4409 7.73419 12.4917 7.61302C12.5425 7.49185 12.6167 7.38188 12.71 7.28938C12.8033 7.19687 12.9139 7.12366 13.0356 7.07392C13.1572 7.02417 13.2874 6.99886 13.4188 6.99944C13.5502 7.00002 13.6802 7.02648 13.8014 7.0773C13.9225 7.12812 14.0325 7.2023 14.125 7.29563L19.0869 12.2956C19.2727 12.4829 19.377 12.7361 19.377 13C19.377 13.2639 19.2727 13.5171 19.0869 13.7044L14.125 18.7044C14.0325 18.7978 13.9225 18.8721 13.8012 18.923C13.6799 18.9738 13.5498 19.0003 13.4184 19.0008C13.2869 19.0013 13.1566 18.9759 13.0349 18.926C12.9132 18.8761 12.8026 18.8027 12.7094 18.71Z" fill="white"/></svg>,
    fromIcon : <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.516 4.687h3.758L12.93.343v3.758c0 .324.262.586.586.586z" fill="#133159" fill-opacity=".65"/><path d="M4.14 20h11.72a1.76 1.76 0 0 0 1.757-1.758V5.86h-4.101a1.76 1.76 0 0 1-1.758-1.757V0H4.14a1.76 1.76 0 0 0-1.758 1.758v16.484c0 .97.788 1.758 1.758 1.758zM7.657 9.414h5.86a.586.586 0 1 1 0 1.172h-5.86a.586.586 0 1 1 0-1.172zm0 2.344h5.86a.586.586 0 1 1 0 1.172h-5.86a.586.586 0 1 1 0-1.172zm0 2.344h5.86a.586.586 0 1 1 0 1.171h-5.86a.586.586 0 1 1 0-1.171zM5.313 9.414a.586.586 0 1 1 0 1.172.586.586 0 0 1 0-1.172zm0 2.344a.586.586 0 1 1 0 1.172.586.586 0 0 1 0-1.172zm0 2.344a.586.586 0 1 1 0 1.171.586.586 0 0 1 0-1.171z" fill="#133159" fill-opacity=".65"/></svg>,
    cubeIcon: <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 13.334v-6.67a1.25 1.25 0 0 0-.62-1.079L10.941 2.13a1.873 1.873 0 0 0-1.885 0L3.121 5.585A1.25 1.25 0 0 0 2.5 6.664v6.67a1.25 1.25 0 0 0 .62 1.08l5.938 3.455a1.875 1.875 0 0 0 1.885 0l5.937-3.456a1.25 1.25 0 0 0 .62-1.08z" stroke="#627692" stroke-width="1.5" stroke-linecap="round" strokeLinejoin="round"/><path d="M10 10.312 2.695 6.015h14.61L10 10.312zm0 0v7.812" stroke="#627692" stroke-width="1.5" stroke-linecap="round" strokeLinejoin="round"/></svg>,
    newsPaperIcon: <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.375 16.244V2.813a.94.94 0 0 0-.938-.938H2.813a.94.94 0 0 0-.938.938v13.75a1.567 1.567 0 0 0 1.563 1.562H16.25" stroke="#627692" strokeLinejoin="round"/><path d="M16.25 18.125a1.875 1.875 0 0 1-1.875-1.875V5h2.813a.937.937 0 0 1 .937.938V16.25a1.875 1.875 0 0 1-1.875 1.875z" stroke="#627692" strokeLinejoin="round"/><path d="M4.375 15h7.5m-2.5-10h2.5-2.5zm0 2.5h2.5-2.5zm-5 2.5h7.5-7.5zm0 2.5h7.5-7.5z" stroke="#627692" stroke-linecap="round" strokeLinejoin="round"/><path d="M6.875 8.125h-2.5A.625.625 0 0 1 3.75 7.5V5a.625.625 0 0 1 .625-.625h2.5A.625.625 0 0 1 7.5 5v2.5a.625.625 0 0 1-.625.625z" fill="#627692"/></svg>,
    refreshIcon: <svg width="19" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" fill="#3C7EF3"><path d="M2.662 9.83A7.122 7.122 0 0 1 2.609 9c0-3.776 3.115-6.89 6.891-6.89 1.761 0 3.41.71 4.623 1.817l-.608.619a.54.54 0 0 0-.137.527c.063.18.21.316.4.359.107.02 3.594 1.145 3.372 1.1.282.094.7-.207.622-.622l-.749-3.758a.529.529 0 0 0-.369-.401.527.527 0 0 0-.517.137l-.516.506A8.995 8.995 0 0 0 9.5 0c-4.946 0-9 4.054-9 9v.021c0 .297.014.527.03.703a.527.527 0 0 0 .421.47l1.084.216c.352.07.67-.225.627-.58zM18.052 7.806l-1.086-.222a.528.528 0 0 0-.63.58c.038.309.055.608.055.836 0 3.776-3.115 6.89-6.89 6.89-1.762 0-3.41-.71-4.624-1.828l.609-.608c.137-.137.19-.337.137-.527a.552.552 0 0 0-.401-.359c-.106-.021-3.594-1.145-3.371-1.1a.517.517 0 0 0-.475.148.525.525 0 0 0-.148.474l.75 3.723c.041.19.178.348.368.4a.507.507 0 0 0 .517-.136l.506-.506A9.03 9.03 0 0 0 9.5 18c4.947 0 9-4.054 9-9v-.032c0-.233-.008-.463-.027-.691a.527.527 0 0 0-.42-.471z"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(.5)" d="M0 0h18v18H0z"/></clipPath></defs></svg>,
    copyIcon: <svg width="18" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.098.02 5.613 0c-1.32 0-2.495 1.213-2.495 2.5l-.72.017C1.077 2.517 0 3.713 0 5v12.5C0 18.787 1.175 20 2.495 20h9.355c1.32 0 2.495-1.213 2.495-2.5h.624c1.32 0 2.495-1.213 2.495-2.5V5.015L13.098.019zM11.85 18.75H2.495c-.655 0-1.248-.613-1.248-1.25V5c0-.93.764-1.25 1.871-1.25V15c0 1.287 1.176 2.5 2.495 2.5l7.49-.007c0 .759-.496 1.257-1.253 1.257zm1.248-5.618H7.484a.624.624 0 0 1 0-1.249h5.614a.624.624 0 0 1 0 1.249zm0-3.122H7.484a.624.624 0 0 1 0-1.249h5.614a.624.624 0 0 1 0 1.249zM14.345 5c-.664 0-1.247-.6-1.247-1.25V1.268L16.216 5h-1.871z" fill="#ffffff" /></svg>,
    sttingsIcon: <svg width="17" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 10.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5z" fill="#133159"/><path d="M15.434 6.809h-.909c-.116 0-.2-.092-.25-.217a.284.284 0 0 1 0-.308l.675-.617a1.358 1.358 0 0 0 .417-.983 1.393 1.393 0 0 0-.417-.992L13.834 2.55a1.392 1.392 0 0 0-1.975 0l-.642.617a.317.317 0 0 1-.342 0 .284.284 0 0 1-.183-.242v-.858a1.4 1.4 0 0 0-1.358-1.4H7.717a1.408 1.408 0 0 0-1.408 1.4v.908c0 .117-.092.2-.217.25a.283.283 0 0 1-.308 0l-.617-.675a1.433 1.433 0 0 0-.992-.416 1.375 1.375 0 0 0-.983.416L2.05 3.667a1.392 1.392 0 0 0 0 2l.617.617a.317.317 0 0 1 0 .341.283.283 0 0 1-.242.184h-.858a1.4 1.4 0 0 0-1.4 1.4v1.575a1.408 1.408 0 0 0 1.4 1.408h.908c.117 0 .2.092.25.217a.283.283 0 0 1 0 .308l-.675.617c-.262.264-.411.62-.416.991a1.383 1.383 0 0 0 .416.992l1.117 1.133a1.39 1.39 0 0 0 1.975 0l.642-.616a.316.316 0 0 1 .341 0 .283.283 0 0 1 .184.241v.909a1.4 1.4 0 0 0 1.4 1.35h1.575a1.408 1.408 0 0 0 1.408-1.4v-.909c0-.116.092-.2.217-.25a.284.284 0 0 1 .308 0l.633.642c.264.263.62.412.992.417a1.375 1.375 0 0 0 .983-.417l1.117-1.117a1.39 1.39 0 0 0 0-1.975l-.608-.608a.283.283 0 0 1 0-.308.282.282 0 0 1 .241-.184h.909a1.4 1.4 0 0 0 1.35-1.391V8.217a1.408 1.408 0 0 0-1.4-1.408zM8.5 11.917a2.917 2.917 0 1 1 0-5.833 2.917 2.917 0 0 1 0 5.833z" fill="#133159"/></svg>,
    duplicateIcon1: <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.898 5H7.227C5.997 5 5 5.997 5 7.227v8.671c0 1.23.997 2.227 2.227 2.227h8.671c1.23 0 2.227-.997 2.227-2.227V7.227c0-1.23-.997-2.227-2.227-2.227z" stroke="#133159" stroke-width="1.5" strokeLinejoin="round"/><path d="m14.98 5 .02-.938a2.193 2.193 0 0 0-2.188-2.187H4.376a2.507 2.507 0 0 0-2.5 2.5v8.438A2.194 2.194 0 0 0 4.063 15H5" stroke="#133159" stroke-width="1.5" stroke-linecap="round" strokeLinejoin="round"/></svg>,
    brushIcon: <svg width="21" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" fill="#133159"><path d="M8.757 12.865a4.218 4.218 0 0 0-1.422-1.703c.404-.622.713-1.293 1.245-1.825 1.054-1.05 2.173-2.03 3.316-2.978C14.2 4.449 16.56 2.612 19.02.903c.37-.257.763-.49 1.149-.724.096-.057.227-.167.317-.048.042.054-.015.222-.072.305-.434.62-.859 1.248-1.326 1.847-2.187 2.804-4.476 5.521-6.945 8.086-.461.476-.94.937-1.434 1.377-.245.218-.539.392-.829.553-.359.204-.736.375-1.122.566zM.5 17.288c.036-.045.06-.108.099-.12.43-.125.769-.395 1.09-.688.628-.568 1.085-1.257 1.415-2.035.29-.685.64-1.329 1.17-1.861.547-.548 1.19-.877 1.987-.856a.997.997 0 0 1 .607.203 3.901 3.901 0 0 1 1.287 1.691c.018.045.03.099.021.144-.188.847-.473 1.658-.975 2.376-.743 1.06-1.775 1.62-3.05 1.745-1.242.122-2.427-.15-3.588-.563-.012-.003-.024-.015-.063-.036z"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(.5 .08)" d="M0 0h20v17.839H0z"/></clipPath></defs></svg>,
    plusIcon: <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="#3C7EF3" stroke-width="2" stroke-linecap="round" d="M1 10h18M10 1v18"/></svg>,
    minusIcon: <svg width="20" height="2" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="#3C7EF3" stroke-width="2" stroke-linecap="round" d="M1 1h18"/></svg>,
    leftIcon: <svg width="25" height="26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.584 5.708a1.042 1.042 0 0 1 .802 1.709L10.719 13l4.5 5.594a1.042 1.042 0 0 1-.156 1.468 1.04 1.04 0 0 1-1.521-.156l-5.031-6.25a1.042 1.042 0 0 1 0-1.323l5.208-6.25a1.042 1.042 0 0 1 .865-.375z" fill="#133159"/></svg>,
    plus: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="10" fill="white"/><path d="M13.8447 9.9985C13.8447 10.353 13.8139 10.6395 13.4594 10.6395H10.6395V13.4594C10.6395 13.8132 10.353 13.8447 9.9985 13.8447C9.64401 13.8447 9.35747 13.8132 9.35747 13.4594V10.6395H6.5376C6.18375 10.6395 6.15234 10.353 6.15234 9.9985C6.15234 9.64401 6.18375 9.35747 6.5376 9.35747H9.35747V6.5376C9.35747 6.18311 9.64401 6.15234 9.9985 6.15234C10.353 6.15234 10.6395 6.18311 10.6395 6.5376V9.35747H13.4594C13.8139 9.35747 13.8447 9.64401 13.8447 9.9985Z" className="accent--fill--color"/></svg>,
    cross : <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.1252 17.0812L17.1357 15.0693L6.878 4.80485L4.86747 6.8167L15.1252 17.0812Z" fill="white"></path><path d="M6.878 17.0838L17.1357 6.81934L15.1252 4.80749L4.86747 15.072L6.878 17.0838Z" fill="white"></path></svg>,
    crossWhiteBg : <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.1016 3.90156C14.2016 0.0015626 7.80156 0.0015626 3.90156 3.90156C0.00156271 7.80156 0.00156271 14.2016 3.90156 18.1016C7.80156 22.0016 14.1016 22.0016 18.0016 18.1016C21.9016 14.2016 22.0016 7.80156 18.1016 3.90156ZM13.8016 15.2016L11.0016 12.4016L8.20156 15.2016L6.80156 13.8016L9.60156 11.0016L6.80156 8.20156L8.20156 6.80156L11.0016 9.60156L13.8016 6.80156L15.2016 8.20156L12.4016 11.0016L15.2016 13.8016L13.8016 15.2016Z" fill="white"/></svg>,
    search: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z" fill="#133159" fillOpacity="0.65"/></svg>,
    edit: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.08573 2.91356L13.0862 6.91425L4.39929 15.6016L0.832505 15.9953C0.355018 16.0482 -0.0484094 15.6444 0.00471426 15.1669L0.401579 11.5975L9.08573 2.91356ZM15.5606 2.31793L13.6822 0.43946C13.0962 -0.146487 12.146 -0.146487 11.56 0.43946L9.7929 2.20668L13.7934 6.20737L15.5606 4.44015C16.1465 3.85389 16.1465 2.90388 15.5606 2.31793Z" fill="#133159"/></svg>,
    previous: <svg width="14" height="14" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.50171 0.248609C8.79377 0.248039 9.07682 0.349756 9.30171 0.536108C9.42828 0.641045 9.53291 0.769922 9.6096 0.915356C9.68629 1.06079 9.73353 1.21992 9.74863 1.38364C9.76372 1.54737 9.74637 1.71246 9.69757 1.86946C9.64876 2.02646 9.56946 2.1723 9.46421 2.29861L3.86421 8.99861L9.26421 15.7111C9.36804 15.839 9.44558 15.9861 9.49237 16.144C9.53916 16.3019 9.55427 16.4676 9.53685 16.6313C9.51943 16.7951 9.46981 16.9539 9.39084 17.0984C9.31187 17.2429 9.20511 17.3705 9.07671 17.4736C8.94737 17.5874 8.79592 17.6732 8.63184 17.7257C8.46776 17.7782 8.2946 17.7962 8.12324 17.7786C7.95187 17.761 7.78599 17.7082 7.63602 17.6234C7.48604 17.5387 7.3552 17.4238 7.25171 17.2861L1.21421 9.78611C1.03035 9.56244 0.929847 9.28189 0.929847 8.99236C0.929847 8.70283 1.03035 8.42227 1.21421 8.19861L7.46421 0.69861C7.5896 0.547338 7.7489 0.427757 7.92916 0.349571C8.10942 0.271387 8.30558 0.236795 8.50171 0.248609Z" fill="white"/></svg>,
    backwardSlash : <svg width="21" height="37" viewBox="0 0 21 37" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="19.7058" y1="0.470588" x2="0.88231" y2="35.7647" stroke="#3C7EF3" strokeWidth="2"/></svg>,
    next: <svg width="14" height="14" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.32251 17.7514C1.03045 17.752 0.747402 17.6502 0.522512 17.4639C0.395939 17.359 0.291312 17.2301 0.214622 17.0846C0.137932 16.9392 0.0906866 16.7801 0.0755918 16.6164C0.0604969 16.4526 0.0778495 16.2875 0.126655 16.1305C0.175461 15.9735 0.25476 15.8277 0.360012 15.7014L5.96001 9.00139L0.560013 2.28889C0.456181 2.16103 0.378641 2.01391 0.331851 1.85599C0.285062 1.69806 0.269944 1.53245 0.287368 1.36866C0.304792 1.20488 0.354413 1.04615 0.433381 0.901604C0.512348 0.757058 0.619104 0.629544 0.747513 0.52639C0.876845 0.412595 1.0283 0.32676 1.19238 0.274274C1.35646 0.221788 1.52961 0.203783 1.70098 0.221389C1.87235 0.238995 2.03822 0.291833 2.1882 0.376585C2.33818 0.461337 2.46902 0.576175 2.57251 0.713891L8.61001 8.21389C8.79387 8.43756 8.89437 8.71811 8.89437 9.00764C8.89437 9.29717 8.79387 9.57773 8.61001 9.80139L2.36001 17.3014C2.23462 17.4527 2.07532 17.5722 1.89506 17.6504C1.7148 17.7286 1.51865 17.7632 1.32251 17.7514Z" fill="white"/></svg>,
    pixelIcon:<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 0h35A2.5 2.5 0 0 1 40 2.5v35a2.5 2.5 0 0 1-2.5 2.5h-35A2.5 2.5 0 0 1 0 37.5v-35A2.5 2.5 0 0 1 2.5 0Z" fill="#05A081"/><path d="M16.25 26.25h4.829v-4.69h1.459a3.906 3.906 0 0 0 0-7.81H16.25v12.5Zm7.329 2.5H13.75v-17.5h8.788a6.405 6.405 0 0 1 1.04 12.725v4.775Z" fill="#fff"/></svg>,
    fileUploadIcon:<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M32.25 16.733C31.117 10.983 26.067 6.667 20 6.667c-4.817 0-9 2.733-11.083 6.733A9.99 9.99 0 0 0 0 23.333c0 5.517 4.483 10 10 10h21.667C36.267 33.333 40 29.6 40 25c0-4.4-3.417-7.967-7.75-8.267Zm-8.917 4.934v6.666h-6.666v-6.666h-5L20 13.333l8.333 8.334h-5Z" fill="#3C7EF3"/></svg>,
    infoSvgIcon:<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm0 14.546A6.553 6.553 0 0 1 1.455 8 6.553 6.553 0 0 1 8 1.455 6.553 6.553 0 0 1 14.546 8 6.553 6.553 0 0 1 8 14.546Z" fill="#546376"/><path d="M8 3.394a.97.97 0 0 0 0 1.94.97.97 0 0 0 0-1.94Zm0 3.394a.727.727 0 0 0-.728.727v4.364a.727.727 0 1 0 1.455 0V7.515A.727.727 0 0 0 8 6.788Z" fill="#546376"/></svg>,
    backArrowIcon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.494 20.564a.75.75 0 0 1-1.058-.07l-7-8a.75.75 0 0 1 0-.988l7-8a.75.75 0 1 1 1.128.988L5.653 11.25H20a.75.75 0 0 1 0 1.5H5.653l5.911 6.756a.75.75 0 0 1-.07 1.058Z" fill="#030D45"/></svg>,
    deleteIcon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M3 6h18m-2 0v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m-6 5v6m4-6v6"/></svg>,
    formCheckIcon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#00ff00"/><path d="M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="white"/></svg>,
}