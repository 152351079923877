import React, {useEffect, useState} from 'react';
import { fetchForms } from '../../actions/formBuilderAction'
import {connect} from "react-redux";
import If, {Else} from "if-else-react";
import Grid from '@material-ui/core/Grid';
import './form.css'
import FormListHeader from "./FormListHeader";
import FormBottomButtons from "./FormBottomButtons";
import PreviewForm from "./PreviewForm";
import Utils from "../../helpers/Utils";
import ListSkeleton from "../Common/Skeletons/ListSkeleton";

const FormBuilder = props => {
    const [loading, setLoading] = useState(props.loading);
    const [forms, setForms] = useState(props.forms);

    useEffect(() => {
        document.title = `Form Builder | ${Utils.getAccountData('AuthUserAgencyName')}`
    }, []);

    useEffect(() => {
        if (props.forms !== undefined) {
            setForms(props.forms);
        } else {
            props.fetchForms();
        }
    }, [props.forms]);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    const renderForms = () => {
        if (!loading) {
            if (typeof props.forms[0] === 'undefined') {
                return <div className="text-center w-100 mt-4"><h5>No Forms found in the system</h5></div>
            }
            return forms.map((form, i) => {
                return (
                    <Grid item xs={6} sm={4}>
                        <div className="form__wrapper">
                            <div className="form__top">
                                <div className="form__top__left">
                                    {/*{loading ?*/}
                                    {/*    <ListSkeleton width={160} />*/}
                                    {/*    :*/}
                                    <h3>{form.title}</h3>
                                    {/*}*/}
                                </div>
                                <PreviewForm builderHtml={form.builder_html}/>
                            </div>

                            <If condition={form.builder_html}>
                                <div className="awesome__scroll_bar from-builder-preview-wrapper" dangerouslySetInnerHTML={{__html: form.builder_html}}/>
                            <Else/>
                                <p className="no-preview no-form-preview-text-center">No Form Preview</p>
                            </If>

                            <FormBottomButtons form={form}/>
                        </div>
                    </Grid>
                );
            })
        } else {
            let blocks = [];
            for (let i = 0; i < 6; i++) {
                blocks.push(
                    <Grid item xs={6} sm={4}>
                        <div className="form__wrapper">
                            <div className="form__top">
                                <div className="form__top__left">
                                    <ListSkeleton width={160} />
                                </div>
                                <ListSkeleton width={120}/>
                            </div>

                            <ListSkeleton width={280} number={6}/>
                        </div>
                    </Grid>
                );
            }
            return blocks;
        }
    }

    return (
        <div>
            {/* === Start All form top bar === */}
            <FormListHeader />
            {/*<If condition={loading}>*/}
            {/*    <Loading size={50} />*/}
            {/*<Else />*/}
                <div className="allform__sections">
                    <div className="all__form__wr">
                        <Grid container spacing={3}>
                            { renderForms() }
                        </Grid>
                    </div>
                </div>
            {/*</If>*/}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        forms: state.formBuilderReducer.forms,
        loading: state.formBuilderReducer.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchForms: () => dispatch(fetchForms())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormBuilder);