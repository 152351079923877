import {IconList} from "../../constants/IconList";
import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Utils from "../../helpers/Utils";
import CoreConstants from "../../constants/CoreConstants";
import './edit_form_builder.css';
import ActionControl from "../Common/ActionControl/ActionControl";

const PreviewAndState = props => {
    const isTempUser = Utils.getAccountData('isTemplateUser');
    const [hasShadow, setHasShadow] = useState(false);

    useEffect(() => {
        window.refreshRenderedForm(props.formDetails.builder_json);
    }, []);

    useEffect(() => {
        setHasShadow(props.formStyle.form_page_box_shadow === undefined || props.formStyle.form_page_box_shadow === 1 || props.formStyle.form_page_box_shadow === true);
    },[props.formStyle]);

    const copyUrl = () => {
        navigator.clipboard.writeText(`/form/${props.formDetails.unique_id}`);
        Utils.showNotification('Form url copied to clipboard', 'success');
    }

    const renderStyle = () => {
        return `
            .form-${props.formDetails.unique_id} * {
                color: ${ props.formStyle.form_text_color ?? '#575962' }
            }
            .form-${props.formDetails.unique_id} label, .form-${props.formDetails.unique_id} input, .form-${props.formDetails.unique_id} button {
                font-size: ${props.formStyle.font_size ? props.formStyle.font_size + 'px !important' : '16px !important'};
            }
            .form-${props.formDetails.unique_id} label {
                font-weight: normal;
            }
            .form-${props.formDetails.unique_id} button:focus {
                background-color: ${props.formStyle.submit_button_bg ?? '#34bfa3'} !important
            }
            .form-group .formbuilder-required {
                color: #c10000;
            }
            .form-card {
                width: 100% !important;
                box-shadow: unset !important;
                padding: 20px;
                box-sizing: border-box;
            }
            .rendered-form * {
                box-sizing: border-box;
            }
            .rendered-form .form-group {
                margin-left: 0px;
                margin-bottom: 15px;
            }
            #fb-rendered-form .form-control {
                background: #F6F7FB !important;
                border: 1px solid #E5E8EF !important;
                box-sizing: border-box !important;
                border-radius: 5px !important;
                padding: 10px 12px !important;
                height: 3rem;
                width: 100%;
                line-height: 1.42857143;
                transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;

            }
            input:not([type]):focus:not([readonly]), input[type="text"]:not(.browser-default):focus:not([readonly]), input[type="password"]:not(.browser-default):focus:not([readonly]), input[type="email"]:not(.browser-default):focus:not([readonly]), input[type="url"]:not(.browser-default):focus:not([readonly]), input[type="time"]:not(.browser-default):focus:not([readonly]), input[type="date"]:not(.browser-default):focus:not([readonly]), input[type="datetime"]:not(.browser-default):focus:not([readonly]), input[type="datetime-local"]:not(.browser-default):focus:not([readonly]), input[type="tel"]:not(.browser-default):focus:not([readonly]), input[type="number"]:not(.browser-default):focus:not([readonly]), input[type="search"]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
                border-bottom: 1px solid #26a69a;
                -webkit-box-shadow: 0 1px 0 0 #26a69a;
                box-shadow: 0 1px 0 0 #26a69a;
                outline: none;
            }
            .submit-btn{
                text-align: center;
                letter-spacing: .5px;
                -webkit-transition: background-color .2s ease-out;
                transition: background-color .2s ease-out;
                cursor: pointer;
                width: 100%;
                border: none;
                border-radius: 2px;
                display: inline-block;
                height: 36px;
                line-height: 36px;
                padding: 0 16px;
                text-transform: uppercase;
                vertical-align: middle;
                -webkit-box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14),0 1px 7px 0 rgba(0,0,0,0.12),0 3px 1px -1px rgba(0,0,0,0.2);
                box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14),0 1px 7px 0 rgba(0,0,0,0.12),0 3px 1px -1px rgba(0,0,0,0.2);
            }
            .form-group {
                display: block;
            }
            .form-input-none{ display: none }
        `
    }

    return (
        <Grid container spacing={3} className="accordionContain">
            <Grid item xs={6} className="leftSide">
                <div className="topbar">
                    <p>Refresh to see unsaved changes</p>
                    <button className="btn" onClick={() => window.refreshRenderedForm(props.formDetails.builder_json)}>
                        {IconList.refreshIcon}
                        Refresh
                    </button>
                </div>
                <div className="textArea">
                    <div className="circleArea">
                        <div className="circle red"></div>
                        <div className="circle yellow"></div>
                        <div className="circle green"></div>
                    </div>
                    <div className="form_url_area">
                        <ActionControl alertMessage="Form builder feature is not available for template user" status="error"  isDisabled={isTempUser == 1}>
                            <a href={`/form/${props.formDetails.unique_id}`} target="_blank" rel="noreferrer">Click here to visit the form</a>
                            <button type="button" className="copy__button" onClick={() => copyUrl()}>{IconList.copyIcon}</button>
                        </ActionControl>
                    </div>
                </div>
                <div id='public-form-wrapper'>
                    <div id="form-wrapper" className="form-wrapper-main" style={{
                        height: 'auto',
                        padding: '20px 0',
                        backgroundImage: props.formStyle.landing_page_bg_type == CoreConstants.LANDING_PAGE_BG_IMAGE ? 'url('+process.env.REACT_APP_BASE_URL+'upload/formBuilder/bg/'+props.formDetails.user_id+'/'+props.formStyle.landing_page_bg+')' : '',
                        backgroundColor: props.formStyle.landing_page_bg_type == CoreConstants.LANDING_PAGE_BG_COLOR ? props.formStyle.landing_page_bg : ''}}
                    >
                    <style>{renderStyle()}</style>
                    <form id="my-form" className={`form-${props.formDetails.unique_id}`} method="POST" action={ `${Utils.getAccountData('baseUrl')}/form/${props.formDetails.unique_id}`} style={{
                        width: props.formStyle.form_width ? props.formStyle.form_width+'px' : '700px',
                        margin: '0 auto',
                        color:  props.formStyle.form_text_color ?? '#575962',
                        boxShadow: hasShadow ? 'rgba(69, 65, 78, 0.2) 0px 0px 15px 1px' : 'none',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        maxWidth : '100%'
                    }} >
                        <input type="hidden" className="form-input-none" name="unique_id" value={props.formDetails.unique_id} />
                        
                        <div className="form-card m-0" style={{backgroundColor: (props.formStyle.form_bg ?? '#ffffff')}}>
                            <div>
                                <div id="fb-rendered-form">
                                    <div className="render-wrap"></div>
                                </div>
                                <div className="card-text text-center">
                                    <button type="submit" className="submit-btn btn"
                                            style={{
                                                width: `${props.formStyle.submit_button_width ?? '100'}%`,
                                                backgroundColor: `${props.formStyle.submit_button_bg ?? '#34bfa3'}`,
                                                color: `${props.formStyle.submit_button_color ?? '#ffffff'}`,
                                            }}
                                    >
                                        { props.formStyle.submit_button_text ?? 'Save Information' }
                                    </button>
                                </div>
                            </div>
                            {(props.formSetting.agency_branding != '' && props.formSetting.agency_branding == CoreConstants.VALUE_YES) &&
                                <div className="card-footer text-right" style={{backgroundColor: 'unset', borderTop: 'unset', display: 'flex', justifyContent: 'flex-end', margin: '20px 0 0 0'}}>
                                    <img className="logo" src={Utils.getAccountData('agencyLogo')} alt="Logo" style={{fontSize: '25px', fontWeight: 'bold', maxWidth: '150px'}} />
                                </div>
                            }
                        </div>
                    </form>
                </div>
                </div>
            </Grid>
            <Grid item xs={6} className="rightSide">
                <Grid container spacing={3}>
                    <Grid item xs={4} className="countCard lightOrange">
                        <h5 className="digit">{ props.formDetails.total_visit }</h5>
                        <p className="text">Unique Visit</p>
                    </Grid>
                    <Grid item xs={4} className="countCard lightBlue">
                        <h5 className="digit">{ props.formDetails.total_response }</h5>
                        <p className="text">Unique Visit</p>
                    </Grid>
                    <Grid item xs={4} className="countCard lightGreen" >
                        <h5 className="digit">{ props.formDetails.total_contact_insert }</h5>
                        <p className="text">Unique Visit</p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        formDetails: state.formBuilderReducer.formDetails,
        formStyle: state.formBuilderReducer.formStyle,
        formSetting: state.formBuilderReducer.formSetting,
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewAndState);
