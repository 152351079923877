import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {IconList} from "../../../constants/IconList";
import GlobalModal from "../../Common/Modal/GlobalModal";
import {Add} from "@material-ui/icons";
import {connect} from "react-redux";
import Utils from "../../../helpers/Utils";
import { useSimpleValidator } from "../../../hooks/useSimpleValidator";
import { createNewCustomForm } from "../../../api/customFormBuilderApi";
import { addCustomForms } from "../../../actions/customFormBuilderAction";

const FormListHeader = props => {

    const history = useHistory();
    const simpleValidator = useSimpleValidator();

    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [formTitle, setFormTitle] = useState('');

    useEffect(() => {
        if(!openCreateModal) {
            setFormTitle('');
        }
    },[openCreateModal]);

    const handleClickOpen = () => {
        setOpenCreateModal(true);
    };

    const addNewForm = (e) => {

        e.preventDefault();

        if(!simpleValidator.validate()){
            return;
        }

        createNewCustomForm({title: formTitle}).then(response => {
            if (response.data.status) {
                props.addCustomForms(response.data.html);
                history.push(`/user/new/form-builder/${response.data.html.unique_id}`);
                Utils.showNotification('Form created successfully', 'success');
            } else {
                Utils.showNotification(response.data.html, 'error');
            }
        });
    }

    return (
        <div className="section__top">
            <div className="section__top__left">
                <h2>All Forms</h2>
            </div>
            <div className="section__top__right d-flex justify-content-end">
                { openCreateModal &&
                    <GlobalModal className='global-xs-modal' open={openCreateModal} onClose={() => setOpenCreateModal(false)} title={"Create new form"} buttonText={"Create New Form"} buttonIcon={IconList.plusIcon26} buttonClick={addNewForm}>
                        <form onSubmit={addNewForm}>
                            <div className="row">
                                <div className="input-field col s12">
                                    <label htmlFor="from-title">Give a Form Name <small><small>(Max : 100 characters)</small></small></label>
                                    <input placeholder="Enter Form Name" type="text" value={formTitle} onChange={(e) => setFormTitle(e.target.value)}/>
                                    {simpleValidator.message('FormName', formTitle, 'required|string|max:100')}
                                </div>
                            </div>
                        </form>
                    </GlobalModal>
                }
                <Button
                    className="add__icon accent--bg--color"
                    startIcon={<Add />}
                    onClick={handleClickOpen}
                >
                    Create New Form
                </Button>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        addCustomForms: (params) => dispatch(addCustomForms(params)),
    }
}

export default connect(null, mapDispatchToProps)(FormListHeader);