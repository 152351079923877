import "date-fns";
import React, { useState } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import moment from "moment";


const EditTimeField = (props) => {

    const [open, setOpen] = useState(false);
        
    const handleTimeChange = (time) => {
        props.onChange(moment(time).format('hh:mm A'))
    };
    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    onClick={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    open={open}
                    inputProps={{readOnly : true}}
                    margin="normal"
                    value={moment(props.value, 'hh:mm A').toDate()}           
                    mask="__:__ _M"
                    onChange={handleTimeChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                />
            </MuiPickersUtilsProvider>
        </div>
    )
}

export default EditTimeField
