import React from "react";
import "./FormAnimation.css";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IconList } from "../../../../../constants/IconList";

const animations = [
  {
    name: "Default",
    value : null,
    imageName : 'form_animation_default.jpg'
  },
  {
    name: "Slide Left",
    value : "slideLeft",
    imageName : 'form_animation_slideleft.gif'
  },
  {
    name: "Slide Right",
    value : "slideRight",
    imageName : 'form_animation_slideright.gif'
  },
  {
    name: "Fade up",
    value : "fadeUp",
    imageName : 'form_animation_fadeup.gif'
  },
  {
    name: "Fade down",
    value : "fadeDown",
    imageName : 'form_animation_fadedown.gif'
  }
];

const FormAnimation = (props) => {

  const renderOptions = () => {
    return animations.map(eachAnimation => (
       <div className="each_animation_option" onClick={() => props.setAnimation(eachAnimation.value)}>
         {(props.builderJson?.animation === eachAnimation.value || (!props.builderJson?.animation && eachAnimation.value === null))
           && IconList.formCheckIcon}
         <img src={`https://d24gujj67p1uc1.cloudfront.net/asset/images/releases/1.0.1/${eachAnimation.imageName}`} alt={eachAnimation.value}/>
         { eachAnimation.name }
       </div>
    ))
  }

  return (
    <div className="form__animation_acc__wr">
      <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span className="form__animation__heading">
              Animation Slider Templates
            </span>
          </AccordionSummary>
          <AccordionDetails className="form__animation__acc">
            <div className="form__animation_list">
              { renderOptions() }
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
  );
};

export default FormAnimation;
