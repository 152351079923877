import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const RepositionView = ({ isRepositionEnabled }) => {

    if(!isRepositionEnabled) {
        return "";
    }

    return <div className="cover_image__drag_info">
            <FontAwesomeIcon icon={["fas", "arrows-alt"]} className="mr-2"/>
            Drag to Reposition
      </div>
};
