import React, { useEffect, useState } from "react";
import PreviewText from "../shared/PreviewInputField/PreviewText";
import PreviewEmbed from "../shared/PreviewInputField/PreviewEmbed";
import PreviewFileUpload from "../shared/PreviewInputField/PreviewFileUpload";
import PreviewTextArea from "../shared/PreviewInputField/PreviewTextArea";
import PreviewInput from "../shared/PreviewInputField/PreviewInput";
import PreviewDate from "../shared/PreviewInputField/PreviewDate";
import PreviewCheckbox from "../shared/PreviewInputField/PreviewCheckbox";
import { useSimpleFormValidator } from "../shared/PreviewInputField/useSimpleFormValidator";
import PreviewDropdown from "../shared/PreviewInputField/PreviewDropdown";
import PreviewRadio from "../shared/PreviewInputField/PreviewRadio";
import PreviewDefaultField from "../shared/PreviewInputField/PreviewDefaultField";
import PreviewHiddenField from "../shared/PreviewInputField/PreviewHiddenField";
import Thankyou from "../shared/ThankYouPage/Thankyou";
import { publicCustomFormDetails } from "../../../api/customFormBuilderApi";
import { PreviewPublicImage } from "./PreviewPublicImage";
import Utils from "../../../helpers/Utils";
import PreviewPhoneNumberInput from "../shared/PreviewInputField/PreviewPhoneNumberInput";
import PreviewTime from "../shared/PreviewInputField/PreviewTime";
import { PreviewButton } from "../shared/PreviewInputField/PreviewButton";
import PreviewNumber from "../shared/PreviewInputField/PreviewNumber";
import { focusFirstField, focusNextField, getFormbackground, getProgressbarPercentage, readyPreviewObject } from "../NewFormUtils";
import useStateCallback from "../../../hooks/useStateCallback";
import { PreviewProgressbar } from "../shared/PreviewInputField/PreviewProgressbar";
import { AnimationHandler } from "../shared/PreviewInputField/AnimationHandler";
import PreviewChoiceGrid from "../shared/PreviewInputField/PreviewChoiceGrid";
import PreviewChoiceList from "../shared/PreviewInputField/PreviewChoiceList";

export const PublicCustomForm = (props) => {

  const simpleValidator = useSimpleFormValidator();
  const [previewList, setPreviewList] = useStateCallback([]);
  const [previewValue, setPreviewValue] = useState({});
  const [fields, setFields] = useState([]);
  const [formName,setFormName] = useState('');
  const [formSetting, setFormSetting] = useState(null);
  const [formId, setFormId] = useState(null);
  const [inactiveFormText,setInactiveFormText] = useState('');
  const [invalidPhoneMessage,setInvalidPhoneMessage] = useState('');
  const [formStyleSettings, setFormStyleSettings] = useState({});
  const [percentage, setPercentage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const apiKey = props.match.params.uniqueId;

  useEffect(() => {
    loadData();
  },[]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fillUpPreviewFields();
  },[fields.length]) // eslint-disable-line react-hooks/exhaustive-deps
 
  useEffect(() => {
    if(invalidPhoneMessage !== '') {
      simpleValidator.showMessages();
    }
  },[invalidPhoneMessage]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = () => {
    publicCustomFormDetails({uniqueCode: props.match.params.uniqueId}).then(res => {
      if(res.data.status === 'success') {

        Utils.setFavIcon(res.data.data.agencyLogo);
        document.title=res.data.data.agencyName;

        if(res.data.data.form.status === 0) {
          setInactiveFormText(res.data.data.formSetting.inactive_form_text);
        }

        setFormName(res.data.data.builderJson.form_name);
        setFields(res.data.data.builderJson.fields);
        setFormSetting(res.data.data.formSetting);
        setFormId(res.data.data.form.id);

        let progressBar = null;
        if(res.data.data.builderJson?.progressbar) {
          progressBar = {
            color : res.data.data.builderJson.progressbar.color,
            backgroundColor : res.data.data.builderJson.progressbar.backgroundColor
          }
        }

        setFormStyleSettings({
          cover: res.data.data.builderJson.cover,
          logo: res.data.data.builderJson.logo,
          coverPosition: res.data.data.builderJson?.coverPosition ? res.data.data.builderJson.coverPosition : 50,
          coverHeight: res.data.data.builderJson?.coverHeight ? res.data.data.builderJson.coverHeight : 170,
          floatingCover: res.data.data.builderJson?.floatingCover ? res.data.data.builderJson.floatingCover : 0,
          formBackground : res.data.data.builderJson?.formBackground ? res.data.data.builderJson.formBackground : null,
          logoFillColor: res.data.data.builderJson?.logoFillColor ? res.data.data.builderJson.logoFillColor : null,
          logoSize: res.data.data.builderJson?.logoSize ? res.data.data.builderJson?.logoSize : null,
          animation : res.data.data.builderJson?.animation ? res.data.data.builderJson.animation : "default",
          progressBar : progressBar
        })
      } else {
        window.showNotification('ERROR','Unable to find your form, please try again later');
      }
    })
  }

  const fillUpPreviewFields = () => {
    let previewObject = readyPreviewObject(fields);
    setPreviewValue(previewObject);
    setPreviewList(
      fields.filter((dt) => dt.page === 1),
      state => {
        setTimeout(() => {
          focusFirstField(state)
        }, 300);
      }
    );
  }

  const handleNextPreviewSteps = (step) => {
    if (!simpleValidator.validate() || invalidPhoneMessage !== '') {
      return;
    }
    
    let nextPage = fields.find((dt) => dt.page > step); //Next Page
    if (nextPage) {
      setPreviewList(
        fields.filter((dt) => dt.page === nextPage.page),
        () => setCurrentPage(currentPage + 1)
      );
    } else {
      setPreviewList("");
    }
    setPercentage(getProgressbarPercentage(step,fields));
  };

  const handleInvalidPhoneNumberResponse = (invalidMessage) => {
    try {
      let field = fields.find(el => el.type === "phoneNumber");
      setPreviewList(
      fields.filter((dt) => dt.page === field.page),
      state => {
        focusFirstField(state)
        setPercentage(getProgressbarPercentage(field.page-1,fields))
      }
    );
      setInvalidPhoneMessage(invalidMessage);
    } catch (e) {
      console.log(e);
    }
  }

  const getPaddingTop = () => {
      const isFloatingEnabled = formStyleSettings.cover && formStyleSettings.floatingCover;
      return isFloatingEnabled ? formStyleSettings.coverHeight : 0;
  }

  const handleFocusNextField = (e,index) => {
    e.stopPropagation();
    if(!focusNextField(previewList,index)) {
      handleNextPreviewSteps(previewList[index].page);
    }
  }

  const handleFocusFirstField = () => {
    focusFirstField(previewList);
  }

  if(inactiveFormText) {
    return <>
      <PreviewPublicImage settings = {formStyleSettings} />
      <div className="new__form__builder__preview__body pb-5" style={{ paddingTop : getPaddingTop() }}>
        <div className="new__form__builder__preview__body__container">
          <div
              className="new__form__builder__preview__title"
              dangerouslySetInnerHTML={{ __html: inactiveFormText }}
            />
        </div>
      </div>
    </>
  }

  return (
    <div className="min-h-100vh public__page__background" style={getFormbackground(formStyleSettings)}>
      <PreviewPublicImage settings = {formStyleSettings} />
      <div className="new__form__builder__preview__body pb-5" style={{ paddingTop : getPaddingTop() }}>
        {
          formStyleSettings.progressBar &&
          <PreviewProgressbar 
            percentage={percentage}
            fontColor={formStyleSettings.progressBar.color}
            color={formStyleSettings.progressBar.backgroundColor}/>
        }
        
        <div className="new__form__builder__preview__body__container">
          { previewList &&
            <div
              className="new__form__builder__preview__title"
              dangerouslySetInnerHTML={{ __html: formName }}
            />
          }
          <AnimationHandler animation={formStyleSettings.animation} page={currentPage} onAnimationChangeCallback={handleFocusFirstField}>
            <form onSubmit={e => e.preventDefault()}>
              {previewList ? (
                previewList.map((dt, index) => {
                  if (dt.type === "button") {
                    return <PreviewButton
                            key={dt.id}
                            data={dt}
                            onClick={() => handleNextPreviewSteps(dt.page)}
                          />
                  } else if (dt.type === "textarea" || dt.type === "address" || dt.type === "note") {
                    return <PreviewTextArea
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                          />
                  } else if (dt.type === "firstName" || dt.type === "lastName" || dt.type === "email" || dt.type === "companyName" || dt.type === "city" || dt.type === "state" || dt.type === "zip" || dt.type === "ipAddress" || dt.type === "link" || dt.type === "shortAnswer"  || dt.type === "rating") {
                    return <PreviewInput
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                            onEnterPress={e => handleFocusNextField(e,index)}
                          />
                  
                  } else if(dt.type === "number") {
                    return <PreviewNumber
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                            onEnterPress={e => handleFocusNextField(e,index)}
                          />
                  } else if (dt.type === "time") {
                    return <PreviewTime
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                          />
                  } else if (dt.type === "phoneNumber" || dt.type === "phoneInput") {
                    return <PreviewPhoneNumberInput
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                            invalidMessage={invalidPhoneMessage}
                            clearInvalidMessage={() => setInvalidPhoneMessage('')}
                            onEnterPress={e => handleFocusNextField(e,index)}
                          />
                  } else if (dt.type === "date" || dt.type === "birthDate") {
                    return <PreviewDate
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                          />
                  } else if (dt.type === "fileUpload") {
                    return <PreviewFileUpload 
                            data={dt} 
                            key={dt.id}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                          />
                  } else if (dt.type === "checkbox") {
                    return <PreviewCheckbox
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                          />
                  } else if (dt.type === "choice") {
                    return dt.configuration.selectedView?.value === 'grid' ? 
                      <PreviewChoiceGrid
                            key={dt.id}
                            data={dt}
                            index={index}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                            onEnterPress={e => handleFocusNextField(e,index)}
                      /> : 
                      <PreviewChoiceList
                            key={dt.id}
                            data={dt}
                            index={index}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                            onEnterPress={e => handleFocusNextField(e,index)}
                      />
                  } else if (dt.type === "dropdown") {
                    return <PreviewDropdown
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                          />
                  } else if (dt.type === "radio") {
                    return <PreviewRadio
                            key={dt.id}
                            data={dt}
                            previewValue={previewValue}
                            setPreviewValue={setPreviewValue}
                            simpleValidator={simpleValidator}
                          />
                  } else if (dt.type === "hidden") {
                    return <PreviewHiddenField
                            key={dt.id}
                            data={dt}
                          />
                  } else if (dt.type === "heading1" || dt.type === "heading2" || dt.type === "heading3" || dt.type === "label" || dt.type === "texts") {
                    return <PreviewText 
                            data={dt} 
                            key={dt.id}
                          />;
                  } else if (dt.type === "embedImage" || dt.type === "embedVideo" || dt.type === "embedAudio" || dt.type === "embedAnything") {
                    return <PreviewEmbed 
                            data={dt} 
                            key={dt.id}
                          />;
                  } else if (dt.type === "thankYouPage") {
                    return !dt.configuration.hideBlock.isEnable && 
                          <Thankyou
                            apiKey={apiKey} 
                            formSetting={formSetting} 
                            submitFields={previewValue}
                            fields={fields}
                            formId={formId}
                            onInvalidPhoneNumber={handleInvalidPhoneNumberResponse}
                          />;
                  } else {
                    return <PreviewDefaultField 
                            key={dt.id} 
                            data={dt}
                          />
                  }
                })
              ) : (
                <Thankyou
                  apiKey={apiKey} 
                  formSetting={formSetting} 
                  submitFields={previewValue}
                  fields={fields}
                  formId={formId}
                  onInvalidPhoneNumber={handleInvalidPhoneNumberResponse}/>
              )}
            </form>
          </AnimationHandler>
        </div>
      </div>
    </div>
  );
};
export default PublicCustomForm;
