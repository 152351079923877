import React, { useState } from "react";
import "./UploadModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormBuilderModal from "../FormBuilderModal/FormBuilderModal";
import { useDispatch } from "react-redux";
import { setCustomFormBuilderValue, setCustomFormSaveVisible } from "../../../../actions/customFormBuilderAction";
import { uploadNewFormBuilderImage } from "../../../../api/customFormBuilderApi";

const MAX_FILE_SIZE = 10485760; //1024*1024*10 --- 10MB

const UploadModal = (props) => {

  const dispatch = useDispatch();
  const [uploadStatus, setUploadStatus] = useState(false);

  const uploadImage = (e, type) => {

    if(e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/gif' ) {
      window.showNotification('ERROR','Please select a valid image.');
      return;
    }

    if(e.target.files[0].size > MAX_FILE_SIZE) {  
      window.showNotification('ERROR','Maximum upload size : 10MB');
      return;
    }
    
    setUploadStatus(true);
    const dt = new FormData();
    dt.append("qqfile", e.target.files[0], e.target.files[0].name);
    uploadNewFormBuilderImage(dt).then(res => {
      dispatch(setCustomFormSaveVisible(true));
      dispatch(setCustomFormBuilderValue({
        key : type === "logo" ? "logo" : "cover",
        value : res.data.mediaUrl
      }));
      setUploadStatus(false);
      props.closeModal();
    });
  };


  return (
    <FormBuilderModal
      className="upload-modal ar__upload-modal"
      size="md"
      centered
      open={props.uploadShow}
      onClose={() => props.closeModal()}
    >
      <div>
        <form
          id="frmImage"
          class="form-area"
          enctype="multipart/form-data"
          method="post"
        >
          <div className="d-flex justify-content-left align-items-center">
            <FontAwesomeIcon icon={["fas", "camera"]} />
            <h2>{uploadStatus ? "Uploading" : "Upload"} {props.uploadType} </h2>
          </div>
          <label class="img-label" id="img-label" for="image">
            <FontAwesomeIcon
              icon={["fas", "cloud-upload-alt"]}
              className={uploadStatus ? "bouncing" : ""}
            />
          </label>
          <div className="fb__image-info-modal">
            <p>Max upload size : 10MB</p>
            <p>Supported image formats : jpg,jpeg,png,gif</p>
          </div>
          <input
            disabled={uploadStatus}
            type="file"
            name="image"
            id="image"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => uploadImage(e, props.uploadType)}
          />
        </form>
      </div>
    </FormBuilderModal>
  );
}

export default UploadModal;
