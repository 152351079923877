import React, { useState } from "react";
import EditActionButtons from "./EditActionButtons";
import EditPageSubmitButton from "./EditPageSubmitButton";
import EditCheckbox from "../Checkbox/CheckBoxes";
import MultipleChoices from "../MultipleChoice/MultipleChoices";
import Dropdown from "../Dropdown/Dropdown";
import FileUpload from "../FileUpload/FileUpload";
import LayoutBlock from "../LayoutBlock/LayoutBlock";
import ThankYouPage from "../ThankYouPage/Thankyou";
import "./EditInputFields.css";
import EditRadioButton from "../RadioButton/EditRadioButton";
import { v4 as uuidv4 } from "uuid";
import BootstrapTooltip from "../../BootstrapTooltip";
import { duplicateInputFieldNewForm } from "../../NewFormUtils";
import { useDispatch } from "react-redux";
import { customFormFieldUpdate } from "../../../../actions/customFormBuilderAction";
import formIcons from "../AddModal/FormIcons";
import { EditEmbedFields } from "./EditEmbedFields";

function EditInputFields({
  allInfo,
  handleModal,
  addInputField,
  removeFieldItem,
  updatePlaceholder,
  fields,
  updateFields,
  updateOptionPlaceholder,
  setShow,
  updateLabel
}) {
  const dispatch = useDispatch();
  const { type, placeholder, index, label, name } = allInfo;
  const fieldId = allInfo.id;
  const [isOpen, setIsOpen] = useState(false);

  const duplicateInputField = (id, index, type) => {
    dispatch(
      customFormFieldUpdate(duplicateInputFieldNewForm(id, index, type, fields))
    );
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleAddEmbeddedFile = (type,source) => {
    const copyField = [...fields];

    copyField[index][type] = {
      id: uuidv4(),
      source: source,
    };
    updateFields(copyField);
  };

  const filedFilter = () => {

    let labelColor = allInfo.configuration.labelColor ? allInfo.configuration.labelColor.value : "#333";
    let fontFamily = allInfo.configuration.fontFamily ? allInfo.configuration.fontFamily.value : "'Poppins', sans-serif";
    let fontSize = allInfo.configuration.fontSize ? allInfo.configuration.fontSize.value : 14;

    const setLabelColor = (element) => {
      if (element) {
        element.style.setProperty('color', labelColor, 'important');
        element.style.setProperty('font-family', fontFamily, 'important');
        element.style.setProperty('font-size', parseInt(fontSize)+"px", 'important');
      } 
    }

    if (
      type === "firstName" ||
      type === "lastName" ||
      type === "email" ||
      type === "phoneNumber" ||
      type === "birthDate" ||
      type === "companyName" ||
      type === "city" ||
      type === "state" ||
      type === "zip" ||
      type === "ipAddress"
    ) {
      return (
        <div
          className={`specific__input__field__wr ${
            allInfo.configuration.hideBlock.isEnable ? "decrease__opacity" : ""
          }`}
        >
          <EditActionButtons
            allInfo={allInfo}
            handleModal={handleModal}
            addInputField={addInputField}
            removeFieldItem={removeFieldItem}
            duplicateInputField={duplicateInputField}
            isOpen={isOpen}
            togglePopup={togglePopup}
            fields={fields}
            updateFields={updateFields}
          />
          <div className="specific__input__field__wr__left ar__specific_input">
            <label htmlFor={'edit'+fieldId} className="input__label" style={{color : labelColor,fontFamily : fontFamily, fontSize : parseInt(fontSize)}}>
              {label}
            </label>
            <input
              disabled={type === "birthDate"}
              id={'edit'+fieldId}
              name={name}
              defaultValue={placeholder}
              placeholder={"Add placeholder here"}
              onChange={(e) => updatePlaceholder(e.target.value, index)}
              type="text"
            />
          </div>
          {allInfo.configuration.required.isEnable && (
            <BootstrapTooltip arrow title="Required" placement="top">
              <span className="ar__req">*</span>
            </BootstrapTooltip>
          )}
        </div>
      );
    } else if (type === "address" || type === "note") {
      return (
        <div
          className={`specific__input__field__wr customRow textareaAlt ${
            allInfo.configuration.hideBlock.isEnable ? "decrease__opacity" : ""
          }`}
        >
          <EditActionButtons
            allInfo={allInfo}
            handleModal={handleModal}
            addInputField={addInputField}
            removeFieldItem={removeFieldItem}
            duplicateInputField={duplicateInputField}
            isOpen={isOpen}
            togglePopup={togglePopup}
            fields={fields}
            updateFields={updateFields}
          />

          <div className="specific__input__field__wr__left ar__specific_input">
            <label htmlFor={'edit'+fieldId} className="input__label" style={{color : labelColor,fontFamily : fontFamily, fontSize : parseInt(fontSize)}}>
              {label}
            </label>
            <textarea
              Grids="3"
              cols="50"
              id={'edit'+fieldId}
              name={name}
              placeholder={"Add placeholder here"}
              defaultValue={placeholder}
              onChange={(e) => {
                updatePlaceholder(e.target.value, index);
              }}
            />
          </div>
          {allInfo.configuration.required.isEnable && (
            <BootstrapTooltip arrow title="Required" placement="top">
              <span className="ar__req">*</span>
            </BootstrapTooltip>
          )}
        </div>
      );
    } else if (type === "link") {
      return (
        <div
          className={`specific__input__field__wr alter customRow ${
            allInfo.configuration.hideBlock.isEnable ? "decrease__opacity" : ""
          }`}
        >
          <EditActionButtons
            allInfo={allInfo}
            handleModal={handleModal}
            addInputField={addInputField}
            removeFieldItem={removeFieldItem}
            duplicateInputField={duplicateInputField}
            isOpen={isOpen}
            togglePopup={togglePopup}
            fields={fields}
            updateFields={updateFields}
          />

          <div className="specific__input__field__wr__left ar__specific_input alt ar__input_group_item">
            <label htmlFor={'edit'+fieldId} className="input__label ar__input__label" style={{color : labelColor, fontFamily : fontFamily, fontSize : parseInt(fontSize)}}>
              <input
                ref={setLabelColor}
                className="ppfb__label_input"
                type="text"
                placeholder={"Add label here"}
                defaultValue={label}
                onChange={(e) => {
                  updateLabel(e.target.value, index);
                }}
              />
              <input
                id={'edit'+fieldId}
                name={name}
                placeholder={"Add placeholder here"}
                defaultValue={placeholder}
                onChange={(e) => {
                  updatePlaceholder(e.target.value, index);
                }}
                type="text"
              />
              <BootstrapTooltip arrow title="Link" placement="left">
                {formIcons[type]}
              </BootstrapTooltip>
            </label>
          </div>
          {allInfo.configuration.required.isEnable && (
            <BootstrapTooltip arrow title="Required" placement="top">
              <span className="ar__req">*</span>
            </BootstrapTooltip>
          )}
        </div>
      );
    } else if (type === "shortAnswer") {
      return (
        <div
          className={`specific__input__field__wr customRow textarea2 ar_shrtans ${
            allInfo.configuration.hideBlock.isEnable ? "decrease__opacity" : ""
          }`}
        >
          <EditActionButtons
            allInfo={allInfo}
            handleModal={handleModal}
            addInputField={addInputField}
            removeFieldItem={removeFieldItem}
            duplicateInputField={duplicateInputField}
            isOpen={isOpen}
            togglePopup={togglePopup}
            fields={fields}
            updateFields={updateFields}
          />

          <div className="specific__input__field__wr__left ar__specific_input ar__input_group_item ar__input_group_item_sa">
            <label className="input__label">
              <input
                ref={setLabelColor}
                className="ppfb__label_input"
                type="text"
                placeholder={"Add label here"}
                defaultValue={label}
                onChange={(e) => {
                  updateLabel(e.target.value, index);
                }}
              />
              
              <input
                name={name}
                placeholder={"Add placeholder here"}
                defaultValue={placeholder}
                required={allInfo.configuration.required.isEnable}
                onChange={(e) => {
                  updatePlaceholder(e.target.value, index);
                }}
                type="text"
              />
              <BootstrapTooltip arrow title="Short Answer" placement="left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 14H3M21 10H3"
                  ></path>
                </svg>
              </BootstrapTooltip>
            </label>
          </div>
          {allInfo.configuration.required.isEnable && (
            <BootstrapTooltip arrow title="Required" placement="top">
              <span className="ar__req">*</span>
            </BootstrapTooltip>
          )}
        </div>
      );
    } else if (type === "phoneInput") {
      return (
        <div
          className={`specific__input__field__wr customRow textarea2 ar_shrtans ${
            allInfo.configuration.hideBlock.isEnable ? "decrease__opacity" : ""
          }`}
        >
          <EditActionButtons
            allInfo={allInfo}
            handleModal={handleModal}
            addInputField={addInputField}
            removeFieldItem={removeFieldItem}
            duplicateInputField={duplicateInputField}
            isOpen={isOpen}
            togglePopup={togglePopup}
            fields={fields}
            updateFields={updateFields}
          />

          <div className="specific__input__field__wr__left ar__specific_input ar__input_group_item ar__input_group_item_sa">
            <label className="input__label">
              <input
                ref={setLabelColor}
                className="ppfb__label_input"
                type="text"
                placeholder={"Add label here"}
                defaultValue={label}
                onChange={(e) => {
                  updateLabel(e.target.value, index);
                }}
              />
              
              <input
                name={name}
                placeholder={"Add placeholder here"}
                defaultValue={placeholder}
                required={allInfo.configuration.required.isEnable}
                onChange={(e) => {
                  updatePlaceholder(e.target.value, index);
                }}
                type="text"
              />
              <BootstrapTooltip arrow title="Phone Number" placement="left">
                {formIcons.phoneNumber}
              </BootstrapTooltip>
            </label>
          </div>
          {allInfo.configuration.required.isEnable && (
            <BootstrapTooltip arrow title="Required" placement="top">
              <span className="ar__req">*</span>
            </BootstrapTooltip>
          )}
        </div>
      );
    } else if (type === "date") {
      return (
        <div
          className={`specific__input__field__wr alter customRow ${
            allInfo.configuration.hideBlock.isEnable ? "decrease__opacity" : ""
          }`}
        >
          <EditActionButtons
            allInfo={allInfo}
            handleModal={handleModal}
            addInputField={addInputField}
            removeFieldItem={removeFieldItem}
            duplicateInputField={duplicateInputField}
            isOpen={isOpen}
            togglePopup={togglePopup}
            fields={fields}
            updateFields={updateFields}
          />

          <div className="specific__input__field__wr__left ar__specific_input  alt">
            <label className="input__label ar__input__label">
              <input
                ref={setLabelColor}
                className="ppfb__label_input"
                type="text"
                placeholder={"Add label here"}
                defaultValue={label}
                onChange={(e) => {
                  updateLabel(e.target.value, index);
                }}
              />
              <input
                disabled
                name={name}
                placeholder={"Add placeholder here"}
                defaultValue={placeholder}
                required={allInfo.configuration.required.isEnable}
                onChange={(e) => {
                  updatePlaceholder(e.target.value, index);
                }}
                type="text"
              />
            </label>
          </div>
          {allInfo.configuration.required.isEnable && (
            <BootstrapTooltip arrow title="Required" placement="top">
              <span className="ar__req">*</span>
            </BootstrapTooltip>
          )}
        </div>
      );
    } else if (type === "time") {
      return (
        <div
          className={`specific__input__field__wr alter customRow ${
            allInfo.configuration.hideBlock.isEnable ? "decrease__opacity" : ""
          }`}
        >
          <EditActionButtons
            allInfo={allInfo}
            handleModal={handleModal}
            addInputField={addInputField}
            removeFieldItem={removeFieldItem}
            duplicateInputField={duplicateInputField}
            isOpen={isOpen}
            togglePopup={togglePopup}
            fields={fields}
            updateFields={updateFields}
          />

          <div className="specific__input__field__wr__left ar__specific_input  alt">
            <label className="input__label ar__input__label">
              <input
                ref={setLabelColor}
                className="ppfb__label_input"
                type="text"
                placeholder={"Add label here"}
                defaultValue={label}
                onChange={(e) => {
                  updateLabel(e.target.value, index);
                }}
              />
              
              <input
                disabled
                name={name}
                placeholder={"Add placeholder here"}
                defaultValue={placeholder}
                required={allInfo.configuration.required.isEnable}
                onChange={(e) => {
                  updatePlaceholder(e.target.value, index);
                }}
                type="text"
              />
            </label>
          </div>
          {allInfo.configuration.required.isEnable && (
            <BootstrapTooltip arrow title="Required" placement="top">
              <span className="ar__req">*</span>
            </BootstrapTooltip>
          )}
        </div>
      );
    } else if (type === "textarea") {
      return (
        <div
          className={`specific__input__field__wr customRow ar__lanswer textareaAlt3 ${
            allInfo.configuration.hideBlock.isEnable ? "decrease__opacity" : ""
          }`}
        >
          <EditActionButtons
            allInfo={allInfo}
            handleModal={handleModal}
            addInputField={addInputField}
            removeFieldItem={removeFieldItem}
            duplicateInputField={duplicateInputField}
            isOpen={isOpen}
            togglePopup={togglePopup}
            fields={fields}
            updateFields={updateFields}
          />

          <div className="specific__input__field__wr__left ar__specific_input ar__input_group_item ar__input_group_item_la">
            <input
                ref={setLabelColor}
                className="ppfb__label_input"
                type="text"
                placeholder={"Add label here"}
                defaultValue={label}
                onChange={(e) => {
                  updateLabel(e.target.value, index);
                }}
            />
            <textarea
              Grids="3"
              cols="50"
              name={name}
              placeholder={"Add placeholder here"}
              defaultValue={placeholder}
              onChange={(e) => {
                updatePlaceholder(e.target.value, index);
              }}
            />
            <BootstrapTooltip arrow title="Long Answer" placement="left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="21" y1="10" x2="3" y2="10"></line>
                <line x1="21" y1="6" x2="3" y2="6"></line>
                <line x1="21" y1="14" x2="3" y2="14"></line>
                <line x1="21" y1="18" x2="3" y2="18"></line>
              </svg>
            </BootstrapTooltip>
          </div>
          {allInfo.configuration.required.isEnable && (
            <BootstrapTooltip arrow title="Required" placement="top">
              <span className="ar__req">*</span>
            </BootstrapTooltip>
          )}
        </div>
      );
    } else if (type === "number") {
      return (
        <div
          className={`specific__input__field__wr alter customRow ${
            allInfo.configuration.hideBlock.isEnable ? "decrease__opacity" : ""
          }`}
        >
          <EditActionButtons
            allInfo={allInfo}
            handleModal={handleModal}
            addInputField={addInputField}
            removeFieldItem={removeFieldItem}
            duplicateInputField={duplicateInputField}
            isOpen={isOpen}
            togglePopup={togglePopup}
            fields={fields}
            updateFields={updateFields}
          />

          <div className="specific__input__field__wr__left ar__specific_input ar__input_group_item ar__nmbr">
            <input
                ref={setLabelColor}
                className="ppfb__label_input"
                type="text"
                placeholder={"Add label here"}
                defaultValue={label}
                onChange={(e) => {
                  updateLabel(e.target.value, index);
                }}
              />
            <input
              type="text"
              name={name}
              // pattern="[1]{1}-[0-9]{3}-[0-9]{7}"
              placeholder={"Add placeholder here"}
              defaultValue={placeholder}
              onChange={(e) => {
                updatePlaceholder(e.target.value, index);
              }}
            />
            <BootstrapTooltip arrow title="Number" placement="left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="4" y1="9" x2="20" y2="9"></line>
                <line x1="4" y1="15" x2="20" y2="15"></line>
                <line x1="10" y1="3" x2="8" y2="21"></line>
                <line x1="16" y1="3" x2="14" y2="21"></line>
              </svg>
            </BootstrapTooltip>
          </div>
          {allInfo.configuration.required.isEnable && (
            <BootstrapTooltip arrow title="Required" placement="top">
              <span className="ar__req">*</span>
            </BootstrapTooltip>
          )}
        </div>
      );
    } else if (type === "button") {
      return (
        <>
          <div
            className={`specific__input__field__wr ${
              allInfo.configuration.hideBlock.isEnable
                ? "decrease__opacity"
                : ""
            }`}
          >
            <EditActionButtons
              allInfo={allInfo}
              handleModal={handleModal}
              addInputField={addInputField}
              duplicateInputField={duplicateInputField}
              isOpen={isOpen}
              togglePopup={togglePopup}
              isButton
              fields={fields}
              updateFields={updateFields}
            />
          </div>

          <EditPageSubmitButton
            btnInfo={allInfo}
            fields={fields}
            updateFields={updateFields}
          />
        </>
      );
    } else if (type === "radio") {
      return (
        <>
          <div
            className={`specific__input__field__wr alternative customRow ${
              allInfo.configuration.hideBlock.isEnable
                ? "decrease__opacity"
                : ""
            }`}
          >
            <EditActionButtons
              allInfo={allInfo}
              handleModal={handleModal}
              addInputField={addInputField}
              removeFieldItem={removeFieldItem}
              duplicateInputField={duplicateInputField}
              isOpen={isOpen}
              togglePopup={togglePopup}
              fields={fields}
              updateFields={updateFields}
            />

            <div className="specific__input__field__wr__left ar__specific_input">
              <EditRadioButton
                allInfo={allInfo}
                fields={fields}
                updateFields={updateFields}
                index={allInfo.index}
                updateOptionPlaceholder={updateOptionPlaceholder}
                label={label}
                updateLabel={updateLabel}
              />
            </div>
          </div>
        </>
      );
    } else if (type === "checkbox") {
      return (
        <>
          <div
            className={`specific__input__field__wr customRow alternative ${
              allInfo.configuration.hideBlock.isEnable
                ? "decrease__opacity"
                : ""
            }`}
          >
            <EditActionButtons
              allInfo={allInfo}
              handleModal={handleModal}
              addInputField={addInputField}
              removeFieldItem={removeFieldItem}
              duplicateInputField={duplicateInputField}
              isOpen={isOpen}
              togglePopup={togglePopup}
              fields={fields}
              updateFields={updateFields}
            />

            <div className="specific__input__field__wr__left ar__specific_input">
              <EditCheckbox
                allInfo={allInfo}
                fields={fields}
                updateFields={updateFields}
                index={allInfo.index}
                updateOptionPlaceholder={updateOptionPlaceholder}
                label={label}
                updateLabel={updateLabel}
              />
            </div>
          </div>
        </>
      );
    } else if (type === "choice") {
      return (
        <>
          <div
            className={`specific__input__field__wr alternative ${
              allInfo.configuration.hideBlock.isEnable
                ? "decrease__opacity"
                : ""
            }`}
          >
            <EditActionButtons
              allInfo={allInfo}
              handleModal={handleModal}
              addInputField={addInputField}
              removeFieldItem={removeFieldItem}
              duplicateInputField={duplicateInputField}
              isOpen={isOpen}
              togglePopup={togglePopup}
              fields={fields}
              updateFields={updateFields}
            />

            <div className="specific__input__field__wr__left">
              <MultipleChoices
                allInfo={allInfo}
                fields={fields}
                updateFields={updateFields}
                index={allInfo.index}
                updateOptionPlaceholder={updateOptionPlaceholder}
                label={label}
                updateLabel={updateLabel}
              />
            </div>
          </div>
        </>
      );
    } else if (type === "dropdown") {
      return (
        <>
          <div
            className={`specific__input__field__wr alternative ${
              allInfo.configuration.hideBlock.isEnable
                ? "decrease__opacity"
                : ""
            }`}
          >
            <EditActionButtons
              allInfo={allInfo}
              handleModal={handleModal}
              addInputField={addInputField}
              removeFieldItem={removeFieldItem}
              duplicateInputField={duplicateInputField}
              isOpen={isOpen}
              togglePopup={togglePopup}
              fields={fields}
              updateFields={updateFields}
            />

            <div className="specific__input__field__wr__left">
              <Dropdown
                allInfo={allInfo}
                fields={fields}
                updateFields={updateFields}
                index={allInfo.index}
                updateOptionPlaceholder={updateOptionPlaceholder}
                label={label}
                updateLabel={updateLabel}
              />
            </div>
          </div>
        </>
      );
    } else if (type === "fileUpload") {
      return (
        <>
          <div
            className={`specific__input__field__wr ${
              allInfo.configuration.hideBlock.isEnable
                ? "decrease__opacity"
                : ""
            }`}
          >
            <EditActionButtons
              allInfo={allInfo}
              handleModal={handleModal}
              addInputField={addInputField}
              removeFieldItem={removeFieldItem}
              duplicateInputField={duplicateInputField}
              isOpen={isOpen}
              togglePopup={togglePopup}
              fields={fields}
              updateFields={updateFields}
            />

            <div className="specific__input__field__wr__left ar__specific_input file__upload__with__label">
              <div className="file__upload__with__label__input">
                <input
                  ref={setLabelColor}
                  className="ppfb__label_input"
                  type="text"
                  placeholder={"Add label here"}
                  defaultValue={label}
                  onChange={(e) => {
                    updateLabel(e.target.value, index);
                  }}
                />
              </div>  
              <form onSubmit={(e) => e.preventDefault()}>
                <FileUpload
                  accept=".jpg,.png,.jpeg, .pdf, .gif .docx"
                  multiple
                  isDisbaled={true}
                />
                {/* <button type="submit">Create New User</button> */}
              </form>
            </div>
            {allInfo.configuration.required.isEnable && (
              <BootstrapTooltip arrow title="Required" placement="top">
                <span className="ar__req">*</span>
              </BootstrapTooltip>
            )}
          </div>
        </>
      );
    } else if (type === "hidden") {
      return (
        <>
          <div
            className={`specific__input__field__wr ar__specific_input ${
              allInfo.configuration.hideBlock.isEnable
                ? "decrease__opacity"
                : ""
            }`}
          >
            <EditActionButtons
              allInfo={allInfo}
              handleModal={handleModal}
              addInputField={addInputField}
              removeFieldItem={removeFieldItem}
              isOpen={isOpen}
              togglePopup={togglePopup}
              duplicateInputField={duplicateInputField}
              fields={fields}
              updateFields={updateFields}
            />

            <div className="specific__input__field__wr__left">
              <input
                type="text"
                placeholder="Enter your hidden value"
                defaultValue={placeholder}
                onChange={(e) => updatePlaceholder(e.target.value, index)}
              />
            </div>
          </div>
        </>
      );
    } else if (type === "heading1") {
      return (
        <>
          <div
            className={`specific__input__field__wr ${
              allInfo.configuration.hideBlock.isEnable
                ? "decrease__opacity"
                : ""
            }`}
          >
            <EditActionButtons
              allInfo={allInfo}
              handleModal={handleModal}
              addInputField={addInputField}
              removeFieldItem={removeFieldItem}
              duplicateInputField={duplicateInputField}
              isOpen={isOpen}
              togglePopup={togglePopup}
              fields={fields}
              updateFields={updateFields}
            />

            <div className="specific__input__field__wr__left ar__specific_input">
              <LayoutBlock
                field={type}
                updatePlaceholder={updatePlaceholder}
                allInfo={allInfo}
                placeholder={placeholder}
                className={"heading_1 ar__title_heading"}
                initialPlaceholder={"Heading 1"}
              />
            </div>
          </div>
        </>
      );
    } else if (type === "heading2") {
      return (
        <>
          <div
            className={`specific__input__field__wr ${
              allInfo.configuration.hideBlock.isEnable
                ? "decrease__opacity"
                : ""
            }`}
          >
            <EditActionButtons
              allInfo={allInfo}
              handleModal={handleModal}
              addInputField={addInputField}
              removeFieldItem={removeFieldItem}
              duplicateInputField={duplicateInputField}
              isOpen={isOpen}
              togglePopup={togglePopup}
              fields={fields}
              updateFields={updateFields}
            />

            <div className="specific__input__field__wr__left ar__specific_input">
              <LayoutBlock
                field={type}
                updatePlaceholder={updatePlaceholder}
                allInfo={allInfo}
                placeholder={placeholder}
                className={"heading_2 ar__title_heading"}
                initialPlaceholder={"Heading 2"}
              />
            </div>
          </div>
        </>
      );
    } else if (type === "heading3") {
      return (
        <>
          <div
            className={`specific__input__field__wr ${
              allInfo.configuration.hideBlock.isEnable
                ? "decrease__opacity"
                : ""
            }`}
          >
            <EditActionButtons
              allInfo={allInfo}
              handleModal={handleModal}
              addInputField={addInputField}
              removeFieldItem={removeFieldItem}
              duplicateInputField={duplicateInputField}
              isOpen={isOpen}
              togglePopup={togglePopup}
              fields={fields}
              updateFields={updateFields}
            />

            <div className="specific__input__field__wr__left ar__specific_input">
              <LayoutBlock
                field={type}
                updatePlaceholder={updatePlaceholder}
                allInfo={allInfo}
                placeholder={placeholder}
                className={"heading_3 ar__title_heading"}
                initialPlaceholder={"Heading 3"}
              />
            </div>
          </div>
        </>
      );
    } else if (type === "label") {
      return (
        <>
          <div
            className={`specific__input__field__wr ${
              allInfo.configuration.hideBlock.isEnable
                ? "decrease__opacity"
                : ""
            }`}
          >
            <EditActionButtons
              allInfo={allInfo}
              handleModal={handleModal}
              addInputField={addInputField}
              removeFieldItem={removeFieldItem}
              duplicateInputField={duplicateInputField}
              isOpen={isOpen}
              togglePopup={togglePopup}
              fields={fields}
              updateFields={updateFields}
              setShow={setShow}
            />

            <div className="specific__input__field__wr__left">
              <LayoutBlock
                field={type}
                updatePlaceholder={updatePlaceholder}
                allInfo={allInfo}
                placeholder={placeholder}
                className={"label box_shadow_none"}
                initialPlaceholder={"Label"}
              />
            </div>
          </div>
        </>
      );
    } else if (type === "texts") {
      return (
        <>
          <div
            className={`specific__input__field__wr ${
              allInfo.configuration.hideBlock.isEnable
                ? "decrease__opacity"
                : ""
            }`}
          >
            <EditActionButtons
              allInfo={allInfo}
              handleModal={handleModal}
              addInputField={addInputField}
              removeFieldItem={removeFieldItem}
              duplicateInputField={duplicateInputField}
              isOpen={isOpen}
              togglePopup={togglePopup}
              fields={fields}
              updateFields={updateFields}
              setShow={setShow}
            />

            <div className="specific__input__field__wr__left  ar__specific_input">
              <LayoutBlock
                field={type}
                updatePlaceholder={updatePlaceholder}
                allInfo={allInfo}
                placeholder={placeholder}
                className={"texts"}
                initialPlaceholder={"Text"}
              />
            </div>
          </div>
        </>
      );
    } else if (type === "newPage") {
      return <></>;
    } else if (type === "thankYouPage") {
      return (
        <>
          <div
            className={`specific__input__field__wr ${
              allInfo.configuration.hideBlock.isEnable
                ? "decrease__opacity"
                : ""
            }`}
          >
            <EditActionButtons
              allInfo={allInfo}
              handleModal={handleModal}
              addInputField={addInputField}
              removeFieldItem={removeFieldItem}
              duplicateInputField={duplicateInputField}
              isOpen={isOpen}
              togglePopup={togglePopup}
              fields={fields}
              updateFields={updateFields}
              setShow={setShow}
            />

            <div className="specific__input__field__wr__left">
              <ThankYouPage />
            </div>
          </div>
        </>
      );
    } else if (type === "embedImage" || type === "embedVideo" || type === "embedAudio" || type === "embedAnything") {
      return (
          <EditEmbedFields
            type={type}
            allInfo={allInfo}
            handleModal={handleModal}
            addInputField={addInputField}
            removeFieldItem={removeFieldItem}
            duplicateInputField={duplicateInputField}
            isOpen={isOpen}
            togglePopup={togglePopup}
            fields={fields}
            updateFields={updateFields}
            setShow={setShow}
            handleAddEmbeddedFile={handleAddEmbeddedFile}/>
      );
    } else {
      let newType = "text";
      return (
        <div
          className={`specific__input__field__wr ${
            allInfo.configuration.hideBlock.isEnable ? "decrease__opacity" : ""
          }`}
        >
          <EditActionButtons
            allInfo={allInfo}
            handleModal={handleModal}
            addInputField={addInputField}
            removeFieldItem={removeFieldItem}
            duplicateInputField={duplicateInputField}
            isOpen={isOpen}
            togglePopup={togglePopup}
            fields={fields}
            updateFields={updateFields}
            setShow={setShow}
          />

          <div className="specific__input__field__wr__left ar__specific_input">
            <input
              type={newType}
              placeholder={"Add placeholder here"}
              defaultValue={placeholder}
              onChange={(e) => updatePlaceholder(e.target.value, index)}
            />
          </div>
        </div>
      );
    }
  };
  return <>{filedFilter()}</>;
}

export default EditInputFields;
