import React from "react";
import GlobalModal from "../Common/Modal/GlobalModal";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Paper from '@material-ui/core/Paper';
import LinkIcon from '@material-ui/icons/Link';
import PublicIcon from '@material-ui/icons/Public';
import CodeIcon from '@material-ui/icons/Code';
import {Box, Tab, Tabs, Typography} from "@material-ui/core";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const FromIntegrate = props => {
    const [tabValue, setTabValue] = React.useState(0);
    const [isCopied, setIsCopied] = React.useState(false);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const copyToClipBoard = () => {
        let copyText = '';

        if (tabValue === 0) {
            copyText = `${window.location.origin}/form/${props.form.unique_id}`;
        } else if (tabValue === 1) {
            copyText = getFromEmbedLink();
        } else if (tabValue === 2) {
            copyText = props.form.builder_html;
        }

        navigator.clipboard.writeText(copyText);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false),2000);
    }

    const getFromEmbedLink = () => {
        return (`<iframe src=${window.location.origin}/form/${props.form.unique_id} style="height:100%;width:100%;" scrolling="yes"></iframe>`);
    }

    return (
        <React.Fragment>
            
            <GlobalModal className={`pp_integration-modal ${props.className !== undefined ? props.className : ''} ${tabValue === 2 ? 'integrate-html' : ''}`} open={props.openIntegrateModal} onClose={() => props.setOpenIntegrateModal(false)} title={"Integrate Form"} buttonText={isCopied ? 'Copied to clipboard' : 'Copy to clipBoard'} buttonIcon={isCopied ? <CheckCircleIcon /> : <FileCopyIcon /> } buttonClick={copyToClipBoard}>
            
                <Paper>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Link" icon={<PublicIcon />} />
                        <Tab label="Embed" icon={<LinkIcon />} />
                        <Tab label="HTML" icon={<CodeIcon />}/>
                    </Tabs>
                </Paper>
                <div className="awesome__scroll_bar integrate__form__v2">
                    <TabPanel value={tabValue} index={0}>
                        <p>{ `${window.location.origin}/form/${props.form.unique_id}` }</p>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <p>{getFromEmbedLink()}</p>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <div>  <p>{props.form.builder_html}</p> </div>
                    </TabPanel>
                </div>
            </GlobalModal>
            
        </React.Fragment>
    );
}

export default FromIntegrate;