import React from 'react';
import BootstrapTooltip from '../../BootstrapTooltip';
import FileUpload from "../FileUpload/FileUpload";

const PreviewFileUpload = (props) => {

    const labelColor = props.data.configuration.labelColor ? props.data.configuration.labelColor.value : "#333";
    const fontFamily = props.data.configuration.fontFamily ? props.data.configuration.fontFamily.value : "'Poppins', sans-serif";
    const fontSize = props.data.configuration.fontSize ? props.data.configuration.fontSize.value : 14;
    
    return (
        !props.data.configuration.hideBlock.isEnable && (
          <>
            <form onSubmit={e => e.preventDefault()}>
              <FileUpload
                accept=".jpg,.png,.jpeg, .pdf, .gif .docx"
                label={props.data.label}
                multiple
                updateFilesCb={(file) => {
                  props.setPreviewValue({
                    ...props.previewValue,
                    [props.data.id]: file,
                  });
                }}
                isDisbaled={false}
                labelColor={labelColor}
                fontFamily={fontFamily}
                fontSize={fontSize}
              />
              {props.data.configuration.required.isEnable && (
                <BootstrapTooltip
                  arrow
                  title="Required"
                  placement="top"
                >
                  <span className="ar__req">*</span>
                </BootstrapTooltip>
              )}
            </form>
            { props.simpleValidator.addFormValidator( props.data.component, props.data.type, props.previewValue[props.data.id], props.data.configuration ) }
          </>
        )
      );
}

export default PreviewFileUpload;
