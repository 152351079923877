import React from 'react'

//Key value must be same as type
const styles = {
    heading1 : {
        fontSize: 30,
        fontWeight: 600,
        lineHeight: 1.25,
    },
    heading2 : {
        fontSize: 24,
        fontWeight: 600,
        lineHeight: 1.25,
    },
    heading3 : {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: 1.25,
    },
    label : {
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: 1.618,
    }
    
}

const components = {
    heading1: "h1",
    heading2: "h2",
    heading3: "h3",
    label: "h4",
    texts: "p",
};


const PreviewText = ({ data }) => {

    const TagName = components[data.type];
    const labelColor = data.configuration.labelColor ? data.configuration.labelColor.value : "#333";
    const fontFamily = data.configuration.fontFamily ? data.configuration.fontFamily.value : "'Poppins', sans-serif";


    return (
        !data.configuration.hideBlock.isEnable && (
          <div className="mb-3 singleItem" key={data.id}>
            <div>
                <TagName style={styles[data.type]} style={{color : labelColor, fontFamily : fontFamily}}>
                    {data.placeholder}
                </TagName>
            </div>
          </div>
        )
      )
}

export default PreviewText;
