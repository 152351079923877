import {useEffect, useState} from "react";
import Api from "../../../constants/Api";
import Utils from "../../../helpers/Utils";
import CoreConstants from '../../../constants/CoreConstants';
import $ from "jquery";
import {Skeleton} from "@material-ui/lab";
import ListSkeleton from "../../Common/Skeletons/ListSkeleton";
import {Helmet} from "react-helmet";
import './form_builder_public_page.css';
import NotFound from "../../NotFound";

require('formBuilder/dist/form-render.min');

const INACTIVE = 0;
const ACTIVE = 1;
const INVISIBLE = 2;

const RenderForm = props => {
    const [state, setState] = useState({
        form: null,
        formSetting: null,
        formStyle: null,
        agencyLogo: '',
        loading: true,
        hasShadow : true,
    });
    const [pageInfo, setPageInfo] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState({
        message: "",
        show: false
    });

    useEffect(() => {
        fetchFormData();

        if (document.body.style){
            document.body.style.position = "relative";
            document.body.style.overflow = "hidden";
            document.body.style.height = "100vh";
        }

    }, []);

    const fetchFormData = () => {
        let formData = new FormData();
        formData.append('uniqueCode', props.match.params.uniqueId);

        fetch(Api.fetchFormDetailsForPublicPage,{
            method: 'POST',
            body: formData
        }).then(response => {
            response.json().then(result => {
                if (result.status) {
                    if(result.data.pageInfo){
                        setPageInfo(result.data.pageInfo);
                    }

                    setState({
                        ...state,
                        form: result.data.form,
                        formSetting: result.data.formSetting,
                        formStyle: result.data.formStyle,
                        agencyLogo: result.data.agencyLogo,
                        loading: false,
                        hasShadow: result.data.formStyle.form_page_box_shadow === undefined || result.data.formStyle.form_page_box_shadow === 1,
                    });

                    let renderTemplate = $(document).find('#fb-rendered-form').find('.render-wrap');
                    $(renderTemplate).formRender({ formData: result.data.form.builder_json });
                } else {
                    setState({...state, loading: false});
                    Utils.showNotification(result.message, 'Error');
                }
            });
        }).catch(err => {
            setState({...state, loading: false});
            Utils.showNotification(`Server Error. ${err}`, 'Error');
        });
    }

    const submitForm = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        formData.append('unique_id', state.form.unique_id);
        formData.append('campaign_id', state.formSetting.campaign_id ?? null);
        formData.append('stage_id', state.formSetting.stage_id ?? null);
        formData.append('version', 'v2');

        fetch(Api.formSubmit,{
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
            },
        }).then(response => {
            response.json().then(result => {
                if (result.status === 'success') {
                    if (state.formSetting.action_after_submit_type == CoreConstants.REDIRECT_AFTER_SUBMIT) {
                        window.location.href = state.formSetting.action_after_submit
                    } else {
                        document.getElementById("form").reset();
                        setSubmitSuccess({
                            show: true,
                            message: result.html
                        });
                    }
                } else {
                    setState({...state, loading: false});
                    Utils.showNotification(result.html, 'Error');
                }
            });
        }).catch(err => {
            setState({...state, loading: false});
            Utils.showNotification(`Server Error. ${err}`, 'Error');
        });
    }

    const renderStyle = () => {
        return `
            .from-wrapper {
                height: 100vh;
                padding:20px 0;
                overflow-y: auto;
                ${state.formStyle.landing_page_bg_type == CoreConstants.LANDING_PAGE_BG_IMAGE ?
                    'background-image:url('+process.env.REACT_APP_BASE_URL+'upload/formBuilder/bg/'+state.form.user_id+'/'+state.formStyle.landing_page_bg+')'
                    :
                    'background-color:'+state.formStyle.landing_page_bg ?? '#ffffff'
                }
            }
            .form-${state.form.unique_id} * {
                color: ${ state.formStyle.form_text_color ?? '#575962' }
            }
            .form-${state.form.unique_id} label, .form-${state.form.unique_id} input, .form-${state.form.unique_id} button {
                font-size: ${state.formStyle.font_size ? state.formStyle.font_size + 'px !important' : '16px !important'};
            }
            .form-${state.form.unique_id} button:focus {
                background-color: ${state.formStyle.submit_button_bg ?? '#34bfa3'} !important
            }
        `
    }

    const renderImage = () => {
        if(state.formStyle !== undefined && state.formStyle !== null){
            if(state.formStyle.landing_page_top_logo !== undefined && state.formStyle.landing_page_top_logo !== 0){
                return <img className="m-2" src={state.agencyLogo} style={{maxWidth: '220px'}} alt="Logo"/>
            }
        }
        return null;
    }

    if (state && state.form && state.form.status == INVISIBLE){
        return (<NotFound/>);
    }

    return (
        <>
            {!state.loading &&
                <Helmet>
                    <link rel="icon" href={pageInfo.favIcon}/>
                    <title>{`${state.form.title} | ${pageInfo.agencyName}`}</title>
                    <meta name="description" content="All-In-One Sales & Marketing Platform"/>
                    <meta name="keywords" content={`CRM, Automation, Sales, Marketing, ${pageInfo.agencyName}`}/>
                    <meta name="author" content={pageInfo.agencyName}/>
                </Helmet>
            }
            <div className='from-wrapper awesome__scroll_bar'>
                <div className="mx-auto">
                    {state.form !== null &&
                        <style>{renderStyle()}</style>
                    }
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <a href="#" className="d-flex justify-content-center">
                                {state.loading ?
                                    <Skeleton animation={'wave'} width={220} height={120}/>
                                    :
                                    renderImage()
                                }
                            </a>
                        </div>
                    </div>
                    <div>
                        {state.loading ?
                            <div style={{width: '700px', margin: '0 auto'}}>
                                <ListSkeleton width={650} height={70} number={8}/>
                            </div>
                            :
                            (
                                submitSuccess.show ? (
                                    <div style={{
                                        width: state.formStyle.form_width ? state.formStyle.form_width+'px' : '700px',
                                        margin: '0 auto',
                                        boxShadow: state.hasShadow ? 'rgba(69, 65, 78, 0.2) 0px 0px 15px 1px' : 'none',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                    }}>
                                        <div className="card form-card m-0" style={{backgroundColor: (state.formStyle.form_bg ?? '#ffffff')}}>
                                            <div className="card-body">
                                                <div className="form_success_wrapper">
                                                    <div className="form_title">
                                                        {state.form.title}
                                                    </div>
                                                    <div className="form_success_message">{submitSuccess.message}</div>
                                                    <div className="form_resubmit">
                                                        <span onClick={()=>{window.location.reload()}}>
                                                            Submit another response
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {(state.formSetting.agency_branding != '' && state.formSetting.agency_branding == CoreConstants.VALUE_YES) &&
                                                <div className="card-footer text-right" style={{backgroundColor: 'unset', borderTop: 'unset', display: 'flex', justifyContent: 'flex-end', margin: '20px 0 0 0', alignItems: "center"}}>
                                                    <span style={{ marginRight: "10px" }}>Powered By</span> <img className="logo" src={state.agencyLogo} alt="Logo" style={{fontSize: '25px', fontWeight: 'bold', maxWidth: '150px'}} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <form className={`form-${state.form.unique_id}`} id="form" onSubmit={(e) => submitForm(e)} style={{
                                        width: state.formStyle.form_width ? state.formStyle.form_width+'px' : '700px',
                                        margin: '0 auto',
                                        boxShadow: state.hasShadow ? 'rgba(69, 65, 78, 0.2) 0px 0px 15px 1px' : 'none',
                                        borderRadius: '8px',
                                    }} >
                                        <div className="card form-card m-0" style={{backgroundColor: (state.formStyle.form_bg ?? '#ffffff'), position: "relative"}}>
                                            {
                                                state.form.status == INACTIVE && (
                                                    <div className="form_disabled_wrapper">
                                                        <div className="form_disabled_alert">
                                                            <h1>{state.formSetting && state.formSetting.inactive_form_text}</h1>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            <div className="card-body">
                                                <div id="fb-rendered-form">
                                                    <div className="render-wrap"></div>
                                                </div>
                                                <div className="card-text text-center">
                                                    <button type="submit" className="submit-btn btn"
                                                            style={{
                                                                width: `${state.formStyle.submit_button_width ?? '100'}%`,
                                                                backgroundColor: `${state.formStyle.submit_button_bg ?? '#34bfa3'}`,
                                                                color: `${state.formStyle.submit_button_color ?? '#ffffff'}`,
                                                            }}
                                                    >
                                                        { state.formStyle.submit_button_text ?? 'Save Information' }
                                                    </button>
                                                </div>
                                            </div>
                                            {(state.formSetting.agency_branding != '' && state.formSetting.agency_branding == CoreConstants.VALUE_YES) &&
                                                <div className="card-footer text-right" style={{backgroundColor: 'unset', borderTop: 'unset', display: 'flex', justifyContent: 'flex-end', margin: '20px 0 0 0', alignItems: "center"}}>
                                                    <span style={{ marginRight: "10px" }}>Powered By</span> <img className="logo" src={state.agencyLogo} alt="Logo" style={{fontSize: '25px', fontWeight: 'bold', maxWidth: '150px'}} />
                                                </div>
                                            }
                                        </div>
                                    </form>
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </>
        
    );
}

export default RenderForm;
