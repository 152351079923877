import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React from 'react'

export const DropdownContent = () => {
  return (
    <FormControl className="ar__dropdown mt-40px">
        <InputLabel id="demo-controlled-open-select-label">Age</InputLabel>
        <Select
            fullWidth
            labelId="demo-controlled-open-select-label"
            disabled>
            <MenuItem value="">
            <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
        </Select>
    </FormControl>
  )
}
