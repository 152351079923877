import React from "react";
import FormBuilderModal from "../../../../FormBuilderModal/FormBuilderModal";

const CanvaStartModal = (props) => {
  
  const closeModal = () => {
    props.closeModal();
  };

  const callback = (e) => {
    e.preventDefault();
    props.callback();
  };

  return (
    <FormBuilderModal 
      open={props.isOpen}
      onClose={closeModal}
      className="form__builder__modal ar__form_builder"
    >      
      <div className="canva__alert__wr">
        <span className="canva__alert__title">{props.title}</span>

        <div className="canva__alert__note">
          <p>
            <strong>Please Note:</strong>
            <br />
              We use Canva for our designer, you will need to create an
              account if you don't already have one. Please note if you use
              any of their stock photography there is a cost of $1.
          </p>
          <div className="canva__alert__image__section">
            <img
              src={`https://d24gujj67p1uc1.cloudfront.net/asset/images/releases/1.0.0/canva-alert.png`}
              className="canva__alert__image"
              alt="canva-alert"
            />
          </div>
        </div>

        <div className="canva__alert__btn__section">
          <button
            type="button"
            className="canva__alert__btn"
            color="primary"
            onClick={(e) => callback(e)}
          >
            <span>OK</span>
          </button>
        </div>
      </div>

    </FormBuilderModal>
  );
}

export default CanvaStartModal;
