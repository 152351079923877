import React, {useState} from "react";
import {IconList} from "../../../constants/IconList";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useDispatch } from "react-redux";
import Utils from '../../../helpers/Utils';
import {Link, useHistory} from "react-router-dom";
import FormIntegrate from "./FormIntegrate";
import { cloneCustomForm, deleteCustomForm } from "../../../api/customFormBuilderApi";
import { fetchCustomForms } from "../../../actions/customFormBuilderAction";
import BootstrapTooltip from "../BootstrapTooltip";
import ActionControl from "../../Common/ActionControl/ActionControl";

const FormBottomButtons = props => {
    const isTempUser = Utils.getAccountData('isTemplateUser');
    const dispatch = useDispatch();
    const history = useHistory();
    const [openIntegrateModal, setOpenIntegrateModal] = useState(false);

    const formDelete = () => {
        confirmAlert({
            title: 'Confirm to delete?',
            message: 'You will not able to undo it.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        deleteCustomForm({form_id : props.form.id}).then(response => {
                            if(response.data.status === 'success') {
                                Utils.showNotification(response.data.html, 'success');
                                dispatch(fetchCustomForms())
                            }else{
                                Utils.showNotification(response.data.html, 'error');
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    }

    const formClone = () => {
        cloneCustomForm({form_id: props.form.id}).then(response => {
            if (response.data.status === 'success') {
                history.push(`/user/new/form-builder/${response.data.html.unique_id}`);
                dispatch(fetchCustomForms())
            } else {
                Utils.showNotification(response.data.html, 'error');
            }
        });
    }

    return (
        <ul className="form__bottom">
            <BootstrapTooltip title="Entries" arrow>
                <Link className="form__action__buttons" to={`/user/new/form-entries/${props.form.unique_id}`}>
                    <li>
                        {IconList.newsPaperIcon}
                    </li>
                </Link>
            </BootstrapTooltip>

            <BootstrapTooltip title="Edit" arrow>
               <Link className="form__action__buttons" to={`/user/new/form-builder/${props.form.unique_id}`}>
                   <li>
                        {IconList.pencilIcon}
                    </li>
                </Link>
            </BootstrapTooltip>
            <ActionControl alertMessage="Form builder feature is not available for template user" status="error" isDisabled={isTempUser == 1}>
                <BootstrapTooltip title="Integrate" arrow>
                    <li className="form__action__buttons" onClick={() => setOpenIntegrateModal(true)}>
                        {IconList.cubeIcon}
                    </li>
                </BootstrapTooltip>
            </ActionControl>
            <BootstrapTooltip title="Clone" arrow>
                <li className="form__action__buttons" onClick={() => formClone()}>
                    {IconList.duplicateIcon}
                </li>
            </BootstrapTooltip>

            <BootstrapTooltip title="Delete" arrow>
                <li className="form__action__buttons" onClick={() => formDelete()}>
                    {IconList.redDeleteIcon}
                </li>
            </BootstrapTooltip>

            {openIntegrateModal &&
                <FormIntegrate
                    className="global-xs-modal"
                    form={props.form}
                    openIntegrateModal={openIntegrateModal}
                    setOpenIntegrateModal={setOpenIntegrateModal}
                />
            }

        </ul>
    );
}

export default FormBottomButtons;