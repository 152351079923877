import React from "react";
import PropTypes from "prop-types";
import "./ToggleButton.css";

const ToggleButton = ({ selected, toggleSelected }) => {
  return (
    <div className="toggle-container" onClick={toggleSelected}>
      <div className={`dialog-button ${selected ? "" : "disabled"}`}>
        {selected ? "YES" : "NO"}
      </div>
    </div>
  );
};

export default ToggleButton;

ToggleButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired,
};
