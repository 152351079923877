import { useEffect, useState } from 'react';

export function useDelayMounted(delay = 300) {
    
    const [isDelayMounted, setIsDelayMounted] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsDelayMounted(true);
          }, delay);

        return () => {
            clearTimeout(timer);
        }
    }, [delay]);

    return isDelayMounted;
}