import "date-fns";
import React, { useState } from 'react'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import moment from "moment";


const EditDateField = (props) => {

    const [open, setOpen] = useState(false);

    const handleDateChange = (date) => {
        props.onChange(moment(date).format('MM/DD/YYYY'))
    };
    return (
        
        <div className="preview__date_field">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    onClick={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    open={open}
                    inputProps={{readOnly : true}}
                    value={props.value}
                    onChange={handleDateChange}
                    margin="normal"
                    format="MM/dd/yyyy"
                    KeyboardButtonProps={{
                        "aria-label": "change date"
                    }}
                />
            </MuiPickersUtilsProvider>
        </div>
    )
}

export default EditDateField
