import { CircularProgress } from '@material-ui/core';
import React, { useRef, useState } from 'react'
import { uploadNewFormBuilderImage } from '../../../../../../api/customFormBuilderApi';
import { IconList } from '../../../../../../constants/IconList'

const MAX_FILE_SIZE = 10485760; //1024*1024*10 --- 10MB

export const UploadImage = (props) => {

    const inputFile = useRef(null);
    const [uploading, setUploading] = useState(false);
    const [hasDrag,setHasDrag] = useState(false);

    const handleFileUpload = (file) => {

        if(file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif' ) {
            window.showNotification('ERROR','Please select a valid image.');
            return;
        }

        if(file.size > MAX_FILE_SIZE) {  
            window.showNotification('ERROR','Maximum upload size : 10MB');
            return;
        }

        setUploading(true);

        const dt = new FormData();
        dt.append("qqfile", file, file.name);
        uploadNewFormBuilderImage(dt).then(res => {
          props.onImageUpload({
              image : res.data.mediaUrl
          })
          window.showNotification('SUCCESS','Image added successfully');
          setUploading(false);
        });

    }

    const renderUploadOption = () => {
        return <>
        <span> {IconList.fileUploadIcon}</span>
        <h4 className="file__upload__signle__v2__drag__title"> Drag & Drop files here </h4>
        <h4 className="file__upload__signle__v2__drag__sub__title">OR</h4>
        <input type='file' accept="image/png, image/gif, image/jpeg" ref={inputFile} style={{display: 'none'}} onChange={e => handleFileUpload(e.target.files[0])}/>
        <button className="file__for__upload__btn" onClick={e => inputFile.current.click()}>Browse File</button>
        </>
    }

    const renderUploading = () => {
        return <div className='d-flex'>
            <CircularProgress className='position-static mr-2' size={23}/> <p>Uploading...</p>
        </div>
    }

    const handleDragover = (e,drag) => {
        e.stopPropagation();
        e.preventDefault();
        if(!uploading) {
            setHasDrag(drag)
        }
    }

    const handleDragDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if(hasDrag) {
            setHasDrag(false);
            handleFileUpload(e.dataTransfer.files[0]);
        }
    }

    return (
        <div className={`file__upload__signle__v2 ${hasDrag ? "file__drag__zone" : "file__upload"}`} 
            onDragEnter={e => handleDragover(e,true)}
            onDragOver={e => handleDragover(e,true)}
            onDragLeave={e => handleDragover(e,false)}
            onDrop={handleDragDrop}>
            {
                uploading ? 
                renderUploading() :
                hasDrag ? 
                "Drop file Here" :
                renderUploadOption()
            }
            
        </div>
    )
}
