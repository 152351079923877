import React, {useRef, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {IconList} from "../../constants/IconList";
import GlobalModal from "../Common/Modal/GlobalModal";
import {Add} from "@material-ui/icons";
import { createNewForm } from '../../api/formBuilderApi'
import {connect} from "react-redux";
import {fetchForms, addForms} from "../../actions/formBuilderAction";
import Utils from "../../helpers/Utils";
import SimpleReactValidator from "simple-react-validator";

const FormListHeader = props => {
    const history = useHistory();
    const [, forceUpdate] = useState() //this is a dummy state, when form submitted, change the state so that message is rendered
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: {forceUpdate: () => forceUpdate(1)},
        className: 'text-danger'
    }));
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [formTitle, setFormTitle] = useState('');

    const handleClickOpen = () => {
        setOpenCreateModal(true);
    };

    const addNewForm = () => {
        if (validator.current.allValid()) {
            createNewForm({title: formTitle}).then(response => {
                if (response.data.status) {
                    props.addForms(response.data.html);
                    history.push(`/user/form-builder/${response.data.html.unique_id}`);
                    // Utils.showNotification('Form created successfully', 'success');
                } else {
                    Utils.showNotification(response.data.html, 'error');
                }
            });
        } else {
            validator.current.showMessages();
        }
    }

    return (
        <div className="section__top">
            <div className="section__top__left">
                <h2>All Forms</h2>
            </div>
            <div className="section__top__right d-flex justify-content-end">
                <Link to="/user/form/template">
                    <Button
                        className="tempalte__icon"
                        startIcon={IconList.fromIcon}
                    >
                        form template
                    </Button>
                </Link>
                {openCreateModal &&
                <GlobalModal className='global-xs-modal' open={openCreateModal} onClose={() => setOpenCreateModal(false)} title={"Create new form"} buttonText={"Create New Form"} buttonIcon={IconList.plusIcon26} buttonClick={addNewForm}>
                    <form action="/">
                        <div className="row">
                            <div className="input-field col s12">
                                <label htmlFor="from-title">Give a Form Title</label>
                                <input placeholder="Enter Form Title" id="Enter Form Title" type="text" value={formTitle} onChange={(e) => setFormTitle(e.target.value)}/>
                                {validator.current.message('FormTitle', formTitle, 'required|string|max:190')}
                            </div>
                        </div>
                    </form>
                </GlobalModal>
                }
                <Button
                    className="add__icon accent--bg--color"
                    startIcon={<Add />}
                    onClick={handleClickOpen}
                >
                    Create New Form
                </Button>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        fetchForms: () => dispatch(fetchForms()),
        addForms: (params) => dispatch(addForms(params)),
    }
}

export default connect(null, mapDispatchToProps)(FormListHeader);