const KEY = 'iconFinderCache';

const get = (query) => {

    let itemStr = localStorage.getItem(KEY+query);
    if (!itemStr) {
		return null
	}

    const item = JSON.parse(itemStr)
	const now = new Date()
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(KEY+query)
		return null
	}
	return item.value

}

const set = (data,query) => {

    const now = new Date()

	const item = {
		value: data,
		expiry: now.getTime() + 3600000, //1Hr
	}
	localStorage.setItem(KEY+query, JSON.stringify(item))

}

const IconFinderCacheHandler = { get,set }

export default IconFinderCacheHandler;