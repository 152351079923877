import React from "react";
import EditCheckboxAction from "./EditCheckboxAction";
import { v4 as uuidv4 } from "uuid";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BootstrapTooltip from "../../BootstrapTooltip";

function EditCheckbox({
  allInfo,
  fields,
  updateFields,
  index,
  updateOptionPlaceholder,
  label,
  updateLabel,
}) {
  const handleChange = (i) => {
    let oldFields = [...fields];
    oldFields[index].checkbox[i].isChecked =
      !oldFields[index].checkbox[i].isChecked;
    updateFields(oldFields);
  };

  let newType = "text";
  let type = "checkbox";

  //Add checkbox option
  const handleAddOption = (id, i) => {
    let oldFields = [...fields];
    oldFields[i].checkbox.push({
      id: uuidv4(),
      optionValue: "",
      placeholder: "",
      isChecked: false,
    });
    updateFields(oldFields);
  };

  //Remove checkbox option
  const handleRemoveOption = (checkboxIndex) => {
    let oldFields = [...fields];
    oldFields[index].checkbox.splice(checkboxIndex, 1);
    updateFields(oldFields);
  };

  const renderOptions = () => {

    let labelColor = allInfo.configuration.labelColor ? allInfo.configuration.labelColor.value : "#333";
    let fontFamily = allInfo.configuration.fontFamily ? allInfo.configuration.fontFamily.value : "'Poppins', sans-serif";
    let fontSize = allInfo.configuration.fontSize ? allInfo.configuration.fontSize.value : 14;


    const setLabelColor = (element) => {
      if (element) {
         element.style.setProperty('color', labelColor, 'important'); 
         element.style.setProperty('font-family', fontFamily, 'important');
         element.style.setProperty('font-size', parseInt(fontSize)+"px", 'important');
      } 
    }

    return (
      <>
        <input
          ref={setLabelColor}
          className="mt3-space"
          type="text"
          defaultValue={label}
          placeholder={"Add label here"}
          onChange={(e) => {
            updateLabel(e.target.value, index);
          }}
        />

        {fields[index].checkbox.map((option, index) => {
          return (
            <FormControlLabel
              className="ar__formControlLabel"
              key={option.id}
              control={
                <Checkbox
                  checked={option.isChecked}
                  onChange={() => handleChange(index)}
                  // name={Object.keys(isChecked)[index]}
                  color="primary"
                />
              }
              label={
                <>
                  <Grid className="single-input-area mb1 ar__formControlLabel_grid">
                    <div className="col-lg-10">
                      <input
                        type={newType}
                        placeholder={"option"}
                        defaultValue={option.placeholder}
                        min={allInfo.configuration.minOptions.value}
                        max={allInfo.configuration.maxOptions.value}
                        required={allInfo.configuration.required.isEnable}
                        onChange={(e) => {
                          updateOptionPlaceholder(
                            e.target.value,
                            allInfo.index,
                            index,
                            type
                          );
                        }}
                      />
                    </div>
                    <EditCheckboxAction
                      removeOption={handleRemoveOption}
                      index={index}
                    />
                  </Grid>
                </>
              }
            />
          );
        })}

        {allInfo.configuration.required.isEnable && (
          <BootstrapTooltip arrow title="Required" placement="top">
            <span className="ar__req">*</span>
          </BootstrapTooltip>
        )}
      </>
    );
  };

  return (
    <div>
      {renderOptions()}
      <button
        className="ar__add_option"
        onClick={() => handleAddOption(allInfo.id, allInfo.index)}
      >
        Add option
      </button>
    </div>
  );
}

export default EditCheckbox;
