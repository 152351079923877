import React from 'react'

const components = {
    heading1: "h1",
    heading2: "h2",
    heading3: "h3",
    label: "h4",
    texts: "p",
};

const headingNumber = {
    heading1: 1,
    heading2: 2,
    heading3: 3,
    label: 4,
    texts: null,
};

export const TextContent = (props) => {

    const TagName = components[props.type];

    if(props.type === 'texts') {
        return <div className="logical__demo__content__middle__label alt v3">
            <p>
              We would{" "}
              <span className="loveFeedback">love to get your feedback</span> on
              or new product features. Would you spare{" "}
              <span className="minutes">5 minutes</span> to share your thoughts
              with us?
            </p>
          </div>
    }

    if(props.type === 'label') { 
        return <div className="logical__demo__content__middle__label alt v2">
            <label for="fname">Name</label>
            <input type="text" id="fname" name="fname" disabled />
            <label for="fname">Email</label>
            <input type="email" id="email" name="email" disabled />
        </div>
    }

    return (
        <div className="ar__heading">
            <TagName>Heading {headingNumber[props.type]}</TagName>
            <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s.
            </p>
        </div>
    )
}
