import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const fetchFormList = async (payload) => {
    return sendRequest("POST", Api.list, payload);
}

export const createNewForm = async (payload) => {
    return sendRequest("POST", Api.create, payload);
}

export const deleteForm = async (payload) => {
    return sendRequest("POST", Api.delete, payload);
}

export const cloneForm = async (payload) => {
    return sendRequest("POST", Api.clone, payload);
}

export const fetchFormDetail = async (payload) => {
    return sendRequest("POST", Api.detail, payload);
}

export const getFormSettingPageData = async (payload) => {
    return sendRequest("POST", Api.getFormSettingPageData, payload);
}

export const fetchStages = async (payload) => {
    return sendRequest("POST", Api.fetchStages, payload);
}

export const settingFormSubmit = async (payload) => {
    return sendRequest("POST", Api.settingFormSubmit, payload);
}

export const styleFormSubmit = async (payload) => {
    return sendRequest("POST", Api.styleFormSubmit, payload);
}

export const getEditorData = async (payload) => {
    return sendRequest("POST", Api.getEditorData, payload);
}

export const updateForm = async (payload) => {
    return sendRequest("POST", Api.updateForm, payload);
}

export const fetchFormCategories = async (payload) => {
    return sendRequest("POST", Api.fetchFormCategories, payload);
}

export const cloneFormTemplate = async (payload) => {
    return sendRequest("POST", Api.cloneFormTemplate, payload);
}

export const getFormEntries = async (payload) => {
    return sendRequest("POST", Api.getFormEntries, payload);
}

export const deleteFormEntry = async (payload) => {
    return sendRequest("POST", Api.deleteFormEntry, payload);
}

export const fetchSubmissionResult = async (payload) => {
    return sendRequest("POST", Api.fetchSubmission, payload);
}

export const moveContactToCampaign = async (payload) => {
    return sendRequest("POST", Api.moveToCampaign, payload);
}