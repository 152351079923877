import * as ACTION from "../constants/formBuilderActionTypes";

const initialState = {
    forms: undefined,
    loading: true,
    formDetails: null,
    formSetting: null,
    formStyle: null,
    agencyLogo: null,
    formCategories: [],
    campaigns: [],
    stages: []
};

const marketingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.STORE_FORMS:
            return {...state, forms: action.payload, loading: false}

        case ACTION.ADD_FORMS:
            let newFormList = state.forms;
            newFormList.unshift(action.payload);
            return {...state, forms: newFormList}

        case ACTION.STORE_FORM_DETAIL:
            return {...state, formDetails: action.payload.form, formSetting: action.payload.formSetting, formStyle: action.payload.formStyle, agencyLogo: action.payload.agencyLogo}

        case ACTION.UPDATE_SETTING_DETAILS:
            return {
                ...state,
                formDetails: {
                    ...state.formDetails,
                    title: action.payload.title,
                    status: action.payload.status
                },
                formSetting: {
                    ...state.formSetting,
                    'campaign_id': action.payload.campaign_id,
                    'pipeline_id': action.payload.pipeline_id,
                    'stage_id': action.payload.stage_id,
                    'action_after_submit_type': action.payload.form_submit_action_type,
                    'action_after_submit': action.payload.form_submit_action,
                    'agency_branding': action.payload.agency_branding,
                    'update_duplicate_values': action.payload.update_duplicate_values,
                    'inactive_form_text': action.payload.inactive_form_text,
                    'is_duplicate_submission_allowed': action.payload.is_duplicate_submission_allowed,
                },
            }

        case ACTION.UPDATE_STYLE_DETAILS:
            return {
                ...state,
                formStyle: {
                    ...state.formStyle,
                    ...action.payload.formStyle
                },
            }

        case ACTION.UPDATE_FORM:
            return {
                ...state,
                formDetails: {
                    ...state.formDetails,
                    builder_json: action.payload.builder_json,
                    builder_html: action.payload.builder_html
                },
            }

        case ACTION.STORE_FORM_CATEGORIES:
            return {
                ...state,
                formCategories: action.payload.agencyFormCategories,
            }

        case ACTION.STORE_FORM_SETTING_PAGE_DATA:
            return {
                ...state,
                campaigns: action.payload.data.campaigns,
                stages: action.payload.data.stages,
                formDetails: action.payload.data.formDetails,
            }
        default:
            return state;
    }
}

export default marketingReducer;
