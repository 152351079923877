import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import * as COMMON_API from '../api/commonApi';
import * as ACTION from '../constants/formBuilderActionTypes'
import Utils from "../helpers/Utils";

function* commonMiddlewareWatcher() {
    yield takeEvery(ACTION.FETCH_CUSTOM_FIELDS, fetchCustomFieldsMiddleware);
}

function* fetchCustomFieldsMiddleware(action) {
    try {
        const { isLoadedCustomFields } = yield select(state => state.commonReducer);
        if(!isLoadedCustomFields) {
            const response = yield call(COMMON_API.fetchCustomFields, action.payload);
            yield put({type: ACTION.STORE_CUSTOM_FIELDS, payload: response.data.data});
        }
    } catch (error) {
        Utils.handleException(error);
    }
}


export default function* commonMiddleware() {
    yield all([
        commonMiddlewareWatcher(),
    ])
}
