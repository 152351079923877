import { Button, MenuItem, Modal, Select } from '@material-ui/core'
import React from 'react'
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import './maping.css'
import { useDispatch, useSelector } from 'react-redux';
import { CONTACT_FIELDS_MAP } from '../../../../constants/CoreConstants';
import { customFormFieldUpdate } from '../../../../actions/customFormBuilderAction';


export const MappingModal = (props) => {

    const dispatch = useDispatch();
    const { fields } = useSelector(state => state.customFormBuilderReducer);

    const updateMapField = (value,index) => {
        const data = [...fields];
        data[index] = {...data[index], mapTo : value} 
        dispatch(customFormFieldUpdate(data));
    }

    const renderSelectFields = () => {
        const tempArr = [];
        for(const key in CONTACT_FIELDS_MAP) {
            tempArr.push(<MenuItem key={key} value={key}>{CONTACT_FIELDS_MAP[key].label}</MenuItem>);
        }
        return tempArr;
    }

    const renderRows = () => {

        //For duplicate checking
        let fieldMapsCount = {};
        fields.forEach(field => {
            if(field.mapTo) {
                fieldMapsCount[field.mapTo] = fieldMapsCount[field.mapTo] ? fieldMapsCount[field.mapTo] + 1 : 1;
            }
        });


        return fields.map((item,index) => {

            //Hide block is enable or
            //Fields those are not necessary to show
            if(item.configuration.hideBlock?.isEnable || item.type === "button" || item.type === "heading1" || item.type === "heading2" || item.type === "heading3" || item.type === "label" || item.type === "texts" || item.type === "newPage" || item.type === "phoneNumber" || item.type === "email") {
                return "";
            }

            let defaultAns = "";
            if(item.component === "date") {
                defaultAns = item.configuration.specificDate.isEnable ? item.configuration.specificDate.value : "";
            } else if(item.component === "time") {
                defaultAns = item.configuration.specificTime.isEnable ? item.configuration.specificTime.value : "";
            } else if(item.component === "checkbox") {
                let checkboxFields = [];
                item.checkbox.forEach(el => {
                    if(el.isChecked) {
                        checkboxFields.push(el.placeholder);
                    }
                });
                defaultAns = checkboxFields.toString();
            } else {
                defaultAns = item.configuration.defaultAnswer?.isEnable ? item.configuration.defaultAnswer.value : "";
            }
            


            return <TableRow>
            <TableCell>
                <div className="mapping__select__wrapper">
                    <Select displayEmpty value={item.mapTo ? item.mapTo : null} onChange={e => updateMapField(e.target.value,index)}>
                        <MenuItem value={null}>Select Field</MenuItem>
                        {renderSelectFields()}
                    </Select>
                    <span className="maping__select__arrow"><ArrowForwardIosIcon/></span>
                </div>
                {
                    item.mapTo && item.component !== CONTACT_FIELDS_MAP[item.mapTo].type &&
                    <p className='mt-2 text-danger'>{`Type mismatch, ${CONTACT_FIELDS_MAP[item.mapTo].label} must be type of ${CONTACT_FIELDS_MAP[item.mapTo].type}`}</p>
                }

                {
                    item.mapTo && fieldMapsCount[item.mapTo] > 1 &&
                    <p className='mt-2 text-danger'>{`Duplicate mapping set`}</p>
                }
                
            </TableCell>
            <TableCell>{item.label}</TableCell>
            <TableCell>{item.component}</TableCell>
            <TableCell>{defaultAns}</TableCell>
            <TableCell>{item.customFieldId ? "Yes" : "No"}</TableCell>
        </TableRow>
        })
    }

    return (
        <Modal
          open={props.open}
          onClose={() => props.onClose(false)}
          className="modal-100w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <div className="modal__preview__content__wr">
            <div className="modal__preview__content__heading__wr">
                <h2>Mapping</h2>
                <Button
                  className="back__to__edit"
                  onClick={() => props.onClose(false)}
                >
                <ExitToAppIcon />
                <span>Back to Edit</span>
              </Button>
            </div>
            <div className='mapping__enabled_button_wrapper'>
                <div className='mapping__enabled_button'>
                    View and manage the field mapping for this form. The field mapping specifies how fields from your form artifact map to fields in our system.
                    <p><b>If you don't want to map a specific field just leave it unselected.</b></p>
                    <p className='mt-3'><b>Phone number and email field are excluded from mapping.</b></p>
                </div>
            </div>
            


            <div className='maping__table__wrapper'>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Form Field</TableCell>
                                <TableCell >Field</TableCell>
                                <TableCell>Type </TableCell>
                                <TableCell>Deafult Value</TableCell>
                                <TableCell>Custom Field</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { renderRows() }
                        </TableBody>
                    </Table>
                    </TableContainer>
            </div>
          </div>
        </Modal>
    )
}
