import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'
import React from 'react'

export const RadioContent = () => {
  return (
    <FormControl className="mt-40px" component="fieldset">
        <FormLabel component="legend">Gender</FormLabel>
        <RadioGroup aria-label="gender" name="gender1">
            <FormControlLabel
                value="male"
                control={<Radio />}
                label="Male"
                disabled
                checked
            />
            <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
                disabled
            />
            <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
                disabled
            />
        </RadioGroup>
    </FormControl>
  )
}
