import $ from "jquery";
import React, {useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import { getEditorData } from '../../api/formBuilderApi';
import {fetchForms, formSave} from '../../actions/formBuilderAction';
import {IconList} from "../../constants/IconList";
import Button from "@material-ui/core/Button";
import Utils from '../../helpers/Utils';
import Loading from "../Common/Loading";
import ListSkeleton from "../Common/Skeletons/ListSkeleton";
import Grid from "@material-ui/core/Grid";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require('formBuilder');
require('formBuilder/dist/form-render.min');
let formBuilder;

const FormBuilder = props => {
    const fb = useRef();
    const [loading, setLoading] = useState(false);
    const [formDataLoading, setFormDataLoading] = useState(true);

    useEffect(() => {
        getEditorData({form_id: props.formDetails.id}).then(response => {
            let allFields = response.data.output;
            if (allFields){
                allFields.push({
                    type: "checkbox",
                    label: "Disclaimer",
                    name: "disclaimer",
                    id: "disclaimer",
                    values: [{ label: "Write about disclaimer", value: true, selected: true }],
                    icon: "⚠"
                });
            }
            let fbOptions = {
                scrollToFieldOnAdd: false,
                disabledAttrs: ["access", "other", "inline" ,"toggle"],
                disabledActionButtons: ["data"],
                showActionButtons: false,
                disableFields: ["file"],
                fields: allFields,
                disableHTMLLabels: true,
                controlOrder: [
                    "text",
                ]
            };
            formBuilder = $(fb.current).formBuilder(fbOptions);
            formBuilder.promise.then(function(fb) {
                formBuilder.actions.setData(response.data.builderJson);
                setFormDataLoading(false);
            });
        });

    }, [])

    window.refreshRenderedForm = (builderJson) => {
        let renderTemplate = $(document).find('#fb-rendered-form').find('.render-wrap');
        $(renderTemplate).formRender({ formData: builderJson });
    }

    const saveForm = () => {
        if (!loading) {
            formBuilder.actions.save();
            var builder_json = formBuilder.formData;
            window.refreshRenderedForm(builder_json);
            var builder_html = document.getElementById('public-form-wrapper').innerHTML;

            setLoading(true);

            props.formSave({
                form_id: props.formDetails.id,
                builder_json: builder_json,
                builder_html: builder_html
            }, formSaveResponse);
        }
    }

    const formSaveResponse = response => {
        setLoading(false);
        if (response.status === 'success') {
            props.fetchForms();
            Utils.showNotification(response.html, 'success');
        } else {
            Utils.showNotification(response.html, 'error');
        }
    }

    return (
        <div className="editor-wrapper">
            <div id="fb-editor" className="formBuilder" ref={fb}>
                {formDataLoading &&
                    <Grid container spacing={3}>
                        <Grid item xs={8} sm={8}>
                            <ListSkeleton width={'100%'} height={70} number={8}/>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <ListSkeleton width={'100%'} height={70} number={8}/>
                        </Grid>
                    </Grid>

                }
            </div>
            <div className="section__top__right mt-4">
                {loading ?
                    <Button className="add__icon accent--bg--color" startIcon={<Loading/>}>
                        Save Form
                    </Button>
                    :
                    <Button className="add__icon accent--bg--color" startIcon={IconList.copyIcon} onClick={() => saveForm()}>
                        Save Form
                    </Button>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        formDetails: state.formBuilderReducer.formDetails
    }
}

const mapDispatchToProps= dispatch => {
    return {
        formSave: (params, callback) => dispatch(formSave(params, callback)),
        fetchForms: () => dispatch(fetchForms())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormBuilder);
