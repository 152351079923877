import { v4 as uuidv4 } from "uuid";

export const getType = () => {
  return {
    id: uuidv4(),
    page: 1,
    type: "button", // state type
    placeholder: "Send",
    name: uuidv4(),
    component: "button", // input field type
    label: "Submit",
    configuration: {
      hideBlock: {
        name: "Hide block",
        hidden: true,
        value: false,
      },
    },
  };
};

export const getCheckboxDefaultOptions = () => (
  [{ id: uuidv4(), optionValue: "", placeholder: "", isChecked: false }]
)

export const getMultipleChoiceDefaultOptions = () => (
  [{ id: uuidv4(), choiceValue: "", placeholder: "" }]
)

export const getDropdownDefaultOptions = () => (
  [{ id: uuidv4(), dropdownValue: "", placeholder: "" }]
)

export const getRadioDefaultOptions = () => (
  [{ id: uuidv4(), radioValue: "", placeholder: "" }]
)