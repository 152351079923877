import React, { useRef, useState } from "react";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  InputLabel,
  UploadWarning,
  RemoveFileIcon
} from "./file-upload.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FileUpload.css";

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10_000_000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({ isDisbaled, label, updateFilesCb, maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,...otherProps }) => {
  
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  return (
    <>
      <FileUploadContainer>
        <InputLabel style={{color : otherProps.labelColor, fontFamily : otherProps.fontFamily, fontSize : parseInt(otherProps.fontSize)}}>{label}</InputLabel>
        {/* <DragDropText>Drag and drop your files anywhere or</DragDropText> */}
        <DragDropText>Upload your file</DragDropText>
        <UploadFileBtn
          type="button"
          onClick={handleUploadBtnClick}
          disabled={isDisbaled}
        >
          <i className="fas fa-file-upload" />
          <span> Upload {otherProps.multiple ? "files" : "a file"} </span>
        </UploadFileBtn>

        {
          !isDisbaled &&        
            <UploadWarning>
              <p>Max Upload Size: 10 MB</p>
              <p>Supported Formats: .jpg, .png, .jpeg, .pdf, .gif, .docx</p>
            </UploadWarning>
        }

        <FormField
          type="file"
          disabled={isDisbaled}
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
      </FileUploadContainer>

      <FilePreviewContainer>
        <span>To Upload</span>
        <PreviewList>
          {Object.keys(files).map((fileName, index) => {
            const file = files[fileName];
            const fileNameExtension = fileName.split('.').pop();
            const isImageFile = file.type.split("/")[0] === "image";
            return (
              <PreviewContainer key={fileName}>
                <div>
                  {isImageFile && (
                    <ImagePreview
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )}
                  <FileMetaData isImageFile={isImageFile}>
                    <>
                      <span className="uploaded__file__name__v1">{file.name.split('.').shift()}</span>.{fileNameExtension}
                    </>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>

                      <RemoveFileIcon>
                        <FontAwesomeIcon
                          className="fas fa-trash-alt"
                          icon={["far", "trash-alt"]}
                          onClick={() => removeFile(fileName)}
                        />
                      </RemoveFileIcon>
                      
                    </aside>
                  </FileMetaData>
                </div>
              </PreviewContainer>
            );
          })}
        </PreviewList>
      </FilePreviewContainer>
    </>
  );
};

export default FileUpload;
