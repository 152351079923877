import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@material-ui/core'
import React from 'react'
import BootstrapTooltip from '../../BootstrapTooltip'

export const PageDivider = (props) => {
  return (
    <div className="page-devider">
        <BootstrapTooltip title="Delete whole page" placement="top" arrow>
            <Button
                variant="dark"
                onClick={() => props.onRemovePage()}>
                <FontAwesomeIcon icon={["far", "trash-alt"]}/>
            </Button>
        </BootstrapTooltip>

        <BootstrapTooltip title="Move page up" placement="top" arrow>
            <Button
                variant="dark"
                onClick={() => props.onSwapPage(props.page, props.page-1)}>
                <FontAwesomeIcon icon={["fas", "arrow-up"]}/>
            </Button>
        </BootstrapTooltip>

        {
            props.page < props.totalPages &&
            <BootstrapTooltip title="Move page down" placement="top" arrow>
                <Button
                    variant="dark"
                    onClick={() => props.onSwapPage(props.page, props.page+1)}>
                    <FontAwesomeIcon icon={["fas", "arrow-down"]}/>
                </Button>
            </BootstrapTooltip>
        }
        

        {/* Page devider */}
        <div className="horizontal-line-area">
            <div></div>
            <div>Page {props.page}</div>
            <div></div>
        </div>
    </div>
  )
}
