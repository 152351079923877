/* eslint-disable import/no-anonymous-default-export */
import Configuration from "../../Configuration";
import { v4 as uuidv4 } from "uuid";
import formIcons from "./FormIcons";

export default [
  {
    name: "Most Usable",
    menus: [
      {
        type: "firstName",
        name: "first_name",
        component: "text",
        label: "First Name",
        menu: "First Name",
        config: Configuration.textField,
        icon: formIcons.firstName,
      },
      {
        type: "lastName",
        name: "last_name",
        component: "text",
        label: "Last Name",
        menu: "Last Name",
        config: Configuration.textField,
        icon: formIcons.lastName,
      },
      {
        type: "email",
        name: "email",
        component: "email",
        label: "Email",
        menu: "Email",
        config: Configuration.common,
        icon: formIcons.email,
      },
      {
        type: "phoneNumber",
        name: "number",
        component: "phone",
        label: "Phone Number",
        menu: "Phone Number",
        config: Configuration.common,
        icon: formIcons.phoneNumber,
      },
      {
        type: "birthDate",
        name: "birth_date",
        component: "date",
        label: "Birth Date",
        menu: "Birth Date",
        config: Configuration.date,
        icon: formIcons.birthDate,
      },
      {
        type: "companyName",
        name: "company_name",
        component: "text",
        label: "Company Name",
        menu: "Company Name",
        config: Configuration.common,
        icon: formIcons.companyName,
      },
      {
        type: "address",
        name: "address",
        component: "textarea",
        label: "Address",
        menu: "Address",
        config: Configuration.textArea,
        icon: formIcons.address,
      },
      {
        type: "city",
        name: "city",
        component: "text",
        label: "City",
        menu: "City",
        config: Configuration.common,
        icon: formIcons.city,
      },
      {
        type: "state",
        name: "state",
        component: "text",
        label: "State",
        menu: "State",
        config: Configuration.common,
        icon: formIcons.state,
      },
      {
        type: "zip",
        name: "zip",
        component: "text",
        label: "Zip",
        menu: "Zip",
        config: Configuration.common,
        icon: formIcons.zip,
      },
      {
        type: "note",
        name: "note",
        component: "textarea",
        label: "Note",
        menu: "Note",
        config: Configuration.textArea,
        icon: formIcons.note,
      },
      {
        type: "ipAddress",
        name: "ip_address",
        component: "text",
        label: "IP Address",
        menu: "Ip Address",
        config: Configuration.common,
        icon: formIcons.ipAddress,
      },
      {
        type: "progressBar",
        name: "progress_bar",
        component: "progressBar",
        label: "Progress Bar",
        menu: "Progress Bar",
        config: Configuration.default,
        icon: formIcons.progressBar,
      },
    ],
  },
  {
    name: "INPUT BLOCKS",
    menus: [
      {
        type: "shortAnswer",
        name: `text-${uuidv4()}`,
        component: "text",
        label: "",
        menu: "Short Answer",
        config: Configuration.common,
        icon: formIcons.shortAnswer,
      },
      {
        type: "textarea",
        name: `text_area-${uuidv4()}`,
        component: "textarea",
        label: "",
        menu: "Long Answer",
        config: Configuration.textArea,
        icon: formIcons.textarea,
      },
      {
        type: "phoneInput",
        name: `phoneInput-${uuidv4()}`,
        component: "phone",
        label: "",
        menu: "Phone Input",
        config: Configuration.common,
        icon: formIcons.phoneNumber,
      },
      {
        type: "number",
        name: `normal_number-${uuidv4()}`,
        component: "number",
        label: "",
        menu: "Number",
        config: Configuration.number,
        icon: formIcons.number,
      },
      {
        type: "link",
        name: `link-${uuidv4()}`,
        component: "url",
        label: "",
        menu: "Link",
        config: Configuration.common,
        icon: formIcons.link,
      },
      {
        type: "date",
        name: `date-${uuidv4()}`,
        component: "date",
        label: "",
        menu: "Date",
        config: Configuration.date,
        icon: formIcons.date,
      },
      {
        type: "time",
        name: `time-${uuidv4()}`,
        component: "time",
        label: "",
        menu: "Time",
        config: Configuration.time,
        icon: formIcons.time,
      },
      {
        type: "radio",
        name: `radio-${uuidv4()}`,
        component: "radio",
        label: "",
        menu: "Radio",
        config: Configuration.common,
        icon: formIcons.radio,
      },
      {
        type: "checkbox",
        name: `checkbox-${uuidv4()}`,
        component: "checkbox",
        label: "",
        menu: "Checkbox",
        config: Configuration.checkbox,
        icon: formIcons.checkbox,
      },
      {
        type: "choice",
        name: `mutiple_choice-${uuidv4()}`,
        component: "choice",
        label: "",
        menu: "Multiple Choices",
        config: Configuration.choice,
        icon: formIcons.choice,
      },
      {
        type: "dropdown",
        name: `dropdown-${uuidv4()}`,
        component: "select",
        label: "",
        menu: "Dropdown",
        config: Configuration.common,
        icon: formIcons.dropdown,
      },
      {
        type: "fileUpload",
        name: `file_upload-${uuidv4()}`,
        component: "file",
        label: "",
        menu: "File upload",
        config: Configuration.fileUpload,
        icon: formIcons.fileUpload,
      },
      {
        type: "hidden",
        name: `hidden-input-field-${uuidv4()}`,
        component: "hidden",
        label: "",
        menu: "Hidden Fields",
        config: Configuration.default,
        icon: formIcons.hidden,
      },
    ],
  },
  {
    name: "LAYOUT BLOCKS",
    menus: [
      {
        type: "heading1",
        name: `heading_1-${uuidv4()}`,
        component: "text",
        label: "",
        menu: "Heading 1",
        config: Configuration.texts,
        icon: formIcons.heading1,
      },
      {
        type: "heading2",
        name: `heading_2-${uuidv4()}`,
        component: "text",
        label: "",
        menu: "Heading 2",
        config: Configuration.texts,
        icon: formIcons.heading2,
      },
      {
        type: "heading3",
        name: `heading_3-${uuidv4()}`,
        component: "text",
        label: "",
        menu: "Heading 3",
        config: Configuration.texts,
        icon: formIcons.heading3,
      },
      {
        type: "label",
        name: `label-${uuidv4()}`,
        component: "text",
        label: "",
        menu: "Label",
        config: Configuration.texts,
        icon: formIcons.label,
      },
      {
        type: "texts",
        name: `texts-${uuidv4()}`,
        component: "text",
        label: "",
        menu: "Text",
        config: Configuration.texts,
        icon: formIcons.texts,
      },
      {
        type: "newPage",
        name: `new_page-${uuidv4()}`,
        component: "text",
        label: "",
        menu: "New Page",
        config: Configuration.empty,
        icon: formIcons.newPage,
      },
      // {
      //   type: "thankYouPage",
      //   name: `thank_you_page-${uuidv4()}`,
      //   component: "text",
      //   label: "",
      //   menu: "Thank You Page",
      //   config: Configuration.default,
      //   icon: formIcons.thankYouPage,
      // },
    ],
  },
  {
    name: "EMBED BLOCKS",
    menus: [
      {
        type: "embedImage",
        name: `embed_image-${uuidv4()}`,
        component: "text",
        label: "",
        menu: "Image",
        config: Configuration.default,
        icon: formIcons.embedImage,
      },
      {
        type: "embedVideo",
        name: `embed_video-${uuidv4()}`,
        component: "text",
        label: "",
        menu: "Video",
        config: Configuration.default,
        icon: formIcons.embedVideo,
      },
      {
        type: "embedAudio",
        name: `embed_audio-${uuidv4()}`,
        component: "text",
        label: "",
        menu: "Audio",
        config: Configuration.default,
        icon: formIcons.embedAudio,
      },
      {
        type: "embedAnything",
        name: `embed_anything-${uuidv4()}`,
        component: "text",
        label: "",
        menu: "Embed Anything",
        config: Configuration.default,
        icon: formIcons.embedAnything,
      },
    ],
  },
];

export const getCustomFormTypes = (field) => {

  const appendMaxCharacters = (conf,total) => {
    return {...conf,
      maxCharacters: {
        name: "Max characters",
        showField: true,
        isEnable: true,
        type: "number",
        value: total,
      }
    }
  }
  
  if(field.type_id === 2) {
    return {
      type: "textarea",
      component: "textarea",
      label: field.title,
      config: Configuration.textArea,
      customFieldId : field.id,
    }
  } else if(field.type_id === 3) {
    return {
      type: "number",
      component: "number",
      label: field.title,
      config: appendMaxCharacters(Configuration.common,11),
      customFieldId : field.id,
    }
  } else if(field.type_id === 4) {
    return {
      type: "date",
      component: "date",
      label: field.title,
      config: Configuration.date,
      customFieldId : field.id,
    }
  } else if(field.type_id === 5) {
    return {
      type: "checkbox",
      component: "checkbox",
      label: field.title,
      config: Configuration.checkbox,
      customFieldValues : field.default_values,
      customFieldId : field.id,
    }
  } else if(field.type_id === 6) {
    return {
      type: "dropdown",
      component: "select",
      label: field.title,
      config: Configuration.common,
      customFieldValues : field.default_values,
      customFieldId : field.id,
    }
  } else if(field.type_id === 7) {
    return {
      type: "radio",
      component: "radio",
      label: field.title,
      config: Configuration.common,
      customFieldValues : field.default_values,
      customFieldId : field.id,
    }
  } else if(field.type_id === 8) {
    return {
      type: "phoneInput",
      component: "phone",
      label: field.title,
      config: Configuration.common,
      customFieldValues : field.default_values,
      customFieldId : field.id,
    }
  } else if(field.type_id === 10) {
    return {
      type: "link",
      component: "url",
      label: field.title,
      config: appendMaxCharacters(Configuration.common,255),
      customFieldId : field.id,
    }
  }

  return {
    type: "shortAnswer",
    name: `text-${uuidv4()}`,
    component: "text",
    label: field.title,
    config: appendMaxCharacters(Configuration.common,255),
    customFieldId : field.id,
  }
}
