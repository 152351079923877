const Empty = ({title = 'No Data Found!', subTitle = 'Looks very empty here'}) => {
    return (
        <div className="col s12 m12 l12 xl9 main-inbox_column_empty">
            <div className="mt-0 card message-info-details b-radius-10 box-shadow-none">
                <div className="inbox-main-section">
                    <div className="chatbox-area chatbox-empty" id="cutomScrollbar2">
                        <div className="row">
                            <div className="col s12">
                                <div className="no-data-found">
                                    <img className=" responsive-img" src="../assets/images/no-data-found.svg"
                                         alt="No Data Found"/>
                                    <h3>{ title }</h3>
                                    <p>{subTitle}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Empty;