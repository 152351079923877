import { Fade, Slide } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useIsMounted } from '../../../../hooks/IsMounted';

export const AnimationHandler = (props) => {

    const isMounted = useIsMounted();
    const [animationIn, setAnimationIn] = useState(false);
    const [direction, setDirection] = useState();

    useEffect(() => {
        getAnimationDetails(true);
    },[props.animation]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(isMounted) {
            getAnimationDetails(false);
            setTimeout(() => {
                getAnimationDetails(true);
                props.onAnimationChangeCallback && props.onAnimationChangeCallback();
            }, 300);
        }
    },[props.page]) // eslint-disable-line react-hooks/exhaustive-deps

    const getAnimationDetails = (anim) => {
        if(props.animation === 'slideLeft') {
            setDirection(anim ? 'left' : 'right');
        } else if (props.animation === 'slideRight') {
            setDirection(anim ? 'right' : 'left');
        } else if (props.animation === 'fadeUp') {
            setDirection(anim ? 'up' : 'down');
        } else if (props.animation === 'fadeDown') {
            setDirection(anim ? 'down' : 'up');
        }
        setAnimationIn(anim);
    }

    //No animation neccessary
    if(!props.animation || props.animation === 'default' || direction === undefined) {
        return props.children;
    }

    if(props.animation === 'fadeUp' || props.animation === 'fadeDown') {
        return (
            <Slide direction={direction} in={animationIn}>
                <Fade in={animationIn}>
                    {props.children}    
                </Fade>
            </Slide>
        )
    }

    return (
        <Slide direction={direction} in={animationIn}>
            {props.children}    
        </Slide>
    )
}
