import { Container } from '@material-ui/core';
import React from 'react'

export const PreviewPublicImage = (props) => {

    const innerHeight = window.innerWidth < 768 ? 170 : props.settings.coverHeight

    return (
        <>
        <div className={ props.settings?.floatingCover ? "preview__top__fixed" : ""}>
            { props.settings.cover && (
                <div className="cover-area" style={{height : innerHeight}}>
                    <img src={props.settings.cover} alt="cover" style={{objectPosition : `0 ${props.settings.coverPosition}%`}}/>
                </div>
            )}
            { props.settings.logo && 
                <Container className={"logo-container"}>
                    <div className="col-lg-8 mx-auto">
                        <div
                            className={`logo-area ${!props.settings.cover ? "mt-3" : ""}`}
                        >
                            { props.settings.logo && 
                                <div 
                                className={`preview__image__ratio
                                            ${!props.settings.cover ? "mt-0" : ""}
                                            ${props.settings.logoFillColor ? "fill__with__color" : ""} 
                                            ${props.settings.logoSize !== undefined ? props.settings.logoSize + "__logo" : ""}`}
                                style={ {backgroundImage: `url(${props.settings.logo})`} }
                                />
                            }
                        </div>
                    
                    </div>
                </Container>
            }

            {
                !props.settings.logo && !props.settings.cover && 
                <Container className={"logo-container"}>
                    <div className="col-lg-8 mx-auto"></div>
                </Container>
            }
            
        </div>
        </>
    )
}


export default PreviewPublicImage;