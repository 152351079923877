import React, {useState} from "react";
import {IconList} from "../../constants/IconList";
import {confirmAlert} from "react-confirm-alert";
import { deleteForm, cloneForm } from "../../api/formBuilderApi";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {fetchForms} from "../../actions/formBuilderAction";
import {connect} from "react-redux";
import Utils from '../../helpers/Utils';
import {Link, useHistory} from "react-router-dom";
import FormIntegrate from "./FormIntegrate";
import {Tooltip} from "@material-ui/core";
import ActionControl from "../Common/ActionControl/ActionControl";

const FormBottomButtons = props => {
    const isTempUser = Utils.getAccountData('isTemplateUser');
    const history = useHistory();
    const [openIntegrateModal, setOpenIntegrateModal] = useState(false);

    const formDelete = () => {
        confirmAlert({
            title: 'Confirm to delete?',
            message: 'You will not able to undo it.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        deleteForm({form_id : props.form.id}).then(response => {
                            if(response.data.status === 'success') {
                                Utils.showNotification(response.data.html, 'success');
                                props.fetchForms();
                            }else{
                                Utils.showNotification(response.data.html, 'error');
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    }

    const formClone = () => {
        cloneForm({form_id: props.form.id}).then(response => {
            if (response.data.status === 'success') {
                history.push(`/user/form-builder/${response.data.html.unique_id}`);
            } else {
                Utils.showNotification(response.data.html, 'error');
            }
        });
    }

    return (
        <ul className="form__bottom">
            <li>
                <Tooltip title="Entries">
                    <Link className="form__action__buttons" to={`/user/form-entries/${props.form.unique_id}`}>
                        {IconList.newsPaperIcon}
                    </Link>
                </Tooltip>
            </li>
            <li>
                <Tooltip title="Edit">
                    <Link className="form__action__buttons" to={`/user/form-builder/${props.form.unique_id}`}>
                        {IconList.pencilIcon}
                    </Link>
                </Tooltip>
            </li>
            <ActionControl alertMessage="Form builder feature is not available for template user" status="error"  isDisabled={isTempUser == 1}>
                <li>
                    <Tooltip title="Integrate">
                        <button className="form__action__buttons" onClick={() => setOpenIntegrateModal(true)}>
                            {IconList.cubeIcon}
                        </button>
                    </Tooltip>
                    {openIntegrateModal &&
                    <FormIntegrate className="global-xs-modal" form={props.form} openIntegrateModal={openIntegrateModal} setOpenIntegrateModal={setOpenIntegrateModal}/>
                    }
                </li>
            </ActionControl>
            <li>
                <Tooltip title="Clone">
                    <button className="form__action__buttons" onClick={() => formClone()}>
                        {IconList.duplicateIcon}
                    </button>
                </Tooltip>
            </li>
            <li>
                <Tooltip title="Delete">
                    <button className="form__action__buttons" onClick={() => formDelete()}>
                        {IconList.redDeleteIcon}
                    </button>
                </Tooltip>
            </li>
        </ul>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        fetchForms: () => dispatch(fetchForms())
    }
}
export default connect(null, mapDispatchToProps)(FormBottomButtons);