import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {IconList} from "../../constants/IconList";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { fetchFormCategories } from '../../actions/formBuilderAction';
import { cloneFormTemplate } from '../../api/formBuilderApi';
import { confirmAlert } from 'react-confirm-alert';
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {Tooltip} from "@material-ui/core";
import Utils from "../../helpers/Utils";
import ListSkeleton from "../Common/Skeletons/ListSkeleton";

const FormTemplate = props => {
    const [loading, setLoading] = useState(true);
    const [formCategories, setFormCategories] = useState(props.formCategories);

    useEffect(() => {
        document.title = `Form Template | ${Utils.getAccountData('AuthUserAgencyName')}`
    }, []);

    useEffect(() => {
        if (props.formCategories.length) {
            setFormCategories(props.formCategories);
        } else {
            props.fetchFormCategories(null, responseFormCategories);
        }
    }, [props.formCategories]);

    const responseFormCategories = response => {
        setLoading(false);
    }

    const formTemplateClone = categoryId => {
        confirmAlert({
            title : 'Are you sure?',
            message : 'Do you want to clone selected categories system forms?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        cloneFormTemplate({categoryId: categoryId}).then(response => {
                            if (response.data.status === 'success') {
                                Utils.showNotification(response.data.html, 'success');
                                props.fetchFormCategories(null, responseFormCategories);
                            } else {
                                Utils.showNotification(response.data.html, 'error');
                            }
                        });
                    }
                },
                {
                    label: 'No'
                }
            ],
        });
    }

    const renderFormCategories = () => {
        if (!loading) {
            return formCategories.map((formCategory, i) => {
                return (
                    <Grid item xs={6} sm={4}>
                        <div className="systemFromTemplate">
                            <p>{formCategory.category_name}</p>
                            <img
                                src={formCategory.image}
                                alt="Template Image"/>
                            <div className="formCount">
                                <p>Total Form : <span>{formCategory.public_form_count.count}</span></p>
                                <Tooltip title="Clone">
                                    <button className="clone_button" onClick={() => formTemplateClone(formCategory.id)}>
                                        {IconList.duplicateIcon1}
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    </Grid>
                );
            })
        } else {
            let blocks = [];
            for (let i = 0; i < 6; i++) {
                blocks.push(
                    <Grid item xs={6} sm={4}>
                        <div className="systemFromTemplate">
                            <ListSkeleton width={'100%'} />
                            <ListSkeleton width={'100%'} height={250}/>
                            <div className="formCount">
                                <ListSkeleton width={'100%'} />
                            </div>
                        </div>
                    </Grid>
                );
            }
            return blocks;
        }
    }

    return (
        <React.Fragment>
            <div className="systemform__sections">
                <div className="section__top">
                    <div className="section__top__left">
                        <h2>System Form Template</h2>
                    </div>
                    <div className="section__top__right">
                        <Link to="/user/form-builder" className="section__back"><ChevronLeftIcon/><span>Back</span></Link>
                    </div>
                </div>

                <Grid container spacing={3}>
                    { renderFormCategories() }
                </Grid>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        formCategories: state.formBuilderReducer.formCategories
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchFormCategories: (payload, callback) => dispatch(fetchFormCategories(payload, callback))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormTemplate);
