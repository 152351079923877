import React from 'react';

export const FontSelectOptions = () => {
  return <>
    <option value={"Arial, sans-serif"} style={{fontFamily : "Arial, sans-serif"}}>Arial</option>
    <option value={"'Poppins', sans-serif"} style={{fontFamily : "'Poppins', sans-serif"}}>Poppins</option>
    <option value={"Verdana, sans-serif"} style={{fontFamily : "Verdana, sans-serif"}}>Verdana</option>
    <option value={"Helvetica, sans-serif"} style={{fontFamily : "Helvetica, sans-serif"}}>Helvetica</option>
    <option value={"Tahoma, sans-serif"} style={{fontFamily : "Tahoma, sans-serif"}}>Tahoma</option>
    <option value={"'Trebuchet MS', sans-serif"} style={{fontFamily : "'Trebuchet MS', sans-serif"}}>Trebuchet MS</option>
    <option value={"'Times New Roman', serif"} style={{fontFamily : "'Times New Roman', serif"}}>Times New Roman</option>
    <option value={"Georgia, serif"} style={{fontFamily : "Georgia, serif"}}>Georgia</option>
    <option value={"Garamond, serif"} style={{fontFamily : "Garamond, serif"}}>Garamond</option>
    <option value={"'Courier New', monospace"} style={{fontFamily : "'Courier New', monospace"}}>Courier New</option>
    <option value={"'Brush Script MT', cursive"} style={{fontFamily : "'Brush Script MT', cursive"}}>Brush Script MT</option>
  </>;
};
