import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const getProgressbarStyle = ({ color, percentage }) => {
    return {
        backgroundColor : color,
        width : percentage + "%"
    }
}

const StyledTooltipDiv = (props) => {

    //On mobile device we set the percentage fixed position.
    let percentage = window.innerWidth <= 480 ? 50 : props.options.percentage;

    let InnerDiv = styled.div`
        left: calc(${percentage}% - 62px);
        background : ${props.options.color};
        color : ${props.options.fontColor};
        &:before {
            border-bottom: 8px solid ${props.options.color}
        }
    `

    return <InnerDiv className={props.className}>{props.children}</InnerDiv>
}

export const PreviewProgressbar = (props) => {
    return (
        <div className='progressbar__preview-container'>
            <div className='progressbar__preview-bar' style={getProgressbarStyle(props)}/>
            <StyledTooltipDiv className='progressbar__tooltip' options={props}>
                    { Math.floor(props.percentage) + "% completed" }
            </StyledTooltipDiv>
        </div>
    )
}

PreviewProgressbar.defaultProps = {
    color : "#3C7EF3",
    fontColor : '#FFFFFF',
    percentage : 88
}

PreviewProgressbar.propTypes = {
    color: PropTypes.string,
    fontColor : PropTypes.string,
    percentage : PropTypes.number
};
