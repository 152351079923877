import 'date-fns';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import React, { useState } from 'react'
import BootstrapTooltip from '../../BootstrapTooltip';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import moment from "moment";


const PreviewTime = (props) => {

    const labelColor = props.data.configuration.labelColor ? props.data.configuration.labelColor.value : "#333";
    const fontFamily = props.data.configuration.fontFamily ? props.data.configuration.fontFamily.value : "'Poppins', sans-serif";
    const fontSize = props.data.configuration.fontSize ? props.data.configuration.fontSize.value : 14;


    const [open, setOpen] = useState(false);

    const handleTimeChange = (time) => {
        props.setPreviewValue({
          ...props.previewValue,
          [props.data.id]: moment(time).format('hh:mm A'),
        });
    };

    return (
        !props.data.configuration.hideBlock.isEnable && (
          <div className="mb-3 singleItem date-field">
            <div className="preview__date_field">
              <h5 style={{color : labelColor, fontFamily : fontFamily, fontSize : parseInt(fontSize)}}>{props.data.label}</h5>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  onClick={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  open={open}
                  inputProps={{readOnly : true}}
                  margin="normal"
                  id={props.data.id}
                  value={moment(props.previewValue[props.data.id], 'hh:mm A').toDate()}
                  mask="__:__ _M"
                  onChange={handleTimeChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  keyboardIcon={<AccessTimeIcon />} 
                />
              </MuiPickersUtilsProvider>

              {props.data.configuration.required.isEnable && (
                <BootstrapTooltip
                  arrow
                  title="Required"
                  placement="top"
                >
                  <span className="ar__req">*</span>
                </BootstrapTooltip>
              )}
              {props.simpleValidator.addFormValidator(
                props.data.component,
                "time",
                props.previewValue[props.data.id],
                props.data.configuration
              )}
              </div>
            </div>
        )
      );
}

export default PreviewTime;
