import React from 'react'

const PreviewHiddenField = (props) => {
    return (
        !props.data.configuration.hideBlock.isEnable && (
          <div className="mb-3 singleItem">
            <div>
              <input
                type={props.data.component}
                name={props.data.id}
                className="hidden"
                placeholder={props.data.placeholder}
                value={props.data.placeholder}
                id={props.data.id}
              />
            </div>
          </div>
        )
      );
}

export default PreviewHiddenField;
