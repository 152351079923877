import React, {useEffect, useRef, useState} from "react";
import {IconList} from "../../constants/IconList";
import GlobalModal from "../Common/Modal/GlobalModal";
import {connect} from "react-redux";
import {settingFormSubmit} from "../../actions/formBuilderAction";
import {getFormSettingPageData, fetchStages} from "../../api/formBuilderApi";
import SimpleReactValidator from 'simple-react-validator';
import {NativeSelect} from "@material-ui/core";
import Loading from "../Common/Loading";
import If, {Else} from 'if-else-react';
import Utils from "../../helpers/Utils";
import CoreConstants from "../../constants/CoreConstants";
import CustomSwitch from "../Common/Switch";

const FormSetting = props => {
    const [, forceUpdate] = useState() //this is a dummy state, when form submitted, change the state so that message is rendered
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: {forceUpdate: () => forceUpdate(1)},
        className: 'text-danger'
    }));
    const [loading, setLoading] = useState(true);
    const [campaigns, setCampaigns] = useState([]);
    const [linkedCampaign, setLinkedCampaign] = useState(props.formSetting.campaign_id ?? '');
    const [pipelines, setPipelines] = useState([]);
    const [linkedPipeline, setLinkedPipeline] = useState(props.formSetting.pipeline_id ?? '');
    const [stages, setStages] = useState([]);
    const [linkedStage, setLinkedStage] = useState(props.formSetting.stage_id ?? '');

    const [formName, setFormName] = useState(props.formDetails.title);
    const [actionAfterSubmitType, setActionAfterSubmitType] = useState(props.formSetting.action_after_submit_type);
    const [afterSubmitActionValue, setAfterSubmitActionValue] = useState(props.formSetting.action_after_submit);
    const [agencyBranding, setAgencyBranding] = useState(props.formSetting.agency_branding);
    const [updateExistingContact, setUpdateExistingContact] = useState(props.formSetting.update_duplicate_values);
    const [updateAllowDuplicateSubmission, setUpdateAllowDuplicateSubmission] = useState(props.formSetting.is_duplicate_submission_allowed ?? 1);
    const [formStatus, setFormStatus] = useState(props.formDetails.status);
    const [disabledMessage, setDisabledMessage] = useState(props.formSetting.inactive_form_text);

    useEffect(() => {
        getFormSettingPageData({ pipelineId : linkedPipeline }).then(response => {
            if (response.data.status) {
                setLoading(false);
                setCampaigns(response.data.data.campaigns);
                setPipelines(response.data.data.pipelines);
                setStages(response.data.data.stages);
            } else {
                Utils.showNotification(response.data.message);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const formSettingSubmit = () => {
        if (validator.current.allValid()) {
            props.settingFormSubmit({
                'form_id': props.formDetails.id,
                'title': formName,
                'campaign_id': linkedCampaign,
                'pipeline_id': linkedPipeline,
                'stage_id': linkedStage,
                'form_submit_action_type': actionAfterSubmitType,
                'form_submit_action': afterSubmitActionValue,
                'status': formStatus,
                'inactive_form_text': disabledMessage,
                'agency_branding': agencyBranding,
                'update_duplicate_values': updateExistingContact,
                'is_duplicate_submission_allowed': updateAllowDuplicateSubmission,
            }, formSettingSubmitCallback);
        } else {
            validator.current.showMessages();
        }
    }

    const formSettingSubmitCallback = response => {
        if (response.status === 'success') {
            Utils.showNotification(response.html, 'success');
            props.setOpenSettingModal(false)
        } else {
            Utils.showNotification(response.html, 'error');
        }
    }

    const renderCampaigns = () => {
        if (campaigns.length) {
            return campaigns.map((campaign, i) => {
                // eslint-disable-next-line eqeqeq
                return (<option selected={campaign.id == linkedCampaign} value={campaign.id}>{ campaign.title }</option>);
            });
        } else {
            return <option value='' disabled>No Campaign Found</option>;
        }
    }

    const renderPipelines = () => {
        if (pipelines.length) {
            return pipelines.map((pipeline, i) => {
                // eslint-disable-next-line eqeqeq
                return (<option selected={pipeline.id == linkedPipeline} value={pipeline.id}>{ pipeline.title }</option>);
            });
        } else {
            return <option value='' disabled>No Pipeline Found</option>;
        }
    }

    const renderStages = () => {
        if (stages.length) {
            return stages.map((stages, i) => {
                // eslint-disable-next-line eqeqeq
                return (<option selected={stages.id == linkedStage} value={stages.id}>{ stages.stage }</option>);
            });
        } else {
            return <option value='' disabled>No Stage Found</option>;
        }
    }

    const handleLinkedCampaignChange = (event) => {
        setLinkedCampaign(event.target.value);
    }

    const handleLinkedPipelineChange = (event) => {
        setLinkedPipeline(event.target.value);

        fetchStages({pipeline_id: event.target.value}).then(response => {
            if (response.data.status) {
                setStages(response.data.data.stages);
                setLinkedStage(response.data.data.stages[0] ? response.data.data.stages[0].id : null);
            } else {
                Utils.showNotification(response.data.message);
            }
        });
    }

    const handleLinkedStageChange = (event) => {
        setLinkedStage(event.target.value);
    }

    const handleStatusChange = (event) => {
        setFormStatus(event.target.value);
    }

    const handleActionAfterSubmitTypeChange = (event) => {
        setAfterSubmitActionValue('');
        setActionAfterSubmitType(event.target.value);
    }

    return (
        <React.Fragment>
            <GlobalModal open={props.openSettingModal} onClose={() => props.setOpenSettingModal(false)} title={"Form Setting"} buttonText={"Save Setting"} buttonIcon={IconList.copyIcon} buttonClick={formSettingSubmit}>
                <div className='setting_container form-settings-form-builder'>
                    <If condition={loading}>
                        <Loading size={50}/>
                        <Else />
                        <form action="/">
                            <div className="row mb-0">
                                <div className="input-field col s12">
                                    <label htmlFor="from-title">Form Title</label>
                                    <input placeholder="Enter Form Title" id="Enter Form Title" type="text" value={formName} onChange={(e) => setFormName(e.target.value)}/>
                                    {validator.current.message("form_title", formName, 'required|string')}
                                </div>
                            </div>
                            <div className="row mb-0">
                                <div className="input-field col s12">
                                    <label htmlFor="campaign-link">Link to Campaign </label>
                                    <NativeSelect
                                        value={linkedCampaign}
                                        name="selectName"
                                        className="pp_setting_select"
                                        onChange={handleLinkedCampaignChange}
                                        inputProps={{ 'aria-label': 'selectName' }}
                                    >
                                        <option value="">Select Campaign</option>
                                        {renderCampaigns()}
                                    </NativeSelect>
                                </div>
                            </div>
                            <div className="row mb-0">
                                <div className="input-field col s6">
                                    <label htmlFor="campaign-link">Link to Pipeline </label>
                                    <NativeSelect
                                        value={linkedPipeline}
                                        name="selectName"
                                        className="pp_setting_select"
                                        onChange={handleLinkedPipelineChange}
                                        inputProps={{ 'aria-label': 'selectName' }}
                                    >
                                        <option value="">Select Pipeline</option>
                                        {renderPipelines()}
                                    </NativeSelect>
                                </div>
                                <div className="input-field col s6">
                                    <label htmlFor="campaign-link">Link to Stage</label>
                                    <NativeSelect
                                        value={linkedStage}
                                        disabled={linkedPipeline === ""}
                                        name="selectName"
                                        className="pp_setting_select"
                                        onChange={handleLinkedStageChange}
                                        inputProps={{ 'aria-label': 'selectName' }}
                                    >
                                        <option value="">Select Stage</option>
                                        {renderStages()}
                                    </NativeSelect>
                                </div>
                            </div>
                            <div className="row mb-0">
                                <div className="input-field col s12">
                                    <label className="pr-2" htmlFor="campaign-link">Action After Submit</label>
                                    <NativeSelect
                                        value={actionAfterSubmitType}
                                        name="selectName"
                                        className="pp_setting_select"
                                        onChange={handleActionAfterSubmitTypeChange}
                                        inputProps={{ 'aria-label': 'selectName' }}
                                    >
                                        <option value="2">Thank you message</option>
                                        <option value="1">Redirect To Url</option>
                                    </NativeSelect>
                                    {validator.current.message('action_after_submit', actionAfterSubmitType, 'required')}
                                </div>
                                <div className="input-field col s12">
                                    {/* eslint-disable-next-line eqeqeq */}
                                    <input placeholder={actionAfterSubmitType == CoreConstants.MESSAGE_AFTER_SUBMIT ? "Enter thank you message" : "Enter redirect url"} id="after_submit" type="text" value={afterSubmitActionValue} onChange={(e) => {setAfterSubmitActionValue(e.target.value)}}/>
                                    {/* eslint-disable-next-line eqeqeq */}
                                    {actionAfterSubmitType == CoreConstants.MESSAGE_AFTER_SUBMIT ?
                                        validator.current.message("greeting_message", afterSubmitActionValue, 'required|string')
                                        :
                                        validator.current.message("redirect_url", afterSubmitActionValue, 'required|url')
                                    }
                                    {/*{validator.current.message((actionAfterSubmitType == CoreConstants.MESSAGE_AFTER_SUBMIT ? "greeting_message" : "redirect_url"), afterSubmitActionValue, 'required|string')}*/}
                                </div>
                            </div>
                            <div className="row mb-0">
                                <div className="input-field col s6 pp_switcher_field">
                                    <label className="d-flex align-items-center">
                                        <span className="pr-2">Include Agency Branding</span>
                                        <CustomSwitch 
                                            status={agencyBranding} 
                                            brandColor={"#f94f72"} 
                                            onChange={setAgencyBranding}
                                        />
                                        {/* <Switch onChange={setAgencyBranding} checked={agencyBranding} /> */}
                                    </label>
                                </div>
                                <div className="input-field col s6 pp_switcher_field">
                                    <label className="d-flex align-items-center">
                                        <span className="pr-2">Update Existing Contact's Values</span>
                                        <CustomSwitch 
                                            status={updateExistingContact} 
                                            brandColor={"#f94f72"} 
                                            onChange={setUpdateExistingContact}
                                        />
                                        {/* <Switch onChange={setUpdateExistingContact} checked={updateExistingContact} /> */}
                                    </label>
                                </div>
                            </div>
                            <div className="row mb-0">
                                <div className="input-field col s6 pp_switcher_field">
                                    <label className="d-flex align-items-center">
                                        <span className="pr-2">Allow duplicate submission</span>
                                        <CustomSwitch
                                            status={updateAllowDuplicateSubmission}
                                            brandColor={"#f94f72"}
                                            onChange={setUpdateAllowDuplicateSubmission}
                                        />
                                        {/* <Switch onChange={setUpdateExistingContact} checked={updateExistingContact} /> */}
                                    </label>
                                </div>
                            </div>
                            <div className="row mb-0">
                                <div className="input-field col s12">
                                    <label htmlFor="campaign-link">Status </label>
                                    <NativeSelect
                                        value={formStatus}
                                        name="selectName"
                                        className="pp_setting_select"
                                        onChange={handleStatusChange}
                                        inputProps={{ 'aria-label': 'selectName' }}
                                    >
                                        <option value="1">Accept New Response</option>
                                        <option value="0">Disabled</option>
                                        <option value="2">Invisible</option>
                                    </NativeSelect>
                                    {validator.current.message('status', formStatus, 'required')}
                                </div>
                                {/* eslint-disable-next-line eqeqeq */}
                                <If condition={formStatus == CoreConstants.STATUS_DISABLE}>
                                    <div className="input-field col s12">
                                        <label htmlFor="campaign-link">Show this when disable</label>
                                        <textarea id="" cols="30" rows="10" defaultValue={disabledMessage} onChange={(e) => setDisabledMessage(e.target.value)}/>
                                    </div>
                                </If>
                            </div>
                        </form>
                    </If>
                </div>
            </GlobalModal>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        formDetails: state.formBuilderReducer.formDetails,
        formSetting: state.formBuilderReducer.formSetting,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        settingFormSubmit: (params, callback) => dispatch(settingFormSubmit(params, callback))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormSetting);