import React from "react";
import { Router, Route, Switch } from "react-router-dom";

// import components
import FormBuilder from "../components/FormBuilder";
import EditFormBuilder from "../components/EditFormBuilder";
import FormTemplate from "../components/FormTemplate";
import FormEntries from "../components/FormEntries";
import RenderForm from "../components/PublicPage/RenderForm";
import HealthCheck from "../components/HealthCheck";

import FormBuilderNew from "../components/FormBuilderNew/formBuildersList";
import EditNewFormBuilder from "../components/FormBuilderNew/EditNewFormBuilder";
import CustomFormEntries from "../components/FormBuilderNew/FormEntries/CustomFormEntries";
import PublicCustomForm from "../components/FormBuilderNew/publicCustomForm/PublicCustomForm";

const Routes = (props) => {
  return <Router history={props.history}>
    <div>
      <Switch>
        <Route exact path="/user/form-builder" component={FormBuilder} />
        <Route exact path="/user/form-builder/:uniqueCode" component={EditFormBuilder}/>
        <Route exact path="/user/form-entries/:uniqueCode" component={FormEntries}/>
        <Route exact path="/user/form/template" component={FormTemplate} />
        <Route exact path="/form/:uniqueId" component={RenderForm} />
        <Route exact path="/form-builder/health-check" component={HealthCheck} />


        <Route exact path="/user/new/form-builder" component={FormBuilderNew} />
        <Route exact path="/user/new/form-builder/:uniqueCode" component={EditNewFormBuilder}/>
        <Route exact path="/user/new/form-entries/:uniqueCode" component={CustomFormEntries}/>
        <Route exact path="/c/form/:uniqueId" component={PublicCustomForm} />

      </Switch>
    </div>
  </Router>
}

export default Routes;
