export const FETCH_FORMS = 'FETCH_FORMS';
export const ADD_FORMS = 'ADD_FORMS';
export const STORE_FORMS = 'STORE_FORMS';
export const FETCH_FORM_DETAIL = 'FETCH_FORM_DETAIL';
export const STORE_FORM_DETAIL = 'STORE_FORM_DETAIL';
export const SUBMIT_SETTING_FORM = 'SUBMIT_SETTING_FORM';
export const UPDATE_SETTING_DETAILS = 'UPDATE_SETTING_DETAILS';
export const SUBMIT_STYLE_FORM = 'SUBMIT_STYLE_FORM';
export const UPDATE_STYLE_DETAILS = 'UPDATE_STYLE_DETAILS';
export const UPDATE_FORM_SUBMIT = 'UPDATE_FORM_SUBMIT';
export const UPDATE_FORM = 'UPDATE_FORM';
export const FETCH_FORM_CATEGORIES = 'FETCH_FORM_CATEGORIES';
export const STORE_FORM_CATEGORIES = 'STORE_FORM_CATEGORIES';
export const FETCH_FORM_SETTING_PAGE_DATA = 'FETCH_FORM_SETTING_PAGE_DATA';
export const STORE_FORM_SETTING_PAGE_DATA = 'STORE_FORM_SETTING_PAGE_DATA';


//custom forms
export const FETCH_CUSTOM_FORMS = 'FETCH_CUSTOM_FORMS';
export const STORE_CUSTOM_FORMS = 'STORE_CUSTOM_FORMS';
export const ADD_CUSTOM_FORMS = 'ADD_CUSTOM_FORMS';
export const FETCH_CUSTOM_FORM_DETAIL = 'FETCH_CUSTOM_FORM_DETAIL';
export const STORE_CUSTOM_FORM_DETAIL = 'STORE_CUSTOM_FORM_DETAIL';
export const SUBMIT_SETTING_CUSTOM_FORM = 'SUBMIT_SETTING_CUSTOM_FORM';
export const UPDATE_CUSTOM_FORM_SETTING_DETAILS = 'UPDATE_CUSTOM_FORM_SETTING_DETAILS';
export const SAVE_BUTTON_ENABLE_DISABLE = "SAVE_BUTTON_ENABLE_DISABLE";
export const SET_CUSTOM_FORM_BUILDER_VALUE = "SET_CUSTOM_FORM_BUILDER_VALUE";
export const UPDATE_CUSTOM_FORM_SUBMIT = 'UPDATE_CUSTOM_FORM_SUBMIT';
export const CUSTOM_FORM_BUILDER_FIELD_UPDATE = 'CUSTOM_FORM_BUILDER_FIELD_UPDATE';
export const CUSTOM_FORM_EDIT_RESET = 'CUSTOM_FORM_EDIT_RESET';

//Other fetches
export const FETCH_CUSTOM_FIELDS = 'FETCH_CUSTOM_FIELDS';
export const STORE_CUSTOM_FIELDS = 'STORE_CUSTOM_FIELDS';
