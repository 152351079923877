const Configuration = {
  empty: {},
  default: {
    hideBlock: {
      name: "Hide block",
      hidden: true,
      isEnable: false,
    },
  },
  texts: {
    hideBlock: {
      name: "Hide block",
      hidden: true,
      isEnable: false,
    },
    labelColor : {
      name : "Color",
      value : "#333"
    },
    fontFamily : {
      name : "Font Style",
      value : "'Poppins', sans-serif"
    }
  },
  common: {
    hideBlock: {
      name: "Hide block",
      hidden: true,
      isEnable: false,
    },
    required: {
      name: "Required",
      isEnable: true,
    },
    defaultAnswer: {
      name: "Default answer ",
      showField: true,
      isEnable: false,
      type: "text",
      value: "",
    },
    labelColor : {
      name : "Label color",
      value : "#333"
    },
    fontFamily : {
      name : "Font Style",
      value : "'Poppins', sans-serif"
    },
    fontSize : {
      name : "Font Size",
      value : "14"
    }
  },
  choice: {
    hideBlock: {
      name: "Hide block",
      hidden: true,
      isEnable: false,
    },
    required: {
      name: "Required",
      isEnable: true,
    },
    defaultAnswer: {
      name: "Default answer ",
      showField: true,
      isEnable: false,
      type: "text",
      value: "",
    },
    selectedView : {
      name : "Selected view",
      value : "list"
    },
    labelColor : {
      name : "Label color",
      value : "#333"
    },
    fontFamily : {
      name : "Font Style",
      value : "'Poppins', sans-serif"
    },
    fontSize : {
      name : "Font Size",
      value : "14"
    }
  },
  fileUpload: {
    hideBlock: {
      name: "Hide block",
      hidden: true,
      isEnable: false,
    },
    required: {
      name: "Required",
      isEnable: true,
    },
    labelColor : {
      name : "Label color",
      value : "#333"
    },
    fontFamily : {
      name : "Font Style",
      value : "'Poppins', sans-serif"
    },
    fontSize : {
      name : "Font Size",
      value : "14"
    }
  },

  textField: {
    hideBlock: {
      name: "Hide block",
      hidden: true,
      isEnable: false,
    },
    required: {
      name: "Required",
      isEnable: true,
    },
    defaultAnswer: {
      name: "Default answer ",
      showField: true,
      isEnable: false,
      type: "text",
      value: "",
    },
    minCharacters: {
      name: "Min characters",
      showField: true,
      isEnable: false,
      type: "number",
      value: 1,
    },
    maxCharacters: {
      name: "Max characters",
      showField: true,
      isEnable: false,
      type: "number",
      value: 30,
    },
    labelColor : {
      name : "Label color",
      value : "#333"
    },
    fontFamily : {
      name : "Font Style",
      value : "'Poppins', sans-serif"
    },
    fontSize : {
      name : "Font Size",
      value : "14"
    }
  },
  textArea: {
    hideBlock: {
      name: "Hide block",
      hidden: true,
      isEnable: false,
    },
    required: {
      name: "Required",
      isEnable: true,
    },
    defaultAnswer: {
      name: "Default answer ",
      showField: true,
      isEnable: false,
      type: "text",
      value: "",
    },
    minCharacters: {
      name: "Min characters",
      showField: true,
      isEnable: false,
      type: "number",
      value: 1,
    },
    maxCharacters: {
      name: "Max characters",
      showField: true,
      isEnable: false,
      type: "number",
      value: 100,
    },
    labelColor : {
      name : "Label color",
      value : "#333"
    },
    fontFamily : {
      name : "Font Style",
      value : "'Poppins', sans-serif"
    },
    fontSize : {
      name : "Font Size",
      value : "14"
    }
  },

  number: {
    hideBlock: {
      name: "Hide block",
      hidden: true,
      isEnable: false,
    },
    required: {
      name: "Required",
      isEnable: true,
    },
    defaultAnswer: {
      name: "Default answer ",
      showField: true,
      isEnable: false,
      type: "text",
      value: "",
    },
    minNumber: {
      name: "Min number",
      showField: true,
      isEnable: false,
      type: "number",
      value: 1,
    },
    maxNumber: {
      name: "Max number",
      showField: true,
      isEnable: false,
      type: "number",
      value: 20,
    },
    labelColor : {
      name : "Label color",
      value : "#333"
    },
    fontFamily : {
      name : "Font Style",
      value : "'Poppins', sans-serif"
    },
    fontSize : {
      name : "Font Size",
      value : "14"
    }
  },

  date: {
    hideBlock: {
      name: "Hide block",
      hidden: true,
      isEnable: false,
    },
    required: {
      name: "Required",
      isEnable: true,
    },
    specificDate: {
      name: "Specific dates",
      showField: true,
      isEnable: false,
      type: "date",
      value: "",
    },
    labelColor : {
      name : "Label color",
      value : "#333"
    },
    fontFamily : {
      name : "Font Style",
      value : "'Poppins', sans-serif"
    },
    fontSize : {
      name : "Font Size",
      value : "14"
    }
  },
  time: {
    hideBlock: {
      name: "Hide block",
      hidden: true,
      isEnable: false,
    },
    required: {
      name: "Required",
      isEnable: true,
    },
    specificTime: {
      name: "Specific time",
      showField: true,
      isEnable: false,
      type: "time",
      value: "",
    },
    labelColor : {
      name : "Label color",
      value : "#333"
    },
    fontFamily : {
      name : "Font Style",
      value : "'Poppins', sans-serif"
    },
    fontSize : {
      name : "Font Size",
      value : "14"
    }
  },

  checkbox: {
    hideBlock: {
      name: "Hide block",
      hidden: true,
      isEnable: false,
    },
    required: {
      name: "Required",
      isEnable: true,
    },
    minOptions: {
      name: "Min Options",
      showField: true,
      isEnable: false,
      type: "number",
      value: 1,
    },
    maxOptions: {
      name: "Max Options",
      showField: true,
      isEnable: false,
      type: "number",
      value: 10,
    },
    labelColor : {
      name : "Label color",
      value : "#333"
    },
    fontFamily : {
      name : "Font Style",
      value : "'Poppins', sans-serif"
    },
    fontSize : {
      name : "Font Size",
      value : "14"
    }
  },
  rating: {
    hideBlock: {
      name: "Hide block",
      hidden: true,
      isEnable: false,
    },
    required: {
      name: "Required",
      isEnable: true,
    },
    star: {
      name: "Stars",
      showField: true,
      isEnable: false,
      type: "number",
      value: 5,
    },
  },
};

export default Configuration;
