import { all, takeEvery, call, put,select } from 'redux-saga/effects';
import * as FORM_BUILDER_API from '../api/customFormBuilderApi';
import * as ACTION from '../constants/formBuilderActionTypes'
import Utils from "../helpers/Utils";

function* customFormBuilderWatcher() {
    yield takeEvery(ACTION.FETCH_CUSTOM_FORMS, fetchFormsMiddleware);
    yield takeEvery(ACTION.FETCH_CUSTOM_FORM_DETAIL, fetchFormDetailMiddleware);
    yield takeEvery(ACTION.SUBMIT_SETTING_CUSTOM_FORM, settingFormSubmitMiddleware);
    yield takeEvery(ACTION.UPDATE_CUSTOM_FORM_SUBMIT, updateFormMiddleware);
}

function* fetchFormsMiddleware(action) {
    try {
        const response = yield call(FORM_BUILDER_API.fetchCustomFormList, action.payload);
        let responseData = response.data.html;
        yield put({type: ACTION.STORE_CUSTOM_FORMS, payload: responseData});
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchFormDetailMiddleware (action) {
    const {payload} = action;
    try {
        const response = yield call(FORM_BUILDER_API.fetchCustomFormDetail, payload.payload);
        let responseData = response.data.data;
        yield put({type: ACTION.STORE_CUSTOM_FORM_DETAIL, payload: responseData});
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* settingFormSubmitMiddleware (action) {
    const {payload} = action;
    try {
        const response = yield call(FORM_BUILDER_API.settingCustomFormSubmit, payload.payload);
        if (response.data.status === 'success') {
            yield put({type: ACTION.UPDATE_CUSTOM_FORM_SETTING_DETAILS, payload: payload.payload});
        }
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updateFormMiddleware (action) {
    const {payload} = action;
    try {
        yield put({type: ACTION.SAVE_BUTTON_ENABLE_DISABLE, payload: false});
        const { builderJson, formDetails, fields } = yield select(state => state.customFormBuilderReducer);
        const response = yield call(FORM_BUILDER_API.updateCustomForm, {...builderJson,form_id : formDetails.id, fields : fields,uniqueId : formDetails.unique_id});
        if(response?.data.status !== "Success") {
            yield put({type: ACTION.SAVE_BUTTON_ENABLE_DISABLE, payload: true});
        }
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* customFormBuilderMiddleware() {
    yield all([
        customFormBuilderWatcher(),
    ])
}
