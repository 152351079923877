import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Navigation.css";
import FormSettingModal from "./shared/SettingModal/FormSettingModal";
import { useSelector, useDispatch } from "react-redux";
import { customFormSave } from "../../actions/customFormBuilderAction";
import { Link } from "react-router-dom";
import { validateFields } from "./NewFormUtils";
import { RouterPrompt } from "../Common/RouterPrompt";
import { MappingModal } from "./shared/Mapping/MappingModal";
import FormStyleModal from './shared/FormStyle/FormStyleModal';
import FormIntegrate from '../FormBuilderNew/formBuildersList/FormIntegrate';
import ActionControl from "../Common/ActionControl/ActionControl";
import Utils from "../../helpers/Utils";

function Navigation(props) {

  const dispatch = useDispatch();
  const isTempUser = Utils.getAccountData('isTemplateUser');
  const { saveRequired, fields, formDetails } = useSelector(state => state.customFormBuilderReducer);
  const [isOpenSettingModal, setIsOpenSettingModal] = useState(false);
  const [isOpenMappingModal, setIsOpenMappingModal] = useState(false);
  const [isOpenFormStyleModal, setIsOpenFormStyleModal] = useState(false);
  const [openIntegrateModal, setOpenIntegrateModal] = useState(false);

  useEffect(() => {
    return () => {
      //Just cancelling the onbeforeunload on unmount
      window.onbeforeunload = () => {};
    }
  },[]);

  const saveForm = () => {
    let validationObj = validateFields(fields);
    if(!validationObj.status) {
      window.showNotification("ERROR",validationObj.message);
      return;
    }

    dispatch(customFormSave((data) => {
      if(data?.status === "Success") {
        window.showNotification('SUCCESS',"Form saved");
      } else {
        window.showNotification('ERROR','Update failed, please reload the page and submit again');
      }
    }));
  }

  window.onbeforeunload = () => {
    if(saveRequired) {
      return "You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?";
    }
  }

  return (
    <>
    <RouterPrompt
      when={saveRequired}
      onCancel={() => {
        window.setActiveSidebar('marketing');
      }}
    />
    <header className="header-area container-fluid justify_center">
      <Link className="newFormBuilderBack" to={'/user/new/form-builder'}>
        <FontAwesomeIcon icon={["fas", "arrow-left"]} />
        <span>Back</span>
      </Link> 
      <div className="header-middle">
        <ul>
          <li onClick={() => props.handleInsert()}>
            <FontAwesomeIcon icon={["fas", "plus"]} />
            <span>Insert</span>
          </li>

          <li onClick={props.addNewPage}>
            <FontAwesomeIcon icon={["far", "file-alt"]} />
            <span>New Page</span>
          </li>

          <li onClick={() => props.handleUploadOpen("logo")}>
            <FontAwesomeIcon icon={["fab", "pied-piper"]} />
            <span>Logo</span>
          </li>

          <li onClick={() => props.handleUploadOpen("cover photo")}>
            <FontAwesomeIcon icon={["far", "image"]} />
            <span>Cover</span>
          </li>

          <li onClick={() => setIsOpenMappingModal(true)}>
            <FontAwesomeIcon icon={["fas", "map-signs"]} />
            <span>Mapping</span>
          </li>

          <li onClick={() => setIsOpenSettingModal(true)}>
            <FontAwesomeIcon icon={["fas", "cog"]} />
            <span>Settings</span>
          </li>

          <li onClick={() => setIsOpenFormStyleModal(true)}>
            <FontAwesomeIcon icon={["fas", "brush"]} />
            <span>Form Style</span>
          </li>
          <ActionControl alertMessage="Form builder feature is not available for template user" status="error"  isDisabled={isTempUser == 1}>
            <li onClick={() => setOpenIntegrateModal(true)}>
              <FontAwesomeIcon icon={["fas", "cube"]} />
              <span>Integrate</span>
            </li>
          </ActionControl>
          <ActionControl alertMessage="Form builder feature is not available for template user" status="error"  isDisabled={isTempUser == 1}>
            <li onClick={props.preview}>
              <FontAwesomeIcon icon={["far", "eye"]} />
              <span>Preview</span>
            </li>
          </ActionControl>

          {
            saveRequired && 
            <li className="form-save-button" onClick={() => saveForm()}>
              <FontAwesomeIcon icon={["fas", "save"]} />
              <span>Save</span>
            </li>
          }
        </ul>
      </div>

        {
          isOpenSettingModal &&
          <FormSettingModal 
            open={isOpenSettingModal}
            handleClose={() => setIsOpenSettingModal(false)}
          />
        }

        {
          isOpenMappingModal &&       
          <MappingModal
            open={isOpenMappingModal}
            onClose={setIsOpenMappingModal}
          />
        }

        {
          isOpenFormStyleModal &&
          <FormStyleModal 
            open={isOpenFormStyleModal}
            onClose={setIsOpenFormStyleModal}
          />
        }

        {openIntegrateModal &&
            <FormIntegrate
                className="global-xs-modal"
                form={formDetails}
                openIntegrateModal={openIntegrateModal}
                setOpenIntegrateModal={setOpenIntegrateModal}
            />
        }

    </header>
    
    </>
  );
}

export default Navigation;
