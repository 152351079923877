import React from "react";
import { IconList } from "../../../../../../constants/IconList";

const formColors = [
  '#e3f2fd','#e8eaf6','#f3e5f5','#fce4ec','#ffebee',
  '#f1f8e9','#e8f5e9','#e0f2f1','#e0f7fa','#fbe9e7',
  '#fff3e0','#fff8e1','#fffde7','#f9fbe7','#2e2e2e',
  "#3941b2","#beb5e3","#e0c4ad","#0aa74a","#910aa7",
  '#DAF7A6','#FFC300','#FF5733','#C70039','#900C3F','#581845',
  '#0097a7','#82b1ff','#7986cb','#7b1fa2','#311b92','#ce93d8'
]

const BackgroundColor = (props) => {

  const setBackgroundColor = (color) => {
    props.setBackground({ color : color });
    window.showNotification("SUCCESS","Background color set successfully");
  }

  const getFormColors = () => {
    return formColors.map((color,index) => (
      <div 
        key={index} 
        className={`form__fixed__background__single ${props.backgroundColor === color ? " active" : ""}`} 
        style={{backgroundColor : color}}
        onClick={() => setBackgroundColor(color)}
      >
        {props.backgroundColor === color && IconList.formCheckIcon}
      </div>
      
    ))
  }

  return (
    <div className="form__fixed__background">
      {getFormColors()}
    </div>
  );
};

export default BackgroundColor;
