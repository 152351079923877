import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Container from "@material-ui/core/Container";
import "./Body.css";
import Navigation from "./Navigation";
import EditInputFields from "./shared/EditInputField/EditInputFields";
import ModalAdd from "./shared/AddModal/ModalAdd";
import { v4 as uuidv4 } from "uuid";
import LogoUpload from './shared/UploadModal/LogoUpload';
import UploadModal from "./shared/UploadModal/UploadModal";
import ImagePreview from "./shared/ImagePreview/ImagePreview";
import Configuration from "./Configuration";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PreviewPage from "./shared/PreviewInputField/PreviewPage";
import Utils from "../../helpers/Utils";
import { customFormFieldUpdate, customFromEditReset, fetchCustomFormDetails, setCustomFormBuilderValue, setCustomFormSaveVisible } from "../../actions/customFormBuilderAction";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Common/Loading";
import useDelayCallback from "../../hooks/useDelayCallback";
import { createFieldNewFieldObject, getFormbackground, hasProgressbar } from "./NewFormUtils";
import FormNameEditor from "./shared/CkEditor/FormNameEditor";
import { fetchCustomFields } from "../../actions/commonAction";
import { EditProgressbarField } from "./shared/EditInputField/EditProgressbarField";
import { MODAL_DEFAULT_OPTIONS, PROGRESSBAR_DEFAULT_OPTIONS } from "../../constants/CoreConstants";
import { PageDivider } from "./shared/EditInputField/PageDivider";

const EditNewFormBuilder = (props) =>  {

  const dispatch = useDispatch();
  const { builderJson, fields } = useSelector(state => state.customFormBuilderReducer);

  const [formName, setFormName] = useState("");
  const [uploadType, setUploadType] = useState("");
  const [uploadShow, setUploadShow] = useState(false);
  const [selected, setSelected] = useState(Configuration);
  const [show, setShow] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dragEnabled, setDragEnabled] = useState(false);
  const [modalData, setModalData] = useState(MODAL_DEFAULT_OPTIONS);

  useEffect(() => {
    //update page title
    dispatch(customFromEditReset());
    document.title = `Form Setting | ${Utils.getAccountData('AuthUserAgencyName')}`;
    window.setActiveSidebar('marketing');
    dispatch(fetchCustomFormDetails({uniqueCode: props.match.params.uniqueCode}, res => {
      if (res.status === 'success') {
        setLoading(false);
      }
    }));
    dispatch(fetchCustomFields());

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFormName(builderJson ? builderJson.form_name : "");
  },[ builderJson?.form_name ]); // eslint-disable-line react-hooks/exhaustive-deps
  

  useDelayCallback(() => {
    dispatch(setCustomFormBuilderValue({
      key : 'form_name',
      value : formName
    }));
  },[formName],1000)

  const handleUploadOpen = (type) => {
    setUploadShow(true);
    setUploadType(type);
  };


  // Add New Page functionality
  const addNewPage = () => {
    setShow(false);
    let oldFields = [...fields];

    //Generating New Page Number
    const newPageNumber = Math.max.apply(Math,fields.map(el => el.page)) + 1;

    oldFields.forEach(elem => {
      if(elem.type === "button" && elem.placeholder === "Send") {
        elem.placeholder = "Next Page";
      }
    })

    const newPageBtn = {
      id: uuidv4(),
      page: newPageNumber,
      type: "button",
      placeholder: "Send",
      name: uuidv4(),
      configuration: Configuration.default,
    };
    oldFields.push(newPageBtn);
    dispatch(customFormFieldUpdate(oldFields));
  };

  // Page Remove functionality
  const removePage = (pageNumber) => {
    const keepFields = fields.filter((dt) => dt.page !== pageNumber);
    if(keepFields[keepFields.length-1].type === "button" && keepFields[keepFields.length-1].placeholder === "Next Page") {
      keepFields[keepFields.length-1].placeholder = "Send";
    }
    dispatch(customFormFieldUpdate(keepFields));
  };

  //Single field removal functionality
  const removeFieldItem = (index) => {
    let oldFields = [...fields];
    oldFields.splice(index, 1);
    dispatch(customFormFieldUpdate(oldFields));
  };

  //Open Modal and Passing Current (clicked) Field object
  const handleModal = (allInfo) => {
    setModalData(allInfo);
    setShow(true);
  };

  //Update Placeholder and Button Name
  const updatePlaceholder = (placeholder, index) => {
    let oldFields = [...fields];
    oldFields[index].placeholder = placeholder;
    dispatch(customFormFieldUpdate(oldFields));
  };

  //Update Label
  const updateLabel = (label, index) => {
    let oldFields = [...fields];
    oldFields[index].label = label;
    dispatch(customFormFieldUpdate(oldFields));
  };

  //Update checkbox, multiple choice, dropdown, radio button option placeholder
  const updateOptionPlaceholder = ( placeholder, fieldIndex, optionIndex, type ) => {
    let oldFields = [...fields];
    oldFields[fieldIndex][type][optionIndex].placeholder = placeholder;
    dispatch(customFormFieldUpdate(oldFields));
  };

  //Add New Input Field functionality
  const addInputField = (newFieldData) => {
    //If the type is progressbar
    if(newFieldData.newFieldType === "progressBar") {
      if(!hasProgressbar(builderJson?.progressbar)) {
        dispatch(setCustomFormBuilderValue({ key : 'progressbar', value : PROGRESSBAR_DEFAULT_OPTIONS }));
        dispatch(setCustomFormSaveVisible(true));
        setShow(false); //modal hiding
      }
      return;
    }

    let newFields = createFieldNewFieldObject(fields,newFieldData);
    if(newFields) {
      setShow(false); //modal hiding
      dispatch(customFormFieldUpdate(newFields));
    }
  };

  const onDragEnd = (result) => {
    setDragEnabled(false);
    if (!result.destination) {
      return;
    }
    const newItems = [...fields];
    const [removed] = newItems.splice(result.source.index, 1);
    let destinationIndex = result.destination.index === newItems.length ? result.destination.index - 1 : result.destination.index;
    newItems.splice(destinationIndex, 0, removed);
    newItems[destinationIndex].page = newItems[destinationIndex+1].page;
    dispatch(customFormFieldUpdate(newItems));
  };

  //We also maintaining page order through index,currentPage
  const handleSwapPageOrder = (orderA,orderB) => {
    let oldFields = [...fields];
    let index = 1,currentPage = 1;

    for(let i = 0; i< oldFields.length; i++) {
      if(oldFields[i].page !== currentPage) {
        currentPage = oldFields[i].page;
        index++;
      }

      oldFields[i].page = index;

      if(oldFields[i].page === orderA){
        oldFields[i].page = orderB;
      } else if(oldFields[i].page === orderB) {
        oldFields[i].page = orderA;
      }
    }

    //Sorting
    oldFields.sort((a,b) => a.page-b.page);

    dispatch(customFormFieldUpdate(oldFields));
  }

  //Showing All Fields from fields Array and drag and drop them
  const DragAndDropList = () => {
    let i = 1; //Page counter]
    let fieldsLength = fields.length;
    let totalPages = fields.filter(field => field.type === "button").length;

    return (
      <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setDragEnabled(true)}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map((dt, index) => {
                  return (
                    <Draggable key={dt.id} draggableId={dt.id} index={index} isDragDisabled={dt.type === "button"}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            key={dt.id}
                            ref={provided.innerRef}
                            snapshot={snapshot}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="input__with__buttons">
                              <EditInputFields
                                allInfo={{ ...dt, index: index }}
                                handleModal={handleModal}
                                addInputField={addInputField}
                                removeFieldItem={removeFieldItem}
                                updatePlaceholder={updatePlaceholder}
                                selected={selected}
                                setSelected={setSelected}
                                modalData={modalData}
                                fields={fields}
                                updateFields={(data) => dispatch(customFormFieldUpdate(data))}
                                updateOptionPlaceholder={
                                  updateOptionPlaceholder
                                }
                                setModalData={setModalData}
                                updateLabel = {updateLabel}
                              />
                            </div>

                            {dt.type === "button" && fieldsLength-1 > index && !dragEnabled && (
                              <PageDivider 
                                page={++i}
                                onSwapPage={handleSwapPageOrder} 
                                totalPages={totalPages}
                                onRemovePage={() => removePage(fields[index + 1].page)}/>
                            )}
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

  if(loading) {
    return <div className="p-5"><Loading/></div>
  }

  return (
    <div
      className="form__builder__background__image__section"
      style={getFormbackground(builderJson)}
    >
      <Navigation
        addNewPage={addNewPage}
        preview={() => setPreviewModal(true)}
        handleUploadOpen={handleUploadOpen}
        handleInsert={() =>
          handleModal({
            ...fields[fields.length - 1],
            index: fields.length - 1,
          })
        }
      />
      <ImagePreview handleUploadOpen={handleUploadOpen} />
      <Container>
        
        <EditProgressbarField setting={builderJson?.progressbar}/>

        <div
          className={`body__container__form__builder ${
            builderJson?.logo ? "logo__area" : ""
          }  ar__body_container_form_builder`}
        >
        
          <div className="add__title">
            <FormNameEditor setFormName={setFormName} data={formName} />
          </div>

          {DragAndDropList()}
        </div>

        {/* Modal for Field Insert */}
        <ModalAdd
          show={show}
          onHide={() => setShow(false)}
          handleClose={() => setShow(false)}
          modalData={modalData}
          addInputField={addInputField}
          addNewPage={addNewPage}
          setModalData={setModalData}
        ></ModalAdd>

        { uploadType === "logo" ?
          <LogoUpload
            uploadShow={uploadShow}
            closeModal={() => setUploadShow(false)}
            uploadType={uploadType}
            builderJson={builderJson}
          />
          :
          <UploadModal
            uploadShow={uploadShow}
            closeModal={() => setUploadShow(false)}
            uploadType={uploadType}
          />
        }

        {/* Preview Modal */}
        <Modal
          open={previewModal}
          onClose={() => setPreviewModal(false)}
          className="modal-100w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <div
            className={
              builderJson?.floatingCover
                ? "preview__top__fixed modal__preview__content__wr"
                : "preview__top__relative modal__preview__content__wr"
            }
            style={getFormbackground(builderJson)}
          >
            <ImagePreview setPreviewModal={setPreviewModal} prevModal />

            <PreviewPage
              formName={formName}
              fields={fields}
              builderJson={builderJson}
            />
          </div>
        </Modal>
      </Container>
    </div>
  );
}

export default EditNewFormBuilder;
