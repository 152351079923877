import React, { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import BackgroundLink from "./Link/BackgroundLink";
import BackgroundColor from "./Color/BackgroundColor";
import "./FormBackground.css";
import BackgroundUpload from "./Upload/BackgroundUpload";
import { IconList } from "../../../../../constants/IconList";
import { confirmAlert } from "react-confirm-alert";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const FormBackground = (props) => {
  const [tabValue, setTabValue] = useState(0);

  function tabName(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const removeFormBackground = (e) => {
    e.stopPropagation();
    confirmAlert({
      title: "Confirm to reset the background?",
      message: "You will not able to undo it.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.setBackground(null);
            window.showNotification("SUCCESS","Background reset successfully");
          },
        },
        {
          label: "No",
          onClick: () => {
            return;
          },
        },
      ],
    });
  }

  return (
    <div className="form__background__wr">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <span className="form__background__heading">
            Change Background Image
          </span>
        </AccordionSummary>
        <AccordionDetails className="form__background__details">
          <div className="form__style__background__tabs">
            <AppBar position="static">
              <div className="form__style__background__tab__section__with__remove">
                <div className="form__style__background__tab__section">
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="simple tabs example"
                  >
                    <Tab label="Upload" {...tabName(0)} disableRipple />
                    <Tab label="Link" {...tabName(1)} disableRipple />
                    <Tab label="Color" {...tabName(2)} disableRipple />
                  </Tabs>
                </div>

              { 
                props.builderJson?.formBackground && 
                <div className="form__style__background__remove__section" onClick={removeFormBackground}>
                  {IconList.deleteIcon}
                  <span>Remove</span>
                </div>
              }
                
              </div>
              <TabPanel value={tabValue} index={0}>
                <BackgroundUpload setBackground={props.setBackground}/>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <BackgroundLink onLinkSet={props.setBackground}/>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <BackgroundColor 
                  setBackground={props.setBackground} 
                  backgroundColor={props.builderJson?.formBackground?.color}/>
              </TabPanel>
            </AppBar>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FormBackground;
