import { useState } from 'react';
import useDelayCallback from '../../../../hooks/useDelayCallback';
import { getIconFinderImages } from '../../../../api/customFormBuilderApi';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from "@material-ui/lab/Skeleton";
import IconFinderCacheHandler from '../../IconFinderCacheHandler';

export const IconFinderOptions = (props) => {

    const [query, setQuery] = useState('');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useDelayCallback(() => {
        setIsLoading(true);
        let item = IconFinderCacheHandler.get(query);
        if(item) {
            setData(item);
            setIsLoading(false);
        } else {
            getIconFinderImages({query : query}).then(res => {
                setData(res.data.data.icons)
                setIsLoading(false);
                IconFinderCacheHandler.set(res.data.data.icons,query);
            });
        }
        
        
    },[query],500);

    const handleSetIcon = (link) => {
        props.onImageSelect(link);
        window.showNotification('SUCCESS','Icon set success');
    }
    
    const renderData = () => {
        return data.map(icon => {
            let iconPreview = null;
            for(let i = 0; i < icon.raster_sizes.length; i++) {
                if(icon.raster_sizes[i].size_width === 48) {
                    iconPreview = icon.raster_sizes[i].formats[0].preview_url;
                    break;
                }
            }
            if(iconPreview) {
                return <span onClick={() => handleSetIcon(iconPreview)}><img src={iconPreview} alt="preview"/></span> 
            } 
    
            return null;
        })
    }

    const loadingSkeleton = () => {
        let skeletons = [];
        for (let i = 0; i < 20; i++) {
            skeletons.push(
            <div
                key={i}
                className={`skeleton__each__icon__section`}
            >
            <Skeleton variant="rect" className="skeleton__each__icon"/>
            </div>
            );
        }
        return skeletons;
    };
    
    return (
        <>
            <div className='search-form-group'>
                <input placeholder='Search icon finder icon' onChange={e => setQuery(e.target.value)}/>
                <SearchIcon />
            </div>

            { isLoading && (
                <div className="icon__finder__skeleton__wr">
                    {loadingSkeleton()}
                </div>
            )}

            { data.length <= 0 && !isLoading &&  (
                <div className="no__icon__found__section">
                    <span>No Icon Found</span>
                </div>
            )}

            { data.length > 0 && !isLoading &&  (
                <div className='ifo-icon-wrapper awesome__scroll_bar'>
                    {renderData()}
                </div>
            )}
            
        </>
    )
}