import React, { useEffect, useState } from "react";
import { IconList } from "../../../../../../constants/IconList";
import Utils from "../../../../../../helpers/Utils";
import CanvaStartModal from "./canva/CanvaStartModal";
import PexelImageList from "./pexel/PexelImageList";
import { UploadImage } from "./UploadImage";

const canvaApiKey = "b39MbXGEYSY6mzq7Y87SPXcE";

const BackgroundUpload = (props) => {
  const [canvaApi, setCanvaApi] = useState("");
  const [hasInit, setHasInit] = useState(false);
  const [isOpenCanvaStartModal, setIsOpenCanvaStartModal] = useState(false);
  const [isOpenPexelStartModal, setIsOpenPexelStartModal] = useState(false);

  useEffect(() => {
    if (!hasInit) {
      (function (document, url) {
        let script = document.createElement("script");
        script.src = url;
        script.onload = function () {
          init();
        };
        document.body.appendChild(script);
      })(document, "https://sdk.canva.com/designbutton/v2/api.js");
    } else {
      createCanvaDesign();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const init = () => {
    (async function () {
      if (window.Canva && window.Canva.DesignButton) {
        if (!hasInit) {
          const canvaApi = await window.Canva.DesignButton.initialize({
            apiKey: canvaApiKey,
          });

          setHasInit(true);
          setCanvaApi(canvaApi);
        } else {
          createCanvaDesign();
        }
      }
    })();
  };

  const createCanvaDesign = () => {
    setIsOpenCanvaStartModal(false);
    canvaApi.createDesign({
      design: {
        type: "Poster",
        dimensions: {
          width: 595,
          height: 842,
          units: "px",
        },
      },
      editor: {
        fileType: "png",
      },
      onDesignOpen: ({ designId }) => {
        // Triggered when editor finishes loading and opens a new design.
        // You can save designId for future use.
      },
      onDesignPublish: ({ exportUrl, designId }) => {
        // Triggered when design is published to an image.
        // Save the image to your server as the exportUrl will expire shortly.
        props.setBackground({
          image : exportUrl
        });
      },
      onDesignClose: () => {
        // Triggered when editor is closed.
      },
    });
  };

  const drawCanva = (e) => {
    e.preventDefault();
    setIsOpenCanvaStartModal(true);
  };

  const closeDrawCanva = () => {
    setIsOpenCanvaStartModal(false);
  };

  return (
    <>
      {isOpenPexelStartModal ? (
        <PexelImageList backToMain={() => setIsOpenPexelStartModal(false)} setBackground={props.setBackground}/>
      ) : (
        <>
          <div className="file__upload__wrapper__v2__top">
            <span>{IconList.infoSvgIcon}</span>
            <p>
              Use Images with a 2*1 Ratio (minimum of 680px width, Supports any
              .jpg, .png file up to 10 MB in size.).
            </p>
          </div>
          <div className="file__upload__wrapper__v2">
            <div className="file__upload__signle__v2 pexel">
              <span> {IconList.pixelIcon}</span>
              <h4>Add background from</h4>
              <button
                className="pixel__for__upload__btn"
                onClick={() => setIsOpenPexelStartModal(true)}>
                Pexels
              </button>
            </div>

            <UploadImage onImageUpload={props.setBackground}/>

            {
              parseInt(Utils.getAccountData('agencyId')) === 1 &&
              <div className="file__upload__signle__v2 canva">
                <span>
                  <img
                    src={`https://d24gujj67p1uc1.cloudfront.net/asset/images/releases/1.0.0/81z01Q736F604c1aiq9R.png`}
                    className="mb-1"
                    width="45px"
                    alt=""
                  />
                </span>
                <h4>Design background in canva </h4>
                <button className="canva__for__upload__btn" onClick={drawCanva}>
                  Design a Canva
                </button>
            </div>

            }
            

            {isOpenCanvaStartModal && (
              <CanvaStartModal
                callback={createCanvaDesign}
                title={"Canva Integration Alert"}
                isOpen={isOpenCanvaStartModal}
                closeModal={closeDrawCanva}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default BackgroundUpload;
