import axios from "axios";
import { getCookie } from "../helpers/Cookie";

export const sendRequest = (method, url, payload = [], config = []) => {
    let data = [];
    let headers = [];
    try{
        payload.append('api-key', getCookie(process.env.REACT_APP_ACCESS_TOKEN))
        data = payload;
    } catch (error) {
        data = {...payload, ...{'api-key': getCookie(process.env.REACT_APP_ACCESS_TOKEN)}};
    }
    return axios.request({
        method: method,
        url: url,
        data: data,
        params: (method === 'get' || method === 'GET') ? data : null
    });
}

export const sendRequestBackEnd = (method, url, postData, apiKey) => {

    return fetch(url, {
      method: method,
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + apiKey,
  
      },
      referrerPolicy: 'origin',
      body: JSON.stringify(postData)
    }).then((response) => response.json()).then((responseData) => {
        return responseData
    }).catch(error=>{
        return false;
    })
}
