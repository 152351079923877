import React, { useEffect, useState } from 'react'
import Loading from '../../../Common/Loading';
import FormBuilderModal from '../FormBuilderModal/FormBuilderModal';
import If, {Else} from 'if-else-react';
import { useSimpleValidator } from '../../../../hooks/useSimpleValidator';
import { NativeSelect } from '@material-ui/core';
import { fetchStages, getFormSettingPageData } from '../../../../api/formBuilderApi';
import Utils from '../../../../helpers/Utils';
import CoreConstants from '../../../../constants/CoreConstants';
import CustomSwitch from '../../../Common/Switch';
import "./formSetting.css";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomForms, settingCustomFormSubmit } from '../../../../actions/customFormBuilderAction';

const FormSettingModal = (props) => {
    
    const dispatch = useDispatch();
    const simpleValidator = useSimpleValidator({
        url : "The :attribute must be a url. (Make sure you including 'https://')"
    });
    const { formDetails,formSetting } = useSelector(state => state.customFormBuilderReducer);

    const [loading, setLoading] = useState(false);
    const [formName, setFormName] = useState('');
    const [linkedCampaign, setLinkedCampaign] = useState('');
    const [linkedPipeline, setLinkedPipeline] = useState('');
    const [linkedStage, setLinkedStage] = useState('');
    const [campaigns, setCampaigns] = useState([]);
    const [pipelines, setPipelines] = useState([]);
    const [stages, setStages] = useState([]);
    const [actionAfterSubmitType, setActionAfterSubmitType] = useState();
    const [afterSubmitActionValue, setAfterSubmitActionValue] = useState(null);
    const [agencyBranding, setAgencyBranding] = useState("");
    const [updateExistingContact, setUpdateExistingContact] = useState("");
    const [updateAllowDuplicateSubmission, setUpdateAllowDuplicateSubmission] = useState();
    const [formStatus, setFormStatus] = useState();
    const [disabledMessage, setDisabledMessage] = useState();
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(formDetails !== null) {
            setFormName(formDetails.title);
            setFormStatus(formDetails.status);
            simpleValidator.purgeFields();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formDetails]);

    useEffect(() => {
        if(formSetting !== null) {
            setLinkedCampaign(formSetting.campaign_id ?? '');
            setLinkedPipeline(formSetting.pipeline_id ?? '');
            setLinkedStage(formSetting.stage_id ?? '');
            setActionAfterSubmitType(formSetting.action_after_submit_type);
            setAfterSubmitActionValue(formSetting.action_after_submit);
            setAgencyBranding(formSetting.agency_branding);
            setUpdateExistingContact(formSetting.update_duplicate_values);
            setUpdateAllowDuplicateSubmission(formSetting.is_duplicate_submission_allowed ?? 1);
            setDisabledMessage(formSetting.inactive_form_text);
            simpleValidator.purgeFields();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formSetting]);

    useEffect(() => {
        getFormSettingPageData({ pipelineId : formSetting.pipeline_id ?? '' }).then(response => {
            if (response.data.status) {
                setLoading(false);
                setCampaigns(response.data.data.campaigns);
                setPipelines(response.data.data.pipelines);
                setStages(response.data.data.stages);
            } else {
                Utils.showNotification(response.data.message);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const renderCampaigns = () => {
        if (campaigns.length) {
            return campaigns.map((campaign, i) => {
                // eslint-disable-next-line eqeqeq
                return (<option selected={campaign.id == linkedCampaign} value={campaign.id}>{ campaign.title }</option>);
            });
        } else {
            return <option value='' disabled>No Campaign Found</option>;
        }
    }

    const renderPipelines = () => {
        if (pipelines.length) {
            return pipelines.map((pipeline, i) => {
                // eslint-disable-next-line eqeqeq
                return (<option selected={pipeline.id == linkedPipeline} value={pipeline.id}>{ pipeline.title }</option>);
            });
        } else {
            return <option value='' disabled>No Pipeline Found</option>;
        }
    }

    const renderStages = () => {
        if (stages.length) {
            return stages.map((stages, i) => {
                // eslint-disable-next-line eqeqeq
                return (<option selected={stages.id == linkedStage} value={stages.id}>{ stages.stage }</option>);
            });
        } else {
            return <option value='' disabled>No Stage Found</option>;
        }
    }

    const handleLinkedPipelineChange = (event) => {
        setLinkedPipeline(event.target.value);

        if(event.target.value === "") {
            setStages([]);
            setLinkedStage("");
        } else {
            fetchStages({pipeline_id: event.target.value}).then(response => {
                if (response.data.status) {
                    setStages(response.data.data.stages);
                    setLinkedStage(response.data.data.stages[0] ? response.data.data.stages[0].id : "");
                } else {
                    Utils.showNotification(response.data.message);
                }
            });
        }
    }

    const handleActionAfterSubmitTypeChange = (event) => {
        setAfterSubmitActionValue('');
        setActionAfterSubmitType(event.target.value);
        simpleValidator.purgeFields();
    }

    const formSettingSubmitCallback = response => {
        setSaving(false);
        if (response.status === 'success') {
            Utils.showNotification(response.html, 'success');
            dispatch(fetchCustomForms());
            props.handleClose()
        } else {
            Utils.showNotification(response.html, 'error');
        }
    }

    const formSettingSubmit = (e) => {
        e.preventDefault();
        
        if(!simpleValidator.validate() || saving){
            return;
        }

        setSaving(true);

        dispatch(settingCustomFormSubmit({
            'form_id': formDetails.id,
            'title': formName,
            'campaign_id': linkedCampaign ? parseInt(linkedCampaign) : "",
            'pipeline_id': linkedPipeline ? parseInt(linkedPipeline) : "",
            'stage_id': linkedStage ? parseInt(linkedStage) : "",
            'form_submit_action_type': actionAfterSubmitType,
            'form_submit_action': afterSubmitActionValue,
            'status': formStatus,
            'inactive_form_text': disabledMessage,
            'agency_branding': agencyBranding,
            'update_duplicate_values': updateExistingContact,
            'is_duplicate_submission_allowed': updateAllowDuplicateSubmission,
        }, formSettingSubmitCallback));
    }

    return <FormBuilderModal
        open={props.open}
        onClose={props.handleClose}
        className={"form__builder__modal ar__form_builder form_builder_modal_settings_v2"}
      >
          <div className='setting_container form-settings-form-builder custom-form-setting w-100 px-5'>
            <If condition={loading}>
                <Loading size={50}/>
                <Else />
                <form className='form__modal_setting' action="/" onSubmit={e => e.preventDefault()}>
                    <div className="row">
                        <div className="input-field col s12">
                            <label className='active' htmlFor="from-title">Form Name <small>(Max : 100 characters)</small></label>
                            <input placeholder="Enter Form Name" id="from-title" type="text" value={formName} onChange={(e) => setFormName(e.target.value)}/>
                            {simpleValidator.message("form_name", formName, 'required|string|max:100')}
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12">
                            <label className='active'>Link to Campaign </label>
                            <NativeSelect
                                value={linkedCampaign}
                                name="selectName"
                                className="pp_setting_select"
                                onChange={(e) => setLinkedCampaign(e.target.value)}
                                inputProps={{ 'aria-label': 'selectName' }}
                            >
                                <option value={""}>Select Campaign</option>
                                {renderCampaigns()}
                            </NativeSelect> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s6">
                            <label className='active'>Link to Pipeline </label>
                            <NativeSelect
                                value={linkedPipeline}
                                name="selectName"
                                className="pp_setting_select"
                                onChange={handleLinkedPipelineChange}
                                inputProps={{ 'aria-label': 'selectName' }}
                            >
                                <option value="">Select Pipeline</option>
                                {renderPipelines()}
                            </NativeSelect>
                        </div>
                        <div className="input-field col s6">
                            <label className='active'>Link to Stage</label>
                            <NativeSelect
                                value={linkedStage}
                                disabled={linkedPipeline === ""}
                                name="selectName"
                                className="pp_setting_select"
                                onChange={(e) => setLinkedStage(e.target.value)}
                                inputProps={{ 'aria-label': 'selectName' }}
                            >
                                <option value="">Select Stage</option>
                                {renderStages()}
                            </NativeSelect>
                        </div>
                    </div>
                    <div className="row action-row">
                        <div className="input-field col s12">
                            <label className="pr-2 active">Action After Submit</label>
                            <NativeSelect
                                value={actionAfterSubmitType}
                                name="selectName"
                                className="pp_setting_select"
                                onChange={handleActionAfterSubmitTypeChange}
                                inputProps={{ 'aria-label': 'selectName' }}
                            >
                                <option value="2">Thank you message</option>
                                <option value="1">Redirect To Url</option>
                            </NativeSelect>
                            {simpleValidator.message('action_after_submit', actionAfterSubmitType, 'required')}
                        </div>
                        <div className="input-field col s12">
                            {/* eslint-disable-next-line eqeqeq */}
                            <input placeholder={actionAfterSubmitType == CoreConstants.MESSAGE_AFTER_SUBMIT ? "Enter thank you message" : "Enter redirect url"} id="after_submit" type="text" value={afterSubmitActionValue} onChange={(e) => {setAfterSubmitActionValue(e.target.value)}}/>
                            {/* eslint-disable-next-line eqeqeq */}
                            {actionAfterSubmitType == CoreConstants.MESSAGE_AFTER_SUBMIT ?
                                simpleValidator.message("greeting_message", afterSubmitActionValue, 'required|string')
                                :
                                simpleValidator.message("redirect_url", afterSubmitActionValue, 'required|url')
                            }
                        </div>
                    </div>
                    {/* <div className="row switcher-row"> */}
                        {/* <div className="input-field col s6 pp_switcher_field">
                            <label className="d-flex align-items-center active">
                                <span className="pr-2">Include Agency Branding</span>
                                <CustomSwitch 
                                    status={agencyBranding} 
                                    brandColor={"#f94f72"} 
                                    onChange={setAgencyBranding}
                                />
                            </label>
                        </div> */}
                        {/* <div className="input-field col s6 pp_switcher_field">
                            <label className="d-flex align-items-center active">
                                <span className="pr-2">Update Existing Contact's Values</span>
                                <CustomSwitch 
                                    status={updateExistingContact} 
                                    brandColor={"#f94f72"} 
                                    onChange={setUpdateExistingContact}
                                />
                            </label>
                        </div> */}
                    {/* </div> */}
                    <div className="row switcher-row">
                        <div className="input-field col s6 pp_switcher_field">
                            <label className="d-flex align-items-center active">
                                <span className="pr-2">Allow duplicate submission</span>
                                <CustomSwitch
                                    status={updateAllowDuplicateSubmission}
                                    brandColor={"#f94f72"}
                                    onChange={setUpdateAllowDuplicateSubmission}
                                />
                            </label>
                        </div>
                    </div>
                    <div className="row disable-row">
                        <div className="input-field col s12">
                            <label htmlFor="campaign-link" className='active'>Status </label>
                            <NativeSelect
                                value={formStatus}
                                name="selectName"
                                className="pp_setting_select"
                                onChange={(e) => setFormStatus(e.target.value)}
                                inputProps={{ 'aria-label': 'selectName' }}
                            >
                                <option value="1">Accept New Response</option>
                                <option value="0">Disabled</option>
                                <option value="2">Invisible</option>
                            </NativeSelect>
                            {simpleValidator.message('status', formStatus, 'required')}
                        </div>
                        {/* eslint-disable-next-line eqeqeq */}
                        <If condition={formStatus == CoreConstants.STATUS_DISABLE}>
                            <div className="input-field col s12">
                                <label className='active' htmlFor='disabled-message'>Show this when disable</label>
                                <textarea placeholder='Enter message' id="disabled-message" defaultValue={disabledMessage} onChange={(e) => setDisabledMessage(e.target.value)}/>
                            </div>
                        </If>
                    </div>
                    <div className="row button-row">
                        <button className="accent--bg--text--color cursor-pointer" onClick={formSettingSubmit}>{saving ? 'Saving...' : 'Save'}</button>
                    </div>
                </form>
            </If>
          </div>
      </FormBuilderModal>
}


export default FormSettingModal;