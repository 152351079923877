import { useRef,useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

export function useSimpleFormValidator(messages = {}) {
    const [update,forceUpdate] = useState(0);

    const simpleValidator = useRef(new SimpleReactValidator({
        className: 'small text-danger mdi mdi-alert pt-1 pl-1',
        autoForceUpdate: {forceUpdate: forceUpdate},
        messages : {...messages,url : "The :attribute must be a url. (Make sure you including 'https://')"},
    }));

    simpleValidator.current.validate = () => {
        if(!simpleValidator.current.allValid()) {
            simpleValidator.current.showMessages();
            forceUpdate(update+1);
            return false;
        }

        return true;
    }

    simpleValidator.current.addFormValidator = (type,name,value,config) => {
        let conditions = [];

        if (config.required.isEnable) {
            conditions.push("required");

            if (type === "email") {
                conditions.push("email");
            } else if (type === "phone") {
                conditions.push({ min: 11 });
                conditions.push({ max: 11 });
            } else if (type === "checkbox") {
                let totalNumberOfChecked = 0;
                value.forEach((element) => {
                    totalNumberOfChecked += element.isChecked ? 1 : 0;
                });
                value = totalNumberOfChecked;
                conditions.push("min:1,num");
            } else if (type === "url") {
                conditions.push("url");
            } else if (type === "number") {
                if(value === "-") {
                    value = "";
                }
            }
        }

        //Minimum character validation
        if (config.minCharacters?.isEnable) {
            conditions.push({ min: config.minCharacters.value });
        }

        //Maximum character validation
        if (config.maxCharacters?.isEnable) {
            conditions.push({ max: config.maxCharacters.value });
        }

        //Minimum number validation
        if (config.minNumber?.isEnable) {
            conditions.push(`min:${config.minNumber.value},num`);
        }

        //Maximum number validation
        if (config.maxNumber?.isEnable) {
            conditions.push(`max:${config.maxNumber.value},num`);
        }

        return simpleValidator.current.message(name, value, conditions);
    }

    return simpleValidator.current;
}