import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

const PreviewDefaultField = (props) => {
    return (
        !props.data.configuration.hideBlock.isEnable && (
          <div className="mb-3 singleItem">
            <div>
              <div>
                {props.data.type === "text" ? (
                  <FontAwesomeIcon icon={["fas", "align-left"]} />
                ) : props.data.type === "number" ? (
                  <FontAwesomeIcon icon={["fas", "phone"]} />
                ) : (
                  <FontAwesomeIcon icon={["fas", "at"]} />
                )}
              </div>
              <input
                placeholder={props.data.placeholder}
                id={props.data.id}
                name={props.data.id}
                type={props.data.type}
              />
            </div>
          </div>
        )
    );
}

export default PreviewDefaultField;
