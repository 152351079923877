/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IconList } from '../../constants/IconList';
import { DropdownHelper } from '../Common/DropdownHelper';
import { GlobalTable } from '../Common/Datatable/GlobalTable';
import {getFormEntries } from '../../api/formBuilderApi';
import {getFormSettingPageData } from '../../actions/formBuilderAction';
import EntryStatus from "./EntryStatus";
import EntryAction from "./EntryAction";
import './form_entries.css';
import {Tooltip} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import Utils from "../../helpers/Utils";
import ListSkeleton from "../Common/Skeletons/ListSkeleton";
import {If} from "react-if";

const FormEntries = (props) => {
    const history = useHistory();
    const [formEntries, setFormEntries] = useState([]);

    //Pagination
    const [totalFormEntries, setTotalFormEntries] = useState(0)
    const [itemPerPage, setItemPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState('')

    useEffect(() => {
        //update page title
        document.title = `Form Entries | ${Utils.getAccountData('AuthUserAgencyName')}`
        props.getFormSettingPageData({uniqueCode: props.match.params.uniqueCode});
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchFormEntries()
    }, [itemPerPage, page, search]) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchFormEntries = () => {
        getFormEntries({perPage : itemPerPage, searchKey: search, currentPage: page, uniqueCode: props.match.params.uniqueCode}).then(response => {
            setFormEntries(response.data.data)
            setTotalFormEntries(response.data.total)
            setLastPage(response.data.last_page)
            setLoading(false)
        });
    }

    const columns = [
        {
            name: 'Submitted By',
            cell: row => {
                let text = row['number'];
                if(row['first_name'] != null && row['last_name'] != null ) {
                    if ((row['first_name'] + ' ' + row['last_name']).trim().length > 0) {
                        text = row['first_name'] + ' ' + row['last_name']
                    }
                }else if(row['email'] != null && row['email'] !== '' ){
                    text = row['email'];
                }
                return <a href={`/contacts/${row.contact_id}`} onClick={(e) => {e.preventDefault();history.push(`/contacts/${row.contact_id}`);}}>{text}</a>
            },
        // {
        //         return ((row['contact']['first_name']+ ' ' +row['contact']['last_name']).length ? row['contact']['first_name']+ ' ' +row['contact']['last_name'] : row['contact']['email']);
        //     },
            // selector: row => ((row['contact']['first_name']+ ' ' +row['contact']['last_name']).length ? row['contact']['first_name']+ ' ' +row['contact']['last_name'] : row['contact']['email']),
            // selector: row => row['contact']['email'],
            minWidth: '250px',
            /*
            please do not remove those code
            cell: row => <button row={row} onClick={(event) => {
                console.log(event)
            }} >hello</button>,
            allowOverflow: true,
            button: true,
            width: '56px',
            grow: 2, //do not know this props
            ignoreRowClick: true
            right: true,
            center: true
            */
        },
        {
            name: 'Submitted At',
            sortable: false,
            minWidth: '250px',
            selector: row => {
                try {
                    return window.globalTimezoneConversionToDifferentTimezone(row['created_at'])
                } catch(e) {
                    return row['created_at'];
                }
            }
        },
        {
            name: 'Status',
            sortable: false,
            minWidth: '250px',
            cell: row => <EntryStatus entry={row} />,
        },
        {
            name: 'Message',
            sortable: false,
            minWidth: '250px',
            cell: row => <ol>
                <li>{row['comment']}</li>
                <If condition={row['campaign_id']}>
                    <li>{row['campaign_comment']}</li>
                </If>
            </ol>,
        },
        {
            name: 'Action',
            sortable: false,
            minWidth: '250px',
            // selector: row => row['number']
            selector: row => <EntryAction entry={row} fetchFormEntries={fetchFormEntries}/>,
        },
    ];

    // pagination
    const handlePaginationChange = (event, page) => {
        event.preventDefault()
        if(page != null){
            setPage(page)
        }
    }

    let previous_page = null, next_page = null;
    if(page > 1){
        previous_page = page - 1
    }

    // eslint-disable-next-line eqeqeq
    if(page != lastPage){
        next_page = page + 1
    }

    return (
        <div className="form_entry_container">
            <div className="section__top">
                <div className="section__top__left">
                    <h2>
                        Form Entries
                        {props.formDetails != null &&
                            <span> for {props.formDetails.title}</span>
                        }
                    </h2>
                </div>
                <div className="section__top__right justify-content-end">
                    <ul className="section__top__link">
                        <li>
                            <Tooltip title="Back to list">
                                <Link to={'/user/form-builder'}>{IconList.leftIcon}</Link>
                            </Tooltip>
                        </li>
                    </ul>
                    <div className="form_builder body-search">
                        <form action="#">
                            <input type="search" value={search} onChange={(event) => setSearch(event.target.value)} placeholder="Search" />
                            <button className="pr-2">{IconList.search}</button>
                        </form>
                    </div>
                </div>
            </div>

            <div className="container-fulid">
                <div className="datatable-area pp_form_entry_table_wrapper">
                    <div className="row datatable-scroll-row">
                        <div className="Drip_table pp_form_entry_table">
                            {loading ?
                                <div style={{background: '#ffffff', padding: '20px'}}>
                                    <ListSkeleton width={'100%'} number={10}/>
                                </div>
                                :
                                <GlobalTable
                                    table_unique_id={'drip_batch_contact_list'}
                                    columns={columns}
                                    data={formEntries}
                                    className="highlight"
                                    keyField={'id'}
                                    noDataComponent={(
                                        <span style={{ padding: 10, color: 'red' }}>No entries in the form!</span>
                                    )}
                                    progressPending={loading}
                                    defaultSortField="first_name"
                                    selectableRows={false}
                                    pagination={false}
                                    subHeader={false}
                                    noHeader={true}
                                />
                            }
                            </div>
                    </div>
                    <div className="datatable_helper">
                        {totalFormEntries > 0 &&
                        <div className="pagination_bar">
                            <ul>
                                <li>
                                    <a href="#!" onClick={(event) => handlePaginationChange(event, previous_page)}
                                        style={previous_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                                    >
                                        {IconList.previous}
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <span>{page}</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="d-flex align-items-center">
                                        <span>
                                            {IconList.backwardSlash}
                                        </span>
                                        {lastPage}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#" onClick={(event) => handlePaginationChange(event, next_page)}
                                        style={next_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                                    >
                                        {IconList.next}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        }
                        <div className="total_entries">
                            <small className="paginations_text">Total entries : {totalFormEntries}</small>
                        </div>
                        <div className="select-wrapper">
                            <DropdownHelper
                                datalist={[
                                    {value: 10, id: 10},
                                    {value: 25, id: 25},
                                    {value: 50, id: 50},
                                    {value: 100, id: 100},
                                ]}
                                className="second__step__area__select"
                                mapping={{ title: "value" }}
                                menuItemClassName=""
                                selectedValue={itemPerPage}
                                dropdownHeader={'ownerChange'}
                                parentDivClassName="ppc__select_owner"
                                updateSelectedData={(value) => setItemPerPage(value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateTProps = state => {
    return {
        formDetails: state.formBuilderReducer.formDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getFormSettingPageData: (params) => dispatch(getFormSettingPageData(params))
    }
}

export default connect(mapStateTProps, mapDispatchToProps)(FormEntries)