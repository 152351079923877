import React from "react";
import MultipleChoiceAction from "./MultipleChoiceAction";
import { v4 as uuidv4 } from "uuid";
import BootstrapTooltip from "../../BootstrapTooltip";
import { ChoiceIconPickModal } from "./ChoiceIconPickModal";

const alphabets = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

function MultipleChoice({ allInfo, fields, updateFields, index, updateOptionPlaceholder, label, updateLabel }) {

  let type = "choice";

  //Add a Choice
  const handleAddChoice = (id, i) => {
    let oldFields = [...fields];
    oldFields[i].choice.push({
      id: uuidv4(),
      choiceValue: "",
      placeholder: "",
    });
    updateFields(oldFields);
  };

  //Remove a Choice
  const handleRemoveChoice = (index, optionIndex) => {
    let oldFields = [...fields];
    oldFields[index].choice.splice(optionIndex, 1);
    updateFields(oldFields);
  };

  const handleChangeIcon = (index, optionIndex, link) => {
    let oldFields = [...fields];
    oldFields[index].choice[optionIndex].icon = link;
    updateFields(oldFields);
  }

  const renderchoices = () => {

    let labelColor = allInfo.configuration.labelColor ? allInfo.configuration.labelColor.value : "#333";
    let fontFamily = allInfo.configuration.fontFamily ? allInfo.configuration.fontFamily.value : "'Poppins', sans-serif";
    let fontSize = allInfo.configuration.fontSize ? allInfo.configuration.fontSize.value : 14;


    const setLabelColor = (element) => {
      if (element) {
         element.style.setProperty('color', labelColor, 'important');
         element.style.setProperty('font-family', fontFamily, 'important');
         element.style.setProperty('font-size', parseInt(fontSize)+"px", 'important');
      } 
    }

    return (
      <div className="ar__specific_input">
        <input
          ref={setLabelColor}
          type="text"
          defaultValue={label}
          placeholder={"Add label here"}
          onChange={(e) => {
            updateLabel(e.target.value, index);
          }}
        />
        {allInfo.configuration.required.isEnable && (
          <BootstrapTooltip arrow title="Required" placement="top">
            <span className="ar__req">*</span>
          </BootstrapTooltip>
        )}
        {fields[index].choice.map((choice, index) => {
          return (
            <div
              className="single-input-area my-2 inserted__fieldLevel mc-single-input-area"
              key={choice.id}
            >
              <p id="multiple-choice">{alphabets[index]}</p>
              <ChoiceIconPickModal onImageChange={(link) => handleChangeIcon(allInfo.index, index, link)}/>
              <input
                type="text"
                key={choice.id}
                placeholder={"option"}
                aria-label="multiple-choice"
                aria-describedby="multiple-choice"
                defaultValue={choice.placeholder}
                required={allInfo.configuration.required.isEnable}
                onChange={(e) => {
                  updateOptionPlaceholder(
                    e.target.value,
                    allInfo.index,
                    index,
                    type
                  );
                }}
              />
              <MultipleChoiceAction
                removeChoice={() => handleRemoveChoice(allInfo.index, index)}
                index={index}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {renderchoices()}
      <button
        className="ar__add_option"
        onClick={() => handleAddChoice(allInfo.id, allInfo.index)}
      >
        Add choice
      </button>
    </div>
  );
}

export default MultipleChoice;
