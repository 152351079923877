import React from "react";
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import { createGenerateClassName, StylesProvider } from "@material-ui/core";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fab, fas, far);

const defaultHistory = createBrowserHistory();

const generateClassName = createGenerateClassName({
  productionPrefix: "formBuilder__jss",
});

function App({ history = defaultHistory }) {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <Routes history={history}/>
    </StylesProvider>
  );
}

export default App;
