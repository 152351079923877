import React from 'react';

export const FontSizeSelectOptions = () => {
  return <>
    <option value={12} style={{fontSize : 12}}>12px</option>
    <option value={13} style={{fontSize : 13}}>13px</option>
    <option value={14} style={{fontSize : 14}}>14px</option>
    <option value={15} style={{fontSize : 15}}>15px</option>
    <option value={16} style={{fontSize : 16}}>16px</option>
    <option value={17} style={{fontSize : 17}}>17px</option>
    <option value={18} style={{fontSize : 18}}>18px</option>
  </>;
};
