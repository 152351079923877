import React from 'react';
import Slider from '@material-ui/core/Slider';
import { makeStyles, Typography, withStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
      width: 400,
    },
    margin: {
      height: theme.spacing(3),
    }
}));

const CustomSlider = withStyles({
    root: {
      color: '#fff',
      height: 8,
      margin : 15,
      width : "92%"
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
      '& *': {
        color: '#000',
        background: '#fff',
      },
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
    markLabel : {
        color : '#fff',
        marginTop : 5
    }
  })(Slider);

const valuetext = (value) => {
    return `${value} px`;
}

const calculateValue = (x) => {
    return (x * 3) + 100;
}

const calculateDecresedValue = (x) => {
    return (x - 100)/3;
}

const marks = [
    {
        value: 0,
        label: '100',
    },
    {
        value: 10,
        label: '130',
    },
    {
        value: 20,
        label: '160',
    },
    {
        value: 30,
        label: '190',
    },
    {
        value: 40,
        label: '220',
    },
    {
        value: 50,
        label: '250',
    },
    {
        value: 60,
        label: '280',
    },
    {
        value: 70,
        label: '310',
    },
    {
        value: 80,
        label: '340',
    },
    {
        value: 90,
        label: '370',
    },
    {
        value: 100,
        label: '400',
    },

];

export const HeightChangeSettingView = ({ isSettingEnabled, changeSetting, defaultSetting }) => {

    const classes = useStyles();

    if(!isSettingEnabled) {
        return "";
    }

    return <div className="cover_image__drag_info">
            <div className={classes.root}>
                <Typography id="discrete-slider-always">
                    Change the height
                </Typography>
                <p className='mb-4'><small>Only applicable to desktop devices</small></p>
                <CustomSlider
                    scale={(x) => calculateValue(x)}
                    defaultValue={calculateDecresedValue(defaultSetting)}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={10}
                    marks={marks}
                    valueLabelDisplay="on"
                    onChange={(e,value) => changeSetting(calculateValue(value))}
                />
                </div>
      </div>
};
