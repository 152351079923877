import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Thankyou.css";
import CoreConstants from "../../../../constants/CoreConstants";
import Loading from "../../../Common/Loading";
import { processCustomFormSubmit } from "../../NewFormUtils";
import { backendFormSubmit, publicUploadFile } from "../../../../api/customFormBuilderApi";
import Utils from "../../../../helpers/Utils";
const Thankyou = (props) => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(props.from !== "preview") {
      processFieldsAndSubmitData();
    } else {
      setLoading(false);
    }
  },[]);

  const processFieldsAndSubmitData = () => {
    uploadFilesToS3(props.submitFields).then(res => {
      let processedFields = processCustomFormSubmit(props.fields,res);
      backendFormSubmit({
        formId : props.formId,
        formRequest : processedFields
      },props.apiKey).then(res => {
        try{
          if(res.message.includes("Invalid phone number")) {
            window.showNotification("ERROR",res.message);
            props.onInvalidPhoneNumber(res.message);
          } else {
            setLoading(false);
          }
        } catch(e){
          setLoading(false);
          console.log(e);
        }
      });
    });
    
  }

  const uploadFilesToS3 = async (submitFields) => {
    let hasFile = false;
    let formData = new FormData();
    formData.append('uniqueCode',props.apiKey);

    for(let field in submitFields) {
      let value = submitFields[field];
      if(Array.isArray(value) && value.length > 0 && File.prototype.isPrototypeOf(value[0])) {
        hasFile = true;
        value.forEach(file => {
          formData.append('files[]',file);
        })
      }
    }

    if(hasFile) {
      await publicUploadFile(formData).then(res => {
        let data = res.data.data;
        let index = 0;
  
        for(let key in submitFields) {
          let value = submitFields[key];
          if(Array.isArray(value) && value.length > 0 && File.prototype.isPrototypeOf(value[0])) {
            let filesArray = [];
            for(let i = 0;i<value.length;i++) {
              filesArray.push(data[index++]);
            }
            submitFields[key] = filesArray;
          }
        }
      })
    }

    
    return submitFields;
  }

  if(loading) {
    return <Loading/>;
  }

  if(props.formSetting) {
    if(parseInt(props.formSetting.action_after_submit_type) === CoreConstants.MESSAGE_AFTER_SUBMIT) {
      return (
        <div className="thankyou-area">
          <FontAwesomeIcon icon={["fas", "check"]} />
          <h3>Thanks for completing this form!</h3>
          <p>{props.formSetting.action_after_submit}</p>
          <a href="#!" onClick={e => {e.preventDefault();window.location.reload()}} className="btn btn-secondary">
            Try this again
          </a>
        </div>
      );
    } else if(parseInt(props.formSetting.action_after_submit_type) === CoreConstants.REDIRECT_AFTER_SUBMIT){
      //Checking inside iframe
      if(Utils.inIframe()) {
        try {
          window.top.location.href = props.formSetting.action_after_submit; 
        } catch (e) {
          window.location.href = props.formSetting.action_after_submit;
        }
      } else {
        window.location.href = props.formSetting.action_after_submit;
      }
    }
  }

  return "";

}

export default Thankyou;
