import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as FORM_BUILDER_API from '../api/formBuilderApi';
import * as ACTION from '../constants/formBuilderActionTypes'
import Utils from "../helpers/Utils";

function* formBuilderWatcher() {
    yield takeEvery(ACTION.FETCH_FORMS, fetchFormsMiddleware);
    yield takeEvery(ACTION.FETCH_FORM_DETAIL, fetchFormDetailMiddleware);
    yield takeEvery(ACTION.SUBMIT_SETTING_FORM, settingFormSubmitMiddleware);
    yield takeEvery(ACTION.SUBMIT_STYLE_FORM, styleFormSubmitMiddleware);
    yield takeEvery(ACTION.UPDATE_FORM_SUBMIT, updateFormMiddleware);
    yield takeEvery(ACTION.FETCH_FORM_CATEGORIES, fetchFormCategoryMiddleware);
    yield takeEvery(ACTION.FETCH_FORM_SETTING_PAGE_DATA, fetchFormSettingPageDataMiddleware);
}

function* fetchFormsMiddleware(action) {
    try {
        const response = yield call(FORM_BUILDER_API.fetchFormList, action.payload);
        let responseData = response.data.html;
        yield put({type: ACTION.STORE_FORMS, payload: responseData});
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchFormDetailMiddleware (action) {
    const {payload} = action;
    try {
        const response = yield call(FORM_BUILDER_API.fetchFormDetail, payload.payload);
        let responseData = response.data.data;
        yield put({type: ACTION.STORE_FORM_DETAIL, payload: responseData});
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* settingFormSubmitMiddleware (action) {
    const {payload} = action;
    try {
        const response = yield call(FORM_BUILDER_API.settingFormSubmit, payload.payload);
        if (response.data.status === 'success') {
            yield put({type: ACTION.UPDATE_SETTING_DETAILS, payload: payload.payload});
        }
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* styleFormSubmitMiddleware (action) {
    const {payload} = action;
    try {
        const response = yield call(FORM_BUILDER_API.styleFormSubmit, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updateFormMiddleware (action) {
    const {payload} = action;
    try {
        const response = yield call(FORM_BUILDER_API.updateForm, payload.payload);
        if (response.data.status === 'success') {
            yield put({type: ACTION.UPDATE_FORM, payload: payload.payload});
        }
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchFormCategoryMiddleware (action) {
    const {payload} = action;
    try {
        const response = yield call(FORM_BUILDER_API.fetchFormCategories, payload.payload);
        if (response.data.status) {
            yield put({type: ACTION.STORE_FORM_CATEGORIES, payload: response.data});
        }
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchFormSettingPageDataMiddleware (action) {
    try {
        const response = yield call(FORM_BUILDER_API.getFormSettingPageData, action.payload);
        if (response.data.status) {
            yield put({type: ACTION.STORE_FORM_SETTING_PAGE_DATA, payload: response.data});
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* formBuilderMiddleware() {
    yield all([
        formBuilderWatcher(),
    ])
}
