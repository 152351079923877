import React, { useState } from "react";
import { useEffect } from "react";
import { fetchSubmissionResultCustomForm } from "../../../api/customFormBuilderApi";
import Utils from "../../../helpers/Utils";
import GlobalModal from "../../Common/Modal/GlobalModal";
import Chip from '@material-ui/core/Chip';
import { InsertDriveFileOutlined } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { getProcessFieldValueForPreview } from "../NewFormUtils";

const CustomFields = (props) => {
  const [entryDetails, setEntryDetails] = useState(null);
  const { fields } = useSelector((state) => JSON.parse(state.formBuilderReducer.formDetails.builder_json));

  useEffect(() => {
    fetchSubmissionResultCustomForm({ entryId: props.entry.id }).then(
      (response) => {
        if (response.data.status) {
          setEntryDetails(response.data.entry);
        } else {
          Utils.showNotification(response.data.html, "error");
        }
      }
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderFormEntriesNew = () => {

    if (entryDetails === null) {
      return "";
    }

    let jsonObj = JSON.parse(entryDetails.entries);
    let fileCountObj = {};    //This is for cotain the count of files under uploadedFiles obj

    return fields.map(field => {

      let fileCount = 0;
      //File label can be multiple so fileupload object contains array.
      if(field.type === 'fileUpload') {
        let label = field.label !== null ? field.label : 'fileUpload';
        fileCount = fileCountObj[label] === undefined ? fileCountObj[label] = 0 : ++fileCountObj[label];
      }

      let obj = getProcessFieldValueForPreview(field,jsonObj,fileCount);
      if(obj) {
        return <div className="row mb-0">
          <div className="input-field col s12 mb-0">
            <label htmlFor="from-title">{obj.label}</label>
            {
              obj.isCustomField &&
              <Chip variant="outlined" color="secondary" size="small" label="Custom Field" className="custom__field__chip"/>
            }
            {
              obj.isFile &&
              <Chip variant="outlined" color="secondary" size="small" label="File" className="custom__field__chip"/>
            }
            {
              obj.isFile ? 
              <a className="custom_form__file_entry" href={obj.url} target="_blank" rel="noreferrer"><InsertDriveFileOutlined/> {obj.name}</a> :
              <input readOnly type="text" value={obj.value} />
            }
          </div>
        </div>
      }

      return null;
    })
  }

  return (
    <>
      <GlobalModal
        open={props.openViewSubmission}
        onClose={() => props.setOpenViewSubmission(false)}
        title={"Submission"}
      >
        <form action="/">{renderFormEntriesNew()}</form>
      </GlobalModal>
    </>
  );
};

export default CustomFields;
