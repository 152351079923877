import * as ACTION from "../constants/formBuilderActionTypes";

const initialState = {
    isLoadedCustomFields : false,
    customFields : []
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.STORE_CUSTOM_FIELDS:
            return {...state, customFields: action.payload, isLoadedCustomFields: true}
        default:
            return state;
    }
}

export default commonReducer;
