import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react'
import BootstrapTooltip from '../../BootstrapTooltip';

const PreviewRadio = (props) => {

    const labelColor = props.data.configuration.labelColor ? props.data.configuration.labelColor.value : "#333";
    const fontFamily = props.data.configuration.fontFamily ? props.data.configuration.fontFamily.value : "'Poppins', sans-serif";
    const fontSize = props.data.configuration.fontSize ? props.data.configuration.fontSize.value : 14;



    const handleChange = (value) => {
        let temPreviewValue = { ...props.previewValue };
        temPreviewValue[props.data.id] = value;
        props.setPreviewValue(temPreviewValue);
    };


    return (
      !props.data.configuration.hideBlock.isEnable && (
        <FormControl
          className="ar__form_global_control"
          component="fieldset"
          key={props.data.id}
        >
          <FormLabel component="legend" style={{color : labelColor, fontFamily : fontFamily, fontSize : parseInt(fontSize)}}>
            {props.data.label}
            {props.data.configuration.required.isEnable && (
              <BootstrapTooltip
                arrow
                title="Required"
                placement="top"
              >
                <span className="ar__req">*</span>
              </BootstrapTooltip>
            )}
          </FormLabel>
          <RadioGroup aria-label="radio" name="radio" value={props.previewValue[props.data.id]}>
            {props.data.radio.map((label, i) => {
              return (
                <FormControlLabel
                  key={label.id}
                  name={props.data.id}
                  value={label.placeholder}
                  onChange={() =>
                    handleChange(label.placeholder)
                  }
                  control={<Radio />}
                  label={label.placeholder}
                />
              );
            })}
            {props.simpleValidator.addFormValidator(
              props.data.component,
              props.data.label === null ? "" : props.data.label, //Validator genrates error if label null,
              props.previewValue[props.data.id],
              props.data.configuration
            )}
          </RadioGroup>
        </FormControl>
      )
    );
}


export default PreviewRadio;