import React from 'react'
import MaskedInput from 'react-text-mask';
import Utils from '../../../../helpers/Utils';
import BootstrapTooltip from '../../BootstrapTooltip';
import formIcons from '../AddModal/FormIcons';

const PreviewPhoneNumberInput = (props) => {

    const labelColor = props.data.configuration.labelColor ? props.data.configuration.labelColor.value : "#333";
    const fontFamily = props.data.configuration.fontFamily ? props.data.configuration.fontFamily.value : "'Poppins', sans-serif";
    const fontSize = props.data.configuration.fontSize ? props.data.configuration.fontSize.value : 14;


    const changePhoneNumber = (e) => {
      if(props.invalidMessage) {
        props.clearInvalidMessage();
      }
      let value = e.target.value
      if(Utils.checkLetterExistInText(value) || value === ''){
        if(value.length > 0 && value[0] !== '1'){
            value= 1+value;
        }
        props.setPreviewValue({
          ...props.previewValue,
          [e.target.name]: value,
        });
      } else {
        try{
            value = value.replace(/[^0-9]+/g, '')
            if(value.length > 0 && value[0] !== '1'){
                value= 1+value;
            }
            props.setPreviewValue({
              ...props.previewValue,
              [e.target.name]: value,
            });
        }
        catch(e){
            console.log(e)
        }
      }
    }

    return (
        !props.data.configuration.hideBlock.isEnable && (
          <div className="mb-3 singleItem" key={props.data.id}>
            <h5 style={{color : labelColor, fontFamily : fontFamily, fontSize : parseInt(fontSize) }}>{props.data.label}</h5>
            <div>
              { formIcons[props.data.type] }
              <MaskedInput
                  mask={ ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  className='ml-1'
                  name={props.data.id}
                  placeholder={props.data.placeholder}
                  guide={true}
                  keepCharPositions={true}
                  id={props.data.id}
                  onChange={changePhoneNumber}
                  value={props.previewValue[props.data.id]}
                  onKeyDown={(e) => e.key === 'Enter' && props.onEnterPress(e)}
              />
              {props.data.configuration.required.isEnable && (
                <BootstrapTooltip
                  arrow
                  title="Required"
                  placement="top"
                >
                  <span className="ar__req">*</span>
                </BootstrapTooltip>
              )}
              
              { props.simpleValidator.messageWhenPresent(props.invalidMessage) }

              { props.simpleValidator.addFormValidator(
                props.data.component,
                props.data.label,
                props.previewValue[props.data.id],
                props.data.configuration
              )}
            </div>
          </div>
        )
      );
}

export default PreviewPhoneNumberInput;