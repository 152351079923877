import React from "react";

const PexelImage = (props) => {
  const setPexelBackground = (url) => {
    props.setBackground({
      image : url
    });
    window.showNotification("SUCCESS","Background image set successfully");
  }
  return (
    <div key={props.eachImage.id} className="form__pexel__image__section">
      <img className="form__pexel__image" src={props.eachImage.src.small} alt={props.eachImage.id} onClick={()=>setPexelBackground(props.eachImage.src.original)}/>
    </div>
  );
};

export default PexelImage;
