import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Button } from "react-bootstrap";
import Button from "@material-ui/core/Button";

function MultipleChoiceAction({ index, isButton, removeChoice }) {
  return (
    <div className="col-lg-2 action-buttons">
      {!isButton && (
        <Button variant="light" onClick={() => removeChoice(index)}>
          <FontAwesomeIcon icon={["far", "trash-alt"]} />
        </Button>
      )}
    </div>
  );
}

export default MultipleChoiceAction;
