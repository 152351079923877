import { getType } from "../components/FormBuilderNew/Type";
import * as ACTION from "../constants/formBuilderActionTypes";

const initialState = {
    forms: undefined,
    loading: true,
    formDetails: null,
    formSetting: null,
    agencyLogo: null,
    builderJson : null,
    saveRequired : false,
    fields : []
};

const customFormBuilderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.STORE_CUSTOM_FORMS:
            return {...state, forms: action.payload, loading: false}
        case ACTION.ADD_CUSTOM_FORMS:
            let newFormList = state.forms;
            newFormList.unshift(action.payload);
            return {...state, forms: newFormList}
        case ACTION.STORE_CUSTOM_FORM_DETAIL:
            return {...state, 
                formDetails: action.payload.form, 
                formSetting: action.payload.formSetting, 
                agencyLogo: action.payload.agencyLogo,
                builderJson : action.payload.builderJson,
                fields : action.payload.builderJson.fields === "" ? [getType()] : action.payload.builderJson.fields
            }
        case ACTION.UPDATE_CUSTOM_FORM_SETTING_DETAILS:
            return {
                ...state,
                formDetails: {
                    ...state.formDetails,
                    title: action.payload.title,
                    status: action.payload.status
                },
                formSetting: {
                    ...state.formSetting,
                    'campaign_id': action.payload.campaign_id,
                    'pipeline_id': action.payload.pipeline_id,
                    'stage_id': action.payload.stage_id,
                    'action_after_submit_type': action.payload.form_submit_action_type,
                    'action_after_submit': action.payload.form_submit_action,
                    'agency_branding': action.payload.agency_branding,
                    'update_duplicate_values': action.payload.update_duplicate_values,
                    'inactive_form_text': action.payload.inactive_form_text,
                    'is_duplicate_submission_allowed': action.payload.is_duplicate_submission_allowed,
                },
            }
        case ACTION.SAVE_BUTTON_ENABLE_DISABLE : 
            return {
                ...state,
                saveRequired : action.payload
            }
        case ACTION.SET_CUSTOM_FORM_BUILDER_VALUE : 
            return {
                ...state,
                builderJson : {
                    ...state.builderJson,
                    [action.payload.key] : action.payload.value
                }
            }
        case ACTION.CUSTOM_FORM_BUILDER_FIELD_UPDATE : 
            return {
                ...state,
                saveRequired : true,
                fields : action.payload
            }
        case ACTION.CUSTOM_FORM_EDIT_RESET : 
            return {
                ...state,
                saveRequired : false,
                formDetails : null,
                formSetting : null,
                agencyLogo : null,
                builderJson : null,
                fields : []
            }
        default:
            return state;
    }
}

export default customFormBuilderReducer;
