import {Link} from "react-router-dom";
import {IconList} from "../../constants/IconList";
import BrushIcon from '@material-ui/icons/Brush';
import React, {useState} from "react";
import {connect} from "react-redux";
import {Tooltip} from "@material-ui/core";
import FormIntegrate from "../FormBuilder/FormIntegrate";
import FormStyle from "./FormStyle";
import FormSetting from "./FormSetting";
import {If} from "react-if";
import Utils from "../../helpers/Utils";
import ActionControl from "../Common/ActionControl/ActionControl";

const SectionTop = props => {
    const isTempUser = Utils.getAccountData('isTemplateUser');
    const [openIntegrateModal, setOpenIntegrateModal] = useState(false);
    const [openStyleModal, setOpenStyleModal] = useState(false);
    const [openSettingModal, setOpenSettingModal] = useState(false);
    return (
        <div className="section__top">
            <div className="section__top__left">
                <h2>{ props.formDetails.title }</h2>
            </div>
            <div className="section__top__right justify-content-end d-flex">
                <ul className="section__top__link">
                    <li>
                        <Tooltip title="Back to list">
                            <Link to={'/user/form-builder'}>{IconList.leftIcon}</Link>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Form Entry">
                            <Link to={`/user/form-entries/${props.formDetails.unique_id}`}>{IconList.newsPaperIcon}</Link>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Form Style">
                            <a href='#' onClick={(e) => {e.preventDefault();setOpenStyleModal(true);}}>
                                <BrushIcon color={'action'}/>
                            </a>
                        </Tooltip>
                        <If condition={openStyleModal}>
                            <FormStyle form={props.formDetails} openStyleModal={openStyleModal} setOpenStyleModal={setOpenStyleModal}/>
                        </If>
                    </li>
                    <li>
                        <Tooltip title="Form Setting">
                            <a href='#' onClick={(e) => {e.preventDefault();setOpenSettingModal(true);}}>
                                {IconList.sttingsIcon}
                            </a>
                        </Tooltip>
                        <If condition={openSettingModal}>
                            <FormSetting openSettingModal={openSettingModal} setOpenSettingModal={setOpenSettingModal}/>
                        </If>
                    </li>
                    <li>
                        <ActionControl alertMessage="Form builder feature is not available for template user" status="error"  isDisabled={isTempUser == 1}>
                            <Tooltip title="Form Integrate">
                                <a href='#' onClick={(e) => {e.preventDefault();setOpenIntegrateModal(true);}}>
                                    {IconList.cubeIcon}
                                </a>
                            </Tooltip>
                            <If condition={openIntegrateModal}>
                                <FormIntegrate className="global-xs-modal" form={props.formDetails} openIntegrateModal={openIntegrateModal} setOpenIntegrateModal={setOpenIntegrateModal}/>
                            </If>
                        </ActionControl>
                    </li>
                </ul>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        formDetails: state.formBuilderReducer.formDetails
    }
}


const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionTop);