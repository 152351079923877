import * as ACTION from "../constants/formBuilderActionTypes";

export const fetchCustomForms = (payload, callback) => ({
    type: ACTION.FETCH_CUSTOM_FORMS,
    payload: {payload, callback}
});

export const addCustomForms = payload => ({
    type: ACTION.ADD_CUSTOM_FORMS,
    payload: payload
});

export const fetchCustomFormDetails = (payload, callback) => ({
    type: ACTION.FETCH_CUSTOM_FORM_DETAIL,
    payload: {payload, callback}
});

export const settingCustomFormSubmit = (payload, callback) => ({
    type: ACTION.SUBMIT_SETTING_CUSTOM_FORM,
    payload: {payload, callback}
});

export const setCustomFormSaveVisible = (payload) => ({
    type: ACTION.SAVE_BUTTON_ENABLE_DISABLE,
    payload: payload
});

export const setCustomFormBuilderValue = (payload) => ({
    type: ACTION.SET_CUSTOM_FORM_BUILDER_VALUE,
    payload: payload
});

export const customFormSave = (callback) => ({
    type: ACTION.UPDATE_CUSTOM_FORM_SUBMIT,
    payload: {callback}
});

export const customFormFieldUpdate = (payload) => ({
    type: ACTION.CUSTOM_FORM_BUILDER_FIELD_UPDATE,
    payload: payload
});

export const customFromEditReset = (payload) => ({
    type: ACTION.CUSTOM_FORM_EDIT_RESET,
    payload: payload
});