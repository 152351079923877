import { Checkbox, FormControl, FormControlLabel, FormLabel } from '@material-ui/core';
import React from 'react'
import BootstrapTooltip from '../../BootstrapTooltip';

const PreviewCheckbox = (props) => {

    const labelColor = props.data.configuration.labelColor ? props.data.configuration.labelColor.value : "#333";
    const fontFamily = props.data.configuration.fontFamily ? props.data.configuration.fontFamily.value : "'Poppins', sans-serif";
    const fontSize = props.data.configuration.fontSize ? props.data.configuration.fontSize.value : 14;


    return (
        <FormControl className="ar__form_global_control ar__cb">
            <FormLabel component="legend" style={{color : labelColor, fontFamily : fontFamily, fontSize : parseInt(fontSize)}}>
                {props.data.label}
                {props.data.configuration.required.isEnable && (
                <BootstrapTooltip
                    arrow
                    title="Required"
                    placement="top"
                >
                    <span className="ar__req">*</span>
                </BootstrapTooltip>
                )}
            </FormLabel>
            { props.data.checkbox.map((option, optionIndex) => {
                const handleChangeCheckbox = (event) => {
                    let tempPreviewValue = { ...props.previewValue };
                    tempPreviewValue[props.data.id][optionIndex].isChecked = !tempPreviewValue[props.data.id][optionIndex].isChecked;
                    props.setPreviewValue(tempPreviewValue);
                };

                return (
                !props.data.configuration.hideBlock.isEnable && (
                    <>
                    <FormControlLabel
                        className="ar__flex_column"
                        key={option.id}
                        control={
                        <Checkbox
                            checked={
                            props.previewValue[props.data.id][optionIndex]
                                .isChecked
                            }
                            onChange={handleChangeCheckbox}
                            name={props.previewValue[props.data.id][optionIndex].id}
                            color="primary"
                        />
                        }
                        label={
                        <>
                            <p>
                            {props.data.checkbox[optionIndex].placeholder}
                            </p>
                        </>
                        }
                    />
                    </>
                )
                );
            })}
            {props.simpleValidator.addFormValidator(
                props.data.component,
                props.data.label === null ? "" : props.data.label, //Validator genrates error if label null
                props.previewValue[props.data.id],
                props.data.configuration
            )}
        </FormControl>
      );
}

export default PreviewCheckbox;
