import { FormControl, FormLabel, InputLabel, makeStyles, MenuItem, Select, withStyles } from '@material-ui/core';
import React from 'react'
import { DROPDOWN_BOTTOM_SETUP } from '../../../../constants/CoreConstants';
import BootstrapTooltip from '../../BootstrapTooltip';
import InputBase from '@material-ui/core/InputBase';


const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);



const PreviewDropdown = (props) => {

    const labelColor = props.data.configuration.labelColor ? props.data.configuration.labelColor.value : "#333";
    const fontFamily = props.data.configuration.fontFamily ? props.data.configuration.fontFamily.value : "'Poppins', sans-serif";
    const fontSize = props.data.configuration.fontSize ? props.data.configuration.fontSize.value : 14;

    return (
        !props.data.configuration.hideBlock.isEnable && (
          <FormControl
            className={`ar__form_global_control`}
          >
            <div className='singleItem dropdown_block'>
              <h5 style={{color : labelColor,  fontFamily : fontFamily, fontSize : parseInt(fontSize)}}>{props.data.label}</h5>
              {props.data.configuration.required.isEnable && (
                  <BootstrapTooltip
                    arrow
                    title="Required"
                    placement="top"
                  >
                    <span className="ar__req">*</span>
                  </BootstrapTooltip>
              )}
            </div>
            

            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={props.previewValue[props.data.id]}
              MenuProps={DROPDOWN_BOTTOM_SETUP}
              input={<BootstrapInput/>}
            >
              { props.data.dropdown.map((label, index) => {
                const handleChange = () => {
                  let tempPreviewValue = { ...props.previewValue };
                  tempPreviewValue[props.data.id] = label.placeholder;
                  props.setPreviewValue(tempPreviewValue);
                };
                return (
                  <MenuItem
                    key={label.id}
                    value={label.placeholder}
                    onClick={handleChange}
                  >
                    {label.placeholder}
                  </MenuItem>
                );
              })}
            </Select>

            {props.simpleValidator.addFormValidator(
              props.data.component,
              props.data.label === null ? "" : props.data.label, //Validator genrates error if label null,
              props.previewValue[props.data.id],
              props.data.configuration
            )}
          </FormControl>
        )
    );
}

export default PreviewDropdown;
