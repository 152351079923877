import React, { useEffect, useMemo, useState } from "react";
import "./ModalAdd.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogicalDemoContent from "../LogicalDemoContent/LogicalDemoContent";
import { InputBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormBuilderModal from "../FormBuilderModal/FormBuilderModal";
import ModalMenu, { getCustomFormTypes } from "./ModalMenu";
import Utils from "../../../../helpers/Utils";
import { useSelector } from "react-redux";
import formIcons from "./FormIcons";


const initialData = (
  <>
    <div className="initial__content__logical__demo__content">
      <span className="initial__content__logical__demo__add">
        <FontAwesomeIcon icon={["fas", "plus"]} />
      </span>

      <h5>Choose input field type from the left side.</h5>
    </div>
  </>
);

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: "4px",
    width: "100%",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    backgroundColor: "#fff",

    "& .MuiInputBase-root": {
      width: "100%",
      "& .MuiInputBase-input": {
        paddingTop: "16px",
        paddingLeft: "42px",
        background: "#fff",
        margin: "0",
      },
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(136, 136, 136)",
    zIndex: "2",
  },
}));

const ModalAdd = (props) => {

  const classes = useStyles();
  const { show, handleClose, addInputField, modalData, addNewPage } = props;
  const [rightContent, setRightContent] = useState();
  const [activeType, setActiveType] = useState("");
  const [query,setQuery] = useState("");
  const { customFields } = useSelector(state => state.commonReducer);

  useEffect(() => {
    setRightContent(initialData);
    setActiveType("");
  }, [show,query]);

  useEffect(() => {
    setQuery('');
  },[show])


  const handleButtonType = (newField) => {

    let newFieldData = {
      ...modalData,
      newFieldType: newField.type,
      newFieldName: newField.name,
      newComponent: newField.component,
      newLabel: newField.label,
      newConfiguration: newField.config,
      newDefaultValues : newField.defaultValues
    };

    if(newField.customFieldId) {
      newFieldData.customFieldId = newField.customFieldId;
    }

    if(newField.customFieldValues) {
      newFieldData.customFieldValues = newField.customFieldValues;
    }

    setRightContent(
      <LogicalDemoContent
        newFieldData={newFieldData}
        addInputField={addInputField}
        addNewPage={addNewPage}
      />
    );
    setActiveType(newField.name);
  };

  const getCustomFieldsOptions = () => {
    let blockMenus = [];
    customFields.forEach((field,index) => {
      if(query === '' || field.title.match(new RegExp(Utils.escapeRegex(query), "gi"))) {
        //Pushing each fields to blocks
        blockMenus.push(<li
          key={index} 
          className={`form__builder__modal__sidebar__single__li ${activeType === "custom_field_"+field.id ? "active" : ""}`}
          onClick={() => handleButtonType({...getCustomFormTypes(field),name : "custom_field_"+field.id})}
          >
            { formIcons.customFieldIcon } {field.title}
          </li>);
      }
    })
    return blockMenus;
  }

  const renderOptions = () => {
    let renderOptions = [];

    ModalMenu.forEach((eachBlock,eachBlockIndex) => {
      let blockMenus = [];
      eachBlock.menus.forEach((eachMenu,eachMenuIndex) => {
        if(query === '' || eachMenu.menu.match(new RegExp(Utils.escapeRegex(query), "gi"))) {
          //Pushing each menus to blocks
          blockMenus.push(<li
              key={eachMenuIndex} 
              className={`form__builder__modal__sidebar__single__li ${activeType === eachMenu.name ? "active" : ""}`}
              onClick={() => handleButtonType(eachMenu)}>
                { eachMenu.icon } {eachMenu.menu}
              </li>);
        }
      });

      if(blockMenus.length > 0) {
        //Pushing to each block
        renderOptions.push(
            <div className="form__builder__modal__sidebar__single__wr" key={eachBlockIndex}>
              <h3 className="subtitle">{eachBlock.name}</h3>
              <ul className="form__builder__modal__sidebar__single__ul">
                { blockMenus }
              </ul>
            </div>
        );
      }
    });

    //Adding custom fields blocks at second index
    if(customFields.length > 0) {

      let customFieldsOptions = getCustomFieldsOptions();

      renderOptions.splice(1, 0,
        <div className="form__builder__modal__sidebar__single__wr" key="__custom_fields">
          <h3 className="subtitle">{customFieldsOptions.length !== 0 && "Custom Fields"}</h3>
          <ul className="form__builder__modal__sidebar__single__ul">
            { customFieldsOptions }
          </ul>
        </div>);
    }

    return renderOptions;
  };

  return (
    <>
      <FormBuilderModal
        open={show}
        onClose={handleClose}
        className={"form__builder__modal ar__form_builder"}
      >
        <div className="form__builder__modal__add__search__bar">
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              {/* <SearchIcon /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </div>
            <InputBase
              placeholder="Find questions, input fields and layout options.."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
        </div>
        <div className="display__input__field__modal__wr">
          <div className="display__input__field__modal__left">
            <div className="form__builder__modal__sidebar">
              { renderOptions() }
            </div>
          </div>

          <div className="logical__demo__content">
            {rightContent && rightContent}
          </div>
        </div>
      </FormBuilderModal>
    </>
  );
}

export default ModalAdd;
